import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    AclPermissions,
    AttributeId,
    BasicMessageDto,
    CreateMeasureRequestBody,
    ErrorConstantKeys,
    FlatMeasureDto,
    MeasureAIExperimentListDto,
    MeasureDto,
    MeasureStatus,
    MeasureUpdate,
} from "api-shared";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { AppState } from "../../infrastructure/store";
import { apiGet, apiPatch, apiPost } from "../../lib/api";
import { MeasureField } from "../../lib/fields";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";
import { ActionType } from "../../types/helpers";
import { Endpoint, EndpointQueryKeys } from "../endpoint";
import { NotificationQueryKeys } from "../measure-notifications";
import { processHistoryKeys } from "../process-history";
import { ProcessPulseQueryKeys } from "../process-pulse";
import { ReportingQueryKeys } from "../reporting";
import { isActiveUser, useCurrentUserId, useUser } from "../users";
import { MeasureListKeys } from "./list";
import {
    MeasurePermissionsQueryKeys,
    useIsUserMeasureEditor,
    useIsUserMeasureViewer,
    useUserHasMeasureStatusPermissionQuery,
} from "./permission";
import { ViewedMeasuresKeys } from "./views";

export const RESET_CURRENT_MEASURE = "RESET_CURRENT_MEASURE";

export const FETCH_MEASURE_SUCCEEDED = "FETCH_MEASURE_SUCCEEDED";

export const UPDATE_MEASURE_SUCCEEDED = "UPDATE_MEASURE_SUCCEEDED";

export const CREATE_MEASURE_SUCCEEDED = "CREATE_MEASURE_SUCCEEDED";

export const resetCurrentMeasureAction = () => ({ type: RESET_CURRENT_MEASURE });

export const MEASURES_PATH = "measures";

export const MeasureDetailQueryKeys = {
    all: [MEASURES_PATH, "detail"] as const,
    byId: (id: number) => [...MeasureDetailQueryKeys.all, id] as const,
    byToken: (token: string) => [...MeasureDetailQueryKeys.all, token] as const,
    aiExperiments: (measureId: number) => [`${MEASURES_PATH}/${measureId}/ai/axperiments`],
};

export const useMeasureQuery = <TSelect = MeasureDto>(
    id: number | undefined,
    select?: (response: MeasureDto) => TSelect,
    ignoreErrors = false,
    onError?: (err: unknown) => void,
) => {
    const dispatch = useDispatch();
    return useQuery({
        queryKey: MeasureDetailQueryKeys.byId(id ?? 0),
        queryFn: ({ queryKey, signal }) => apiGet<MeasureDto>(`${MEASURES_PATH}/${queryKey[2]}`, { signal }),
        onSuccess: (response: TSelect) => {
            dispatch({ type: FETCH_MEASURE_SUCCEEDED, response });
        },
        onError,
        select,
        enabled: id !== undefined,
        meta: {
            skipReportToSentry: ignoreErrors || ErrorConstantKeys.VDERROR_NOT_FOUND_MEASURE,
            skipNotifications: ignoreErrors || ErrorConstantKeys.VDERROR_NOT_FOUND_MEASURE,
        },
    });
};

export const useCreateMeasure = (onSuccess?: (response: MeasureDto) => void) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: CreateMeasureRequestBody) => apiPost<MeasureDto>(MEASURES_PATH, data),
        onSuccess: (response) => {
            dispatch({ type: CREATE_MEASURE_SUCCEEDED });
            response.ideaId !== null &&
                dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_IDEA_TO_MEASURE));
            queryClient.invalidateQueries(MeasureListKeys.all);
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            onSuccess?.(response);
        },
        meta: {
            skipReportToSentry: [ErrorConstantKeys.VDERROR_NOT_FOUND_MEASURE, ErrorConstantKeys.VDERROR_FORBIDDEN_ACCESS_SET_USER],
        },
    });
};

interface IDiscardMeasureDto {
    measureId: number;
    reason: number;
    statement: string;
}

export const useDiscardMeasure = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ measureId, ...data }: IDiscardMeasureDto) => apiPost<MeasureDto>(`${MEASURES_PATH}/${measureId}/discard`, data),
        onSuccess: (response, { measureId }) => {
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(measureId));
            queryClient.invalidateQueries(MeasureDetailQueryKeys.byId(measureId));
            queryClient.invalidateQueries(processHistoryKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Read, measureId }));
            queryClient.invalidateQueries(MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Update, measureId }));
            queryClient.invalidateQueries(NotificationQueryKeys.forMeasure(measureId));
        },
        meta: {
            skipReportToSentry: ErrorConstantKeys.VDERROR_NOT_FOUND_MEASURE,
        },
    });
};

export interface IUpdateMeasureOptions {
    measureId: number;
    changes: MeasureUpdate;
}

export const useUpdateMeasure = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ measureId, changes }: IUpdateMeasureOptions) => apiPatch<MeasureDto>(`${MEASURES_PATH}/${measureId}`, changes),
        onMutate: async ({ measureId, changes }) => {
            const queryKey = MeasureDetailQueryKeys.byId(measureId);

            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(queryKey);

            const previousMeasure = queryClient.getQueryData<MeasureDto>(queryKey);

            if (previousMeasure === undefined) {
                return { previousMeasure: undefined };
            }

            const optimisticUpdatedMeasure = { ...previousMeasure, fields: { ...previousMeasure.fields } };

            // Only optimistically update the fields, other properties are more difficult
            Object.entries(changes).forEach(([fieldTitle, value]) => {
                if (fieldTitle in optimisticUpdatedMeasure.fields) {
                    optimisticUpdatedMeasure.fields[fieldTitle] = {
                        ...optimisticUpdatedMeasure.fields[fieldTitle],
                        value,
                    };
                }
            });

            queryClient.setQueryData(queryKey, optimisticUpdatedMeasure);

            return { previousMeasure };
        },
        onError: (error, { measureId }, context) => {
            if (context === undefined) {
                return;
            }
            // Revert optimistic update
            queryClient.setQueryData(MeasureDetailQueryKeys.byId(measureId), context.previousMeasure);
        },

        onSuccess: (response: MeasureDto, { measureId, changes }: IUpdateMeasureOptions) => {
            queryClient.invalidateQueries(ProcessPulseQueryKeys.forProcess(measureId));
            queryClient.invalidateQueries(processHistoryKeys.forMeasure(measureId));
            queryClient.invalidateQueries(ReportingQueryKeys.all);
            queryClient.invalidateQueries(NotificationQueryKeys.forMeasure(measureId));
            queryClient.invalidateQueries(MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Read, measureId }));
            queryClient.invalidateQueries(MeasurePermissionsQueryKeys.entity({ permission: AclPermissions.Update, measureId }));

            // Directly set query data here to avoid additional roundtrip of fetching measure for two reasons:
            // - Make multi-selection fields more responsive, to reduce issues with users doing fast selections of multiple values
            // - Make refetching of endpoints more responsive
            queryClient.setQueryData(MeasureDetailQueryKeys.byId(measureId), response);

            // Refetch data of endpoints, that could have been soft deleted by backend
            const softDeletableEndpoints = [Endpoint.Projects, Endpoint.CustomValues];
            const endpointsOfChanges = new Set(
                Object.keys(changes)
                    .filter((fieldTitle) => response.fields[fieldTitle])
                    .map((fieldTitle) => response.fields[fieldTitle].tableName as Endpoint),
            );
            [...endpointsOfChanges]
                .filter((x) => softDeletableEndpoints.includes(x))
                .forEach((endpoint) => {
                    queryClient.invalidateQueries(EndpointQueryKeys.forEndpoint(endpoint));
                });
        },
        meta: {
            skipReportToSentry: ErrorConstantKeys.VDERROR_NOT_FOUND_MEASURE,
        },
    });
};

const initialState = { current: null as number | null };
type MeasureDetailReducerActions =
    | ActionType<typeof FETCH_MEASURE_SUCCEEDED, { response: MeasureDto }>
    | ReturnType<typeof resetCurrentMeasureAction>;

// eslint-disable-next-line @typescript-eslint/default-param-last
export function measureReducer(state = initialState, action: MeasureDetailReducerActions) {
    // write the id of the latest current measure request to the store, so its id will be available in sagas
    switch (action.type) {
        case FETCH_MEASURE_SUCCEEDED:
            return { current: "response" in action ? action.response.id : -1 };
        case RESET_CURRENT_MEASURE:
            return { current: null };
        default:
            return state;
    }
}

export const selectCurrentMeasureId = (state: AppState) => state.measures.current;

export const useMeasureFields = (measure: MeasureDto) => {
    return useMemo(() => Object.values(measure.fields) as MeasureField[], [measure.fields]);
};

export const useMethodIdFieldValue = (fields: MeasureField[]) => {
    const methodId = fields.find((field) => field.id === AttributeId.COST_LEVER_ID)?.value;
    if (methodId == null || methodId === "") {
        return null;
    }
    return +methodId;
};

export const useMeasureAssignedTo = (measure: Pick<FlatMeasureDto, "assignedToId">) => {
    return useUser(measure.assignedToId) ?? null;
};

export const useAssignedToIsActive = (measure: Pick<FlatMeasureDto, "assignedToId">) => {
    const assignedTo = useMeasureAssignedTo(measure);
    return assignedTo != null && isActiveUser(assignedTo);
};

function isMeasureCompleted(measure: FlatMeasureDto): boolean {
    const isDiscarded = measure.status === MeasureStatus.STATUS_DISCARDED;
    const isClosed = measure.status === MeasureStatus.STATUS_CLOSED;

    return isDiscarded || isClosed;
}

export const useCurrentUserCanEditMeasure = (measure: FlatMeasureDto) => {
    const measureCompleted = isMeasureCompleted(measure);
    const assignedToIsActive = useAssignedToIsActive(measure);

    const currentUserId = useCurrentUserId();
    const currentUserIsEditor = useIsUserMeasureEditor(measure.id, currentUserId);

    const measureStatusForPermission = [MeasureStatus.STATUS_CLOSED, MeasureStatus.STATUS_DISCARDED].includes(measure.status);
    const hasSpecialStatusPermissionQuery = useUserHasMeasureStatusPermissionQuery(measure.status);

    if (!assignedToIsActive) {
        return false;
    }

    if (!hasSpecialStatusPermissionQuery.isSuccess) {
        return false;
    }

    const canEditSpecialStatus = measureStatusForPermission && hasSpecialStatusPermissionQuery.data.hasPermission;
    if (measureCompleted && !canEditSpecialStatus) {
        // Return early here, so in case of completed measure and user having the permission
        // still regular editing permissions are required
        return false;
    }

    return currentUserIsEditor;
};

export const useCurrentUserCanCommentMeasure = (measure: FlatMeasureDto) => {
    const measureCompleted = isMeasureCompleted(measure);
    const assignedToIsActive = useAssignedToIsActive(measure);
    const currentUserId = useCurrentUserId();
    const currentUserIsViewer = useIsUserMeasureViewer(measure.id, currentUserId);

    if (measureCompleted) {
        return false;
    }

    if (!assignedToIsActive) {
        return false;
    }

    return currentUserIsViewer;
};

export const useCurrentUserIsResponsible = (measure: MeasureDto) => {
    const id = useCurrentUserId();
    return id != null && measure.assignedToId === id;
};

export const useAIActionArea = () => {
    return useMutation({
        mutationFn: (measureId: number) =>
            apiGet<BasicMessageDto>(`${MEASURES_PATH}/${measureId}/ai/action-area`, {
                // Mutations don't provide signals, as they are not idempotent and do not resemble server state
                // Conceptionally, this should actually be a query instead of a mutation
                // Convert this to a query and attach the proper signal when the AI section matures and leaves the "lab" state
                signal: undefined,
            }),
        meta: {
            skipReportToSentry: ErrorConstantKeys.VDERROR_NOT_FOUND_MEASURE,
        },
    });
};

export const useAIExperiments = (measureId: number) => {
    return useQuery({
        queryKey: MeasureDetailQueryKeys.aiExperiments(measureId),
        queryFn: ({ signal }) => apiGet<MeasureAIExperimentListDto>(`${MEASURES_PATH}/${measureId}/ai/experiments`, { signal }),
        meta: {
            skipReportToSentry: ErrorConstantKeys.VDERROR_NOT_FOUND_MEASURE,
        },
    });
};

export const useAIExperiment = () => {
    return useMutation({
        mutationFn: ({ measureId, experiment }: { measureId: number; experiment: string }) =>
            apiPost<BasicMessageDto>(`${MEASURES_PATH}/${measureId}/ai/experiments`, { experiment }),
        meta: {
            skipReportToSentry: ErrorConstantKeys.VDERROR_NOT_FOUND_MEASURE,
        },
    });
};

export const useMeasureViewTracking = (measureId?: number): void => {
    const queryClient = useQueryClient();

    const { mutate } = useMutation({
        mutationFn: (measureId: number) => apiPost<BasicMessageDto>(`${MEASURES_PATH}/${measureId}/views`),
        onSuccess: () => {
            queryClient.invalidateQueries(ViewedMeasuresKeys.all);
        },
        meta: {
            skipReportToSentry: ErrorConstantKeys.VDERROR_NOT_FOUND_MEASURE,
        },
    });

    useEffect(() => {
        if (measureId !== undefined) {
            mutate(measureId);
        }
    }, [measureId, mutate]);
};
