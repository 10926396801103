import { Stack } from "@mui/material";
import { CreateIdeaRequestBody, IdeaAttributeDto, IdeaDto } from "api-shared";
import { sortBy } from "lodash";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Field from "../../components/Field";
import { useCurrentClient } from "../../domain/client";
import { useLanguage } from "../../hooks/useLanguage";
import { translationKeys } from "../../translations/main-translations";

type IdeaFieldListProps = {
    control: Control<CreateIdeaRequestBody>;
    attributes: IdeaAttributeDto[];
    fields: IdeaDto["fields"];
    disabled?: boolean;
};

export const IdeaFieldList = ({ control, attributes, fields, disabled = false }: IdeaFieldListProps) => {
    const { t } = useTranslation();
    const language = useLanguage();
    const client = useCurrentClient();

    const sortedAttributes = sortBy(attributes, (attribute) => attribute.order ?? 0);

    return (
        <Stack spacing={1} width="100%">
            {sortedAttributes.map((attribute) => (
                <Controller
                    key={attribute.title}
                    control={control}
                    name={`fields.${attribute.title}`}
                    defaultValue={fields[attribute.title] ?? null}
                    render={({ field: { onChange, value } }) => (
                        <Field
                            processName={translationKeys.VDLANG_IDEAS_ITEMS_LABEL}
                            client={client}
                            propName={attribute.title}
                            field={{
                                ...attribute,
                                options: attribute.options ?? {},
                                value,
                            }}
                            updateHandler={(change) => {
                                const rawValue = change[attribute.title];
                                const newValue = rawValue !== null ? String(rawValue) : null;
                                onChange(newValue);
                            }}
                            lang={language}
                            translate={t}
                            disabled={disabled}
                            menuPortalTarget={document.body}
                            dense
                        />
                    )}
                />
            ))}
        </Stack>
    );
};
