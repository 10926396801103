import { QueryFunctionContext, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
    ApiTokenDto,
    ApiTokenMetaDto,
    AttributeTable,
    CreateUserRequestBody,
    UserDto,
    UserListDto,
    UserStatus,
    type BasicMessageDto,
    type UpdateCurrentUserRequestBody,
} from "api-shared";
import { useDispatch } from "react-redux";
import useAuthenticatedContext from "../infrastructure/AuthenticatedContext";
import { showNotificationEvent } from "../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../lib/api";
import { NotificationType } from "../lib/notifications";
import { FeedbackTranslationKeys } from "../translations/notification-translations";
import { registerFieldData } from "./field-data";

export const UPDATE_USER_PROFILE_SUCCEEDED = "UPDATE_USER_PROFILE_SUCCEEDED";

export const UsersQueryKeys = {
    all: ["users"] as const,
    list: () => [...UsersQueryKeys.all, "list"] as const,
    current: () => [...UsersQueryKeys.all, "current"] as const,
};

interface UseUsersQueryOptions<TSelect = UserListDto> {
    select?: (users: UserListDto) => TSelect;
    enabled?: boolean;
}

export const useCurrentUser = () => {
    const authenticatedContext = useAuthenticatedContext();
    return authenticatedContext.user;
};

export const useUsersQuery = <TSelect = UserListDto>({ select, enabled }: UseUsersQueryOptions<TSelect> = { enabled: true }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    return useQuery({
        queryKey: UsersQueryKeys.list(),
        queryFn: ({ signal }) => apiGet<UserListDto>("users", { signal }),
        select,
        enabled,
        suspense: true,
        onSuccess: (users) => {
            // In case a custom select is provided, gather the original response from queryClient
            const data = select == null ? users : queryClient.getQueryData(UsersQueryKeys.list());
            dispatch(registerFieldData(AttributeTable.Users, data as UserListDto));
        },
        /**
         * 5min Our rendering is sometimes so slow that even during the initial load of a measure the users are fetched twice
         * after decision we set this to 5min instead of the originally planned 10s as there are one seldom changes and invalidation should handle this
         */
        staleTime: 5 * 60 * 1000,
    });
};

export const makeCurrentUserQuery = () => {
    return {
        queryKey: UsersQueryKeys.current(),
        queryFn: ({ signal }: QueryFunctionContext) => apiGet<UserDto>("users/current", { signal }),
        meta: {
            // Query is triggered during bootstrapping to see if the user is logged in or not
            ignoreErrors: true,
        },
        staleTime: 5 * 60 * 1000, // 5 minutes
    } as const;
};

export const useCurrentUserQuery = (enabled: boolean) => {
    return useQuery({ ...makeCurrentUserQuery(), enabled });
};

export const useTrackedSessionMutation = () =>
    useMutation({
        mutationFn: () => apiPost("users/current/track-session"),
    });

export const useAllUsers = () => {
    return useUsersQuery().data ?? [];
};

export const isNotDeletedUser = (user: UserDto) => user != null && user.status !== UserStatus.STATUS_DELETED;

export const useNonDeletedUsers = () => {
    return useUsersQuery({ select: (users) => users.filter(isNotDeletedUser) }).data ?? [];
};

export const useUser = (id: number | null) => {
    return useUsersQuery({ select: (users) => users.find((u) => u.id === id) }).data;
};

export const useUserQuery = (id: number | null) => {
    return useUsersQuery({ select: (users) => users.find((u) => u.id === id) });
};

interface IConfirmUserEmailInput {
    token: string;
    lang: string;
    realname: string;
    position?: string;
    acceptDataSaving: boolean;
    acceptDataUsage: boolean;
}
export const useConfirmUserEmail = () => {
    return useMutation({
        mutationFn: (input: IConfirmUserEmailInput) => apiPost("confirm-email", input),
        meta: {
            ignoreErrors: true, // error handling is done locally by the component
        },
    });
};

export const useDeleteUserAvatar = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: () => apiDelete("users/current/avatar"),
        onSuccess: () => {
            queryClient.invalidateQueries(UsersQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_AVATAR_DELETED));
        },
    });
};

export const useUpdateUserNotifications = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (enabledNotifications: string[]) => apiPatch("users/current/notifications", { enabledNotifications }),
        onSuccess: () => {
            queryClient.invalidateQueries(UsersQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_NOTIFICATIONS_UPDATED));
        },
    });
};

export const useUpdateUserProfile = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: UpdateCurrentUserRequestBody) => apiPatch<UserDto>("users/current", data),
        onSuccess: (response) => {
            queryClient.invalidateQueries(UsersQueryKeys.all);
            dispatch({ type: UPDATE_USER_PROFILE_SUCCEEDED, response });
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_PROFILE_UPDATED));
        },
    });
};

export const useUploadUserAvatar = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: FormData) => apiPost<BasicMessageDto>("users/current/avatar", data),
        onSuccess: (response) => {
            queryClient.invalidateQueries(UsersQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_PROFILE_UPDATED));
        },
    });
};

export const useInviteUser = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: CreateUserRequestBody) => apiPost<UserDto>("users", data),
        onSuccess: () => {
            queryClient.invalidateQueries([UsersQueryKeys.list]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_USER_INVITED));
        },
    });
};

export const isActiveUser = (user: UserDto) => user?.status === UserStatus.STATUS_ACTIVE;

export const isActiveRegisteredOrInvitedUser = (user: UserDto) =>
    user?.status === UserStatus.STATUS_ACTIVE ||
    user?.status === UserStatus.STATUS_INVITED ||
    user?.status === UserStatus.STATUS_REGISTERED;

export const useCurrentUserId = () => useCurrentUser()?.id ?? null;

export const useGenerateApiToken = () => {
    return useMutation({
        mutationFn: (period: number) => apiPost<ApiTokenDto>("users/api-token", { period }),
    });
};

export const useApiTokenMeta = () => {
    return useQuery({
        queryFn: ({ signal }) => apiGet<ApiTokenMetaDto>("users/api-token", { signal }),
    });
};
