import { useQuery } from "@tanstack/react-query";
import { ClientDto, FeatureFlags } from "api-shared";
import { useDispatch } from "react-redux";
import useAuthenticatedContext from "../infrastructure/AuthenticatedContext";
import { apiGet } from "../lib/api";
import { registerFieldData } from "./field-data";

export const GET_CURRENT_CLIENT = "GET_CURRENT_CLIENT";
export const GET_CURRENT_CLIENT_SUCCEEDED = "GET_CURRENT_CLIENT_SUCCEEDED";
export const GET_CURRENT_CLIENT_FAILED = "GET_CURRENT_CLIENT_FAILED";

const CLIENTS_PATH = "clients";

export const ClientQueryKeys = {
    current: [CLIENTS_PATH, "current"] as const,
};

export function useCurrentClientQuery(enabled = true) {
    const dispatch = useDispatch();
    // Use suspense here so that the loading animation is shown as long as the client is not available yet
    // That way, default values in the hooks below can be safely used, without them ever being rendered
    // This simplifies the api of the hooks, because the components do not have to check for query.isSuccess everytime they use one of the
    // hooks
    return useQuery({
        queryKey: ClientQueryKeys.current,
        queryFn: ({ queryKey, signal }) => apiGet<ClientDto>(`${queryKey[0]}/${queryKey[1]}`, { signal }),
        suspense: true,
        enabled,
        onSuccess: (client) => dispatch(registerFieldData("currencies", client.currencies)),
    });
}

export function useCurrentClient() {
    return useAuthenticatedContext().client;
}

export function useClientFeatures() {
    return useCurrentClient().features ?? {};
}

export function useClientHasFeature(flag: FeatureFlags, value = 1) {
    return useClientFeatures()[flag] === value;
}

export function useClientName() {
    return useCurrentClient().name ?? "";
}

export function useClientDomains() {
    return useCurrentClient().domains ?? [];
}

/**
 * Get the start month of a clients configured fiscal year.
 *
 * 0-index based: 0 is January and so on
 *
 * @export
 * @returns
 */
export function useClientFiscalYear() {
    const client = useCurrentClient();
    return client.fiscalYear;
}
