import { FormGroup, TextField } from "@mui/material";
import { zMinMaxShortText } from "api-shared";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import { useSuppliersQuery } from "../../../domain/suppliers";
import { translationKeys } from "../../../translations/main-translations";

export interface SupplierFormData {
    id?: number;
    name: string;
    supplierNumber: string;
}

interface SupplierFormProps {
    onSubmit: () => void;
    supplier: SupplierFormData;
    updateSupplier: (newChanges: Partial<SupplierFormData>, isValid: boolean) => void;
}

const SupplierForm = ({ onSubmit: save, supplier, updateSupplier }: SupplierFormProps) => {
    const { t: translate } = useTranslation();
    const suppliers = useSuppliersQuery();

    const [isSupplierNameInputTouched, setIsSupplierNameInputTouched] = useState(false);

    const isDuplicateName = (name: string) => {
        return suppliers.data?.some((supplier) => supplier.name === name.trim());
    };

    const validateName = (name?: string | null) => zMinMaxShortText.safeParse(name).success;

    const getValidationStatus = (name: string) => validateName(name) && !isDuplicateName(name);

    const triggerSave = () => {
        if (getValidationStatus(supplier.name)) {
            save();
        }
    };

    const onInputChanged = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked, value, type } = target;

        const realValue = type === "checkbox" ? checked : value;
        const isNewValid = getValidationStatus(name === "name" ? value : supplier.name);

        updateSupplier({ [name]: realValue }, isNewValid);
    };

    const onSupplierNameInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        onInputChanged(event);
        setIsSupplierNameInputTouched(true);
    };

    const { name, supplierNumber } = supplier;

    const isInvalidSupplierName = isSupplierNameInputTouched && !getValidationStatus(name);

    function getErrorMessage() {
        if (!validateName(name)) {
            const parseResult = zMinMaxShortText.safeParse(name);
            if (!parseResult.success) {
                return translate(
                    parseResult.error.name.includes("too_big")
                        ? translationKeys.VDLANG_SUPPLIERS_NAME_TOO_LONG
                        : translationKeys.VDLANG_SUPPLIERS_NAME_TOO_SHORT,
                );
            }
        } else if (isDuplicateName(name)) {
            return translate(translationKeys.VDLANG_SUPPLIERS_NAME_ALREADY_EXISTS);
        }

        return "";
    }

    return (
        <Form onSubmit={triggerSave}>
            <FormGroup>
                <TextField
                    name="name"
                    autoFocus
                    required
                    error={isInvalidSupplierName}
                    helperText={getErrorMessage()}
                    label={translate("Name")}
                    placeholder={translate("enterName")}
                    value={name}
                    onChange={onSupplierNameInputChanged}
                />
                <TextField
                    name="supplierNumber"
                    label={translate("internal_number")}
                    value={supplierNumber ?? ""}
                    onChange={onInputChanged}
                    placeholder={translate("enter_internal_number")}
                />
            </FormGroup>
        </Form>
    );
};

export default SupplierForm;
