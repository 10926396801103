import { Alert, Button, styled } from "@mui/material";
import type { TFunction } from "i18next";
import type { ReactNode } from "react";
import { translationKeys } from "../../translations/main-translations";

const Banner = styled(Alert)({
    borderRadius: 0,
    alignItems: "center",
});

type NotificationBannerProps = {
    children: ReactNode;
    translate: TFunction;
    handleClose: () => void;
};

const NotificationBanner = ({ children, translate, handleClose }: NotificationBannerProps) => {
    return (
        <Banner
            severity="info"
            action={
                <Button variant="text" sx={{ color: "info.dark", alignSelf: "center" }} onClick={handleClose}>
                    {translate(translationKeys.VDLANG_NOTIFICATION_BANNER_CONFIRMATION)}
                </Button>
            }
        >
            {children}
        </Banner>
    );
};

export default NotificationBanner;
