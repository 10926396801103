import { DialogTitle as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps, Stack, styled } from "@mui/material";
import { PropsWithChildren, ReactElement, ReactNode } from "react";
import patternImage from "../../static/images/dialog-header-pattern.svg";
import MoreActionsMenu from "../MoreActionsMenu";

const ActionsContainer = styled("div")(({ theme }) => ({
    position: "absolute",
    right: theme.spacing(),
    top: theme.spacing(1.5),
}));

const ShapedDialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    position: "relative",
    backgroundImage: `linear-gradient(90deg, ${theme.palette.valueBlue.main} 0%, ${theme.palette.primary.main} 100%)`,
    backgroundColor: theme.palette.primary.main,
    lineHeight: "normal",
}));

const Pattern = styled("img")(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
}));

interface IDialogTitleProps extends MuiDialogTitleProps {
    actions?: ReactElement[];
    onClick?: () => void;
    shaped?: boolean;
    titleLabel?: ReactNode;
}

const DialogTitle = ({ classes, actions = [], onClick, children, shaped, titleLabel, ...other }: PropsWithChildren<IDialogTitleProps>) => {
    const Component = shaped ? ShapedDialogTitle : MuiDialogTitle;
    return (
        <Component {...other}>
            {shaped ? <Pattern srcSet={patternImage} alt="" /> : null}
            <Stack direction="row" spacing={1} alignItems="center">
                <div>
                    {/* Wrap children with div, so that margin will also be applied when only a string is provided */}
                    {children}
                </div>
                {titleLabel !== undefined && titleLabel}
                {Array.isArray(actions) && actions.length > 0 ? (
                    <ActionsContainer>{actions.length > 1 ? <MoreActionsMenu actions={actions} /> : actions[0]}</ActionsContainer>
                ) : null}
            </Stack>
        </Component>
    );
};
export default DialogTitle;
