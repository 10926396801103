import CropFreeIcon from "@mui/icons-material/CropFreeRounded";
import { FormControl, FormGroup, Grid, IconButton, styled } from "@mui/material";
import { AttributeTitle, ClientDto, FeatureFlags, MeasureUpdate } from "api-shared";
import { TFunction } from "i18next";
import React, { ReactElement, useState } from "react";
import { FieldValue, FilledField, getFieldValueAsIds, isMandatoryAndNotFilled } from "../lib/fields";
import { Language, translationKeys } from "../translations/main-translations";
import OptionsProvider, { OptionsProviderInjectedProps } from "../view/OptionsProvider";
import TreeProvider from "../view/TreeProvider";
import ApplyActivityTemplateStandalone from "../view/measure/ApplyActivityTemplateStandalone";
import DescriptionDialog from "./DescriptionDialog";
import FeatureFlag from "./FeatureFlag";
import FieldLabel from "./FieldLabel";
import HelpButton from "./HelpButton";
import MethodSelect from "./MethodSelect";
import Tooltip from "./Tooltip";
import ExtendableFieldSelect from "./input/ExtendableFieldSelect";
import FieldSelect from "./input/FieldSelect";
import SupplierFieldSelect from "./input/SupplierFieldSelect";
import FieldTreeInput from "./input/tree/FieldTreeInput";
import MarkdownEditorUserMentionsMeasure from "./markdowneditor/MarkdownEditorUserMentionsMeasure";

const FieldFormControl = styled(FormControl, { shouldForwardProp: (prop) => prop !== "dense" })<{ dense?: boolean }>(
    ({ dense, theme }) => ({
        ...(!dense && {
            margin: theme.spacing(1, 0),
        }),
    }),
);

export interface IFieldProps {
    action?: ReactElement;
    disabled: boolean;
    field: FilledField;
    client: ClientDto;
    processName: string;
    openFieldHelp?: (fieldTitle: string) => void;
    measureId?: number;
    lang: Language;
    dense?: boolean;
    translate: TFunction;
    propName: string;
    updateHandler: (changes: MeasureUpdate) => void;
    preSelectOnlyValue?: boolean;
    parentValue?: number | null;
    menuPortalTarget?: HTMLElement;
    isClearable?: boolean;
    testId?: string;
}

const Field = ({
    action,
    disabled,
    field,
    client,
    processName,
    openFieldHelp,
    measureId,
    lang,
    dense,
    translate,
    propName,
    updateHandler,
    preSelectOnlyValue,
    parentValue,
    menuPortalTarget,
    isClearable,
    testId,
}: IFieldProps) => {
    const { title, mandatory, value } = field;

    const [touched, setTouched] = useState(false);
    const [isDescriptionDialogOpen, setIsDescriptionDialogOpen] = useState(false);

    const isErrorVisible = touched && isMandatoryAndNotFilled(field);

    const updateValue = (newValue: FieldValue, forceUpdate = false) => {
        const { value: oldValue } = field;
        let changed = false;

        if (typeof newValue === "object") {
            // deep compare object values
            changed = JSON.stringify(oldValue) !== JSON.stringify(newValue);
        } else {
            changed =
                oldValue == null
                    ? // update on first fill only when new value is non-empty
                      newValue !== ""
                    : // newValue == null -> value cleared -> update
                      // otherwise update only if value really changed
                      newValue == null || oldValue.toString() !== newValue.toString();
        }

        if (forceUpdate) {
            changed = true;
        }

        if (changed) {
            updateHandler({ [propName]: newValue });
        }
        return changed;
    };

    const getSelectProps = () => ({
        client: client,
        field: field,
        preSelectOnlyValue,
        parentValue,
        updateValue,
        isMulti: field.quantity === 0,
        value: getFieldValueAsIds(field.value),
        menuPortalTarget,
        isClearable,
    });

    const getSelectComponent = () => {
        if (field.title === AttributeTitle.CostLeverId) {
            return MethodSelect;
        }
        return FieldSelect;
    };

    const getOptionProviderProps = () => ({ field, parentValue, value: getFieldValueAsIds(field.value), updateValue, preSelectOnlyValue });

    const getExtendableSelectComponent = () => {
        switch (field.tableName) {
            case "suppliers":
                return SupplierFieldSelect;
            case "custom-values":
            case "projects":
                return ExtendableFieldSelect;
            default:
                return FieldSelect;
        }
    };

    const getField = () => {
        const sharedProps = {
            lang,
            translate,
            disabled,
            updateValue,
            value: field.value,
        };

        const SelectComponent = field.isCreatable ? getExtendableSelectComponent() : getSelectComponent();

        switch (field.type) {
            case "combobox":
                return (
                    <OptionsProvider {...getOptionProviderProps()}>
                        <SelectComponent {...OptionsProviderInjectedProps} {...sharedProps} {...getSelectProps()} />
                    </OptionsProvider>
                );
            case "text":
                return <MarkdownEditorUserMentionsMeasure {...sharedProps} />;
            case "tree":
                return (
                    <TreeProvider
                        componentProps={{
                            ...sharedProps,
                            ...getSelectProps(),
                            dialogTitle: translate(field.title),
                            value: [sharedProps.value].flat(),
                            field,
                            narrowSelection: true,
                            // use dialogTitle instead of label because the label in resting state is rendered here already
                        }}
                        field={field}
                        component={FieldTreeInput}
                    />
                );
            default:
                return <h4>Unknown Field Type: {field.type}</h4>;
        }
    };

    const onBlur = () => setTouched(true);

    const isHelpButtonVisible = openFieldHelp != null && title === AttributeTitle.CostLeverId;

    const fieldComponent = getField();

    return (
        <Grid item xs={12}>
            <FormGroup>
                <FieldFormControl
                    error={!disabled && isErrorVisible}
                    required={mandatory}
                    disabled={disabled}
                    fullWidth
                    onBlur={onBlur}
                    dense={dense}
                >
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <FieldLabel fieldTitle={title} processName={processName} clientName={client.name} translate={translate} />
                        </Grid>
                        {title === "description" && (
                            <Grid item>
                                <Tooltip title={translate(translationKeys.VDLANG_PROCESS_TOOLTIPS_FULLSCREEN)}>
                                    <IconButton size="small" onClick={() => setIsDescriptionDialogOpen(true)}>
                                        <CropFreeIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <DescriptionDialog
                                    open={isDescriptionDialogOpen}
                                    value={value}
                                    updateValue={updateValue}
                                    onClose={() => setIsDescriptionDialogOpen(false)}
                                    disabled={disabled}
                                    lang={lang}
                                    translate={translate}
                                />
                            </Grid>
                        )}
                        {action !== undefined && action}
                    </Grid>
                    {isHelpButtonVisible ? (
                        <HelpButton helpKey={title} disabled={disabled} onOpen={openFieldHelp} translate={translate}>
                            {fieldComponent}
                        </HelpButton>
                    ) : (
                        fieldComponent
                    )}
                </FieldFormControl>
                {measureId !== undefined && title === AttributeTitle.CostLeverId && value != null && value !== "" && !disabled ? (
                    <FeatureFlag feature={FeatureFlags.FEATURE_ACTIVITY_TEMPLATES}>
                        <ApplyActivityTemplateStandalone measureId={measureId} methodId={+value} />
                    </FeatureFlag>
                ) : null}
            </FormGroup>
        </Grid>
    );
};

export default React.memo(Field);
