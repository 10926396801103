import { TFunction } from "i18next";
import React, { Children, cloneElement, ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useClientName } from "../domain/client";
import { useLanguage } from "../hooks/useLanguage";
import {
    filterFieldOptions,
    getRawFieldOptions,
    sortFieldOptions,
    SyntheticField,
    TranslatedOption,
    translateFieldOptions,
    useDataForTableNameFromStore,
} from "../lib/field-options";
import { useFlatTree } from "../lib/tree";

// TypeScript compiler is not able to pick the corret generics for multi-signature TFunction typings, so use a cast here
const dummyTranslate = (() => "") as unknown as TFunction;
// Helper to spread on props of components rendered as children of OptionProvider that will get some props injected
// TS would complain about missing props, so spread this to satisfy the compiler
export const OptionsProviderInjectedProps = {
    options: [],
    translate: dummyTranslate,
};

export interface IOptionsProviderProps {
    field: SyntheticField;
    parentValue?: number | null;
    children: ReactElement<{ options: TranslatedOption[]; translate: TFunction }>;
    preSelectOnlyValue?: boolean;
    value: unknown;
    updateValue?: (newValue: unknown) => void;
}

const OptionsProvider = ({ preSelectOnlyValue, children, parentValue, ...otherProps }: IOptionsProviderProps) => {
    const { value, updateValue, field } = otherProps;

    const { t: translate } = useTranslation();
    const clientName = useClientName();

    const lang = useLanguage();

    // For performance reasons, use the options from redux store, instead of queries
    const storeOptions = useDataForTableNameFromStore(field.tableName);
    const treeOptions = useFlatTree(field.type, storeOptions, true);

    useEffect(() => {
        if (!preSelectOnlyValue || updateValue == null) {
            return;
        }

        const rawOptions = getRawFieldOptions(storeOptions, treeOptions, field, clientName);
        const filteredOptions = filterFieldOptions(rawOptions, field, value, parentValue);

        const isEmpty = value == null || (Array.isArray(value) && value.length === 0);
        if (isEmpty && filteredOptions.length === 1) {
            // autoselect if field is not filled yet and there is only a single available option
            updateValue(filteredOptions[0].id);
        }
        const currentValueNotAvailable = filteredOptions.find(({ id }) => id === value) === undefined;
        if (currentValueNotAvailable && !isEmpty) {
            // reset current selection (if there is any) either with the only available option or null
            const newValue = filteredOptions.length === 1 && field.mandatory ? filteredOptions[0].id : null;
            updateValue(newValue);
        }
    }, [preSelectOnlyValue, updateValue, storeOptions, treeOptions, field, clientName, parentValue, value]);

    const rawOptions = getRawFieldOptions(storeOptions, treeOptions, field, clientName);
    const optionsFiltered = filterFieldOptions(rawOptions, field, value, parentValue);
    const translatedOptions = translateFieldOptions(optionsFiltered, field, lang, translate);
    const optionsSorted = sortFieldOptions(translatedOptions, field);

    return cloneElement(Children.only(children), {
        ...otherProps,
        translate,
        options: optionsSorted,
    });
};

export default React.memo(OptionsProvider);
