import AddIcon from "@mui/icons-material/AddRounded";
import { IconButton, styled } from "@mui/material";
import { DEFAULT_FILTER_ID, FilterDefinition, FilterDto, MeasureAttributeDto } from "api-shared";
import { TFunction } from "i18next";
import { MouseEvent, TouchEvent, useCallback, useEffect, useMemo, useState } from "react";
import { ClearIndicatorProps, components } from "react-select";
import EditIcon from "../../../../components/icons/EditIcon";
import { Option } from "../../../../components/input/select/types";
import SidebarSelect from "../../../../components/sidebar/SidebarSelect";
import { useCreateFilterMutation, useDeleteFilterMutation, useUpdateFilterMutation } from "../../../../domain/filters";
import { SearchConfig, useSearchConfigsByFilter } from "../../../../domain/search-config";
import { useCurrentUserId, useNonDeletedUsers } from "../../../../domain/users";
import useDialog from "../../../../hooks/useDialog";
import { tryTranslate } from "../../../../lib/translate";
import { translationKeys } from "../../../../translations/main-translations";
import FilterDialog from "./FilterDialog";
import FilterForm from "./FilterForm";
import { buildInitialFilterDefinition } from "./conditions";

interface IFilterOption extends Option<number> {
    userId: number | null;
    clientId: number | null;
}
const Root = styled("div")({ position: "relative" });

const AddButton = styled(IconButton)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(0.75),
    right: theme.spacing(-0.5),
}));

interface IFilterSelectProps {
    value: number | null;
    filters: FilterDto[];
    searchConfig?: SearchConfig;
    measureAttributes: MeasureAttributeDto[];
    updateSelectedFilter: (filterId: number | null) => void;
    translate: TFunction;
    label: string;
}

// TODO: Split component and state into dedicated Select and Dialog-Components
const FilterSelect = ({ value, filters, searchConfig, measureAttributes, updateSelectedFilter, translate, label }: IFilterSelectProps) => {
    const [filterId, setFilterId] = useState<number | null>(null);
    const [filterName, setFilterName] = useState("");
    const [filterDefinition, setFilterDefinition] = useState<FilterDefinition>(buildInitialFilterDefinition());
    const [isFilterValid, setIsFilterValid] = useState(true);

    const affectedViews = useSearchConfigsByFilter(filterId);
    const createFilter = useCreateFilterMutation().mutate;
    const deleteFilter = useDeleteFilterMutation().mutate;
    const updateFilter = useUpdateFilterMutation().mutate;

    const isFilterDeletable = (affectedViews.length === 1 && affectedViews[0].id === searchConfig?.id) || affectedViews.length === 0;
    const currentUserId = useCurrentUserId();
    const users = useNonDeletedUsers();
    const { isOpen, openDialog, closeDialog } = useDialog();

    const loadFilter = (filter: FilterDto) => {
        setFilterId(filter.id);
        setFilterName(filter.name);
        setFilterDefinition(filter.definition);
        setIsFilterValid(true);
    };

    const resetFilter = () => {
        setFilterId(null);
        setFilterName("");
        setFilterDefinition(buildInitialFilterDefinition());
        setIsFilterValid(true);
    };

    useEffect(() => {
        const filter = filters.find((filter) => filter.id === value);

        if (filter !== undefined) {
            loadFilter(filter);
        } else {
            resetFilter();
        }
    }, [filters, value]);

    const options = useMemo<IFilterOption[]>(() => {
        const sortedFilterOptions = filters
            .map(({ id, sorting, name, userId, clientId }) => ({
                userId,
                clientId,
                label: name.length <= 0 ? translate(translationKeys.VDLANG_FILTER_CUSTOM_FILTER) : tryTranslate(translate, name),
                value: id ?? 0,
                sorting: sorting ?? id ?? 0,
            }))
            .toSorted((a, b) => a.sorting - b.sorting);

        const systemOptions = sortedFilterOptions.filter((option) => option.userId === null && option.clientId === null);
        const clientOptions = sortedFilterOptions.filter((option) => option.userId === null && option.clientId !== null);
        const userOptions = sortedFilterOptions.filter((option) => option.userId !== null);

        return [...systemOptions, ...clientOptions, ...userOptions];
    }, [filters, translate]);

    const safeFilterId = value ?? DEFAULT_FILTER_ID;
    const selectedOption = options.find((o) => o.value === safeFilterId) ?? null;

    const closeAndResetDialog = () => {
        resetFilter();
        closeDialog();
    };

    const handleSaveFilter = () => {
        // name can might be empty, then it indicates to save as a temp filter
        closeAndResetDialog();

        if (filterId !== null && filterId > 0) {
            updateFilter({ id: filterId, name: filterName, definition: filterDefinition });
            updateSelectedFilter(filterId);
        } else {
            createFilter(
                { name: filterName, definition: filterDefinition },
                {
                    onSuccess: (response) => {
                        updateSelectedFilter(response.id);
                    },
                },
            );
        }
    };

    const handleDeleteFilter = () => {
        if (filterId !== null) {
            deleteFilter(filterId);
            closeAndResetDialog();
            updateSelectedFilter(DEFAULT_FILTER_ID);
        }
    };

    const handleSelectChange = (option: IFilterOption | null) => updateSelectedFilter(option != null ? option.value : null);

    const editOrCreateFilter = () => {
        const filter = filters.find((filter) => filter.name === "");

        if (filter !== undefined) {
            loadFilter(filter);
        } else {
            resetFilter();
        }

        openDialog();
    };

    const triggerFilterEdit = (event: MouseEvent<SVGElement> | TouchEvent<SVGElement>) => {
        // stop event propagation so that it does not clear the select value
        event.stopPropagation();
        event.preventDefault();

        const filter = filters.find((filter) => filter.id === value);

        if (filter !== undefined) {
            loadFilter(filter);
        }

        openDialog();
    };

    const renderClear = <OptionType extends Option, IsMulti extends boolean>(props: ClearIndicatorProps<OptionType, IsMulti>) => (
        <components.ClearIndicator {...props}>
            <EditIcon
                fontSize="small"
                color="action"
                title={translate(translationKeys.VDLANG_FILTER_EDIT_FILTER)}
                onMouseDown={triggerFilterEdit}
                onTouchStart={triggerFilterEdit}
            />
        </components.ClearIndicator>
    );

    const handleFilterDefinitionChange = useCallback((filterDefinition: FilterDefinition, isFilterValid: boolean) => {
        setFilterDefinition(filterDefinition);
        setIsFilterValid(isFilterValid);
    }, []);

    return (
        <Root>
            <SidebarSelect
                isMulti={false}
                value={selectedOption}
                options={options}
                label={label}
                isClearable={selectedOption != null && selectedOption.userId === currentUserId}
                onChange={handleSelectChange}
                components={{ ClearIndicator: renderClear }}
            />
            <AddButton color="primary" size="small" onClick={editOrCreateFilter} data-testid="add_filter">
                <AddIcon />
            </AddButton>
            <FilterDialog
                open={isOpen}
                filterName={filterName}
                filterId={filterId}
                isFilterValid={isFilterValid}
                isFilterDeletable={isFilterDeletable}
                onSave={handleSaveFilter}
                onChange={setFilterName}
                onDelete={handleDeleteFilter}
                onClose={closeDialog}
                translate={translate}
                filters={filters}
            >
                <FilterForm
                    affectedViews={affectedViews}
                    filterDefinition={filterDefinition}
                    measureAttributes={measureAttributes}
                    users={users}
                    onChange={handleFilterDefinitionChange}
                    translate={translate}
                />
            </FilterDialog>
        </Root>
    );
};

export default FilterSelect;
