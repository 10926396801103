/* eslint-disable sonarjs/no-duplicate-string */
import {
    AclNamespaces,
    AclPermissions,
    EstimatesValue,
    FeedEntryTranslationKeys,
    GlobalCalculationIdentifier,
    IdentityProviderType,
    Language,
    LiveRunUpGranularity,
    MeasureCalculationGranularity,
    MeasureGateTaskFieldNames,
    MethodMeasureTimerange,
    PasswordValidityCriteria,
    SubTaskPriority,
    SubTaskStatus,
    UserTier,
    WidgetType,
} from "api-shared";

export type Translation = string[];

export type Translations = {
    // TypeScript does not understand combined types as index key like [x: string | number]
    [x: string]: Translation | Translations;
    [y: number]: Translation | Translations;
};

export const hint = (key: string) => `${key}_hint`;

enum Keys {
    // TODO: Figure out a proper way to use typescript constants derived from generics (see react-i18next typescript guide)
    VDLANG_ALL = "VDLANG_ALL",
    VDLANG_BACK = "VDLANG_BACK",
    VDLANG_DELETE = "VDLANG_DELETE",
    VDLANG_START_DATE = "VDLANG_START_DATE",
    VDLANG_END_DATE = "VDLANG_END_DATE",
    VDLANG_DATE_PLACEHOLDER = "VDLANG_DATE_PLACEHOLDER",
    VDLANG_DATE_MONTH_YEAR_PLACEHOLDER = "VDLANG_DATE_MONTH_YEAR_PLACEHOLDER",
    VDLANG_GREETING_MORNING = "VDLANG_GREETING_MORNING",
    VDLANG_GREETING_EVENING = "VDLANG_GREETING_EVENING",
    VDLANG_GREETING_AFTERNOON = "VDLANG_GREETING_AFTERNOON",
    VDLANG_CALENDAR_WEEK = "VDLANG_CALENDAR_WEEK",
    VDLANG_ID = "VDLANG_ID",
    VDLANG_FEED = "VDLANG_FEED",
    VDLANG_FEED_FOR = "VDLANG_FEED_FOR",
    VDLANG_FEED_COMMENTS = "VDLANG_FEED_COMMENTS",
    VDLANG_FEED_RECENT_MEASURES = "VDLANG_FEED_RECENT_MEASURES",
    VDLANG_FEED_RECENT_MEASURES_EMPTY = "VDLANG_FEED_RECENT_MEASURES_EMPTY",
    VDLANG_FEED_EMBEDDED_DESCRIPTION = "VDLANG_FEED_EMBEDDED_DESCRIPTION",
    VDLANG_FEED_EMBEDDED_OBJECT_NO_ACCESS = "VDLANG_FEED_EMBEDDED_OBJECT_NO_ACCESS",
    VDLANG_FEED_EMBEDDED_OBJECT_NO_ACCESS_DESCRIPTION = "VDLANG_FEED_EMBEDDED_OBJECT_NO_ACCESS_DESCRIPTION",
    VDLANG_FEED_DETAIL_BACK_BUTTON = "VDLANG_FEED_DETAIL_BACK_BUTTON",
    VDLANG_COMMENTED_ON = "VDLANG_COMMENTED_ON",
    VDLANG_MOMENT_AGO = "VDLANG_MOMENT_AGO",
    VDLANG_TODAY = "VDLANG_TODAY",
    VDLANG_HOURS_AGO = "VDLANG_HOURS_AGO",
    VDLANG_DAYS_AGO = "VDLANG_DAYS_AGO",
    VDLANG_LAST_CREATED_PROCESS = "VDLANG_LAST_CREATED_PROCESS",
    VDLANG_LAST_UPDATED_PROCESS = "VDLANG_LAST_UPDATED_PROCESS",
    VDLANG_CREATE_PROCESS = "VDLANG_CREATE_PROCESS",
    VDLANG_MEASURE_MODIFIED = "VDLANG_MEASURE_MODIFIED",
    VDLANG_MEASURE_CREATED = "VDLANG_MEASURE_CREATED",
    VDLANG_MEASURE_COMPLETED = "VDLANG_MEASURE_COMPLETED",
    VDLANG_MEASURE_MODIFIED_COMPLETED = "VDLANG_MEASURE_MODIFIED_COMPLETED",
    VDLANG_MEASURE_DISCARDED = "VDLANG_MEASURE_DISCARDED",
    VDLANG_MEASURE_MODIFIED_BY_SYSTEM = "VDLANG_MEASURE_MODIFIED_BY_SYSTEM",
    VDLANG_GOTO_MEASURE = "VDLANG_GOTO_MEASURE",
    VDLANG_GOTO_IDEA = "VDLANG_GOTO_IDEA",
    VDLANG_NO_COMPANIES = "VDLANG_NO_COMPANIES",
    VDLANG_NO_PROCESSES = "VDLANG_NO_PROCESSES",
    VDLANG_NO_OPPORTUNITIES = "VDLANG_NO_OPPORTUNITIES",
    VDLANG_UNKNOWN_PROCESS_NAME = "VDLANG_UNKNOWN_PROCESS_NAME",
    VDLANG_SHOW_PRIVATE_PROCESSES = "VDLANG_SHOW_PRIVATE_PROCESSES",
    VDLANG_SHOW_PRIVATE_PROCESSES_HINT = "VDLANG_SHOW_PRIVATE_PROCESSES_HINT",
    VDLANG_SHOW_ZERO_GROUPS = "VDLANG_SHOW_ZERO_GROUPS",
    VDLANG_EMAIL_ERROR_HINT = "VDLANG_EMAIL_ERROR_HINT",
    VDLANG_CHECK_LINK = "VDLANG_CHECK_LINK",
    VDLANG_SUBTREE_SELECTED_ICON_TOOLTIP = "VDLANG_SUBTREE_SELECTED_ICON_TOOLTIP",
    VDLANG_SUBTREE_CASCADING_SELECT_LABEL = "VDLANG_SUBTREE_CASCADING_SELECT_LABEL",
    VDLANG_EXPORT_CSV = "VDLANG_EXPORT_CSV",
    VDLANG_EXPORT_CSV_HINT = "VDLANG_EXPORT_CSV_HINT",
    VDLANG_EXPORT_CSV_TOGGLE_CSPLIT = "VDLANG_EXPORT_CSV_TOGGLE_CSPLIT",
    VDLANG_EXPORT_CSV_TOGGLE_UNWIND_BLANK = "VDLANG_EXPORT_CSV_TOGGLE_UNWIND_BLANK",
    VDLANG_EXPORT_CSV_TOGGLE_PRINT_FORMATTING = "VDLANG_EXPORT_CSV_TOGGLE_PRINT_FORMATTING",
    VDLANG_EXPORT_CSV_TOGGLE_LINK_COLUMN = "VDLANG_EXPORT_CSV_TOGGLE_LINK_COLUMN",
    VDLANG_EXPORT_CSV_LINK_COLUMN_TITLE = "VDLANG_EXPORT_CSV_LINK_COLUMN_TITLE",
    VDLANG_EXPORT_SPLIT_CURRENCY_VALUES_TOGGLE = "VDLANG_EXPORT_SPLIT_CURRENCY_VALUES_TOGGLE",
    VDLANG_EXPORT_SPLIT_CURRENCY_VALUES_TOGGLE_HINT = "VDLANG_EXPORT_SPLIT_VALUES_TOGGLE_HINT",
    VDLANG_EXPORT_CURRENCY_TOGGLE = "VDLANG_EXPORT_PROCESS_CURRENCY_TOGGLE",
    VDLANG_EXPORT_CSV_BUTTON = "VDLANG_EXPORT_CSV_BUTTON",
    VDLANG_EXPORT_CSV_LANGUAGE = "VDLANG_EXPORT_CSV_LANGUAGE",
    VDLANG_EXPORT_CSV_TOGGLE_EXCEL_METADATA = "VDLANG_EXPORT_CSV_TOGGLE_EXCEL_METADATA",
    VDLANG_EXPORT_CSV_TOGGLE_EXCEL_METADATA_HINT = "VDLANG_EXPORT_CSV_TOGGLE_EXCEL_METADATA_HINT",
    VDLANG_PREPARING_DATA = "VDLANG_PREPARING_DATA",
    VDLANG_CASCADER_NOT_SELECTED_PARTIALLY_CHILDREN_TOOLTIP = "VDLANG_CASCADER_NOT_SELECTED_PARTIALLY_CHILDREN_TOOLTIP",
    VDLANG_CASCADER_NOT_SELECTED_TOOLTIP = "VDLANG_CASCADER_NOT_SELECTED_TOOLTIP",
    VDLANG_CASCADER_SELECTED_TOOLTIP = "VDLANG_CASCADER_SELECTED_TOOLTIP",
    VDLANG_CASCADER_SELECTED_PARTIALLY_CHILDREN_TOOLTIP = "VDLANG_CASCADER_SELECTED_PARTIALLY_CHILDREN_TOOLTIP",
    VDLANG_METHOD_GUIDE_MAIN_TITLE = "VDLANG_METHOD_GUIDE_MAIN_TITLE",
    VDLANG_METHOD_GUIDE_SEARCH_RESULTS = "VDLANG_METHOD_GUIDE_SEARCH_RESULTS",
    VDLANG_METHOD_GUIDE_APPLY_METHOD_TO_MEASURE = "VDLANG_METHOD_GUIDE_APPLY_METHOD_TO_MEASURE",
    VDLANG_METHOD_GUIDE_METHOD_DETAILS = "VDLANG_METHOD_GUIDE_METHOD_DETAILS",
    VDLANG_METHOD_GUIDE_METHOD_DETAILS_DISABLED_HINT = "VDLANG_METHOD_GUIDE_METHOD_DETAILS_DISABLED_HINT",

    VDLANG_ACTIVITIES_OVERDUE_ICON_TOOLTIP = "VDLANG_ACTIVITIES_OVERDUE_ICON_TOOLTIP",
    VDLANG_ACTIVITIES_DUE_ICON_TOOLTIP = "VDLANG_ACTIVITIES_DUE_ICON_TOOLTIP",
    VDLANG_ACTIVITIES_MARK_COMPLETED_BUTTON_LABEL = "VDLANG_ACTIVITIES_MARK_COMPLETED_BUTTON_LABEL",
    VDLANG_ACTIVITIES_REOPEN_BUTTON_LABEL = "VDLANG_ACTIVITIES_REOPEN_BUTTON_LABEL",
    VDLANG_ACTIVITIES_REOPEN_BUTTON_HINT = "VDLANG_ACTIVITIES_REOPEN_BUTTON_HINT",
    VDLANG_ACTIVITIES_PROGRESS_LABEL = "VDLANG_ACTIVITIES_PROGRESS_LABEL",
    VDLANG_ACTIVITIES_NO_COMMENTS_MESSAGE = "VDLANG_ACTIVITIES_NO_COMMENTS_MESSAGE",
    VDLANG_ACTIVITIES_NUMBER_OF_COMMENTS_HINT = "VDLANG_ACTIVITIES_NUMBER_OF_COMMENTS_HINT",
    VDLANG_ACTIVITIES_NEW_ACTIVITY_PLACEHOLDER = "VDLANG_ACTIVITIES_NEW_ACTIVITY_PLACEHOLDER",
    VDLANG_ACTIVITIES_NEW_ACTIVITY_TEXTFIELD_PLACEHOLDER = "VDLANG_ACTIVITIES_NEW_ACTIVITY_TEXTFIELD_PLACEHOLDER",
    VDLANG_ACTIVITIES_NEW_ACTIVITY_TEXTFIELD_DEFAULT = "VDLANG_ACTIVITIES_NEW_ACTIVITY_TEXTFIELD_DEFAULT",
    VDLANG_ACTIVITIES_REMOVE_ACTIVITY = "VDLANG_ACTIVITIES_REMOVE_ACTIVITY",
    VDLANG_ACTIVITIES_ASSIGNED_TO_HINT = "VDLANG_ACTIVITIES_ASSIGNED_TO_HINT",
    VDLANG_ACTIVITIES_NO_ACTIVITIES = "VDLANG_ACTIVITIES_NO_ACTIVITIES",
    VDLANG_ACTIVITIES_NO_OPEN_ACTIVITIES = "VDLANG_ACTIVITIES_NO_OPEN_ACTIVITIES",
    VDLANG_ACTIVITIES_SHOW_CHANGES = "VDLANG_ACTIVITIES_SHOW_CHANGES",
    VDLANG_ACTIVITIES_TITLE_LENGTH_ERROR = "VDLANG_ACTIVITIES_TITLE_LENGTH_ERROR",
    VDLANG_ACTIVITIES_NOT_ALLOWED_ERROR = "VDLANG_ACTIVITIES_NOT_ALLOWED_ERROR",
    VDLANG_ACTIVITIES_NOT_VISIBLE_IN_LIST_HINT = "VDLANG_ACTIVITIES_NOT_VISIBLE_IN_LIST_HINT",
    VDLANG_ACTIVITIES_GATE_TASK_NOT_ASSIGNED = "VDLANG_ACTIVITIES_GATE_TASK_NOT_ASSIGNED",
    VDLANG_ACTIVITIES_GATE_TASK_NOT_ASSIGNED_LABEL = "VDLANG_ACTIVITIES_GATE_TASK_NOT_ASSIGNED_LABEL",
    VDLANG_ACTIVITIES_PROCESS_FILTER_FILTERS = "VDLANG_ACTIVITIES_PROCESS_FILTER_FILTERS",

    VDLANG_MY_ACTIVITIES_TITLE = "VDLANG_MY_ACTIVITIES_TITLE",

    VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_LABEL = "VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_LABEL",
    VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_ASSIGNED_TO_ME = "VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_ASSIGNED_TO_ME",
    VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_CREATED_BY_ME = "VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_CREATED_BY_ME",
    VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_ALL_I_CAN_SEE = "VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_ALL_I_CAN_SEE",
    VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_ASSIGNED_TO_TITLE = "VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_ASSIGNED_TO_TITLE",
    VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_CREATED_BY_TITLE = "VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_CREATED_BY_TITLE",
    VDLANG_MY_ACTIVITIES_PREFERENCES_UNASSIGNED_USER_OPTION = "VDLANG_MY_ACTIVITIES_PREFERENCES_UNASSIGNED_USER_OPTION",

    VDLANG_MY_ACTIVITIES_PREFERENCES_STATUS_LABEL = "VDLANG_MY_ACTIVITIES_PREFERENCES_STATUS_LABEL",
    VDLANG_MY_ACTIVITIES_PREFERENCES_STATUS_OPEN = "VDLANG_MY_ACTIVITIES_PREFERENCES_STATUS_OPEN",
    VDLANG_MY_ACTIVITIES_PREFERENCES_STATUS_COMPLETED = "VDLANG_MY_ACTIVITIES_PREFERENCES_STATUS_COMPLETED",
    VDLANG_MY_ACTIVITIES_PREFERENCES_STATUS_ALL = "VDLANG_MY_ACTIVITIES_PREFERENCES_STATUS_ALL",

    VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_BY_LABEL = "VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_BY_LABEL",
    VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_BY_ASSIGNED_TO = "VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_BY_ASSIGNED_TO",
    VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_BY_CREATED_AT = "VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_BY_CREATED_AT",
    VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_ASC = "VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_ASC",
    VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_DESC = "VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_DESC",
    VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_ALPHABETICALLY = "VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_ALPHABETICALLY",

    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUPING_TITLE = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUPING_TITLE",

    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_LABEL = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_LABEL",
    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_CREATED_BY = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_CREATED_BY",
    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_ASSIGNED_TO = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_ASSIGNED_TO",
    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_NO_DUEDATE = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_NO_DUEDATE",
    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_OVERDUE = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_OVERDUE",
    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_TODAY = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_TODAY",
    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_WEEK = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_WEEK",
    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_MONTH = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_MONTH",
    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_FUTURE = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_FUTURE",
    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_OPEN_START = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_OPEN_START",
    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_OPEN_END = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_OPEN_END",

    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_ORDER_BY_LABEL = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_ORDER_BY_LABEL",
    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_MEASURE_ORDER_BY_EFFECT = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_MEASURE_ORDER_BY_EFFECT",
    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_PROCESS_ORDER_BY_PROCESS_ID = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_PROCESS_ORDER_BY_PROCESS_ID",
    VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_MEASURE_ORDER_BY_DAYS_UNTIL_EFFECTIVENESS = "VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_MEASURE_ORDER_BY_DAYS_UNTIL_EFFECTIVENESS",

    VDLANG_DATERANGE_PICKER_PRESET_BUTTON_DISABLED_TOOLTIP = "VDLANG_DATERANGE_PICKER_PRESET_BUTTON_DISABLED_TOOLTIP",
    VDLANG_DATERANGE_PICKER_NO_DATE_HINT = "VDLANG_DATERANGE_PICKER_DATE_HINT",
    VDLANG_DATERANGE_PICKER_END_BEFORE_START = "VDLANG_DATERANGE_PICKER_END_BEFORE_START",
    VDLANG_DATERANGE_PICKER_OUTSIDE_SCOPE = "VDLANG_DATERANGE_PICKER_OUTSIDE_SCOPE",

    VDLANG_DATERANGE_PICKER_TODAY_BUTTON_LABEL = "VDLANG_DATERANGE_PICKER_TODAY_BUTTON_LABEL",
    VDLANG_DATERANGE_PICKER_CLEAR_BUTTON_LABEL = "VDLANG_DATERANGE_PICKER_CLEAR_BUTTON_LABEL",

    VDLANG_ADMIN_SIDEBAR_GROUP_SETTINGS = "VDLANG_ADMIN_SIDEBAR_GROUP_SETTINGS",
    VDLANG_ADMIN_SIDEBAR_SETTINGS_ORGANIZATION = "VDLANG_ADMIN_SIDEBAR_SETTINGS_ORGANIZATION",

    VDLANG_ADMIN_SIDEBAR_GROUP_DATA = "VDLANG_ADMIN_SIDEBAR_GROUP_DATA",
    VDLANG_ADMIN_SIDEBAR_DATA_COMPANIES = "VDLANG_ADMIN_SIDEBAR_DATA_COMPANIES",
    VDLANG_ADMIN_SIDEBAR_DATA_TREE_NODES = "VDLANG_ADMIN_SIDEBAR_DATA_TREE_NODES",
    VDLANG_ADMIN_SIDEBAR_DATA_SUPPLIERS = "VDLANG_ADMIN_SIDEBAR_DATA_SUPPLIERS",
    VDLANG_ADMIN_SIDEBAR_DATA_CURRENCIES = "VDLANG_ADMIN_SIDEBAR_DATA_CURRENCIES",
    VDLANG_ADMIN_SIDEBAR_DATA_PROCESSES = "VDLANG_ADMIN_SIDEBAR_DATA_PROCESSES",
    VDLANG_ADMIN_SIDEBAR_DATA_DEPARTMENTS = "VDLANG_ADMIN_SIDEBAR_DATA_DEPARTMENTS",
    VDLANG_ADMIN_SIDEBAR_DATA_OPPS = "VDLANG_ADMIN_SIDEBAR_DATA_OPPS",

    VDLANG_ADMIN_SIDEBAR_GROUP_USERS_AND_ACCESS = "VDLANG_ADMIN_SIDEBAR_GROUP_USERS_AND_ACCESS",
    VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_DEFAULT_PERMISSIONS = "VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_DEFAULT_PERMISSIONS",
    VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_USERS = "VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_USERS",
    VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_GROUPS = "VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_GROUPS",
    VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_PERMISSIONS = "VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_PERMISSIONS",
    VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_DOMAINS = "VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_DOMAINS",
    VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_SIGNON = "VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_SIGNON",
    VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_SCIM = "VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_SCIM",

    VDLANG_ADMIN_DEFAULT_PERMISSIONS_DEFAULT_GROUP = "VDLANG_ADMIN_DEFAULT_PERMISSIONS_DEFAULT_GROUP",
    VDLANG_ADMIN_DEFAULT_PERMISSIONS_DEFAULT_GROUP_HINT = "VDLANG_ADMIN_DEFAULT_PERMISSIONS_DEFAULT_GROUP_HINT",
    VDLANG_ADMIN_DEFAULT_PERMISSIONS_EXTERNAL_USER_GROUP = "VDLANG_ADMIN_DEFAULT_PERMISSIONS_EXTERNAL_USER_GROUP",
    VDLANG_ADMIN_DEFAULT_PERMISSIONS_EXTERNAL_USER_GROUP_HINT = "VDLANG_ADMIN_DEFAULT_PERMISSIONS_EXTERNAL_USER_GROUP_HINT",
    VDLANG_ADMIN_DEFAULT_PERMISSIONS_PSEUDO_PUBLIC_MESURE_GROUP = "VDLANG_ADMIN_DEFAULT_PERMISSIONS_PSEUDO_PUBLIC_MESURE_GROUP",
    VDLANG_ADMIN_DEFAULT_PERMISSIONS_PSEUDO_PUBLIC_MESURE_GROUP_HINT = "VDLANG_ADMIN_DEFAULT_PERMISSIONS_PSEUDO_PUBLIC_MESURE_GROUP_HINT",

    VDLANG_ADMIN_SIGNON = "VDLANG_ADMIN_SIGNON",
    VDLANG_ADMIN_SIGNON_CREATE = "VDLANG_ADMIN_SIGNON_CREATE",
    VDLANG_ADMIN_SIGNON_FROM_METADATA = "VDLANG_ADMIN_SIGNON_FROM_METADATA",
    VDLANG_ADMIN_SIGNON_MANUAL_TEXT = "VDLANG_ADMIN_SIGNON_MANUAL_TEXT",
    VDLANG_ADMIN_SIGNON_MANUAL_LINK = "VDLANG_ADMIN_SIGNON_MANUAL_LINK",
    VDLANG_ADMIN_SIGNON_METADATA_TEXT = "VDLANG_ADMIN_SIGNON_METADATA_TEXT",
    VDLANG_ADMIN_SIGNON_METADATA_LINK = "VDLANG_ADMIN_SIGNON_METADATA_LINK",
    VDLANG_IDENTITY_PROVIDER_TYPE = "VDLANG_IDENTITY_PROVIDER_TYPE",
    VDLANG_IDENTITY_PROVIDER_LOGIN_URL = "VDLANG_IDENTITY_PROVIDER_LOGIN_URL",
    VDLANG_IDENTITY_PROVIDER_ISSUER = "VDLANG_IDENTITY_PROVIDER_ISSUER",
    VDLANG_IDENTITY_PROVIDER_CERT = "VDLANG_IDENTITY_PROVIDER_CERT",
    VDLANG_IDENTITY_PROVIDER_AUTHN_CONTEXT = "VDLANG_IDENTITY_PROVIDER_AUTHN_CONTEXT",
    VDLANG_IDENTITY_PROVIDER_AUTHN_DISABLE = "VDLANG_IDENTITY_PROVIDER_AUTHN_DISABLE",
    VDLANG_IDENTITY_PROVIDER_DELETE_PASSWORD = "VDLANG_IDENTITY_PROVIDER_DELETE_PASSWORD",
    VDLANG_IDENTITY_PROVIDER_2FA = "VDLANG_IDENTITY_PROVIDER_2FA",
    VDLANG_USER_2FA_STATUS = "VDLANG_USER_2FA_STATUS",
    VDLANG_USER_2FA_ACTIVATED_HINT = "VDLANG_USER_2FA_ACTIVATED_HINT",
    VDLANG_USER_2FA_MANAGED_BY_SIGN_ON = "VDLANG_USER_2FA_MANAGED_BY_SIGN_ON",
    VDLANG_USER_2FA_REQUIRED_BUT_NOT_SETUP = "VDLANG_USER_2FA_REQUIRED_BUT_NOT_SETUP",
    VDLANG_USER_ENFORCE_2FA = "VDLANG_USER_ENFORCE_2FA",
    VDLANG_USER_ENFORCE_2FA_BY_PROVIDER = "VDLANG_USER_ENFORCE_2FA_BY_PROVIDER",
    VDLANG_USER_RESET_2FA = "VDLANG_USER_RESET_2FA",
    VDLANG_ADMIN_CURRENCIES_DEFAULT_CURRENCY_HINT = "VDLANG_ADMIN_CURRENCIES_DEFAULT_CURRENCY_HINT",
    VDLANG_ADMIN_CURRENCIES_DEFAULT_CURRENCY_TOOLTIP = "VDLANG_ADMIN_CURRENCIES_DEFAULT_CURRENCY_TOOLTIP",
    VDLANG_ADMIN_CURRENCIES_ADD_CURRENCY_BUTTON_LABEL = "VDLANG_ADMIN_CURRENCIES_ADD_CURRENCY_BUTTON_LABEL",
    VDLANG_ADMIN_CURRENCIES_NO_DATA = "VDLANG_ADMIN_CURRENCIES_NO_DATA",

    VDLANG_ADMIN_COMPANIES_SEATS_HINT = "VDLANG_ADMIN_COMPANIES_SEATS_HINT",
    VDLANG_ADMIN_ADMIN_USER_SEATS_HINT = "VDLANG_ADMIN_ADMIN_USER_SEATS_HINT",
    VDLANG_ADMIN_ADVANCED_USER_SEATS_HINT = "VDLANG_ADMIN_ADVANCED_USER_SEATS_HINT",
    VDLANG_ADMIN_BASIC_USER_SEATS_HINT = "VDLANG_ADMIN_BASIC_USER_SEATS_HINT",
    VDLANG_ADMIN_LIGHT_USER_SEATS_HINT = "VDLANG_ADMIN_LIGHT_USER_SEATS_HINT",
    VDLANG_ADMIN_ADVANCED_USER_SEATS_IN_USE_HINT = "VDLANG_ADMIN_ADVANCED_USER_SEATS_IN_USE_HINT",
    VDLANG_ADMIN_BASIC_USER_SEATS_IN_USE_HINT = "VDLANG_ADMIN_BASIC_USER_SEATS_IN_USE_HINT",
    VDLANG_ADMIN_MEMBERS_GROUPS = "VDLANG_ADMIN_MEMBERS_GROUPS",
    VDLANG_ADMIN_MEMBERS_GROUPS_DIRECT = "VDLANG_ADMIN_MEMBERS_GROUPS_DIRECT",
    VDLANG_ADMIN_MEMBERS_RESTRICTED_GROUPS_HINT = "VDLANG_ADMIN_MEMBERS_RESTRICTED_GROUPS_HINT",
    VDLANG_ADMIN_PARTNER_SEATS_HINT = "VDLANG_ADMIN_PARTNER_SEATS_HINT",

    VDLANG_TOKEN_EXPIRATION_TIME = "VDLANG_TOKEN_EXPIRATION_TIME",
    VDLANG_USER_PROFILE_CUSTOM = "VDLANG_USER_PROFILE_CUSTOM",
    VDLANG_IS_TESTED = "VDLANG_IS_TESTED",
    VDLANG_YES = "VDLANG_YES",
    VDLANG_NO = "VDLANG_NO",
    VDLANG_FIELD_FAVORITE_IS_FAVORITE = "VDLANG_FIELD_FAVORITE_IS_FAVORITE",
    VDLANG_FIELD_FAVORITE_IS_NOT_FAVORITE = "VDLANG_FIELD_FAVORITE_IS_NOT_FAVORITE",

    VDLANG_ADMIN_DOMAIN = "VDLANG_ADMIN_DOMAIN",
    VDLANG_ADMIN_DOMAIN_CREATE = "VDLANG_ADMIN_DOMAIN_CREATE",

    VDLANG_ADMIN_MEASURES_REOPEN_MEASURE = "VDLANG_ADMIN_MEASURES_REOPEN_MEASURE",
    VDLANG_ADMIN_MEASURES_UNDISCARD_MEASURE = "VDLANG_ADMIN_MEASURES_UNDISCARD_MEASURE",
    VDLANG_ADMIN_MEASURES_REOPEN_LAST_GATE_TASK = "VDLANG_ADMIN_MEASURES_REOPEN_LAST_GATE_TASK",
    VDLANG_ADMIN_MEASURES_CHANGE_RESPONSIBILITY = "VDLANG_ADMIN_MEASURES_CHANGE_RESPONSIBILITY",
    VDLANG_ADMIN_OPPS_REOPEN_OPP = "VDLANG_ADMIN_OPPS_REOPEN_OPP",
    VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM = "VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM",
    VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_ERROR = "VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_ERROR",
    VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_ERROR_EC = "VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_ERROR_EC",
    VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_ERROR_GT = "VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_ERROR_GT",
    VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_WARN_FIELD_ADD = "VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_WARN_FIELD_ADD",
    VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_WARN_FIELD_REMOVE = "VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_WARN_FIELD_REMOVE",
    VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_WARN_RESPONSIBLE_MISSING_PERMISSION = "VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_WARN_RESPONSIBLE_MISSING_PERMISSION",

    VDLANG_ADMIN_GROUPS_NO_GROUPS = "VDLANG_ADMIN_GROUPS_NO_GROUPS",
    VDLANG_ADMIN_GROUPS_NO_USERS = "VDLANG_ADMIN_GROUPS_NO_USERS",
    VDLANG_ADMIN_GROUPS_GROUP_NAME = "VDLANG_ADMIN_GROUPS_GROUP_NAME",
    VDLANG_ADMIN_GROUPS_GROUP_DESCRIPTION = "VDLANG_ADMIN_GROUPS_GROUP_DESCRIPTION",
    VDLANG_USERS = "VDLANG_USERS",
    VDLANG_USER_TIER = "VDLANG_USER_TIER",
    VDLANG_USER_TIER_HINT = "VDLANG_USER_TIER_HINT",
    VDLANG_USER_TIERS = "VDLANG_USER_TIERS",
    VDLANG_ADMIN_GROUPS_CREATE = "VDLANG_ADMIN_GROUPS_CREATE",
    VDLANG_ADMIN_GROUPS_DIALOG_CREATE = "VDLANG_ADMIN_GROUPS_DIALOG_CREATE",
    VDLANG_ADMIN_GROUPS_DIALOG_UPDATE = "VDLANG_ADMIN_GROUPS_DIALOG_UPDATE",
    VDLANG_ADMIN_GROUPS_DIALOG_NAME_LONG = "VDLANG_ADMIN_GROUPS_DIALOG_NAME_LONG",
    VDLANG_ADMIN_GROUPS_DIALOG_NAME_SHORT = "VDLANG_ADMIN_GROUPS_DIALOG_NAME_SHORT",
    VDLANG_ADMIN_GROUPS_DIALOG_DESCRIPTION_ERROR = "VDLANG_ADMIN_GROUPS_DIALOG_DESCRIPTION_ERROR",
    VDLANG_ADMIN_GROUPS_DIALOG_CREATE_BUTTON = "VDLANG_ADMIN_GROUPS_DIALOG_CREATE_BUTTON",
    VDLANG_ADMIN_GROUPS_DIALOG_UPDATE_BUTTON = "VDLANG_ADMIN_GROUPS_DIALOG_UPDATE_BUTTON",
    VDLANG_ADMIN_GROUPS_EDIT_BUTTON = "VDLANG_ADMIN_GROUPS_EDIT_BUTTON",
    VDLANG_ADMIN_GROUPS_DUPLICATE_BUTTON = "VDLANG_ADMIN_GROUPS_DUPLICATE_BUTTON",
    VDLANG_ADMIN_GROUPS_DUPLICATE_DISABLED = "VDLANG_ADMIN_GROUPS_DUPLICATE_DISABLED",
    VDLANG_ADMIN_GROUPS_DUPLICATE_ADDITION = "VDLANG_ADMIN_GROUPS_DUPLICATE_ADDITION",
    VDLANG_ADMIN_GROUPS_DELETE_BUTTON = "VDLANG_ADMIN_GROUPS_DELETE_BUTTON",
    VDLANG_ADMIN_GROUPS_DELETE_IN_USE_DISABLED = "VDLANG_ADMIN_GROUPS_DELETE_IN_USE_DISABLED",
    VDLANG_ADMIN_GROUPS_TITLE_ALLGROUPS = "VDLANG_ADMIN_GROUPS_TITLE_ALLGROUPS",
    VDLANG_ADMIN_GROUPS_REMOVE_USER = "VDLANG_ADMIN_GROUPS_REMOVE_USER",
    VDLANG_ADMIN_GROUPS_REMOVE_USER_CONFIRM = "VDLANG_ADMIN_GROUPS_REMOVE_USER_CONFIRM",
    VDLANG_ADMIN_GROUPS_MANAGE_USERS_BUTTON = "VDLANG_ADMIN_GROUPS_MANAGE_USERS_BUTTON",
    VDLANG_ADMIN_GROUPS_SUBGROUPS_DISABLED = "VDLANG_ADMIN_GROUPS_SUBGROUPS_DISABLED",
    VDLANG_ADMIN_GROUPS_SPECIAL_DISABLED = "VDLANG_ADMIN_GROUPS_SPECIAL_DISABLED",
    VDLANG_ADMIN_GROUPS_PERMISSION_DERIVED = "VDLANG_ADMIN_GROUPS_PERMISSION_DERIVED",

    VDLANG_ADMIN_GROUPS_SUBGROUPS = "VDLANG_ADMIN_GROUPS_SUBGROUPS",
    VDLANG_ADMIN_GROUPS_ALL_USERS = "VDLANG_ADMIN_GROUPS_ALL_USERS",
    VDLANG_ADMIN_GROUPS_ALL_SUBGROUPS = "VDLANG_ADMIN_GROUPS_ALL_SUBGROUPS",
    VDLANG_ADMIN_GROUPS_MANAGE_USERS_TITLE = "VDLANG_ADMIN_GROUPS_MANAGE_USERS_TITLE",
    VDLANG_ADMIN_GROUPS_ALL_USERS_TITLE = "VDLANG_ADMIN_GROUPS_ALL_USERS_TITLE",
    VDLANG_ADMIN_GROUPS_ASSIGNED_USERS = "VDLANG_ADMIN_GROUPS_ASSIGNED_USERS",
    VDLANG_ADMIN_GROUPS_USERS = "VDLANG_ADMIN_GROUPS_USERS",
    VDLANG_ADMIN_GROUPS_GROUPS = "VDLANG_ADMIN_GROUPS_GROUPS",
    VDLANG_ADMIN_GROUPS_CACHE_HINT = "VDLANG_ADMIN_GROUPS_CACHE_HINT",
    VDLANG_ADMIN_GROUPS_VISIBILITY = "VDLANG_ADMIN_GROUPS_VISIBILITY",
    VDLANG_ADMIN_GROUPS_VISIBLE = "VDLANG_ADMIN_GROUPS_VISIBLE",
    VDLANG_ADMIN_GROUPS_VISIBLE_HINT = "VDLANG_ADMIN_GROUPS_VISIBLE_HINT",
    VDLANG_ADMIN_GROUPS_HIDDEN = "VDLANG_ADMIN_GROUPS_HIDDEN",

    VDLANG_NEW_DOMAIN_PLACEHOLDER = "VDLANG_NEW_DOMAIN_PLACEHOLDER",
    VDLANG_GLOBAL_DOMAIN_HINT = "VDLANG_GLOBAL_DOMAIN_HINT",
    VDLANG_NO_DOMAINS = "VDLANG_NO_DOMAINS",
    VDLANG_DOMAIN_NAME_ALREADY_EXISTS = "VDLANG_DOMAIN_NAME_ALREADY_EXISTS",
    VDLANG_INVALID_DOMAIN_NAME = "VDLANG_INVALID_DOMAIN_NAME",
    VDLANG_SHOW_ONLY_PROCESSES_IN_PERIOD_UNDER_CONSIDERATION = "VDLANG_SHOW_ONLY_PROCESSES_IN_PERIOD_UNDER_CONSIDERATION",
    VDLANG_MY_PROFILE_TITLE = "VDLANG_MY_PROFILE_TITLE",
    VDLANG_MY_PROFILE_COMPANY = "VDLANG_MY_PROFILE_COMPANY",
    VDLANG_MY_PROFILE_CURRENCY = "VDLANG_MY_PROFILE_CURRENCY",
    VDLANG_INTEGRATIONS_TITLE = "VDLANG_INTEGRATIONS_TITLE",
    VDLANG_INTEGRATIONS_RENEW_TOKEN = "VDLANG_INTEGRATIONS_RENEW_TOKEN",
    VDLANG_INTEGRATIONS_TOKEN = "VDLANG_INTEGRATIONS_TOKEN",
    VDLANG_INTEGRATIONS_RENEW_INFO = "VDLANG_INTEGRATIONS_RENEW_INFO",
    VDLANG_INTEGRATIONS_EXISTS_EXPIRES_AT = "VDLANG_INTEGRATIONS_EXISTS_EXPIRES_AT",
    VDLANG_SCIM_INTEGRATIONS_BASE_PATH = "VDLANG_SCIM_INTEGRATIONS_BASE_PATH",
    VDLANG_UPDATE_PROFILE = "VDLANG_UPDATE_PROFILE",
    VDLANG_UPDATE_PICTURE = "VDLANG_UPDATE_PICTURE",
    VDLANG_UPDATE_LOGO = "VDLANG_UPDATE_LOGO",
    VDLANG_UPDATE_DASHBOARD_COLORS = "VDLANG_UPDATE_DASHBOARD_COLORS",
    VDLANG_UPLOAD = "VDLANG_UPLOAD",
    VDLANG_FILE_SIZE_UPLOAD = "VDLANG_FILE_SIZE_UPLOAD",
    VDLANG_DELETE_AVATAR = "VDLANG_DELETE_AVATAR",
    VDLANG_DELETE_LOGO = "VDLANG_DELETE_LOGO",
    VDLANG_ADMIN_DASHBOARD_COLOR_LABEL = "VDLANG_ADMIN_DASHBOARD_COLOR_LABEL",
    VDLANG_ADMIN_DASHBOARD_WHITE_SPOT_COLOR_LABEL = "VDLANG_ADMIN_DASHBOARD_WHITE_SPOT_COLOR_LABEL",
    VDLANG_ADMIN_DASHBOARD_WHITE_SPOT_COLOR_WARNING = "VDLANG_ADMIN_DASHBOARD_WHITE_SPOT_COLOR_WARNING",
    VDLANG_ADMIN_DASHBOARD_COLOR_LABEL_PREVIEW = "VDLANG_ADMIN_DASHBOARD_COLOR_LABEL_PREVIEW",
    VDLANG_ADMIN_DASHBOARD_RESET_DASHBOARD_COLORS_TO_DEFAULT = "VDLANG_ADMIN_DASHBOARD_RESET_DASHBOARD_COLORS_TO_DEFAULT",
    VDLANG_COLOR_INPUT_BAD_VALUE = "VDLANG_COLOR_INPUT_BAD_VALUE",
    VDLANG_PROCESS = "VDLANG_PROCESS",
    VDLANG_RESPONSIBLE = "VDLANG_RESPONSIBLE",
    VDLANG_RESPONSIBLE_HINT = "VDLANG_RESPONSIBLE_HINT",
    VDLANG_RESPONSIBLE_ASSIGN_PERSON = "VDLANG_RESPONSIBLE_ASSIGN_PERSON",
    VDLANG_ASSIGN_CLIENT_GROUPS = "VDLANG_ASSIGN_CLIENT_GROUPS",
    VDLANG_RESPONSIBLE_CHANGE_PERSON = "VDLANG_RESPONSIBLE_CHANGE_PERSON",
    VDLANG_RESPONSIBLE_CURRENT_PERSON = "VDLANG_RESPONSIBLE_CURRENT_PERSON",
    VDLANG_RESPONSIBLE_NOT_ASSIGNED = "VDLANG_RESPONSIBLE_NOT_ASSIGNED",
    VDLANG_DUE_DATE = "VDLANG_DUE_DATE",
    VDLANG_INVALID_FILETYPE = "VDLANG_INVALID_FILETYPE",
    VDLANG_INVALID_FILESIZE_TOO_LARGE = "VDLANG_INVALID_FILESIZE_TOO_LARGE",
    VDLANG_INVALID_FILECOUNT = "VDLANG_INVALID_FILECOUNT",
    VDLANG_FILE_NOT_READABLE = "VDLANG_FILE_NOT_READABLE",
    VDLANG_PARSING_ERROR = "VDLANG_PARSING_ERROR",
    VDLANG_TFA_ACTIVATE = "VDLANG_TFA_ACTIVATE",
    VDLANG_TFA = "VDLANG_TFA",
    VDLANG_TFA_SETUP_HINT_ENTER_PASSWORD = "VDLANG_TFA_SETUP_HINT_PASSWORD",
    VDLANG_TFA_SETUP_TITLE_ENTER_PASSWORD = "VDLANG_TFA_SETUP_TITLE_ENTER_PASSWORD",
    VDLANG_TFA_SETUP_HINT_SCAN_QR = "VDLANG_TFA_SETUP_HINT_SCAN_QR",
    VDLANG_TFA_SETUP_TITLE_SCAN_QR = "VDLANG_TFA_SETUP_TITLE_SCAN_QR",
    VDLANG_TFA_SETUP_AUTHENTICATOR_1_NAME = "VDLANG_TFA_SETUP_AUTHENTICATOR_1_NAME",
    VDLANG_TFA_SETUP_AUTHENTICATOR_1_LINK = "VDLANG_TFA_SETUP_AUTHENTICATOR_1_LINK",
    VDLANG_TFA_SETUP_AUTHENTICATOR_2_NAME = "VDLANG_TFA_SETUP_AUTHENTICATOR_2_NAME",
    VDLANG_TFA_SETUP_AUTHENTICATOR_2_LINK = "VDLANG_TFA_SETUP_AUTHENTICATOR_2_LINK",
    VDLANG_TFA_SETUP_HINT_VALIDATE_TOKEN = "VDLANG_TFA_SETUP_HINT_VALIDATE_TOKEN",
    VDLANG_TFA_SETUP_TITLE_VALIDATE_TOKEN = "VDLANG_TFA_SETUP_TITLE_VALIDATE_TOKEN",
    VDLANG_TFA_SETUP_ENTER_CODE = "VDLANG_TFA_SETUP_ENTER_CODE",
    VDLANG_TFA_SETUP_INFO = "VDLANG_TFA_SETUP_INFO",
    VDLANG_TFA_SETUP_ALREADY_DONE = "VDLANG_TFA_SETUP_ALREADY_DONE",
    VDLANG_LOGIN_INCORRECT_TFA_TOKEN = "VDLANG_LOGIN_INCORRECT_TFA_TOKEN",
    VDLANG_TFA_REMEMBER_DEVICE = "VDLANG_TFA_REMEMBER_DEVICE",

    VDLANG_CLOSE = "VDLANG_CLOSE",
    VDLANG_ACTIVATED = "VDLANG_ACTIVE",
    VDLANG_NOT_ACTIVATED = "VDLANG_NOT_ACTIVATED",
    VDLANG_DEACTIVATED = "VDLANG_DEACTIVATED",

    VDLANG_INTEGER_INPUT_ONLY_DIGITS = "VDLANG_INTEGER_INPUT_ONLY_DIGITS",
    VDLANG_INTEGER_PERCENTAGE_INPUT_ONLY = "VDLANG_INTEGER_PERCENTAGE_INPUT_ONLY",

    VDLANG_ENTRY_ALREADY_EXISTS = "VDLANG_ENTRY_ALREADY_EXISTS",

    VDLANG_DELETE_USER = "VDLANG_DELETE_USER",
    VDLANG_ENTER_NUMBER_OF_DAYS = "VDLANG_ENTER_NUMBER_OF_DAYS",
    VDLANG_DATERANGE = "VDLANG_DATERANGE",
    VDLANG_DATERANGE_BEFORE = "VDLANG_DATERANGE_BEFORE",
    VDLANG_DATERANGE_AFTER = "VDLANG_DATERANGE_AFTER",
    VDLANG_DATERANGE_DAYS = "VDLANG_DATERANGE_DAYS",
    VDLANG_ERROR_PAGE_TITLE = "VDLANG_ERROR_PAGE_TITLE",
    VDLANG_ERROR_PAGE_SOMETHING_WENT_WRONG = "VDLANG_ERROR_PAGE_SOMETHING_WENT_WRONG",
    VDLANG_ERROR_PAGE_RECOMMENDATION = "VDLANG_ERROR_PAGE_RECOMMENDATION",
    VDLANG_ERROR_PAGE_RELOAD_BUTTON = "VDLANG_ERROR_PAGE_RELOAD_BUTTON",
    VDLANG_ERROR_PAGE_START_PAGE = "VDLANG_ERROR_PAGE_START_PAGE",
    VDLANG_ONLY_NUMBERS = "VDLANG_ONLY_NUMBERS",
    VDLANG_TIMERANGE_PUC_HINT = "VDLANG_TIMERANGE_PUC_HINT",

    VDLANG_MEASURE_NOTIFICATIONS_RECEIVE_NOTIFICATIONS = "VDLANG_MEASURE_NOTIFICATIONS_RECEIVE_NOTIFICATIONS",
    VDLANG_MEASURE_NOTIFICATIONS_ADD_USER_TITLE = "VDLANG_MEASURE_NOTIFICATIONS_ADD_USER_TITLE",
    VDLANG_MEASURE_NOTIFICATIONS_ENABLED_NOTIFICATIONS = "VDLANG_MEASURE_NOTIFICATIONS_ENABLED_NOTIFICATIONS",
    VDLANG_MEASURE_NOTIFICATIONS_ENABLED_NOTIFICATIONS_HINT = "VDLANG_MEASURE_NOTIFICATIONS_ENABLED_NOTIFICATIONS_HINT",
    VDLANG_MEASURE_NOTIFICATIONS_ONLY_MEASURE_OWNER_CAN_CHANGE = "VDLANG_MEASURE_NOTIFICATIONS_ONLY_MEASURE_OWNER_CAN_CHANGE",
    VDLANG_MEASURE_NOTIFICATIONS_ONLY_MEASURE_OWNER_CAN_CHANGE_WITH_USER = "VDLANG_MEASURE_NOTIFICATIONS_ONLY_MEASURE_OWNER_CAN_CHANGE_WITH_USER",
    VDLANG_MEASURE_NOTIFICATIONS_OWN_NOTIFICATIONS_ENABLED = "VDLANG_MEASURE_NOTIFICATIONS_OWN_NOTIFICATIONS_ENABLED",
    VDLANG_MEASURE_NOTIFICATIONS_OWN_NOTIFICATIONS_DISABLED = "VDLANG_MEASURE_NOTIFICATIONS_OWN_NOTIFICATIONS_DISABLED",
    VDLANG_MEASURE_NOTIFICATIONS_OTHER_NOTIFICATIONS_ENABLED = "VDLANG_MEASURE_NOTIFICATIONS_OTHER_NOTIFICATIONS_ENABLED",
    VDLANG_MEASURE_NOTIFICATIONS_OTHER_NOTIFICATIONS_DISABLED = "VDLANG_MEASURE_NOTIFICATIONS_OTHER_NOTIFICATIONS_DISABLED",
    VDLANG_MEASURE_NOTIFICATIONS_USER_DISABLED_HINT = "VDLANG_MEASURE_NOTIFICATIONS_USER_DISABLED_HINT",

    VDLANG_USER_SELECT_WIDGET_NO_USER_RESULTS = "VDLANG_USER_SELECT_WIDGET_NO_USER_RESULTS",
    VDLANG_USER_SELECT_WIDGET_NO_SELECTABLE_USERS = "VDLANG_USER_SELECT_WIDGET_NO_SELECTABLE_USERS",
    VDLANG_USER_SELECT_WIDGET_NO_OTHER_SELECTABLE_USERS = "VDLANG_USER_SELECT_WIDGET_NO_OTHER_SELECTABLE_USERS",
    VDLANG_USER_SELECT_WIDGET_SEARCH_LABEL = "VDLANG_USER_SELECT_WIDGET_SEARCH_LABEL",
    VDLANG_USER_SELECT_WIDGET_SEARCH_PLACEHOLDER = "VDLANG_USER_SELECT_WIDGET_SEARCH_PLACEHOLDER",
    VDLANG_USER_SELECT_WIDGET_SEARCH_ASSIGN = "VDLANG_USER_SELECT_WIDGET_SEARCH_ASSIGN",

    VDLANG_SYSTEM_USER = "VDLANG_SYSTEM_USER",

    VDLANG_MEASURE_DECIDER_ASSIGNMENT_HINT = "VDLANG_MEASURE_DECIDER_ASSIGNMENT_HINT",
    VDLANG_MEASURE_DECIDER_SHOULD_ADD_TEXT = "VDLANG_MEASURE_DECIDER_SHOULD_ADD_TEXT",

    VDLANG_MEASURE_DECIDER_SHOULD_ADD_TITLE = "VDLANG_MEASURE_DECIDER_SHOULD_ADD_TITLE",
    VDLANG_CONFIRM = "VDLANG_CONFIRM",

    VDLANG_MEASURE_VISIBILITY_RESTRICTED = "VDLANG_MEASURE_VISIBILITY_RESTRICTED",
    VDLANG_MEASURE_PRIVILEDGED_ACCESS = "VDLANG_MEASURE_PRIVILEDGED_ACCESS",
    VDLANG_MEASURE_VISIBILITY_RESTRICTED_MESSAGE = "VDLANG_MEASURE_VISIBILITY_RESTRICTED_MESSAGE",
    VDLANG_MEASURE_VISIBILITY_RESTRICTED_HINT = "VDLANG_MEASURE_VISIBILITY_RESTRICTED_HINT",
    VDLANG_MEASURE_PRIVILEDGED_ACCESS_NO_USER = "VDLANG_MEASURE_PRIVILEDGED_ACCESS_NO_USER",

    VDLANG_MEASURE_ACCESS_CONTROL_BUTTON = "VDLANG_MEASURE_ACCESS_CONTROL_BUTTON",
    VDLANG_MEASURE_ACCESS_CONTROL_RESPONSIBLE_USER = "VDLANG_MEASURE_ACCESS_CONTROL_RESPONSIBLE_USER",
    VDLANG_MEASURE_ACCESS_CONTROL_WHO_HAS_ACCESS = "VDLANG_MEASURE_ACCESS_CONTROL_WHO_HAS_ACCESS",
    VDLANG_MEASURE_ACCESS_CONTROL_ADD_MORE_MEMBERS = "VDLANG_MEASURE_ACCESS_CONTROL_ADD_MORE_MEMBERS",
    VDLANG_MEASURE_ACCESS_CONTROL_ADD_MORE_USERS_GROUPS = "VDLANG_MEASURE_ACCESS_CONTROL_ADD_MORE_USERS_GROUPS",
    VDLANG_MEASURE_ACCESS_CONTROL_ONLY_OWNER_CAN_CHANGE = "VDLANG_MEASURE_ACCESS_CONTROL_ONLY_OWNER_CAN_CHANGE",
    VDLANG_MEASURE_ACCESS_CONTROL_ONLY_OWNER_CAN_CHANGE_WITH_USER = "VDLANG_MEASURE_ACCESS_CONTROL_ONLY_OWNER_CAN_CHANGE_WITH_USER",
    VDLANG_MEASURE_ACCESS_CONTROL_MAKE_RESPONSIBLE = "VDLANG_MEASURE_ACCESS_CONTROL_MAKE_RESPONSIBLE",
    VDLANG_MEASURE_ACCESS_CONTROL_CHANGE_RESPONSIBLE_LABEL = "VDLANG_MEASURE_ACCESS_CONTROL_CHANGE_RESPONSIBLE_LABEL",
    VDLANG_MEASURE_ACCESS_CONTROL_PERSONS_WHO_HAVE_EDITED = "VDLANG_MEASURE_ACCESS_CONTROL_PERSONS_WHO_HAVE_EDITED",
    VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_REMOVE_TITLE = "VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_REMOVE_TITLE",
    VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_REMOVE_BUTTON = "VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_REMOVE_BUTTON",
    VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_CHANGE_TITLE = "VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_CHANGE_TITLE",
    VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_CHANGE_BUTTON = "VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_CHANGE_BUTTON",
    VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_USER_WILL_BE_UNASSIGNED = "VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_USER_WILL_BE_UNASSIGNED",
    VDLANG_MEASURE_ACCESS_CONTROL_CANNOT_BE_CLAIMED = "VDLANG_MEASURE_ACCESS_CONTROL_CANNOT_BE_CLAIMED",
    VDLANG_MEASURE_ACCESS_CONTROL_CLAIM = "VDLANG_MEASURE_ACCESS_CONTROL_CLAIM",
    VDLANG_MEASURE_ACCESS_CONTROL_CLAIM_NO_RESPONSIBLE_USER = "VDLANG_MEASURE_ACCESS_CONTROL_CLAIM_NO_RESPONSIBLE_USER",
    VDLANG_MEASURE_ACCESS_CONTROL_CLAIM_INVALID_RESPONSIBLE_USER = "VDLANG_MEASURE_ACCESS_CONTROL_CLAIM_INVALID_RESPONSIBLE_USER",
    VDLANG_MEASURE_TITLE_EMPTY_ERROR = "VDLANG_MEASURE_TITLE_EMPTY_ERROR",
    VDLANG_MEASURE_TITLE_TOO_LONG_ERROR = "VDLANG_MEASURE_TITLE_TOO_LONG_ERROR",
    VDLANG_MEASURE_RESPONSIBLE_FOR_CURRENT_GATETASK = "VDLANG_MEASURE_RESPONSIBLE_FOR_CURRENT_GATETASK",
    VDLANG_DASHBOARD_ACCESS_CONTROL_PRIVATE = "VDLANG_DASHBOARD_ACCESS_CONTROL_PRIVATE",

    VDLANG_REMOVE = "VDLANG_REMOVE",
    VDLANG_ACTIVITY = "VDLANG_ACTIVITY",
    VDLANG_ACTIVITIES = "VDLANG_ACTIVITIES",
    VDLANG_GATETASK = "VDLANG_GATETASK",
    VDLANG_FIELD = "VDLANG_FIELD",
    VDLANG_OPPORTUNITIES = "VDLANG_OPPORTUNITIES",
    VDLANG_PROCESSES = "VDLANG_PROCESSES",

    VDLANG_METHODS_DETAIL_START_A_PROCESS = "VDLANG_METHODS_DETAIL_START_A_PROCESS",
    VDLANG_METHODS_DETAIL_PROCESSES = "VDLANG_METHODS_DETAIL_PROCESSES",
    VDLANG_METHODS_DETAIL_EFFECTIVE_MEASURES = "VDLANG_METHODS_DETAIL_EFFECTIVE_MEASURES",
    VDLANG_METHODS_DETAIL_AVERAGE_EFFECTIVE_EFFECT = "VDLANG_METHODS_DETAIL_AVERAGE_EFFECTIVE_EFFECT",
    VDLANG_METHODS_DETAIL_NO_COST_LEVER_ATTRIBUTE = "VDLANG_METHODS_DETAIL_NO_COST_LEVER_ATTRIBUTE",
    VDLANG_METHODS_METHOD_NOT_FOUND = "VDLANG_METHODS_METHOD_NOT_FOUND",
    VDLANG_METHODS_NAVBAR_ITEM_LABEL = "VDLANG_METHODS_NAVBAR_ITEM_LABEL",

    VDLANG_METHOD_TABLE_CODE_COLUMN_LABEL = "VDLANG_METHOD_TABLE_CODE_COLUMN_LABEL",
    VDLANG_METHOD_TABLE_NAME_COLUMN_LABEL = "VDLANG_METHOD_TABLE_NAME_COLUMN_LABEL",
    VDLANG_METHOD_TABLE_FAVORITE_COLUMN_LABEL = "VDLANG_METHOD_TABLE_FAVORITE_COLUMN_LABEL",
    VDLANG_METHOD_TABLE_COUNT_COLUMN_LABEL = "VDLANG_METHOD_TABLE_COUNT_COLUMN_LABEL",
    VDLANG_METHOD_TABLE_EFFECT_COLUMN_LABEL = "VDLANG_METHOD_TABLE_EFFECT_COLUMN_LABEL",
    VDLANG_METHOD_TABLE_AVERAGE_EFFECT_COLUMN_LABEL = "VDLANG_METHOD_TABLE_AVERAGE_EFFECT_COLUMN_LABEL",
    VDLANG_METHOD_TABLE_RELEVANCY = "VDLANG_METHOD_TABLE_RELEVANCY",
    VDLANG_METHOD_TABLE_NO_METHODS = "VDLANG_METHOD_TABLE_NO_METHODS",
    VDLANG_METHOD_TABLE_FAVORITE_COLUMN_HINT = "VDLANG_METHOD_TABLE_FAVORITE_COLUMN_HINT",
    VDLANG_METHOD_TABLE_COUNT_COLUMN_HINT = "VDLANG_METHOD_TABLE_COUNT_COLUMN_HINT",
    VDLANG_METHOD_TABLE_EFFECT_COLUMN_HINT = "VDLANG_METHOD_TABLE_EFFECT_COLUMN_HINT",
    VDLANG_METHOD_TABLE_AVERAGE_EFFECT_COLUMN_HINT = "VDLANG_METHOD_TABLE_AVERAGE_EFFECT_COLUMN_HINT",
    VDLANG_METHOD_TABLE_RELEVANCY_HINT = "VDLANG_METHOD_TABLE_RELEVANCY_HINT",

    VDLANG_METHODS_ACTIVITY_TEMPLATE = "VDLANG_METHODS_ACTIVITY_TEMPLATE",
    VDLANG_METHODS_ACTIVITY_TEMPLATE_HINT = "VDLANG_METHODS_ACTIVITY_TEMPLATE_HINT",
    VDLANG_METHODS_ACTIVITY_TEMPLATE_NOT_AVAILABLE = "VDLANG_METHODS_ACTIVITY_TEMPLATE_NOT_AVAILABLE",
    VDLANG_METHODS_ACTIVITY_TEMPLATE_SUGGEST_ACTIVITIES = "VDLANG_METHODS_ACTIVITY_TEMPLATE_SUGGEST_ACTIVITIES",
    VDLANG_METHODS_ACTIVITY_TEMPLATE_AVAILABLE = "VDLANG_METHODS_ACTIVITY_TEMPLATE_AVAILABLE",
    VDLANG_METHODS_ACTIVITY_TEMPLATE_AVAILABLE_HINT = "VDLANG_METHODS_ACTIVITY_TEMPLATE_AVAILABLE_HINT",
    VDLANG_METHODS_ACTIVITY_TEMPLATE_PREVIEW_AND_USE = "VDLANG_METHODS_ACTIVITY_TEMPLATE_PREVIEW_AND_USE",
    VDLANG_METHODS_ACTIVITY_TEMPLATE_DIALOG_DESCRIPTION_1 = "VDLANG_METHODS_ACTIVITY_TEMPLATE_DIALOG_DESCRIPTION_1",
    VDLANG_METHODS_ACTIVITY_TEMPLATE_DIALOG_DESCRIPTION_2 = "VDLANG_METHODS_ACTIVITY_TEMPLATE_DIALOG_DESCRIPTION_2",
    VDLANG_METHODS_ACTIVITY_TEMPLATE_CREATE_ACTIVITIES = "VDLANG_METHODS_ACTIVITY_TEMPLATE_CREATE_ACTIVITIES",
    VDLANG_METHODS_ACTIVITY_TEMPLATE_SUGGEST_A_CHANGE = "VDLANG_METHODS_ACTIVITY_TEMPLATE_SUGGEST_A_CHANGE",
    VDLANG_METHODS_ACTIVITY_TEMPLATE_ALL = "VDLANG_METHODS_ACTIVITY_TEMPLATE_ALL",
    VDLANG_METHODS_ACTIVITY_SUBTASK_GATE = "VDLANG_METHODS_ACTIVITY_SUBTASK_GATE",
    VDLANG_SHOW_ACTIVITY_TEMPLATE = "VDLANG_SHOW_ACTIVITY_TEMPLATE",

    VDLANG_METHOD_TILE_EFFECT_LABEL = "VDLANG_METHOD_TILE_EFFECT_LABEL",
    VDLANG_METHOD_MEASURE_WIDGET_TITLE = "VDLANG_METHOD_MEASURE_WIDGET_TITLE",

    VDLANG_FEEDBACK_DIALOG_TEXT = "VDLANG_FEEDBACK_DIALOG_TEXT",
    VDLANG_FEEDBACK_DIALOG_LABEL = "VDLANG_FEEDBACK_DIALOG_LABEL",
    VDLANG_FEEDBACK_DIALOG_SEND = "VDLANG_FEEDBACK_DIALOG_SEND",
    VDLANG_FEEDBACK_DIALOG_SUCCESS = "VDLANG_FEEDBACK_DIALOG_SUCCESS",

    VDLANG_METHODS_SUGGEST_METHOD = "VDLANG_METHODS_SUGGEST_METHOD",

    VDLANG_METHOD_FILES = "VDLANG_METHOD_FILES",
    VDLANG_METHOD_FAVORITE_BUTTON_LABEL = "VDLANG_METHOD_FAVORITE_BUTTON_LABEL",
    VDLANG_METHOD_UNFAVORITE_BUTTON_LABEL = "VDLANG_METHOD_UNFAVORITE_BUTTON_LABEL",
    VDLANG_METHOD_UPLOAD_FILE = "VDLANG_METHOD_UPLOAD_FILE",

    VDLANG_NO_FILES = "VDLANG_NO_FILES",
    VDLANG_NO_PARTNERS = "VDLANG_NO_PARTNERS",
    VDLANG_ENTER_NAME_OR_EMAIL = "VDLANG_ENTER_NAME_OR_EMAIL",
    VDLANG_PERMISSION_DIALOG_REMOVE_HINT = "VDLANG_PERMISSION_DIALOG_REMOVE_HINT",
    VDLANG_PERMISSION_DIALOG_UPDATE_HINT = "VDLANG_PERMISSION_DIALOG_UPDATE_HINT",
    VDLANG_PERMISSION_DIALOG_READ_HINT = "VDLANG_PERMISSION_DIALOG_READ_HINT",
    VDLANG_RESET = "VDLANG_RESET",
    VDLANG_SAVE = "VDLANG_SAVE",
    VDLANG_UPDATE = "VDLANG_UPDATE",

    VDLANG_USER_NOT_ASSIGNED = "VDLANG_USER_NOT_ASSIGNED",

    VDLANG_LOGIN_TITLE = "VDLANG_LOGIN_TITLE",
    VDLANG_LOGIN_NEXT = "VDLANG_LOGIN_NEXT",
    VDLANG_LOGIN_BUTTON = "VDLANG_LOGIN_BUTTON",
    VDLANG_LOGIN_PASSWORD_RESET = "VDLANG_LOGIN_PASSWORD_RESET",
    VDLANG_LOGIN_NOT_REGISTERED = "VDLANG_LOGIN_NOT_REGISTERED",
    VDLANG_LOGIN_REGISTER = "VDLANG_LOGIN_REGISTER",
    VDLANG_LOGIN_INCORRECT_EMAIL_OR_PASSWORD = "VDLANG_INCORRENT_EMAIL_OR_PASSWORD",

    VDLANG_INVITE_GREETING = "VDLANG_INVITE_GREETING",
    VDLANG_INVITE_INSTRUCTIONS = "VDLANG_INVITE_INSTRUCTIONS",
    VDLANG_INVITE_SET_PASSWORD_BUTTON = "VDLANG_INVITE_SET_PASSWORD_BUTTON",

    VDLANG_TOKEN_EXPIRED_TITLE = "VDLANG_TOKEN_EXPIRED_TITLE",
    VDLANG_TOKEN_EXPIRED_CONTENT = "VDLANG_TOKEN_EXPIRED_CONTENT",
    VDLANG_TOKEN_EXPIRED_HINT = "VDLANG_TOKEN_EXPIRED_HINT",

    VDLANG_PASSWORD_RESET_TITLE = "VDLANG_PASSWORD_RESET_TITLE",
    VDLANG_PASSWORD_RESET_BUTTON = "VDLANG_PASSWORD_RESET_BUTTON",

    VDLANG_PASSWORD_TOGGLE_VISIBILITY = "VDLANG_PASSWORD_TOGGLE_VISIBILITY",

    VDLANG_PASSWORD_RESET_CONFIRM_TITLE = "VDLANG_PASSWORD_RESET_CONFIRM_TITLE",
    VDLANG_PASSWORD_RESET_CONFIRM_BUTTON = "VDLANG_PASSWORD_RESET_CONFIRM_BUTTON",

    VDLANG_PASSWORD_REQUIREMENTS = "VDLANG_PASSWORD_REQUIREMENTS",

    VDLANG_DESK_SORT_BY = "VDLANG_DESK_SORT_BY",
    VDLANG_DESK_SORT_BY_LAST_MODIFICATION_AT = "VDLANG_DESK_SORT_BY_LAST_MODIFICATION_AT",
    VDLANG_DESK_SORT_BY_EFFECT = "VDLANG_DESK_SORT_BY_EFFECT",
    VDLANG_DESK_SORT_BY_EFFECTIVENESS_START = "VDLANG_DESK_SORT_BY_EFFECTIVENESS_START",
    VDLANG_DESK_SORT_BY_CREATED_AT = "VDLANG_DESK_SORT_BY_CREATED_AT",

    VDLANG_DESK_TILE_DAYS_UNTIL_EFFECTIVENESS_TOOLTIP = "VDLANG_DESK_TILE_DAYS_UNTIL_EFFECTIVENESS_TOOLTIP",

    VDLANG_PROCESS_COMPLETE_HINT = "VDLANG_PROCESS_COMPLETE_HINT",

    VDLANG_SUPER_ADMIN_MERGE_CLIENTS_TITLE = "VDLANG_SUPER_ADMIN_MERGE_CLIENTS_TITLE",
    VDLANG_SUPER_ADMIN_MERGE_CLIENTS_PRIMARY_CLIENT = "VDLANG_SUPER_ADMIN_MERGE_CLIENTS_PRIMARY_CLIENT",
    VDLANG_SUPER_ADMIN_MERGE_CLIENTS_SECONDARY_CLIENT = "VDLANG_SUPER_ADMIN_MERGE_CLIENTS_SECONDARY_CLIENT",
    VDLANG_SUPER_ADMIN_MERGE_CLIENTS_VERIFY_CLIENT_NAME = "VDLANG_SUPER_ADMIN_MERGE_CLIENTS_VERIFY_CLIENT_NAME",
    VDLANG_SUPER_ADMIN_MERGE_CLIENTS_MERGE_BUTTON = "VDLANG_SUPER_ADMIN_MERGE_CLIENTS_MERGE_BUTTON",
    VDLANG_SUPER_ADMIN_MERGE_CLIENTS_SUCCESS = "VDLANG_SUPER_ADMIN_MERGE_CLIENTS_SUCCESS",
    VDLANG_SUPER_ADMIN_MERGE_CLIENTS_INFO = "VDLANG_SUPER_ADMIN_MERGE_CLIENTS_INFO",
    VDLANG_SUPER_ADMIN_MERGE_CLIENTS_INFO_ITEMS = "VDLANG_SUPER_ADMIN_MERGE_CLIENTS_INFO_ITEMS",

    VDLANG_SUPER_ADMIN_PREFILL_GATE_TASKS_LABEL = "VDLANG_SUPER_ADMIN_PREFILL_GATE_TASKS_LABEL",
    VDLANG_SUPER_ADMIN_PREFILL_GATE_TASKS_HINT = "VDLANG_SUPER_ADMIN_PREFILL_GATE_TASKS_HINT",

    VDLANG_COMPLETION_HINT_PREDECESSORS = "VDLANG_COMPLETION_HINT_PREDECESSORS",
    VDLANG_COMPLETION_HINT_PREDECESSOR = "VDLANG_COMPLETION_HINT_PREDECESSOR",
    VDLANG_COMPLETION_HINT_COMPANY_SPLIT = "VDLANG_COMPLETION_HINT_COMPANY_SPLIT",
    VDLANG_COMPLETION_HINT_COMPANY_SPLIT_MULTIPLE = "VDLANG_COMPLETION_HINT_COMPANY_SPLIT_MULTIPLE",
    VDLANG_COMPLETION_HINT_ATLEAST_ONE_EFFECT_CATEGORY = "VDLANG_COMPLETION_HINT_ATLEAST_ONE_EFFECT_CATEGORY",
    VDLANG_COMPLETION_HINT_NO_PERMISSION = "VDLANG_COMPLETION_HINT_NO_PERMISSION",
    VDLANG_COMPLETION_HINT_DECIDER_HAS_NO_PERMISSION = "VDLANG_COMPLETION_HINT_DECIDER_HAS_NO_PERMISSION",
    VDLANG_COMPLETION_HINT_MANDATORY_FIELD_NOT_FILLED = "VDLANG_COMPLETION_HINT_MANDATORY_FIELD_NOT_FILLED",
    VDLANG_COMPLETION_HINT_LEVEL_DATES_INCOMPLETE = "VDLANG_COMPLETION_HINT_GATE_TASK_DATES_INCOMPLETE",
    VDLANG_COMPLETION_HINT_LEVEL_DATES_START_DATE_BEFORE_PREDECESSOR = "VDLANG_COMPLETION_HINT_LEVEL_DATES_START_DATE_BEFORE_PREDECESSOR",
    VDLANG_COMPLETION_HINT_LEVEL_DATES_DUE_DATE_BEFORE_PREDECESSOR = "VDLANG_COMPLETION_HINT_LEVEL_DATES_DUE_DATE_BEFORE_PREDECESSOR",
    VDLANG_COMPLETION_HINT_MULTIPLE = "VDLANG_COMPLETION_HINT_MULTIPLE",
    VDLANG_COMPLETION_HINT_DIALOG_TITLE = "VDLANG_COMPLETION_HINT_DIALOG_TITLE",
    VDLANG_COMPLETION_HINT_SHOW_DIALOG_LABEL = "VDLANG_COMPLETION_HINT_SHOW_DIALOG_LABEL",
    VDLANG_COMPLETION_HINT_DECIDER_INVALID = "VDLANG_COMPLETION_HINT_DECIDER_INVALID",

    VDLANG_CALCULATION_GATE_SUMMARY_IGNORED_VALUES_HINT = "VDLANG_CALCULATION_GATE_SUMMARY_IGNORED_VALUES_HINT",

    VDLANG_GATE_TASK_FOOTER_IN_CURRENT_GATE_FOR = "VDLANG_GATE_TASK_FOOTER_IN_CURRENT_GATE_FOR",
    VDLANG_GATE_TASK_FOOTER_IN_CURRENT_GATE_SINCE_TODAY = "VDLANG_GATE_TASK_FOOTER_IN_CURRENT_GATE_SINCE_TODAY",
    VDLANG_GATE_TASK_FOOTER_IN_CURRENT_GATE_SINCE_YESTERDAY = "VDLANG_GATE_TASK_FOOTER_IN_CURRENT_GATE_SINCE_YESTERDAY",
    VDLANG_GATE_TASK_FOOTER_LAST_GATE_HINT = "VDLANG_GATE_TASK_FOOTER_LAST_GATE_HINT",

    VDLANG_MARKDOWN_EDITOR_VALUE_TOO_LONG = "VDLANG_MARKDOWN_EDITOR_VALUE_TOO_LONG",
    VDLANG_MARKDOWN_EDITOR_ERROR_INFO = "VDLANG_MARKDOWN_EDITOR_ERROR_INFO",

    VDLANG_CURRENCY_INPUT_DIGIT_LIMIT = "VDLANG_CURRENCY_INPUT_DIGIT_LIMIT",
    VDLANG_CURRENCY_INPUT_INVALID_FORMULA = "VDLANG_CURRENCY_INPUT_INVALID_FORMULA",
    VDLANG_CURRENCY_INPUT_NEGATIVE_NUMBER = "VDLANG_CURRENCY_INPUT_NEGATIVE_NUMBER",

    VDLANG_FORMULA_INPUT_INVALID_FORMULA = "VDLANG_FORMULA_INPUT_INVALID_FORMULA",

    VDLANG_PRIORITY = "VDLANG_PRIORITY",
    VDLANG_LOW = "VDLANG_LOW",
    VDLANG_MEDIUM = "VDLANG_MEDIUM",
    VDLANG_HIGH = "VDLANG_HIGH",

    VDLANG_EMAIL_REMINDER = "VDLANG_EMAIL_REMINDER",
    VDLANG_REMINDER_IN_PAST = "VDLANG_REMINDER_IN_PAST",
    VDLANG_REMINDER_HINT = "VDLANG_REMINDER_HINT",

    VDLANG_MENU = "VDLANG_MENU",
    VDLANG_CONTEXT_MENU = "VDLANG_CONTEXT_MENU",
    VDLANG_BREADCRUMB = "VDLANG_BREADCRUMB",

    VDLANG_PROCESS_ABBREVIATION = "VDLANG_PROCESS_ABBREVIATION",

    VDLANG_NUMBER_MAGNITUDE_TRILLION = "VDLANG_NUMBER_MAGNITUDE_TRILLION",
    VDLANG_NUMBER_MAGNITUDE_BILLION = "VDLANG_NUMBER_MAGNITUDE_BILLION",
    VDLANG_NUMBER_MAGNITUDE_MILLION = "VDLANG_NUMBER_MAGNITUDE_MILLION",
    VDLANG_NUMBER_MAGNITUDE_THOUSAND = "VDLANG_NUMBER_MAGNITUDE_THOUSAND",

    VDLANG_DESK_GRID_SEARCH = "VDLANG_DESK_GRID_SEARCH",
    VDLANG_GRID_CONFIG_TITLE_HINT = "VDLANG_GRID_CONFIG_TITLE_HINT",

    VDLANG_GRID_TOTAL_CONVERSION_RATE_TOOLTIP = "VDLANG_GRID_TOTAL_CONVERSION_RATE_TOOLTIP",
    VDLANG_GRID_CONVERSION_RATE_TOOLTIP = "VDLANG_GRID_CONVERSION_RATE_TOOLTIP",

    VDLANG_ADDITIONAL_INFORMATION = "VDLANG_ADDITIONAL_INFORMATION",

    VDLANG_SWITCH_LANGUAGE = "VDLANG_SWITCH_LANGUAGE",

    VDLANG_DECISION_COMPLETED_POSITIVE = "VDLANG_DECISION_COMPLETED_POSITIVE",
    VDLANG_DECISION_COMPLETED_NEGATIVE = "VDLANG_DECISION_COMPLETED_NEGATIVE",
    VDLANG_DECISION_COMPLETED_POSITIVE_DOCUMENTED = "VDLANG_DECISION_COMPLETED_POSITIVE_DOCUMENTED",
    VDLANG_DECISION_COMPLETED_NEGATIVE_DOCUMENTED = "VDLANG_DECISION_COMPLETED_NEGATIVE_DOCUMENTED",
    VDLANG_DECISION_COMPLETED_POSITIVE_REQUESTED = "VDLANG_DECISION_COMPLETED_POSITIVE_REQUESTED",
    VDLANG_DECISION_COMPLETED_NEGATIVE_REQUESTED = "VDLANG_DECISION_COMPLETED_NEGATIVE_REQUESTED",
    VDLANG_DECISION_COMPLETED_COMMENT = "VDLANG_DECISION_COMPLETED_COMMENT",
    VDLANG_DECISION_COMPLETED_SHOW_COMMENT = "VDLANG_DECISION_COMPLETED_SHOW_COMMENT",

    VDLANG_DECISION_EMPTY_DECIDER = "VDLANG_DECISION_EMPTY_DECIDER",

    VDLANG_MEASURE_TABS_PROCESS = "VDLANG_MEASURE_TABS_PROCESS",
    VDLANG_MEASURE_TABS_CALCULATION = "VDLANG_MEASURE_TABS_CALCULATION",
    VDLANG_MEASURE_TABS_ACTIVITIES = "VDLANG_MEASURE_TABS_ACTIVITIES",
    VDLANG_MEASURE_TABS_ADD_ACTIVITY = "VDLANG_MEASURE_TABS_ADD_ACTIVITY",
    VDLANG_MEASURE_TABS_COMMENTS = "VDLANG_MEASURE_TABS_COMMENTS",
    VDLANG_MEASURE_TABS_DOCUMENTS = "VDLANG_MEASURE_TABS_DOCUMENTS",
    VDLANG_MEASURE_TABS_HISTORY = "VDLANG_MEASURE_TABS_HISTORY",
    VDLANG_MEASURE_TABS_FEED_HISTORY = "VDLANG_MEASURE_TABS_FEED_HISTORY",
    VDLANG_MEASURE_TABS_SUGGESTIONS = "VDLANG_MEASURE_TABS_SUGGESTIONS",

    VDLANG_ACTIVITY_GATE_LIST_TILE = "VDLANG_ACTIVITY_GATE_LIST_TILE",
    VDLANG_ACTIVITY_GATE_HEADER = "VDLANG_ACTIVITY_GATE_HEADER",
    VDLANG_ACTIVITY_GATE_LIST_ORDER_ASCENDING = "VDLANG_ACTIVITY_GATE_LIST_ORDER_ASCENDING",
    VDLANG_ACTIVITY_GATE_LIST_ORDER_DESCENDING = "VDLANG_ACTIVITY_GATE_LIST_ORDER_DESCENDING",
    VDLANG_ACTIVITY_GATE_LIST_LINK = "VDLANG_ACTIVITY_GATE_LIST_LINK",
    VDLANG_ACTIVITY_GATE_LIST_EMPTY_MESSAGE = "VDLANG_ACTIVITY_GATE_LIST_EMPTY_MESSAGE",
    VDLANG_ACTIVITY_CHART = "VDLANG_ACTIVITY_CHART",

    VDLANG_ACTIVITY_OPEN = "VDLANG_ACTIVITY_OPEN",
    VDLANG_ACTIVITY_DUESOON = "VDLANG_ACTIVITY_DUESOON",
    VDLANG_ACTIVITY_OVERDUE = "VDLANG_ACTIVITY_OVERDUE",
    VDLANG_ACTIVITY_COMPLETED = "VDLANG_ACTIVITY_COMPLETED",
    VDLANG_ACTIVITY_EFFORT_ESTIMATED_LABEL = "VDLANG_ACTIVITY_EFFORT_ESTIMATED_LABEL",
    VDLANG_ACTIVITY_TIME_TRACKED_LABEL = "VDLANG_ACTIVITY_TIME_TRACKED_LABEL",
    VDLANG_ACTIVITY_TIME_OVERDRAWN_LABEL = "VDLANG_ACTIVITY_TIME_OVERDRAWN_LABEL",
    VDLANG_ACTIVITY_EMPTY_LABEL = "VDLANG_ACTIVITY_EMPTY_LABEL",
    VDLANG_ACTIVITY_EFFORT_ESTIMATION_TITLE = "VDLANG_ACTIVITY_EFFORT_ESTIMATION_TITLE",
    VDLANG_ACTIVITY_EFFORT_ESTIMATION_OVERVIEW = "VDLANG_ACTIVITY_EFFORT_ESTIMATION_OVERVIEW",
    VDLANG_ACTIVITY_EFFORT_ESTIMATION_OVERVIEW_TOTAL = "VDLANG_ACTIVITY_EFFORT_ESTIMATION_OVERVIEW_TOTAL",
    VDLANG_ACTIVITY_EFFORT_ESTIMATION_OVERVIEW_NO_VALUES = "VDLANG_ACTIVITY_EFFORT_ESTIMATION_OVERVIEW_NO_VALUES",
    VDLANG_ACTIVITY_EFFORT_FORMAT = "VDLANG_ACTIVITY_EFFORT_FORMAT",
    VDLANG_ACTIVITY_EFFORT_FORMAT_WEEKS = "VDLANG_ACTIVITY_EFFORT_FORMAT_WEEKS",
    VDLANG_ACTIVITY_EFFORT_FORMAT_DAYS = "VDLANG_ACTIVITY_EFFORT_FORMAT_DAYS",
    VDLANG_ACTIVITY_EFFORT_FORMAT_HOURS = "VDLANG_ACTIVITY_EFFORT_FORMAT_HOURS",
    VDLANG_ACTIVITY_EFFORT_FORMAT_MINUTES = "VDLANG_ACTIVITY_EFFORT_FORMAT_MINUTES",

    VDLANG_ACTIVITY_EFFORT_ESTIMATION_INPUT_LABEL = "VDLANG_ACTIVITY_EFFORT_ESTIMATION_INPUT_LABEL",
    VDLANG_ACTIVITY_TIME_TRACKED_INPUT_LABEL = "VDLANG_ACTIVITY_TIME_TRACKED_INPUT_LABEL",

    VDLANG_GENERATION_LINEAR_TABLE_HINT = "VDLANG_GENERATION_LINEAR_TABLE_HINT",

    VDLANG_CREATE_MEASURE_LABELS = "VDLANG_CREATE_MEASURE_LABELS",
    VDLANG_CREATE_MEASURE_HINTS = "VDLANG_CREATE_MEASURE_HINTS",
    VDLANG_CREATE_MEASURE_QUESTIONS = "VDLANG_CREATE_MEASURE_QUESTIONS",
    VDLANG_CREATE_IDEA_LABELS = "VDLANG_CREATE_IDEA_LABELS",
    VDLANG_CREATE_IDEA_LABELS_HINT = "VDLANG_CREATE_IDEA_LABELS_HINT",
    VDLANG_CREATE_IDEA_QUESTIONS = "VDLANG_CREATE_IDEA_QUESTIONS",

    VDLANG_SECTIONS_NEWS = "VDLANG_SECTIONS_NEWS",
    VDLANG_SECTIONS_DASHBOARD = "VDLANG_SECTIONS_DASHBOARD",
    VDLANG_DASHBOARD_DEFAULT_NAME = "VDLANG_DASHBOARD_DEFAULT_NAME",
    VDLANG_ENTITY_OWNER = "VDLANG_ENTITY_OWNER",
    VDLANG_SECTIONS_PROCESSES = "VDLANG_SECTIONS_PROCESSES",
    VDLANG_SECTIONS_PROCESSES_GRID = "VDLANG_SECTIONS_PROCESSES_GRID",
    VDLANG_SECTIONS_PROCESSES_DESK = "VDLANG_SECTIONS_PROCESSES_DESK",
    VDLANG_SECTIONS_PARTNERS = "VDLANG_SECTIONS_PARTNERS",
    VDLANG_SECTIONS_SETTINGS = "VDLANG_SECTIONS_SETTINGS",
    VDLANG_SECTIONS_ADMIN = "VDLANG_SECTIONS_ADMIN",
    VDLANG_SECTIONS_SUPER_ADMIN = "VDLANG_SECTIONS_SUPER_ADMIN",
    VDLANG_SECTIONS_LOGIN = "VDLANG_SECTIONS_LOGIN",
    VDLANG_SECTIONS_LOGOUT = "VDLANG_SECTIONS_LOGOUT",
    VDLANG_SECTIONS_IDEAS = "VDLANG_SECTIONS_IDEAS",
    VDLANG_GATE_CLOSED = "VDLANG_GATE_CLOSED",
    VDLANG_GATE_START_DATE_PLACEHOLDER = "VDLANG_GATE_START_DATE_PLACEHOLDER",
    VDLANG_GATE_DUEDATE_DATE_PLACEHOLDER = "VDLANG_GATE_DUEDATE_DATE_PLACEHOLDER",
    VDLANG_ACTIVITY_GATE_LIST_TITLE = "VDLANG_ACTIVITY_GATE_LIST_TITLE",
    VDLANG_GATE_ACTIVITY_TOOLTIP_COMPLETED = "VDLANG_GATE_ACTIVITY_TOOLTIP_COMPLETED",
    VDLANG_GATE_ACTIVITY_TOOLTIP_DUESOON = "VDLANG_GATE_ACTIVITY_TOOLTIP_DUESOON",
    VDLANG_GATE_ACTIVITY_TOOLTIP_OVERDUE = "VDLANG_GATE_ACTIVITY_TOOLTIP_OVERDUE",
    VDLANG_GATE_ACTIVITY_TOOLTIP_OPEN = "VDLANG_GATE_ACTIVITY_TOOLTIP_OPEN",

    VDLANG_DATEPICKER_TOO_LATE = "VDLANG_DATEPICKER_TOO_LATE",
    VDLANG_DATEPICKER_TOO_EARLY = "VDLANG_DATEPICKER_TOO_EARLY",

    VDLANG_PROCESS_HISTORY_FILE_INSERT = "VDLANG_PROCESS_HISTORY_FILE_INSERT",
    VDLANG_PROCESS_HISTORY_FILE_DELETE = "VDLANG_PROCESS_HISTORY_FILE_DELETE",

    VDLANG_PROCESS_HISTORY_PROCESS_INSERT = "VDLANG_PROCESS_HISTORY_PROCESS_INSERT",
    VDLANG_PROCESS_HISTORY_PROCESS_COPY = "VDLANG_PROCESS_HISTORY_PROCESS_COPY",

    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_TITLE = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_TITLE",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_0_1 = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_0_1",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_ON_BEHALF_0_1 = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_ON_BEHALF_0_1",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_1_2 = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_1_2",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_2_0 = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_2_0",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_TO_1 = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_STATUS_TO_1",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_1_2 = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_1_2",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_2_1 = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_2_1",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_0_1 = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_0_1",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_0_2 = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_0_2",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_TO_0 = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VISIBILITY_TO_0",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_ASSIGNED_TO = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_ASSIGNED_TO",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_ASSIGNED_TO_TO_EMPTY = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_ASSIGNED_TO_TO_EMPTY",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_CURRENCY = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_CURRENCY",
    VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VALUESTREAM = "VDLANG_PROCESS_HISTORY_PROCESS_UPDATE_VALUESTREAM",

    VDLANG_PROCESS_HISTORY_MEASURE_VALUE_INSERT = "VDLANG_PROCESS_HISTORY_MEASURE_VALUE_INSERT",

    VDLANG_PROCESS_HISTORY_MEASURE_VALUE_UPDATE_TO_NULL = "VDLANG_PROCESS_HISTORY_MEASURE_VALUE_UPDATE_TO_NULL",
    VDLANG_PROCESS_HISTORY_MEASURE_VALUE_UPDATE = "VDLANG_PROCESS_HISTORY_MEASURE_VALUE_UPDATE",

    VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_STATUS_1002_1000 = "VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_STATUS_1002_1000",
    VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_STATUS_1000_1002 = "VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_STATUS_1000_1002",
    VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_STATUS_1000_1002_DECISION = "VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_STATUS_1000_1002_DECISION",
    VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_ASSIGNED_TO_TO_EMPTY = "VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_ASSIGNED_TO_TO_EMPTY",
    VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_ASSIGNED_TO = "VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_ASSIGNED_TO",
    VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_DATE_FROM_EMPTY = "VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_DATE_FROM_EMPTY",
    VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_DATE = "VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_DATE",
    VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_DUEDATE_FROM_EMPTY = "VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_DUEDATE_FROM_EMPTY",
    VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_DUEDATE = "VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_DUEDATE",
    VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_REMIND_AT_TO_EMPTY = "VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_REMIND_AT_TO_EMPTY",
    VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_REMIND_AT = "VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_START_REMIND_AT",
    VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_REMIND_AT_TO_EMPTY = "VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_REMIND_AT_TO_EMPTY",
    VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_REMIND_AT = "VDLANG_PROCESS_HISTORY_GATE_TASK_UPDATE_REMIND_AT",
    VDLANG_PROCESS_HISTORY_GATE_TASK_DELETE_DUEDATE = "VDLANG_PROCESS_HISTORY_GATE_TASK_DELETE_DUEDATE",
    VDLANG_PROCESS_HISTORY_GATE_TASK_DELETE_START_DATE = "VDLANG_PROCESS_HISTORY_GATE_TASK_DELETE_START_DATE",

    VDLANG_PROCESS_HISTORY_SUBTASK_INSERT = "VDLANG_PROCESS_HISTORY_SUBTASK_INSERT",

    VDLANG_PROCESS_HISTORY_SUBTASK_UPDATE_STATUS_1_2 = "VDLANG_PROCESS_HISTORY_SUBTASK_UPDATE_STATUS_1_2",
    VDLANG_PROCESS_HISTORY_SUBTASK_UPDATE_STATUS_TO_0 = "VDLANG_PROCESS_HISTORY_SUBTASK_UPDATE_STATUS_TO_0",
    VDLANG_PROCESS_HISTORY_SUBTASK_UPDATE_STATUS_2_1 = "VDLANG_PROCESS_HISTORY_SUBTASK_UPDATE_STATUS_2_1",

    VDLANG_PROCESS_HISTORY_EFFECT_CATEGORY_INSERT = "VDLANG_PROCESS_HISTORY_EFFECT_CATEGORY_INSERT",
    VDLANG_PROCESS_HISTORY_EFFECT_CATEGORY_DELETE = "VDLANG_PROCESS_HISTORY_EFFECT_CATEGORY_DELETE",

    VDLANG_PROCESS_HISTORY_DECISION_UPDATE_REQUESTED_DECIDER = "VDLANG_PROCESS_HISTORY_DECISION_UPDATE_REQUESTED_DECIDER",
    VDLANG_PROCESS_HISTORY_DECISION_USER_DECIDER = "VDLANG_PROCESS_HISTORY_DECISION_USER_DECIDER",
    VDLANG_PROCESS_HISTORY_DECISION_USER_DECIDER_REMOVE = "VDLANG_PROCESS_HISTORY_DECISION_USER_DECIDER_REMOVE",

    VDLANG_PROCESS_HISTORY_ACL_USER_INSERT_READ = "VDLANG_PROCESS_HISTORY_ACL_USER_INSERT_READ",
    VDLANG_PROCESS_HISTORY_ACL_USER_INSERT_UPDATE = "VDLANG_PROCESS_HISTORY_ACL_USER_INSERT_UPDATE",
    VDLANG_PROCESS_HISTORY_ACL_USER_DELETE_READ = "VDLANG_PROCESS_HISTORY_ACL_USER_DELETE_READ",
    VDLANG_PROCESS_HISTORY_ACL_USER_DELETE_UPDATE = "VDLANG_PROCESS_HISTORY_ACL_USER_DELETE_UPDATE",

    VDLANG_PROCESS_HISTORY_ACL_GROUP_INSERT_READ = "VDLANG_PROCESS_HISTORY_ACL_GROUP_INSERT_READ",
    VDLANG_PROCESS_HISTORY_ACL_GROUP_INSERT_UPDATE = "VDLANG_PROCESS_HISTORY_ACL_GROUP_INSERT_UPDATE",
    VDLANG_PROCESS_HISTORY_ACL_GROUP_DELETE_READ = "VDLANG_PROCESS_HISTORY_ACL_GROUP_DELETE_READ",
    VDLANG_PROCESS_HISTORY_ACL_GROUP_DELETE_UPDATE = "VDLANG_PROCESS_HISTORY_ACL_GROUP_DELETE_UPDATE",

    VDLANG_EFFECT_CATEGORY_GATE_TOTAL_EFFECT = "VDLANG_EFFECT_CATEGORY_GATE_TOTAL_EFFECT",
    VDLANG_EFFECT_CATEGORY_GATE_HEADER_EFFECT = "VDLANG_EFFECT_CATEGORY_GATE_HEADER_EFFECT",
    VDLANG_EFFECT_CATEGORY_GATE_HEADER_EFFECT_TYPE = "VDLANG_EFFECT_CATEGORY_GATE_HEADER_EFFECT_TYPE",
    VDLANG_EFFECT_CATEGORY_GATE_HEADER_TIMERANGE = "VDLANG_EFFECT_CATEGORY_GATE_HEADER_TIMERANGE",
    VDLANG_EFFECT_CATEGORY_GATE_ADD_BUTTON_DISABLED_HINT = "VDLANG_EFFECT_CATEGORY_GATE_ADD_BUTTON_DISABLED_HINT",

    VDLANG_EFFECT_CATEGORY_MODAL_REMOVE_FIELD = "VDLANG_EFFECT_CATEGORY_MODAL_REMOVE_FIELD",
    VDLANG_EFFECT_CATEGORY_MODAL_ADDITIONAL_FIELDS = "VDLANG_EFFECT_CATEGORY_MODAL_ADDITIONAL_FIELDS",
    VDLANG_EFFECT_CATEGORY_MODAL_NO_ADDITIONAL_FIELDS = "VDLANG_EFFECT_CATEGORY_MODAL_NO_ADDITIONAL_FIELDS",
    VDLANG_EFFECT_CATEGORY_MODAL_HAS_INITIAL = "VDLANG_EFFECT_CATEGORY_MODAL_HAS_INITIAL",
    VDLANG_EFFECT_CATEGORY_MODAL_HAS_INITIAL_HINT = "VDLANG_EFFECT_CATEGORY_MODAL_HAS_INITIAL_HINT",
    VDLANG_EFFECT_CATEGORY_MODAL_CURRENCY_SELECT_LABEL = "VDLANG_EFFECT_CATEGORY_MODAL_CURRENCY_SELECT_LABEL",
    VDLANG_EFFECT_CATEGORY_MODAL_EC_ALREADY_EXISTS = "VDLANG_EFFECT_CATEGORY_MODAL_EC_ALREADY_EXISTS",

    VDLANG_UPLOAD_SELECT_DOCUMENT = "VDLANG_UPLOAD_SELECT_DOCUMENT",
    VDLANG_UPLOAD_SELECT_DOCUMENT_PHOTO = "VDLANG_UPLOAD_SELECT_DOCUMENT_PHOTO",
    VDLANG_UPLOAD_DRAGDROP_DOCUMENT = "VDLANG_UPLOAD_DRAGDROP_DOCUMENT",
    VDLANG_UPLOAD_SELECT_IMAGE = "VDLANG_UPLOAD_SELECT_IMAGE",

    VDLANG_PROCESS_TOOLTIPS_FURTHER_OPTIONS = "VDLANG_PROCESS_TOOLTIPS_FURTHER_OPTIONS",
    VDLANG_PROCESS_TOOLTIPS_FULLSCREEN = "VDLANG_PROCESS_TOOLTIPS_FULLSCREEN",
    VDLANG_PROCESS_TOOLTIPS_CLOSE_FULLSCREEN = "VDLANG_PROCESS_TOOLTIPS_CLOSE_FULLSCREEN",
    VDLANG_PROCESS_TOOLTIPS_ADD_FIELD_OPTION = "VDLANG_PROCESS_TOOLTIPS_ADD_FIELD_OPTION",

    VDLANG_PROCESS_CHANGE_CURRENCY_HINT = "VDLANG_PROCESS_CHANGE_CURRENCY_HINT",

    VDLANG_EFFECT_FIELD_LABEL_COLUMN_HEADER = "VDLANG_EFFECT_FIELD_LABEL_COLUMN_HEADER",

    VDLANG_NEW_VALUEDESK_VERSION_HINT = "VDLANG_NEW_VALUEDESK_VERSION_HINT",

    VDLANG_MEASURE_CALCULATION_GRANULARITY = "VDLANG_MEASURE_CALCULATION_GRANULARITY",
    VDLANG_MEASURE_CALCULATION_GRANULARITY_TOOLTIP = "VDLANG_MEASURE_CALCULATION_GRANULARITY_TOOLTIP",
    VDLANG_MEASURE_CALCULATION_GRANULARITY_PLACEHOLDER = "VDLANG_MEASURE_CALCULATION_GRANULARITY_PLACEHOLDER",

    VDLANG_MEASURE_CALCULATION_TOTAL_COLUMN_HEADER = "VDLANG_MEASURE_CALCULATION_TOTAL_COLUMN_HEADER",
    VDLANG_MEASURE_CALCULATION_TABLE_NO_DATA = "VDLANG_MEASURE_CALCULATION_TABLE_NO_DATA",
    VDLANG_MEASURE_CALCULATION_TABLE_CURRENCY = "VDLANG_MEASURE_CALCULATION_TABLE_CURRENCY",

    VDLANG_TYPE = "VDLANG_TYPE",
    VDLANG_ALLOW_SELF_REGISTER = "VDLANG_ALLOW_SELF_REGISTER",
    VDLANG_VERIFIED_BY_VALUEDESK = "VDLANG_VERIFIED_BY_VALUEDESK",
    VDLANG_VERIFIED_BY_VALUEDESK_INFO = "VDLANG_VERIFIED_BY_VALUEDESK_INFO",
    VDLANG_IDENTITY_PROVIDER_ASSIGNED_DELETE = "VDLANG_IDENTITY_PROVIDER_ASSIGNED_DELETE",
    VDLANG_VERIFIED_BY_VALUEDESK_DELETE = "VDLANG_VERIFIED_BY_VALUEDESK_DELETE",
    VDLANG_USED_IN_IDENTITY_PROVIDER = "VDLANG_USED_IN_IDENTITY_PROVIDER",
    VDLANG_DEFAULT_IDENTITY_PROVIDER_EXISTS = "VDLANG_DEFAULT_IDENTITY_PROVIDER_EXISTS",
    VDLANG_DEFAULT = "VDLANG_DEFAULT",
    VDLANG_NO_CALCULATION_DATA = "VDLANG_NO_CALCULATION_DATA",

    VDLANG_CALCULATION_IDENTIFIER = "VDLANG_CALCULATION_IDENTIFIER",

    VDLANG_APPLY = "VDLANG_APPLY",
    VDLANG_LEVEL_POPOVER_WARNING = "VDLANG_LEVEL_POPOVER_WARNING",
    VDLANG_LEVEL_POPOVER_HEADING = "VDLANG_LEVEL_POPOVER_HEADING",
    VDLANG_LEVEL_HEADER_NONLINEAR_HINT = "VDLANG_LEVEL_HEADER_NONLINEAR_HINT",
    VDLANG_SHOW_MORE = "VDLANG_SHOW_MORE",
    VDLANG_SHOW_LESS = "VDLANG_SHOW_LESS",

    VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_SAVINGS = "VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_SAVINGS",
    VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_EXTRACOSTS = "VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_EXTRACOSTS",
    VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_VALUE = "VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_VALUE",
    VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_UPDATE_VALUE = "VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_UPDATE_VALUE",
    VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_CURRENCY = "VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_INSERT_CURRENCY",
    VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_UPDATE_CURRENCY = "VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_UPDATE_CURRENCY",

    VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_COPY = "VDLANG_CALCULATION_HISTORY_EFFECT_CATEGORY_COPY",

    VDLANG_CALCULATION_HISTORY_GENERATION_INSERT_HAS_INITIAL = "VDLANG_CALCULATION_HISTORY_GENERATION_INSERT_HAS_INITIAL",
    VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_HAS_INITIAL = "VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_HAS_INITIAL",
    VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FROM_EMPTY = "VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FROM_EMPTY",
    VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_TO_EMPTY = "VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_TO_EMPTY",
    VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FIELD = "VDLANG_CALCULATION_HISTORY_GENERATION_UPDATE_FIELD",
    VDLANG_CALCULATION_HISTORY_GENERATION_DELETE_FROM_EMPTY = "VDLANG_CALCULATION_HISTORY_GENERATION_DELETE_FROM_EMPTY",
    VLDANG_CALCULATION_HISTORY_EFFECT_FROM_EMPTY = "VLDANG_CALCULATION_HISTORY_EFFECT_FROM_EMPTY",
    VLDANG_CALCULATION_HISTORY_EFFECT_UPDATE = "VLDANG_CALCULATION_HISTORY_EFFECT_UPDATE",
    VLDANG_CALCULATION_HISTORY_EFFECT_TO_EMPTY = "VLDANG_CALCULATION_HISTORY_EFFECT_TO_EMPTY",

    VDLANG_CALCULATION_TABLE_TOOLTIP = "VDLANG_CALCULATION_TABLE_TOOLTIP",
    VDLANG_CALCULATION_TABLE_COPY_DATA_WARNING = "VDLANG_CALCULATION_TABLE_COPY_DATA_WARNING",

    VDLANG_FILTER_FILTERS = "VDLANG_FILTER_FILTERS",
    VDLANG_FILTER_EDIT_FILTER = "VDLANG_FILTER_EDIT_FILTER",
    VDLANG_FILTER_CUSTOM_FILTER = "VDLANG_FILTER_CUSTOM_FILTER",

    VDLANG_FILTER_COMPLETED_FILTER = "VDLANG_FILTER_COMPLETED_FILTER",
    VDLANG_FILTER_APPLIED_ACROSS_VIEWS_HINT = "VDLANG_FILTER_APPLIED_ACROSS_VIEWS_HINT",
    VDLANG_FILTER_DIALOG_SAVE = "VDLANG_FILTER_DIALOG_SAVE",
    VDLANG_FILTER_DIALOG_SAVE_AS = "VDLANG_FILTER_DIALOG_SAVE_AS",
    VDLANG_FILTER_DIALOG_APPLY = "VDLANG_FILTER_DIALOG_APPLY",
    VDLANG_FILTER_DIALOG_REMOVE = "VDLANG_FILTER_DIALOG_REMOVE",
    VDLANG_FILTER_DIALOG_FILTER_IN_USE = "VDLANG_FILTER_DIALOG_FILTER_IN_USE",
    VDLANG_FILTER_DIALOG_FILTER_NAME_EXISTS = "VDLANG_FILTER_DIALOG_FILTER_NAME_EXISTS",

    VDLANG_FILTER_FORM_NO_CONDITION = "VDLANG_FILTER_FORM_NO_CONDITION",
    VDLANG_FILTER_FORM_ADD_CONDITION = "VDLANG_FILTER_FORM_ADD_CONDITION",

    VDLANG_TARGETS_FORM_NO_TARGETS = "VDLANG_FILTER_FORM_NO_TARGET",
    VDLANG_TARGETS_FORM_ADD_TARGET = "VDLANG_FILTER_FORM_ADD_TARGET",

    VDLANG_TREE_NODE_DELETE_REPLACE = "VDLANG_TREE_NODE_DELETE_REPLACE",
    VDLANG_TREE_NODE_DELETE_REPLACE_DESCRIPTION = "VDLANG_TREE_NODE_DELETE_REPLACE_DESCRIPTION",
    VDLANG_TREE_NODE_DELETE_REMOVE_USAGE = "VDLANG_TREE_NODE_DELETE_REMOVE_USAGE",
    VDLANG_TREE_NODE_DELETE_REMOVE_USAGE_DESCRIPTION = "VDLANG_TREE_NODE_DELETE_REMOVE_USAGE_DESCRIPTION",
    VDLANG_TREE_NODE_DELETE_NO_REPLACE = "VDLANG_TREE_NODE_DELETE_NO_REPLACE",
    VDLANG_TREE_NODE_DELETE_NO_REPLACE_DESCRIPTION = "VDLANG_TREE_NODE_DELETE_NO_REPLACE_DESCRIPTION",
    VDLANG_TREE_NODE_NO_ATTRIBUTE = "VDLANG_TREE_NODE_NO_ATTRIBUTE",
    VDLANG_TREE_NODE_EMPTY = "VDLANG_TREE_NODE_EMPTY",
    VDLANG_TREE_NODE_MOVE_TO_ROOT = "VDLANG_TREE_NODE_MOVE_TO_ROOT",
    VDLANG_TREE_NODE_MOVE_DESCRIPTION = "VDLANG_TREE_NODE_MOVE_DESCRIPTION",
    VDLANG_TREE_NODE_PARENT = "VDLANG_TREE_NODE_PARENT",
    VDLANG_TREE_NODE_NEW_PARENT = "VDLANG_TREE_NODE_NEW_PARENT",
    VDLANG_TREE_NODE_NO_PARENT_SELECTED = "VDLANG_TREE_NODE_NO_PARENT_SELECTED",
    VDLANG_TREE_NODE_ADJUST_ORDER = "VDLANG_TREE_NODE_ADJUST_ORDER",
    VDLANG_TREE_NODE_ADJUST_CHILD_ORDER_DESCRIPTION = "VDLANG_TREE_NODE_ADJUST_CHILD_ORDER_DESCRIPTION",
    VDLANG_TREE_NODE_ADJUST_ROOT_ORDER_DESCRIPTION = "VDLANG_TREE_NODE_ADJUST_ROOT_ORDER_DESCRIPTION",

    VDLANG_TREE_NODE_IMPORT = "VDLANG_TREE_NODE_IMPORT",
    VDLANG_TREE_NODE_IMPORT_TEXT = "VDLANG_TREE_NODE_IMPORT_TEXT",
    VDLANG_SUPPLIERS_IMPORT_TEXT = "VDLANG_SUPPLIERS_IMPORT_TEXT",
    VDLANG_SUPERADMIN_SUPPLIERS_SUCCESS = "VDLANG_SUPERADMIN_SUPPLIERS_SUCCESS",
    VDLANG_IMPORT = "VDLANG_IMPORT",

    VDLANG_TREE_NODE_ADD_NODE = "VDLANG_TREE_NODE_ADD_NODE",
    VDLANG_TREE_NODE_MOVE_NODE = "VDLANG_TREE_NODE_MOVE_NODE",
    VDLANG_TREE_NODE_EDIT_NODE = "VDLANG_TREE_NODE_EDIT_NODE",
    VDLANG_TREE_NODE_EDIT_SELECTABLE_LABEL = "VDLANG_TREE_NODE_EDIT_SELECTABLE_LABEL",
    VDLANG_TREE_NODE_EDIT_SELECTABLE_HINT = "VDLANG_TREE_NODE_EDIT_SELECTABLE_HINT",
    VDLANG_TREE_SELECT_NODE_HINT = "VDLANG_TREE_SELECT_NODE_HINT",
    VDLANG_TREE_MULTI_SELECT_NODE_HINT = "VDLANG_TREE_MULTI_SELECT_NODE_HINT",
    VDLANG_TREE_NODE_NOT_SELECTABLE = "VDLANG_TREE_NODE_NOT_SELECTABLE",
    VDLANG_TREE_NODE_CONTEXT_MENU_TITLE = "VDLANG_TREE_NODE_CONTEXT_MENU_TITLE",
    VDLANG_TREE_NODE_CONTEXT_MENU_HINT = "VDLANG_TREE_NODE_CONTEXT_MENU_HINT",

    VDLANG_MEASURE_PREVIEW_TOTAL_EFFECT = "VDLANG_MEASURE_PREVIEW_TOTAL_EFFECT",
    VDLANG_MEASURE_PREVIEW_NO_DESCRIPTION = "VDLANG_MEASURE_PREVIEW_NO_DESCRIPTION",
    VDLANG_MEASURE_PREVIEW_NO_EFFECT_CATEGORIES = "VDLANG_MEASURE_PREVIEW_NO_EFFECT_CATEGORIES",
    VDLANG_MEASURE_PREVIEW_NOT_FILLED_YET = "VDLANG_MEASURE_PREVIEW_NOT_FILLED_YET",

    VDLANG_CURRENCY_TABLE_COLUMN_NAME = "VDLANG_CURRENCY_TABLE_COLUMN_NAME",
    VDLANG_CURRENCY_TABLE_COLUMN_CODE = "VDLANG_CURRENCY_TABLE_COLUMN_CODE",
    VDLANG_DEPARTMENT_TABLE_COLUMN_NAME_EN = "VDLANG_DEPARTMENT_TABLE_COLUMN_NAME_EN",
    VDLANG_DEPARTMENT_TABLE_COLUMN_NAME_DE = "VDLANG_DEPARTMENT_TABLE_COLUMN_NAME_DE",
    VDLANG_DEPARTMENT_TABLE_NO_DATA = "VDLANG_DEPARTMENT_TABLE_NO_DATA",
    VDLANG_DEPARTMENT_TABLE_CANNOT_DELETE_IN_USE = "VDLANG_DEPARTMENT_TABLE_CANNOT_DELETE_IN_USE",

    VDLANG_PAGING_ROWS_PER_PAGE = "VDLANG_PAGING_ROWS_PER_PAGE",

    VDLANG_DUEDATE_AFTER_START_WARNING = "VDLANG_DUEDATE_AFTER_START_WARNING",

    VDLANG_SELECT_TYPE_AT_LEAST_3_CHARACTERS = "VDLANG_SELECT_TYPE_AT_LEAST_3_CHARACTERS",
    VDLANG_SELECT_PLEASE_SELECT = "VDLANG_SELECT_PLEASE_SELECT",
    VDLANG_SELECT_NO_RESULTS = "VDLANG_SELECT_NO_RESULTS",
    VDLANG_SELECT_MORE_OPTIONS_HINT = "VDLANG_SELECT_MORE_OPTIONS_HINT",
    VDLANG_SELECT_CREATE_OPTION = "VDLANG_SELECT_CREATE_OPTION",
    VDLANG_SELECT_DELETED_OPTION = "VDLANG_SELECT_DELETED_OPTION",

    VDLANG_CURRENCY_INPUT_INVALID_NUMBER_RANGE = "VDLANG_CURRENCY_INPUT_INVALID_NUMBER_RANGE",

    VDLANG_MEASURES_SIDEBAR_SCOPE = "VDLANG_MEASURES_SIDEBAR_SCOPE",

    VDLANG_PRIVACY_POLICY = "VDLANG_PRIVACY_POLICY",

    VDLANG_SAVE_VIEW = "VDLANG_SAVE_VIEW",
    VDLANG_RENAME_VIEW = "VDLANG_RENAME_VIEW",
    VDLANG_SEARCH_CONFIGS_STANDARD_NAME = "VDLANG_SEARCH_CONFIGS_STANDARD_NAME",
    VDLANG_SEARCH_CONFIGS_REMOVE = "VDLANG_SEARCH_CONFIGS_REMOVE",
    VDLANG_SEARCH_CONFIGS_ADD = "VDLANG_SEARCH_CONFIGS_ADD",
    VDLANG_SEARCH_CONFIGS_ADD_HINT = "VDLANG_SEARCH_CONFIGS_ADD_HINT",
    VDLANG_SEARCH_CONFIGS_REMOVE_TITLE = "VDLANG_SEARCH_CONFIGS_REMOVE_TITLE",
    VDLANG_SEARCH_CONFIGS_COMPANY_WIDE_EDITING_DISABLED_HINT = "VDLANG_SEARCH_CONFIGS_COMPANY_WIDE_EDITING_DISABLED_HINT",
    VDLANG_SEARCH_CONFIGS_TITLE_TOO_LONG_HINT = "VDLANG_SEARCH_CONFIGS_TITLE_TOO_LONG_HINT",
    VDLANG_SEARCH_INPUT_HINT = "VDLANG_SEARCH_INPUT_HINT",
    VDLANG_SEARCH_RESULTS = "VDLANG_SEARCH_RESULTS",
    VDLANG_SEARCH_NO_RESULTS = "VDLANG_SEARCH_NO_RESULTS",

    VDLANG_DASHBOARDS_ADD_WIDGET_BUTTON_LABEL = "VDLANG_DASHBOARDS_ADD_WIDGET_BUTTON_LABEL",
    VDLANG_DASHBOARD_EMPTY_WIDGET = "VDLANG_DASHBOARD_EMPTY_WIDGET",
    VDLANG_DASHBOARDS_REMOVE_WIDGET_MENU_ITEM_LABEL = "VDLANG_DASHBOARDS_REMOVE_WIDGET_MENU_ITEM_LABEL",
    VDLANG_DASHBOARDS_REMOVE_WIDGET_CONFIRM_DIALOG_TITLE = "VDLANG_DASHBOARDS_REMOVE_WIDGET_CONFIRM_DIALOG_TITLE",
    VDLANG_DASHBOARDS_WIDGET_CONFIGURATION_DIALOG_TITLE = "VDLANG_DASHBOARDS_WIDGET_CONFIGURATION_DIALOG_TITLE",
    VDLANG_DASHBOARDS_DUPLICATE_WIDGET_MENU_ITEM_LABEL = "VDLANG_DASHBOARDS_DUPLICATE_WIDGET_MENU_ITEM_LABEL",
    VDLANG_DASHBOARDS_DUPLICATE_WIDGET_BUTTON_LABEL = "VDLANG_DASHBOARDS_DUPLICATE_WIDGET_BUTTON_LABEL",
    VDLANG_DASHBOARDS_PERMISSIONS_ACCEPT_BUTTON_LABEL = "VDLANG_DASHBOARDS_PERMISSIONS_ACCEPT_BUTTON_LABEL",
    VDLANG_DASHBOARDS_NO_DASHBOARD = "VDLANG_DASHBOARDS_NO_DASHBOARD",
    VDLANG_DASHBOARDS_NO_DASHBOARD_FOUND = "VDLANG_DASHBOARDS_NO_DASHBOARD_FOUND",
    VDLANG_DASHBOARDS_MY_CREATED = "VDLANG_DASHBOARDS_MY_CREATED",
    VDLANG_DASHBOARDS_OTHERS = "VDLANG_DASHBOARDS_OTHERS",
    VDLANG_DASHBOARDS_LAST_VIEWED = "VDLANG_DASHBOARDS_LAST_VIEWED",
    VDLANG_DASHBOARDS_WIDGET_CONFIG_DISABLED_HINT = "VDLANG_DASHBOARDS_WIDGET_CONFIG_DISABLED_HINT",
    VDLANG_DASHBOARDS_CREATE = "VDLANG_DASHBOARDS_CREATE",
    VDLANG_DASHBOARDS_CREATE_NAME = "VDLANG_DASHBOARDS_CREATE_NAME",
    VDLANG_DASHBOARDS_CREATE_NAME_ERROR = "VDLANG_DASHBOARDS_CREATE_NAME_ERROR",
    VDLANG_DASHBOARDS_CREATE_NAME_LENGTH_ERROR = "VDLANG_DASHBOARDS_CREATE_NAME_LENGTH_ERROR",
    VDLANG_DASHBOARDS_CREATE_BUTTON = "VDLANG_DASHBOARDS_CREATE_BUTTON",
    VDLANG_DASHBOARDS_UPDATE = "VDLANG_DASHBOARDS_UPDATE",
    VDLANG_DASHBOARDS_UPDATE_NAME = "VDLANG_DASHBOARDS_UPDATE_NAME",
    VDLANG_DASHBOARDS_UPDATE_NAME_ERROR = "VDLANG_DASHBOARDS_UPDATE_NAME_ERROR",
    VDLANG_DASHBOARDS_UPDATE_NAME_TOO_LONG = "VDLANG_DASHBOARDS_UPDATE_NAME_TOO_LONG",
    VDLANG_DASHBOARDS_UPDATE_BUTTON = "VDLANG_DASHBOARDS_UPDATE_BUTTON",
    VDLANG_DASHBOARDS_CONTEXT_MENU = "VDLANG_DASHBOARDS_CONTEXT_MENU",
    VDLANG_DASHBOARDS_CONTEXT_UPDATE = "VDLANG_DASHBOARDS_CONTEXT_UPDATE",
    VDLANG_DASHBOARDS_CONTEXT_DELETE = "VDLANG_DASHBOARDS_CONTEXT_DELETE",
    VDLANG_DASHBOARDS_CONTEXT_DELETE_DISABLED_HINT = "VDLANG_DASHBOARDS_CONTEXT_DELETE_DISABLED_HINT",
    VDLANG_DASHBOARDS_DELETE_TITLE = "VDLANG_DASHBOARDS_DELETE_TITLE",
    VDLANG_DASHBOARDS_DUPLICATE = "VDLANG_DASHBOARDS_DUPLICATE",
    VDLANG_DASHBOARDS_DUPLICATE_LABEL = "VDLANG_DASHBOARDS_DUPLICATE_LABEL",
    VDLANG_DASHBOARDS_WIDGET_UPDATED_AT = "VDLANG_DASHBOARDS_WIDGET_UPDATED_AT",
    VDLANG_DASHBOARDS_WIDGET_DESCRIPTION_PLACEHOLDER = "VDLANG_DASHBOARDS_WIDGET_DESCRIPTION_PLACEHOLDER",
    VDLANG_DASHBOARDS_DIFFERENCE_TO_TARGET_LABEL = "VDLANG_DASHBOARDS_DIFFERENCE_TO_TARGET_LABEL",
    VDLANG_DASHBOARDS_TARGET_LABEL = "VDLANG_DASHBOARDS_TARGET_LABEL",
    VDLANG_DASHBOARDS_DASHBOARD_OWNER = "VDLANG_DASHBOARDS_DASHBOARD_OWNER",

    VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_TITLE = "VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_TITLE",
    VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_DESCRIPTION = "VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_DESCRIPTION",
    VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_PROCESS_TYPE = "VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_PROCESS_TYPE",
    VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_NO_POTENTIAL_MESSAGE = "VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_NO_POTENTIAL_MESSAGE",
    VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_GENERAL = "VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_GENERAL",
    VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_DESCRIPTION = "VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_DESCRIPTION",
    VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_SCOPE = "VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_SCOPE",
    VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_FILTER = "VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_FILTER",

    VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_TITLE = "VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_TITLE",
    VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_DESCRIPTION = "VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_DESCRIPTION",
    VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_CONFIG_TAB_GENERAL = "VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_CONFIG_TAB_GENERAL",
    VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_CONFIG_TAB_DESCRIPTION = "VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_CONFIG_TAB_DESCRIPTION",
    VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_CONFIG_TAB_FILTER = "VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_CONFIG_TAB_FILTER",
    VDLANG_DASHBOARDS_COMMENT_STREAM_TITLE_PREFIX = "VDLANG_DASHBOARDS_COMMENT_STREAM_TITLE_PREFIX",
    VDLANG_DASHBOARDS_COMMENT_STREAM_TITLE_SUFFIX = "VDLANG_DASHBOARDS_COMMENT_STREAM_TITLE_SUFFIX",
    VDLANG_DASHBOARDS_COMMENT_STREAM_FILTER_ENTITY = "VDLANG_DASHBOARDS_COMMENT_STREAM_FILTER_ENTITY",
    VDLANG_DASHBOARDS_COMMENT_STREAM_FILTER_COMMENTED_BY = "VDLANG_DASHBOARDS_COMMENT_STREAM_FILTER_COMMENTED_BY",
    VDLANG_DASHBOARDS_COMMENT_STREAM_FILTER_RESPONSIBLE_PERSON = "VDLANG_DASHBOARDS_COMMENT_STREAM_FILTER_RESPONSIBLE_PERSON",

    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_GENERAL_SETTINGS = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_GENERAL_SETTINGS",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_CONFIG_TAB_DESCRIPTION = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_CONFIG_TAB_DESCRIPTION",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_FILTER_CONFIGURATION = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_FILTER_CONFIGURATION",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_PROCESSES_TITLE = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_PROCESSES_TITLE",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_PROCESSES_DESCRIPTION = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_PROCESSES_DESCRIPTION",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_OVERDUE_PROCESSES_TITLE = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_OVERDUE_PROCESSES_TITLE",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_OVERDUE_PROCESSES_DESCRIPTION = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_OVERDUE_PROCESSES_DESCRIPTION",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_TOP_PROCESSES_TITLE = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_TOP_PROCESSES_TITLE",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_TOP_PROCESSES_DESCRIPTION = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_TOP_PROCESSES_DESCRIPTION",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_DISCARDED_LAST_60_DAYS_TITLE = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_DISCARDED_LAST_60_DAYS_TITLE",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_DISCARDED_LAST_60_DAYS_DESCRIPTION = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_DISCARDED_LAST_60_DAYS_DESCRIPTION",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_NEXT_60_DAYS_TITLE = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_NEXT_60_DAYS_TITLE",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_NEXT_60_DAYS_DESCRIPTION = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_NEXT_60_DAYS_DESCRIPTION",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_IN_DECISION_OVER_60_DAYS_TITLE = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_IN_DECISION_OVER_60_DAYS_TITLE",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_IN_DECISION_OVER_60_DAYS_DESCRIPTION = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_IN_DECISION_OVER_60_DAYS_DESCRIPTION",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_WITHOUT_CHANGE_LAST_60_DAYS_TITLE = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_WITHOUT_CHANGE_LAST_60_DAYS_TITLE",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_WITHOUT_CHANGE_LAST_60_DAYS_DESCRIPTION = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_WITHOUT_CHANGE_LAST_60_DAYS_DESCRIPTION",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_FAVORITE_TITLE = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_FAVORITE_TITLE",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_FAVORITE_DESCRIPTION = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_FAVORITE_DESCRIPTION",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_LEVEL_RESPONSIBILITIES = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_LEVEL_RESPONSIBILITIES",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_LEVEL_RESPONSIBILITIES_DESCRIPTION = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_LEVEL_RESPONSIBILITIES_DESCRIPTION",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_BLANK_TITLE = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_BLANK_TITLE",
    VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_BLANK_DESCRIPTION = "VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_BLANK_DESCRIPTION",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_PLAN_FC_VS_TARGET_TITLE = "VDLANG_DASHBOARDS_ROLLING_FORECAST_PLAN_FC_VS_TARGET_TITLE",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_PLAN_FC_VS_TARGET_DESCRIPTION = "VDLANG_DASHBOARDS_ROLLING_FORECAST_PLAN_FC_VS_TARGET_DESCRIPTION",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_VS_PLAN_TITLE = "VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_VS_PLAN_TITLE",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_VS_PLAN_DESCRIPTION = "VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_VS_PLAN_DESCRIPTION",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_PRESENTATION_NONE = "VDLANG_DASHBOARDS_ROLLING_FORECAST_PRESENTATION_NONE",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_PRESENTATION_LINE = "VDLANG_DASHBOARDS_ROLLING_FORECAST_PRESENTATION_LINE",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_PRESENTATION_BAR = "VDLANG_DASHBOARDS_ROLLING_FORECAST_PRESENTATION_BAR",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_CUMULATED_LEGEND = "VDLANG_DASHBOARDS_ROLLING_FORECAST_CUMULATED_LEGEND",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_TARGET = "VDLANG_DASHBOARDS_ROLLING_FORECAST_TARGET",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ROLLING_FORECAST = "VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ROLLING_FORECAST",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_ROLLING_FORECAST = "VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_ROLLING_FORECAST",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_ROLLING_FORECAST_HINT = "VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_ROLLING_FORECAST_HINT",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_ROLLING_FORECAST_SHOW_SUMS = "VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_ROLLING_FORECAST_SHOW_SUMS",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_AXIS_MIN_VALUE = "VDLANG_DASHBOARDS_ROLLING_FORECAST_AXIS_MIN_VALUE",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_AXIS_MAX_VALUE = "VDLANG_DASHBOARDS_ROLLING_FORECAST_AXIS_MAX_VALUE",
    VDLANG_DASHBOARDS_UNDEFINED_GROUP = "VDLANG_DASHBOARDS_UNDEFINED_GROUP",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_TITLE = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_TITLE",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_DESCRIPTION = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_DESCRIPTION",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_EFFECTS = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_EFFECTS",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_TARGET = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_TARGET",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_NAME = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_NAME",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_FISCAL_YEAR = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_FISCAL_YEAR",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_TARGET = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_TARGET",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_SHOW_SUMS = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_SHOW_SUMS",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_AXIS_MIN_VALUE = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_AXIS_MIN_VALUE",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_AXIS_MAX_VALUE = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_AXIS_MAX_VALUE",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_GROUP = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_GROUP",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_NO_GROUP_SELECTED = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_NO_GROUP_SELECTED",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_SUM = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_SUM",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_FORECAST_CONFIGURATION = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_FORECAST_CONFIGURATION",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_TARGET_SUMMARY = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_TARGET_SUMMARY",
    VDLANG_DASHBOARDS_COMPLETED_EFFECTS_FORECAST_HINT = "VDLANG_DASHBOARDS_COMPLETED_EFFECTS_FORECAST_HINT",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_GENERAL = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_GENERAL",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_DESCRIPTION = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_DESCRIPTION",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_SCOPE = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_SCOPE",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_FILTER = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_FILTER",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_TARGETS = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_TARGETS",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_REFERENCE_VALUES = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_REFERENCE_VALUES",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_NAME = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_NAME",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_BARTYPE = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_BARTYPE",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_FIRST_PIVOT_FIELD = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_FIRST_PIVOT_FIELD",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TITLE_ASCENDING = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TITLE_ASCENDING",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TITLE_DESCENDING = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TITLE_DESCENDING",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_ASCENDING = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_ASCENDING",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_DESCENDING = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_DESCENDING",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_FIELD_ORDER_ASCENDING = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_FIELD_ORDER_ASCENDING",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_FIELD_ORDER_DESCENDING = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_FIELD_ORDER_DESCENDING",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_BAR_HORIZONTAL = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_BAR_HORIZONTAL",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_BAR_VERTICAL = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_BAR_VERTICAL",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_SECOND_PIVOT_FIELD = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_SECOND_PIVOT_FIELD",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_COUNT_OF_PROCESSES = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_COUNT_OF_PROCESSES",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_SUM_OF_POTENTIAL = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_SUM_OF_POTENTIAL",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_DEFAULT_TREE_FIELD_LEVEL = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_DEFAULT_TREE_FIELD_LEVEL",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_DEFAULT_TREE_FIELD_LEVEL_HINT = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_DEFAULT_TREE_FIELD_LEVEL_HINT",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_DISCARDED_BY_LEVEL_TITLE = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_DISCARDED_BY_LEVEL_TITLE",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_DISCARDED_BY_LEVEL_DESCRIPTION = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_DISCARDED_BY_LEVEL_DESCRIPTION",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_COMPANY_BY_LEVEL_TITLE = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_COMPANY_BY_LEVEL_TITLE",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_COMPANY_BY_LEVEL_DESCRIPTION = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_COMPANY_BY_LEVEL_DESCRIPTION",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_WIDGET_BLANK_TEMPLATE_TITLE = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_WIDGET_BLANK_TEMPLATE_TITLE",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_WIDGET_BLANK_TEMPLATE_DESCRIPTION = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_WIDGET_BLANK_TEMPLATE_DESCRIPTION",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_PRODUCT_GROUP_BY_LEVEL_TITLE = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_PRODUCT_GROUP_BY_LEVEL_TITLE",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_PRODUCT_GROUP_BY_LEVEL_DESCRIPTION = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_PRODUCT_GROUP_BY_LEVEL_DESCRIPTION",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_DRILLDOWN_TITLE = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_DRILLDOWN_TITLE",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_POTENTIAL = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_POTENTIAL",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AGGREGATION = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AGGREGATION",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AXIS_MIN_VALUE = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AXIS_MIN_VALUE",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AXIS_MAX_VALUE = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AXIS_MAX_VALUE",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_REFERENCE_VALUES_CONFIG_MISMATCH_HINT = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_REFERENCE_VALUES_CONFIG_MISMATCH_HINT",
    VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_SHOW_RELATIVE_REPRESENTATION = "VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_SHOW_RELATIVE_REPRESENTATION",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TAB_GENERAL = "VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TAB_GENERAL",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TAB_DESCRIPTION = "VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TAB_DESCRIPTION",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TAB_TARGET = "VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TAB_TARGET",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_NAME = "VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_NAME",
    VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TARGET_HEADING = "VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TARGET_HEADING",
    VDLANG_DASHBOARDS_WIDGET_NO_DATA = "VDLANG_DASHBOARDS_WIDGET_NO_DATA",
    VDLANG_DASHBOARDS_WIDGET_ERROR = "VDLANG_DASHBOARDS_WIDGET_ERROR",
    VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_TITLE = "VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_TITLE",
    VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_DESCRIPTION = "VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_DESCRIPTION",
    VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_CONFIG_TAB_GENERAL = "VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_CONFIG_TAB_GENERAL",
    VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_CONFIG_TAB_DESCRIPTION = "VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_CONFIG_TAB_DESCRIPTION",
    VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_CONFIG_TAB_FILTER = "VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_CONFIG_TAB_FILTER",
    VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_TITLE = "VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_TITLE",
    VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_DESCRIPTION = "VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_DESCRIPTION",
    VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_CONFIG_TAB_GENERAL = "VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_CONFIG_TAB_GENERAL",
    VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_CONFIG_TAB_FILTER = "VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_CONFIG_TAB_FILTER",
    VDLANG_DASHBOARDS_IDEA_WIDGET_CONFIG_FILTERS_SELECTED = "VDLANG_DASHBOARDS_IDEA_WIDGET_CONFIG_FILTERS_SELECTED",
    VDLANG_DASHBOARDS_IDEA_WIDGET_CONFIG_FILTERS_RESET = "VDLANG_DASHBOARDS_IDEA_WIDGET_CONFIG_FILTERS_RESET",
    VDLANG_DASHBOARDS_IDEA_WIDGET_CONFIG_FILTERS_MORE_FIELDS = "VDLANG_DASHBOARDS_IDEA_WIDGET_CONFIG_FILTERS_MORE_FIELDS",
    VDLANG_DASHBOARDS_MY_NEXT_ACTIVITIES_TITLE = "VDLANG_DASHBOARDS_MY_NEXT_ACTIVITIES_TITLE",
    VDLANG_DASHBOARDS_MY_NEXT_ACTIVITIES_DESCRIPTION = "VDLANG_DASHBOARDS_MY_NEXT_ACTIVITIES_DESCRIPTION",
    VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_BLANK_TEMPLATE_TITLE = "VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_BLANK_TEMPLATE_TITLE",
    VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_BLANK_TEMPLATE_DESCRIPTION = "VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_BLANK_TEMPLATE_DESCRIPTION",
    VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_CONFIG_TAB_DESCRIPTION = "VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_CONFIG_TAB_DESCRIPTION",
    VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_FILTER_HINT = "VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_FILTER_HINT",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_TITLE = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_TITLE",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_DESCRIPTION = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_DESCRIPTION",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_SUM_LABEL = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_SUM_LABEL",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_NOT_FILLED_LABEL = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_NOT_FILLED_LABEL",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_HIDDEN_PROCESSES_MESSAGE = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_HIDDEN_PROCESSES_MESSAGE",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_Y_AXIS = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_Y_AXIS",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_X_AXIS = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_X_AXIS",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_AGGREGATION = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_AGGREGATION",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_MAX_VALUE_POTENTIAL = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_MAX_VALUE_POTENTIAL",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_MAX_VALUE_COUNT = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_MAX_VALUE_COUNT",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_SORT_TITLE = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_SORT_TITLE",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_SORT_AGGREGATION = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_SORT_AGGREGATION",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_SORT_FIELD = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_SORT_FIELD",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_USE_MAX_VALUES_HEADING = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_USE_MAX_VALUES_HEADING",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_USE_MAX_VALUES_HINT = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_USE_MAX_VALUES_HINT",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_DEFAULT_X_AXIS_TREE_FIELD_LEVEL = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_DEFAULT_X_AXIS_TREE_FIELD_LEVEL",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_DEFAULT_Y_AXIS_TREE_FIELD_LEVEL = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_DEFAULT_Y_AXIS_TREE_FIELD_LEVEL",
    VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_DEFAULT_TREE_FIELD_LEVEL_HINT = "VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_DEFAULT_TREE_FIELD_LEVEL_HINT",

    VDLANG_DASHBOARDS_CHART_WIDGET_INCOMPLETE_CONFIG = "VDLANG_DASHBOARDS_CHART_WIDGET_INCOMPLETE_CONFIG",
    VDLANG_DASHBOARDS_CHART_WIDGET_INCOMPLETE_CONFIG_BUTTON_LABEL = "VDLANG_DASHBOARDS_CHART_WIDGET_INCOMPLETE_CONFIG_BUTTON_LABEL",

    VDLANG_DASHBOARDS_LIVE_RUN_UP_EMPTY_TEMPLATE_TITLE = "VDLANG_DASHBOARDS_LIVE_RUN_UP_EMPTY_TEMPLATE_TITLE",
    VDLANG_DASHBOARDS_LIVE_RUN_UP_EMPTY_TEMPLATE_DESCRIPTION = "VDLANG_DASHBOARDS_LIVE_RUN_UP_EMPTY_TEMPLATE_DESCRIPTION",
    VDLANG_DASHBOARDS_LIVE_RUN_UP_TARGET_LABEL = "VDLANG_DASHBOARDS_LIVE_RUN_UP_TARGET_LABEL",
    VDLANG_DASHBOARDS_LIVE_RUN_UP_TARGET_CHIP = "VDLANG_DASHBOARDS_LIVE_RUN_UP_TARGET_CHIP",
    VDLANG_DASHBOARDS_LIVE_RUN_UP_CUSTOMIZE_TIME_AXIS_HEADING = "VDLANG_DASHBOARDS_LIVE_RUN_UP_CUSTOMIZE_TIME_AXIS_HEADING",
    VDLANG_DASHBOARDS_LIVE_RUN_UP_GRANULARITY = "VDLANG_DASHBOARDS_LIVE_RUN_UP_GRANULARITY",
    VDLANG_DASHBOARDS_LIVE_RUN_UP_GRANULARITY_LABELS = "VDLANG_DASHBOARDS_LIVE_RUN_UP_GRANULARITY_LABELS",
    VDLANG_DASHBOARDS_LIVE_RUN_UP_ACCUMULATION_START_LABEL = "VDLANG_DASHBOARDS_LIVE_RUN_UP_ACCUMULATION_START_LABEL",
    VDLANG_DASHBOARDS_LIVE_RUN_UP_SHOW_SUMS = "VDLANG_DASHBOARDS_LIVE_RUN_UP_SHOW_SUMS",
    VDLANG_DASHBOARDS_LIVE_RUN_UP_AXIS_MIN_VALUE = "VDLANG_DASHBOARDS_LIVE_RUN_UP_AXIS_MIN_VALUE",
    VDLANG_DASHBOARDS_LIVE_RUN_UP_AXIS_MAX_VALUE = "VDLANG_DASHBOARDS_LIVE_RUN_UP_AXIS_MAX_VALUE",
    VDLANG_DASHBOARDS_LIVE_RUN_UP_SHOW_REFERENCE_VALUES = "VDLANG_DASHBOARDS_LIVE_RUN_UP_SHOW_REFERENCE_VALUES",

    VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_TITLE = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_TITLE",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_DESCRIPTION = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_DESCRIPTION",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_DRILLDOWN_RECURRING_TAB = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_DRILLDOWN_RECURRING_TAB",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_DRILLDOWN_ONE_TIME_TAB = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_DRILLDOWN_ONE_TIME_TAB",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_GENERAL = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_GENERAL",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_SCOPE = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_SCOPE",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_DESCRIPTION = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_DESCRIPTION",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_FILTER_CONFIGURATION = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_FILTER_CONFIGURATION",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_REFERENCE_VALUES = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_REFERENCE_VALUES",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_TARGETS = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_TARGETS",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_NAME = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_NAME",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_AXIS_MIN_VALUE = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_AXIS_MIN_VALUE",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_AXIS_MAX_VALUE = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_AXIS_MAX_VALUE",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_SHOW_SUMS = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_SHOW_SUMS",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_SHOW_REFERENCE_VALUES = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_SHOW_REFERENCE_VALUES",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_SHOW_TIME_AXIS_HEADING = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_SHOW_TIME_AXIS_HEADING",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_CONFIGURE_RECURRING_EFFECTS = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_CONFIGURE_RECURRING_EFFECTS",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_CONFIGURE_RECURRING_EFFECTS_HINT = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_CONFIGURE_RECURRING_EFFECTS_HINT",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_ATTRIBUTE_LABEL = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_ATTRIBUTE_LABEL",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_ONETIME_LABEL = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_ONETIME_LABEL",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_RECURRING_LABEL = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_RECURRING_LABEL",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_TARGETS_FORM_TARGETS = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_TARGETS_FORM_TARGETS",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_TARGETS_FORM_ADD_TARGET = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_TARGETS_FORM_ADD_TARGET",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_TARGETS_FORM_ADD_TARGET_AFTER = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_TARGETS_FORM_ADD_TARGET_AFTER",
    VDLANG_DASHBOARDS_PROJECT_PROGRESS_TARGETS_FORM_ADD_TARGET_BEFORE = "VDLANG_DASHBOARDS_PROJECT_PROGRESS_TARGETS_FORM_ADD_TARGET_BEFORE",

    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_WIDGET_TITLE = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_WIDGET_TITLE",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_WIDGET_DESCRIPTION = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_WIDGET_DESCRIPTION",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TAB_GENERAL = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TAB_GENERAL",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TAB_DESCRIPTION = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TAB_DESCRIPTION",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TAB_FILTER_CONFIGURATION = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TAB_FILTER_CONFIGURATION",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_NAME = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_NAME",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_FISCAL_YEAR = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_FISCAL_YEAR",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TARGET = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TARGET",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_REFERENCE_DATE = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_REFERENCE_DATE",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_MATERIALIZED_SAVINGS = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_MATERIALIZED_SAVINGS",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_MATERIALIZED_CARRY_OVER = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_MATERIALIZED_CARRY_OVER",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_PLANNED_SAVINGS = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_PLANNED_SAVINGS",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_PLANNED_CARRY_OVER = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_PLANNED_CARRY_OVER",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_OVERDUE_SAVINGS = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_OVERDUE_SAVINGS",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_USE_CUSTOM_REFERENCE_DATE = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_USE_CUSTOM_REFERENCE_DATE",
    VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_DIFFERENCE_TO_TARGET = "VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_DIFFERENCE_TO_TARGET",

    VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_TIMESTAMP = "VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_TIMESTAMP",
    VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_TIMESTAMP_LEGEND = "VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_TIMESTAMP_LEGEND",
    VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIG_MISMATCH_HINT = "VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIG_MISMATCH_HINT",
    VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_NO_VALUES = "VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_NO_VALUES",
    VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_OVERRIDE_TITLE = "VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_OVERRIDE_TITLE",
    VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_OVERRIDE_TEXT = "VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_OVERRIDE_TEXT",
    VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_DELETE_TITLE = "VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_DELETE_TITLE",
    VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_DELETE_TEXT = "VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_DELETE_TEXT",

    VDLANG_DASHBOARDS_POTENTIAL_KPI_WIDGET_TITLE = "VDLANG_DASHBOARDS_POTENTIAL_KPI_WIDGET_TITLE",
    VDLANG_DASHBOARDS_POTENTIAL_KPI_WIDGET_DESCRIPTION = "VDLANG_DASHBOARDS_POTENTIAL_KPI_WIDGET_DESCRIPTION",
    VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_GENERAL = "VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_GENERAL",
    VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_DESCRIPTION = "VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_DESCRIPTION",
    VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_SCOPE = "VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_SCOPE",
    VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_FILTER = "VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_FILTER",
    VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_NAME = "VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_NAME",
    VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_TITLE = "VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_TITLE",
    VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_DESCRIPTION = "VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_DESCRIPTION",
    VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_ON_TRACK = "VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_ON_TRACK",
    VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_DELAYED = "VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_DELAYED",
    VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_GENERAL = "VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_GENERAL",
    VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_DESCRIPTION = "VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_DESCRIPTION",
    VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_SCOPE = "VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_SCOPE",
    VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_FILTER = "VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_FILTER",
    VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_NAME = "VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_NAME",

    VDLANG_DASHBOARDS_TIMELINE_WIDGET_TITLE = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_TITLE",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_DESCRIPTION = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_DESCRIPTION",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_GENERAL = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_GENERAL",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_DESCRIPTION = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_DESCRIPTION",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_FILTER_CONFIGURATION = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_FILTER_CONFIGURATION",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_FLIGHT_PATHS = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_FLIGHT_PATHS",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_FLIGHT_PATH_FORMULA_HEADING = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_FLIGHT_PATH_FORMULA_HEADING",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_FLIGHT_PATH_FORMULA_HINT = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_FLIGHT_PATH_FORMULA_HINT",

    VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_FLIGHT_PATH_FORMULA_LABEL = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_FLIGHT_PATH_FORMULA_LABEL",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_NAME = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_NAME",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TARGET = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TARGET",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_AXIS_MIN_VALUE = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_AXIS_MIN_VALUE",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_AXIS_MAX_VALUE = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_AXIS_MAX_VALUE",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_SCOPE = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_SCOPE",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TIME_FRAME = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TIME_FRAME",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_LEGEND_CARRY_OVER = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_LEGEND_CARRY_OVER",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_LEGEND_TARGET = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_LEGEND_TARGET",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_WEEK = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_WEEK",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_WEEK_AXIS_LABEL = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_WEEK_AXIS_LABEL",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_LEGEND_LABEL = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_LEGEND_LABEL",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_N_WEEKS_ERROR = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_N_WEEKS_ERROR",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_ALL_WEEKS_ERROR = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_ALL_WEEKS_ERROR",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_WEEK_ERROR = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_WEEK_ERROR",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_WEEK_ERROR_LIST = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_WEEK_ERROR_LIST",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_GATETASK = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_GATETASK",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_FLIGHTPATH = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_FLIGHTPATH",
    VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_TOTAL = "VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_TOTAL",

    VDLANG_DASHBOARDS_NOT_FOUND = "VDLANG_DASHBOARDS_NOT_FOUND",

    VDLANG_TIME_RANGE_SELECT_WITHOUT_TIME_RANGE = "VDLANG_TIME_RANGE_SELECT_WITHOUT_TIME_RANGE",
    VDLANG_SCOPE_CURRENCY = "VDLANG_SCOPE_NO_CURRENCY",

    VDLANG_PROCESS_DETAIL_PROCESS_NOT_FOUND = "VDLANG_PROCESS_DETAIL_PROCESS_NOT_FOUND",

    VDLANG_GROUP_CONTRIBUTOR = "VDLANG_GROUP_CONTRIBUTOR",
    VDLANG_GROUP_FULL_USER = "VDLANG_GROUP_FULL_USER",

    VDLANG_IDEAS_IDEA = "VDLANG_IDEA",
    VDLANG_IDEAS_TITLE_EMPTY_ERROR = "VDLANG_IDEAS_TITLE_EMPTY_ERROR",
    VDLANG_IDEAS_TITLE_TOO_LONG_ERROR = "VDLANG_IDEAS_TITLE_TOO_LONG_ERROR",
    VDLANG_IDEAS_NAVBAR_ITEM_LABEL = "VDLANG_IDEAS_NAVBAR_ITEM_LABEL",
    VDLANG_IDEAS_ITEMS_LABEL = "VDLANG_IDEAS_ITEMS_LABEL",
    VDLANG_IDEAS_NO_IDEAS_YET = "VDLANG_IDEAS_NO_IDEAS_YET",
    VDLANG_IDEAS_ADD_IDEA_BUTTON_LABEL = "VDLANG_IDEAS_ADD_IDEA_BUTTON_LABEL",
    VDLANG_IDEAS_IDEA_FIELDS = "VDLANG_IDEAS_IDEA_FIELDS",
    VDLANG_IDEAS_IDEA_DESCRIPTION = "VDLANG_IDEAS_IDEA_DESCRIPTION",
    VDLANG_IDEAS_IDEA_COMMENTS = "VDLANG_IDEAS_IDEA_COMMENTS",
    VDLANG_IDEAS_IDEA_HISTORY = "VDLANG_IDEAS_IDEA_HISTORY",
    VDLANG_IDEAS_IDEA_POTENTIAL = "VDLANG_IDEAS_IDEA_POTENTIAL",
    VDLANG_IDEAS_IDEA_POTENTIAL_TOTAL_HINT = "VDLANG_IDEAS_IDEA_POTENTIAL_TOTAL_HINT",
    VDLANG_IDEAS_IDEA_PERIOD = "VDLANG_IDEAS_IDEA_PERIOD",
    VDLANG_IDEAS_IDEA_SHOW_CREATOR = "VDLANG_IDEAS_IDEA_SHOW_CREATOR",
    VDLANG_IDEAS_IDEA_CREATOR_LABEL = "VDLANG_IDEAS_IDEA_CREATOR_LABEL",
    VDLANG_IDEAS_IDEA_RESPONSIBLE_LABEL = "VDLANG_IDEAS_IDEA_RESPONSIBLE_LABEL",
    VDLANG_IDEAS_FIELDS_NOT_SET = "VDLANG_IDEAS_FIELDS_NOT_SET",
    VDLANG_IDEAS_TIME_ESTIMATE_SLIDER_VALUE = "VDLANG_IDEAS_TIME_ESTIMATE_SLIDER_VALUE",
    VDLANG_IDEAS_EFFORT_ESTIMATE_SLIDER_VALUE = "VDLANG_IDEAS_EFFORT_ESTIMATE_SLIDER_VALUE",
    VDLANG_IDEAS_DISCARD_IDEA = "VDLANG_IDEAS_DISCARD_IDEA",
    VDLANG_IDEAS_DISCARD_TITLE = "VDLANG_IDEAS_DISCARD_TITLE",
    VDLANG_IDEAS_SAVE_IDEA_BUTTON_LABEL = "VDLANG_IDEAS_SAVE_IDEA_BUTTON_LABEL",
    VDLANG_IDEAS_CONVERT_IDEA = "VDLANG_IDEAS_CONVERT_IDEA",
    VDLANG_IDEAS_PROCESS_RESPONSIBLE = "VDLANG_IDEAS_PROCESS_RESPONSIBLE",
    VDLANG_IDEAS_CONVERT_TITLE = "VDLANG_IDEAS_CONVERT_TITLE",
    VDLANG_IDEAS_CONVERT = "VDLANG_IDEAS_CONVERT",
    VDLANG_IDEAS_DISCARDED = "VDLANG_IDEAS_DISCARDED",
    VDLANG_IDEAS_CONVERTED = "VDLANG_IDEAS_CONVERTED",
    VDLANG_IDEAS_OPEN = "VDLANG_IDEAS_OPEN",
    VDLANG_IDEAS_SHOW_MEASURE = "VDLANG_IDEAS_SHOW_MEASURE",
    VDLANG_CREATED_AT = "VDLANG_CREATED_AT",
    VDLANG_IDEAS_SEARCH_RESULT = "VDLANG_IDEAS_SEARCH_RESULT",
    VDLANG_IDEAS_CONVERT_IDEA_NOT_ALLOWED = "VDLANG_IDEAS_CONVERT_IDEA_NOT_ALLOWED",
    VDLANG_IDEAS_SAVE_AND_CONVERT = "VDLANG_IDEAS_SAVE_AND_CONVERT",
    VDLANG_IDEAS_SAVE_AND_CONVERT_INFO = "VDLANG_IDEAS_SAVE_AND_CONVERT_INFO",
    VDLANG_IDEAS_NO_PROCESS_RESPONSIBILITY_INFO = "VDLANG_IDEAS_NO_PROCESS_RESPONSIBILITY_INFO",
    VDLANG_IDEAS_IDEA_POTENTIAL_ESTIMATE_LABEL = "VDLANG_IDEAS_IDEA_POTENTIAL_ESTIMATE_LABEL",
    VDLANG_IDEAS_IDEA_TIME_ESTIMATE_LABEL = "VDLANG_IDEAS_IDEA_TIME_ESTIMATE_LABEL",
    VDLANG_IDEAS_MONTH_LABEL = "VDLANG_IDEAS_MONTH_LABEL",
    VDLANG_IDEAS_TOGGLE_SEARCH = "VDLANG_IDEAS_TOGGLE_SEARCH",
    VDLANG_IDEAS_SORT_BY = "VDLANG_IDEAS_SORT_BY",
    VDLANG_IDEAS_SORT_BY_CREATED_ASC = "VDLANG_IDEAS_SORT_BY_CREATED_ASC",
    VDLANG_IDEAS_SORT_BY_CREATED_DESC = "VDLANG_IDEAS_SORT_BY_CREATED_DESC",
    VDLANG_IDEAS_SORT_BY_TIME_ASC = "VDLANG_IDEAS_SORT_BY_TIME_ASC",
    VDLANG_IDEAS_SORT_BY_TIME_DESC = "VDLANG_IDEAS_SORT_BY_TIME_DESC",
    VDLANG_IDEAS_SORT_BY_POTENTIAL_ASC = "VDLANG_IDEAS_SORT_BY_POTENTIAL_ASC",
    VDLANG_IDEAS_SORT_BY_POTENTIAL_DESC = "VDLANG_IDEAS_SORT_BY_POTENTIAL_DESC",
    VDLANG_IDEAS_SORT_BY_EFFORT_ASC = "VDLANG_IDEAS_SORT_BY_EFFORT_ASC",
    VDLANG_IDEAS_SORT_BY_EFFORT_DESC = "VDLANG_IDEAS_SORT_BY_EFFORT_DESC",
    VDLANG_IDEAS_SORT_BY_LAST_MODIFICATION_ASC = "VDLANG_IDEAS_SORT_BY_LAST_MODIFICATION_ASC",
    VDLANG_IDEAS_SORT_BY_LAST_MODIFICATION_DESC = "VDLANG_IDEAS_SORT_BY_LAST_MODIFICATION_DESC",
    VDLANG_IDEAS_CONVERTED_FROM_INFO = "VDLANG_IDEAS_CONVERTED_FROM_INFO",
    VDLANG_IDEAS_DISCARDED_BY_INFO = "VDLANG_IDEAS_DISCARDED_BY_INFO",
    VDLANG_IDEAS_DISCARDED_BY_INFO_REASON = "VDLANG_IDEAS_DISCARDED_BY_INFO_REASON",
    VDLANG_IDEAS_DISCARDED_BY_INFO_STATEMENT = "VDLANG_IDEAS_DISCARDED_BY_INFO_STATEMENT",
    VDLANG_IDEAS_EFFORT_ESTIMATE_LABEL = "VDLANG_IDEAS_EFFORT_ESTIMATE_LABEL",
    VDLANG_IDEAS_DESCRIPTION_PREFIX = "VDLANG_IDEAS_DESCRIPTION_PREFIX",

    VDLANG_IDEAS_MATRIX_HEADER = "VDLANG_IDEAS_MATRIX_HEADER",
    VDLANG_IDEAS_MATRIX_TIME_ESTIMATE = "VDLANG_IDEAS_MATRIX_TIME_ESTIMATE",
    VDLANG_IDEAS_MATRIX_TIME_ESTIMATE_LOW = "VDLANG_IDEAS_MATRIX_TIME_ESTIMATE_LOW",
    VDLANG_IDEAS_MATRIX_TIME_ESTIMATE_HIGH = "VDLANG_IDEAS_MATRIX_TIME_ESTIMATE_HIGH",
    VDLANG_IDEAS_MATRIX_POTENTIAL_ESTIMATE = "VDLANG_IDEAS_MATRIX_POTENTIAL_ESTIMATE",
    VDLANG_IDEAS_MATRIX_POTENTIAL_ESTIMATE_LOW = "VDLANG_IDEAS_MATRIX_POTENTIAL_ESTIMATE_LOW",
    VDLANG_IDEAS_MATRIX_POTENTIAL_ESTIMATE_HIGH = "VDLANG_IDEAS_MATRIX_POTENTIAL_ESTIMATE_HIGH",
    VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE = "VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE",
    VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE_LOW = "VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE_LOW",
    VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE_HIGH = "VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE_HIGH",
    VDLANG_IDEAS_MATRIX_NO_SORT = "VDLANG_IDEAS_MATRIX_NO_SORT",
    VDLANG_IDEAS_MATRIX_SECTION_QUICKWIN = "VDLANG_IDEAS_MATRIX_SECTION_QUICKWIN",
    VDLANG_IDEAS_MATRIX_SECTION_FUTURE = "VDLANG_IDEAS_MATRIX_SECTION_FUTURE",
    VDLANG_IDEAS_MATRIX_SECTION_SKIP = "VDLANG_IDEAS_MATRIX_SECTION_SKIP",
    VDLANG_IDEAS_MATRIX_SECTION_WHEN_TIME = "VDLANG_IDEAS_MATRIX_SECTION_WHEN_TIME",
    VDLANG_IDEAS_MATRIX_X_AXIS = "VDLANG_IDEAS_MATRIX_X_AXIS",
    VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_HINT = "VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_HINT",
    VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_SUMMARY_HINT = "VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_SUMMARY_HINT",
    VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_SUMMARY = "VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_SUMMARY",

    VDLANG_IDEAS_TAB_GRID = "VDLANG_IDEAS_TAB_GRID",
    VDLANG_IDEAS_TAB_MATRIX = "VDLANG_IDEAS_TAB_MATRIX",
    VDLANG_IDEAS_TAB_TABLE = "VDLANG_IDEAS_TAB_TABLE",

    VDLANG_IDEAS_NUMBER_OF_IDEAS = "VDLANG_IDEAS_NUMBER_OF_IDEAS",
    VDLANG_IDEAS_SEARCH_POTENTIAL = "VDLANG_IDEAS_SEARCH_POTENTIAL",
    VDLANG_IDEAS_SEARCH_TIME = "VDLANG_IDEAS_SEARCH_TIME",
    VDLANG_IDEAS_SEARCH_EFFORT = "VDLANG_IDEAS_SEARCH_EFFORT",
    VDLANG_IDEAS_SEARCH_STATUS = "VDLANG_IDEAS_SEARCH_STATUS",
    VDLANG_IDEAS_TABLE_OPTIONS = "VDLANG_IDEAS_TABLE_OPTIONS",

    VDLANG_USER_PROFILE_PASSWORD_SSO_INFO = "VDLANG_USER_PROFILE_PASSWORD_SSO_INFO",

    VDLANG_INVITE_USER_INVALID_EMAIL = "VDLANG_INVITE_USER_INVALID_EMAIL",
    VDLANG_INVITE_USER_INVALID_DOMAIN = "VDLANG_INVITE_USER_INVALID_DOMAIN",
    VDLANG_INVITE_USER_ALREADY_EXISTS = "VDLANG_INVITE_USER_ALREADY_EXISTS",
    VLDANG_INVITE_NO_USER_RESULTS = "VLDANG_INVITE_NO_USER_RESULTS",

    VDLANG_OPENAI_MEASURE_HINT = "VDLANG_OPENAI_MEASURE_HINT",
    VDLANG_MEASURE_SUGGESTIONS_ACTION_AREA = "VDLANG_MEASURE_SUGGESTIONS_ACTION_AREA",

    VDLANG_DATA_IMPORT_TITLE = "VDLANG_DATA_IMPORT_TITLE",
    VDLANG_DATA_IMPORT_DIALOG_TITLE = "VDLANG_DATA_IMPORT_DIALOG_TITLE",
    VDLANG_DATA_IMPORT_DIALOG_PROCESS_MAIN = "VDLANG_DATA_IMPORT_DIALOG_PROCESS_MAIN",
    VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION = "VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION",
    VDLANG_DATA_IMPORT_DIALOG_OPPS = "VDLANG_DATA_IMPORT_DIALOG_OPPS",

    VDLANG_DATA_IMPORT_DIALOG_PROCESS_MAIN_DESC = "VDLANG_DATA_IMPORT_DIALOG_PROCESS_MAIN_DESC",
    VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION_DESC = "VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION_DESC",
    VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION_EFFECT_DATE_RANGE = "VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION_EFFECT_DATE_RANGE",
    VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION_NO_EFFECTS = "VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION_NO_EFFECTS",
    VDLANG_DATA_IMPORT_DIALOG_OPPS_DESC = "VDLANG_DATA_IMPORT_DIALOG_OPPS_DESC",
    VDLANG_DATA_IMPORT_ACTION_GENERATE = "VDLANG_DATA_IMPORT_ACTION_GENERATE",
    VDLANG_DATA_IMPORT_PROCESS_TYPE = "VDLANG_DATA_IMPORT_PROCESS_TYPE",
    VDLANG_DATA_IMPORT_UPLOAD_DOCUMENT = "VDLANG_DATA_IMPORT_UPLOAD_DOCUMENT",
    VDLANG_DATA_IMPORT_TABLE_NO_DATA = "VDLANG_DATA_IMPORT_TABLE_NO_DATA",
    VDLANG_DATA_IMPORT_TABLE_NUMBER_ITEMS = "VDLANG_DATA_IMPORT_TABLE_NUMBER_ITEMS",
    VDLANG_DATA_IMPORT_TABLE_NUMBER_UPLOADED_ITEMS = "VDLANG_DATA_IMPORT_TABLE_NUMBER_UPLOADED_ITEMS",
    VDLANG_DATA_IMPORT_TABLE_NUMBER_ERROR_ITEMS = "VDLANG_DATA_IMPORT_TABLE_NUMBER_ERROR_ITEMS",
    VDLANG_DATA_IMPORT = "VDLANG_DATA_IMPORT",
    VDLANG_DATA_IMPORT_BUTTON_CANCEL = "VDLANG_DATA_IMPORT_BUTTON_CANCEL",
    VDLANG_DATA_IMPORT_BUTTON_RESUME = "VDLANG_DATA_IMPORT_BUTTON_RESUME",
    VDLANG_DATA_IMPORT_TABLE_CLEAR_DATA = "VDLANG_DATA_IMPORT_TABLE_CLEAR_DATA",
    VDLANG_DATA_IMPORT_TABLE_STATUS_ADD = "VDLANG_DATA_IMPORT_TABLE_STATUS_ADD",
    VDLANG_DATA_IMPORT_TABLE_STATUS_UPDATE = "VDLANG_DATA_IMPORT_TABLE_STATUS_UPDATE",
    VDLANG_DATA_IMPORT_TABLE_STATUS_PENDING = "VDLANG_DATA_IMPORT_TABLE_STATUS_PENDING",
    VDLANG_DATA_IMPORT_TABLE_STATUS_CANCELLED = "VDLANG_DATA_IMPORT_TABLE_STATUS_CANCELLED",
    VDLANG_DATA_IMPORT_NAVIGATION_PROMPT = "VDLANG_DATA_IMPORT_NAVIGATION_PROMPT",
    VDLANG_DATA_IMPORT_INFO_DELETED = "VDLANG_DATA_IMPORT_INFO_DELETED",
    VDLANG_DATA_IMPORT_INFO_CANCELLED = "VDLANG_DATA_IMPORT_INFO_CANCELLED",
    VDLANG_DATA_IMPORT_PARSE_ERROR = "VDLANG_DATA_IMPORT_PARSE_ERROR",
    VDLANG_DATA_IMPORT_CONFIG_SHEET_NOT_FOUND = "VDLANG_DATA_IMPORT_CONFIG_SHEET_NOT_FOUND",
    VDLANG_DATA_IMPORT_MAIN_SHEET_NOT_FOUND = "VDLANG_DATA_IMPORT_MAIN_SHEET_NOT_FOUND",

    VDLANG_REQUEST_DIALOG_TEXT_TOO_LONG = "VDLANG_REQUEST_DIALOG_TEXT_TOO_LONG",
    VDLANG_REQUEST_DIALOG_EMPTY_TEXT = "VDLANG_REQUEST_DIALOG_EMPTY_TEXT",
    VDLANG_COMMENT = "VDLANG_COMMENT",
    VDLANG_COMMENTS = "VDLANG_COMMENTS",
    VDLANG_COMMENT_EDITED = "VDLANG_COMMENT_EDITED",
    VDLANG_COMMENT_EDITED_HINT = "VDLANG_COMMENT_EDITED_HINT",
    VDLANG_COMMENT_ACTION = "VDLANG_COMMENT_ACTION",

    VDLANG_PAGINATION_PAGE = "VDLANG_PAGINATION_PAGE",
    VDLANG_PAGINATION_FIRST_PAGE = "VDLANG_PAGINATION_FIRST_PAGE",
    VDLANG_PAGINATION_PREVIOUS_PAGE = "VDLANG_PAGINATION_PREVIOUS_PAGE",
    VDLANG_PAGINATION_NEXT_PAGE = "VDLANG_PAGINATION_NEXT_PAGE",
    VDLANG_PAGINATION_LAST_PAGE = "VDLANG_PAGINATION_LAST_PAGE",

    VDLANG_COLUMN_CONFIGURATION = "VDLANG_COLUMN_CONFIGURATION",
    VDLANG_FILENAME = "VDLANG_FILENAME",

    VDLANG_REACTION_EMOJI_LIKE = "VDLANG_REACTION_EMOJI_LIKE",
    VDLANG_REACTION_EMOJI_LIGHTBULB = "VDLANG_REACTION_EMOJI_LIGHTBULB",
    VDLANG_REACTION_EMOJI_STRONG = "VDLANG_REACTION_EMOJI_STRONG",
    VDLANG_REACTION_EMOJI_PARTY = "VDLANG_REACTION_EMOJI_PARTY",
    VDLANG_REACTION_EMOJI_THINKING = "VDLANG_REACTION_EMOJI_THINKING",
    VDLANG_REACTION_REACT = "VDLANG_REACTION_REACT",
    VDLANG_REACTION_REACTION = "VDLANG_REACTION_REACTION",
    VDLANG_REACTION_REACTION_UNIFORM = "VDLANG_REACTION_REACTION_UNIFORM",
    VDLANG_REACTION_REACTIONCHIP = "VDLANG_REACTION_REACTIONCHIP",

    VDLANG_SUPERADMIN_VALUE_TOO_LARGE = "VDLANG_SUPERADMIN_VALUE_TOO_LARGE",
    VDLANG_SUPERADMIN_TRANSLATION_KEY_ALREADY_IN_USE = "VDLANG_SUPERADMIN_TRANSLATION_KEY_ALREADY_IN_USE",
    VDLANG_SUPERADMIN_INTERNAL_NAME_ALREADY_IN_USE = "VDLANG_SUPERADMIN_INTERNAL_NAME_ALREADY_IN_USE",
    VDLANG_SUPERADMIN_INTERNAL_NAME_HINT = "VDLANG_SUPERADMIN_INTERNAL_NAME_HINT",

    VDLANG_VALUE_TOO_LONG_SHORT_TEXT = "VDLANG_VALUE_TOO_LONG_SHORT_TEXT",

    VDLANG_ACL_MANAGE_ACCESS = "VDLANG_ACL_MANAGE_ACCESS",
    VDLANG_ACL_VIEW_EFFECTIVE_PERMISSIONS = "VDLANG_ACL_VIEW_EFFECTIVE_PERMISSIONS",
    VDLANG_ACL_SELECT_USER_GROUP_PLACEHOLDER = "VDLANG_ACL_SELECT_USER_GROUP_PLACEHOLDER",
    VDLANG_ACL_EFFECTIVE_PERMISSIONS_SELECTION_REQUIRED = "VDLANG_ACL_EFFECTIVE_PERMISSIONS_SELECTION_REQUIRED",
    VDLANG_ACL_NAMESPACES = "VDLANG_ACL_NAMESPACES",
    VDLANG_ACL_PERMISSIONS = "VDLANG_ACL_PERMISSIONS",
    VDLANG_ACL_NAMESPACE = "VDLANG_ACL_NAMESPACE",
    VDLANG_ACL_PERMISSION = "VDLANG_ACL_PERMISSION",
    VDLANG_ACL_USER_GROUP = "VDLANG_ACL_USER_GROUP",
    VDLANG_ACL_PERMISSION_ORIGIN = "VDLANG_ACL_PERMISSION_ORIGIN",
    VDLANG_ACL_DIRECT = "VDLANG_ACL_DIRECT",
    VDLANG_ACL_INDIRECT = "VDLANG_ACL_INDIRECT",
    VDLANG_ACL_SIMPLE_ENTITY_ID = "VDLANG_ACL_SIMPLE_ENTITY_ID",
    VDLANG_ACL_RULE = "VDLANG_ACL_RULE",
    VDLANG_ACL_ADD_RULE = "VDLANG_ACL_ADD_RULE",
    VDLANG_ACL_TITLE_VALUESTREAM = "VDLANG_ACL_TITLE_VALUESTREAM",
    VDLANG_ACL_TITLE_DASHBOARD = "VDLANG_ACL_TITLE_DASHBOARD",
    VDLANG_ACL_TITLE_PROCESS = "VDLANG_ACL_TITLE_PROCESS",
    VDLANG_ACL_TITLE_PROCESS_CREATION = "VDLANG_ACL_TITLE_PROCESS_CREATION",
    VDLANG_ACL_TITLE_PROCESS_STATUS_VISBILITY = "VDLANG_ACL_TITLE_PROCESS_STATUS_VISBILITY",
    VDLANG_ACL_TITLE_SUPPLIER_CREATION = "VDLANG_ACL_TITLE_SUPPLIER_CREATION",
    VDLANG_ACL_TITLE_PERSON_FILTER = "VDLANG_ACL_TITLE_PERSON_FILTER",
    VDLANG_ACL_HINT_PERSON_FILTER = "VDLANG_ACL_HINT_PERSON_FILTER",
    VDLANG_ACL_TITLE_METHOD_FILE_UPLOAD = "VDLANG_ACL_TITLE_METHOD_FILE_UPLOAD",
    VDLANG_ACL_HINT_METHOD_FILE_UPLOAD = "VDLANG_ACL_HINT_METHOD_FILE_UPLOAD",
    VDLANG_ACL_TITLE_CAN_COMPLETE_EFFECTIVENESS = "VDLANG_ACL_TITLE_CAN_COMPLETE_EFFECTIVENESS",
    VDLANG_ACL_HINT_CAN_COMPLETE_EFFECTIVENESS = "VDLANG_ACL_HINT_CAN_COMPLETE_EFFECTIVENESS",
    VDLANG_ACL_TITLE_ACCESS_PERMISSION = "VDLANG_ACL_TITLE_ACCESS_PERMISSION",
    VDLANG_ACL_HINT_ACCESS_PERMISSION = "VDLANG_ACL_HINT_ACCESS_PERMISSION",
    VDLANG_ACL_TITLE_MEASURE_PERMISSION = "VDLANG_ACL_TITLE_MEASURE_PERMISSION",
    VDLANG_ACL_TITLE_API_PERMISSION = "VDLANG_ACL_TITLE_API_PERMISSION",
    VDLANG_ACL_DISABLED_OTHER = "VDLANG_ACL_DISABLED_OTHER",
    VDLANG_ACL_SHOW_ALL_PERMISSIONS = "VDLANG_ACL_SHOW_ALL_PERMISSIONS",
    VDLANG_ACL_PERMISSION_TABLE_PROCESS_NOT_FOUND = "VDLANG_ACL_PERMISSION_TABLE_PROCESS_NOT_FOUND",
    VDLANG_ACL_PERMISSION_TABLE_DASHBOARD_NOT_FOUND = "VDLANG_ACL_PERMISSION_TABLE_DASHBOARD_NOT_FOUND",
    VDLANG_ACL_PERMISSION_TABLE_VALUESTREAM_NOT_FOUND = "VDLANG_ACL_PERMISSION_TABLE_VALUESTREAM_NOT_FOUND",
    VDLANG_NUMBER_USERS_IN_GROUP = "VDLANG_NUMBER_USERS_IN_GROUP",
    VDLANG_PERMISSION_DIALOG_NO_USERS_FOUND = "VDLANG_PERMISSION_DIALOG_NO_USERS_FOUND",
    VDLANG_PERMISSION_DIALOG_NO_GROUPS_FOUND = "VDLANG_PERMISSION_DIALOG_NO_GROUPS_FOUND",
    VDLANG_PERMISSION_DIALOG_NO_RULES_FOUND = "VDLANG_PERMISSION_DIALOG_NO_RULES_FOUND",
    VDLANG_PERMISSION_DIALOG_ONLY_PERSON_CAN_CHANGE = "VDLANG_PERMISSION_DIALOG_ONLY_PERSON_CAN_CHANGE",
    VDLANG_ACL_TABLE_STATUS = "VDLANG_ACL_TABLE_STATUS",
    VDLANG_SUPPLIERS_NAME_ALREADY_EXISTS = "VDLANG_SUPPLIERS_NAME_ALREADY_EXISTS",
    VDLANG_SUPPLIERS_NAME_TOO_LONG = "VDLANG_SUPPLIERS_NAME_TOO_LONG",
    VDLANG_SUPPLIERS_NAME_TOO_SHORT = "VDLANG_SUPPLIERS_NAME_TOO_SHORT",

    VDLANG_ACL_ACCESS_PERMISSION_API = "VDLANG_ACL_ACCESS_PERMISSION_API",
    VDLANG_ACL_ACCESS_PERMISSION_API_HINT = "VDLANG_ACL_ACCESS_PERMISSION_API_HINT",
    VDLANG_ACL_ACCESS_PERMISSION_IDEA = "VDLANG_ACL_ACCESS_PERMISSION_IDEA",
    VDLANG_ACL_ACCESS_PERMISSION_IDEA_HINT = "VDLANG_ACL_ACCESS_PERMISSION_IDEA_HINT",
    VDLANG_ACL_ACCESS_PERMISSION_ACTIVITY = "VDLANG_ACL_ACCESS_PERMISSION_ACTIVITY",
    VDLANG_ACL_ACCESS_PERMISSION_ACTIVITY_HINT = "VDLANG_ACL_ACCESS_PERMISSION_ACTIVITY_HINT",
    VDLANG_ACL_ACCESS_PERMISSION_DASHBOARD = "VDLANG_ACL_ACCESS_PERMISSION_DASHBOARD",
    VDLANG_ACL_ACCESS_PERMISSION_DASHBOARD_HINT = "VDLANG_ACL_ACCESS_PERMISSION_DASHBOARD_HINT",
    VDLANG_ACL_ACCESS_PERMISSION_FEED = "VDLANG_ACL_ACCESS_PERMISSION_FEED",
    VDLANG_ACL_ACCESS_PERMISSION_FEED_HINT = "VDLANG_ACL_ACCESS_PERMISSION_FEED_HINT",
    VDLANG_ACL_ACCESS_PERMISSION_METHOD = "VDLANG_ACL_ACCESS_PERMISSION_METHOD",
    VDLANG_ACL_ACCESS_PERMISSION_METHOD_HINT = "VDLANG_ACL_ACCESS_PERMISSION_METHOD_HINT",
    VDLANG_ACL_ACCESS_PERMISSION_PROCESS = "VDLANG_ACL_ACCESS_PERMISSION_PROCESS",
    VDLANG_ACL_ACCESS_PERMISSION_PROCESS_HINT = "VDLANG_ACL_ACCESS_PERMISSION_PROCESS_HINT",
    VDLANG_ACL_ACCESS_PERMISSION_SUPPLIER = "VDLANG_ACL_ACCESS_PERMISSION_SUPPLIER",
    VDLANG_ACL_ACCESS_PERMISSION_SUPPLIER_HINT = "VDLANG_ACL_ACCESS_PERMISSION_SUPPLIER_HINT",
    VDLANG_ACL_ACCESS_PERMISSION_ADMIN = "VDLANG_ACL_ACCESS_PERMISSION_ADMIN",
    VDLANG_ACL_ACCESS_PERMISSION_ADMIN_HINT = "VDLANG_ACL_ACCESS_PERMISSION_ADMIN_HINT",

    VDLANG_ACL_MEASURE_PERMISSION_CSV_DOWNLOAD = "VDLANG_ACL_MEASURE_PERMISSION_CSV_DOWNLOAD",
    VDLANG_ACL_MEASURE_PERMISSION_CSV_DOWNLOAD_HINT = "VDLANG_ACL_MEASURE_PERMISSION_CSV_DOWNLOAD_HINT",
    VDLANG_ACL_MEASURE_PERMISSION_EXCEL_IMPORT = "VDLANG_ACL_MEASURE_PERMISSION_EXCEL_IMPORT",
    VDLANG_ACL_MEASURE_PERMISSION_EXCEL_IMPORT_HINT = "VDLANG_ACL_MEASURE_PERMISSION_EXCEL_IMPORT_HINT",
    VDLANG_ACL_MEASURE_PERMISSION_ALWAYS_DECIDE = "VDLANG_ACL_MEASURE_PERMISSION_ALWAYS_DECIDE",
    VDLANG_ACL_MEASURE_PERMISSION_ALWAYS_DECIDE_HINT = "VDLANG_ACL_MEASURE_PERMISSION_ALWAYS_DECIDE_HINT",
    VDLANG_ACL_MEASURE_PERMISSION_CAN_DECIDE_PROCESS_ON_OWN = "VDLANG_ACL_MEASURE_PERMISSION_CAN_DECIDE_PROCESS_ON_OWN",
    VDLANG_ACL_MEASURE_PERMISSION_CAN_DECIDE_PROCESS_ON_OWN_HINT = "VDLANG_ACL_MEASURE_PERMISSION_CAN_DECIDE_PROCESS_ON_OWN_HINT",
    VDLANG_ACL_MEASURE_PERMISSION_CAN_BE_RESPONSIBLE_FOR_PROCESSES = "VDLANG_ACL_MEASURE_PERMISSION_CAN_BE_RESPONSIBLE_FOR_PROCESSES",
    VDLANG_ACL_MEASURE_PERMISSION_CAN_BE_RESPONSIBLE_FOR_PROCESSES_HINT = "VDLANG_ACL_MEASURE_PERMISSION_CAN_BE_RESPONSIBLE_FOR_PROCESSES_HINT",

    VDLANG_DASHBOARDS_WATERFALL_CHART_WIDGET_TITLE = "VDLANG_DASHBOARDS_WATERFALL_CHART_WIDGET_TITLE",
    VDLANG_DASHBOARDS_WATERFALL_CHART_WIDGET_DESCRIPTION = "VDLANG_DASHBOARDS_WATERFALL_CHART_WIDGET_DESCRIPTION",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_GENERAL = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_GENERAL",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_DESCRIPTION = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_DESCRIPTION",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_BLOCKS = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_BLOCKS",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_SCOPE = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_SCOPE",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_FILTER = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_FILTER",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_NAME = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_NAME",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_DESCRIPTION = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_DESCRIPTION",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_AXIS_MIN_VALUE = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_AXIS_MIN_VALUE",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_AXIS_MAX_VALUE = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_AXIS_MAX_VALUE",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_SHOW_SUMS = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_SHOW_SUMS",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_INITIAL_NAME = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_INITIAL_NAME",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_RESULT_PLACEHOLDER = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_RESULT_PLACEHOLDER",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_BLOCK_NAME = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_BLOCK_NAME",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_BLOCK_NAME_DEFAULT = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_BLOCK_NAME_DEFAULT",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_ATTRIBUTE = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_ATTRIBUTE",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_FIELD_VALUES = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_FIELD_VALUES",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_BLOCKS = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_BLOCKS",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_CREATE_BLOCK = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_CREATE_BLOCK",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_DELETE_BLOCK = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_DELETE_BLOCK",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_SIGN = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_SIGN",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_SIGN_PLUS = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_SIGN_PLUS",
    VDLANG_DASHBOARDS_WATERFALL_CONFIG_SIGN_MINUS = "VDLANG_DASHBOARDS_WATERFALL_CONFIG_SIGN_MINUS",

    VDLANG_ENTITY_ACTION_COPY = "VDLANG_ENTITY_ACTION_COPY",
    VDLANG_ENTITY_ACTION_COPY_OPTION = "VDLANG_ENTITY_ACTION_COPY_OPTION",
    VDLANG_ENTITY_ACTION_COPY_OPTIONS_LEGEND = "VDLANG_ENTITY_ACTION_COPY_OPTIONS_LEGEND",
    VDLANG_ENTITY_ACTION_COPY_TITLE_LEGEND = "VDLANG_ENTITY_ACTION_COPY_TITLE_LEGEND",
    VDLANG_ENTITY_ACTION_COPY_HINT = "VDLANG_ENTITY_ACTION_COPY_HINT",
    VDLANG_ENTITY_ACTION_COPY_TITLE_PLACEHOLDER = "VDLANG_ENTITY_ACTION_COPY_TITLE_PLACEHOLDER",

    VDLANG_ATTRIBUTE_CATEGORY_ERROR_FIRST_ITEM_NOT_A_CATEGORY = "VDLANG_ATTRIBUTE_CATEGORY_ERROR_FIRST_ITEM_NOT_A_CATEGORY",

    VDLANG_GATE_TASK_COLUMNS = "VDLANG_GATE_TASK_COLUMNS",
    VDLANG_GATE_TASK_COLUMN_NOT_FOUND = "VDLANG_GATE_TASK_COLUMNS_NO_ACCESS",

    VDLANG_UNKNOWN_GROUP = "VDLANG_UNKNOWN_GROUP",

    VDLANG_EFFECTS_REWORK_HINT_DESCRIPTION = "VDLANG_EFFECTS_REWORK_HINT_DESCRIPTION",
    VDLANG_EFFECTS_REWORK_HINT_LINK_TEXT = "VDLANG_EFFECTS_REWORK_HINT_LINK_TEXT",
    VDLANG_EFFECTS_REWORK_HINT_LINK_URL = "VDLANG_EFFECTS_REWORK_HINT_LINK_URL",

    VDLANG_NOTIFICATION_BANNER_CONFIRMATION = "VDLANG_NOTIFICATION_BANNER_CONFIRMATION",
}
Object.freeze(Keys);
export { Keys as translationKeys };

export type NewKeys = keyof typeof translations;

const SubtaskStatusTranslations: Translations = {
    [SubTaskStatus.STATUS_OPEN]: ["Open", "Offen"],
    [SubTaskStatus.STATUS_COMPLETED]: ["Completed", "Abgeschlossen"],
    [SubTaskStatus.STATUS_REMOVED]: ["Removed", "Entfernt"],
};

const SubtaskPriorityTranslations: Translations = {
    [SubTaskPriority.PRIO_LOW]: ["Low", "Niedrig"],
    [SubTaskPriority.PRIO_MEDIUM]: ["Medium", "Mittel"],
    [SubTaskPriority.PRIO_HIGH]: ["High", "Hoch"],
};

const PasswordCriteriaTranslations: Translations = {
    [PasswordValidityCriteria.NUMBER]: ["Number", "Ziffer"],
    [PasswordValidityCriteria.LOWER]: ["Lowercase", "Kleinbuchstabe"],
    [PasswordValidityCriteria.UPPER]: ["Uppercase", "Großbuchstabe"],
    [PasswordValidityCriteria.SPECIAL]: ["Symbol (!#@$%^&*)(+=._-).", "Sonderzeichen (!#@$%^&*)(+=._-)"],
    [PasswordValidityCriteria.LENGTH]: ["Minimum 12 characters", "Mindestens 12 Zeichen"],
};

const FeedEntrySubjectTranslations: Translations = {
    [FeedEntryTranslationKeys.MeasureCompleted]: [
        "Has completed the <strong>{{processName}}</strong>. 🎉",
        "Hat die <strong>{{processName}}</strong> abgeschlossen. 🎉",
    ],
    [FeedEntryTranslationKeys.MeasureCreated]: [
        "Has created a new <strong>{{processName}}</strong>.",
        "Hat eine neue <strong>{{processName}}</strong> angelegt.",
    ],
    [FeedEntryTranslationKeys.MeasureCreatedFromOpportunity]: [
        "Has converted an <strong>Opportunity</strong> into a <strong>{{processName}}</strong>.",
        "Hat eine <strong>Opportunität</strong> in eine <strong>{{processName}}</strong> überführt.",
    ],
    [FeedEntryTranslationKeys.MeasureLevelCompleted]: [
        "Has completed the level <strong>{{levelName}}</strong>.",
        "Hat den Härtegrad <strong>{{levelName}}</strong> abgeschlossen.",
    ],
    [FeedEntryTranslationKeys.MeasureAssignmentChange]: [
        "Has passed the responsibility for the <strong>{{processName}}</strong> to <strong>{{username}}</strong>.",
        "Hat die Verantwortung für die <strong>{{processName}}</strong> an <strong>{{username}}</strong> übertragen.",
    ],
    [FeedEntryTranslationKeys.NewUserAdded]: [
        "<strong>{{username}}</strong> has been added.",
        "<strong>{{username}}</strong> wurde hinzugefügt.",
    ],
    [FeedEntryTranslationKeys.MeasureDiscarded]: [
        "Has discarded the <strong>{{processName}}</strong>.",
        "Hat die <strong>{{processName}}</strong> verworfen.",
    ],
    [FeedEntryTranslationKeys.IdeaCreated]: [
        "Has created an <strong>Opportunity</strong>.",
        "Hat eine <strong>Opportunität</strong> festgehalten.",
    ],
    [FeedEntryTranslationKeys.IdeaDiscarded]: [
        "Has discarded an <strong>Opportunity</strong>.",
        "Hat eine <strong>Opportunität</strong> verworfen.",
    ],
};

export const translations = {
    [Keys.VDLANG_ALL]: ["All", "Alle"],
    [Keys.VDLANG_CREATED_AT]: ["Creation date", "Erstellungsdatum"],
    [Keys.VDLANG_CALENDAR_WEEK]: ["Calendar week {{week}}", "Kalenderwoche {{week}}"],
    [Keys.VDLANG_ID]: ["ID", "ID"],
    // Feed
    FeedEntrySubject: FeedEntrySubjectTranslations,
    [Keys.VDLANG_FEED]: ["News", "Neuigkeiten"],
    [Keys.VDLANG_FEED_FOR]: ["Welcome to Valuedesk.", "Willkommen bei Valuedesk."],
    [`${Keys.VDLANG_FEED_COMMENTS}_one`]: ["{{count}} comment", "{{count}} Kommentar"],
    [`${Keys.VDLANG_FEED_COMMENTS}_other`]: ["{{count}} comments", "{{count}} Kommentare"],
    [Keys.VDLANG_FEED_RECENT_MEASURES]: ["My last seen processes", "Von mir zuletzt angesehene Prozesse"],
    [Keys.VDLANG_FEED_RECENT_MEASURES_EMPTY]: ["You have not viewed any processes yet", "Sie haben noch keine Prozesse betrachtet"],
    [Keys.VDLANG_FEED_EMBEDDED_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_FEED_EMBEDDED_OBJECT_NO_ACCESS]: ["No access", "Kein Zugriff"],
    [Keys.VDLANG_FEED_EMBEDDED_OBJECT_NO_ACCESS_DESCRIPTION]: [
        "You do not have access to the content embedded in this entry. This could be because you don't have the required permission or the content has been archived.",
        "Sie haben keinen Zugriff auf den Inhalt, der in diesem Eintrag eingebettet ist. Dies könnte daran liegen, dass Ihnen die notwendige Berechtigung fehlt oder der Inhalt archiviert wurde.",
    ],
    [Keys.VDLANG_FEED_DETAIL_BACK_BUTTON]: ["Back to Feed", "Zurück zum Feed"],
    [Keys.VDLANG_GOTO_MEASURE]: ["Go to {{processName}}", "Zur {{processName}}"],
    [Keys.VDLANG_GOTO_IDEA]: ["Go to opportunity", "Zur Opportunität"],
    [Keys.VDLANG_GREETING_MORNING]: ["Good morning", "Guten Morgen"],
    [Keys.VDLANG_GREETING_EVENING]: ["Good evening", "Guten Abend"],
    [Keys.VDLANG_GREETING_AFTERNOON]: ["Good afternoon", "Guten Tag"],
    [Keys.VDLANG_MOMENT_AGO]: ["a moment ago", "vor wenigen Minuten"],
    [Keys.VDLANG_TODAY]: ["today", "heute"],
    [Keys.VDLANG_HOURS_AGO]: ["{{hours}} hours ago", "vor {{hours}} Stunden"],
    [Keys.VDLANG_DAYS_AGO]: ["{{days}} days ago", " vor {{days}} Tagen"],
    [Keys.VDLANG_LAST_CREATED_PROCESS]: ["Last created process", "Zuletzt erstellter Prozess"],
    [Keys.VDLANG_LAST_UPDATED_PROCESS]: ["Last updated process", "Zuletzt aktualisierter Prozess"],
    [Keys.VDLANG_CREATE_PROCESS]: ["Create process", "Prozess erstellen"],
    [Keys.VDLANG_MEASURE_MODIFIED]: [
        "<strong>{{name}}</strong> has edited the <strong>{{processName}}</strong> in level <strong>{{gate}}</strong>.",
        "<strong>{{name}}</strong> hat die <strong>{{processName}}</strong> im Härtegrad <strong>{{gate}}</strong> bearbeitet.",
    ],
    [Keys.VDLANG_MEASURE_MODIFIED_COMPLETED]: [
        "<strong>{{name}}</strong> has edited the completed <strong>{{processName}}</strong>.",
        "<strong>{{name}}</strong> hat die abgeschlossene <strong>{{processName}}</strong> bearbeitet.",
    ],
    [Keys.VDLANG_MEASURE_CREATED]: [
        "<strong>{{name}}</strong> has created the <strong>level {{gate}}</strong>.",
        "<strong>{{name}}</strong> hat den <strong>Härtegrad {{gate}}</strong> erstellt.",
    ],
    [Keys.VDLANG_MEASURE_COMPLETED]: [
        "<strong>{{name}}</strong> has completed the <strong>{{processName}}</strong>.",
        "<strong>{{name}}</strong> hat die <strong>{{processName}}</strong> abgeschlossen.",
    ],
    [Keys.VDLANG_MEASURE_MODIFIED_BY_SYSTEM]: [
        "<strong>Valuedesk System</strong> made changes to the {{processName}}.",
        "<strong>Valuedesk System</strong> hat Änderungen an der {{processName}} vorgenommen.",
    ],

    [Keys.VDLANG_MEASURE_DISCARDED]: [
        "<strong>{{name}}</strong> has discarded the <strong>{{processName}}</strong>.",
        "<strong>{{name}}</strong> hat die <strong>{{processName}}</strong> verworfen.",
    ],
    [Keys.VDLANG_COMMENTED_ON]: [
        "<strong>{{name}}</strong> has commented this {{processName}}.",
        "<strong>{{name}}</strong> hat die {{processName}} kommentiert.",
    ],
    [Keys.VDLANG_NO_PROCESSES]: ["No processes found.", "Keine Prozesse gefunden."],
    [Keys.VDLANG_NO_OPPORTUNITIES]: ["No opportunities found.", "Keine Opportunitäten gefunden."],
    [Keys.VDLANG_NO_COMPANIES]: ["No companies found.", "Keine Gesellschaften gefunden."],
    [Keys.VDLANG_UNKNOWN_PROCESS_NAME]: ["Unknown process name", "Unbekannter Prozessname"],
    [Keys.VDLANG_SHOW_PRIVATE_PROCESSES]: ["Include private processes", "Private Prozesse einbeziehen"],
    [Keys.VDLANG_SHOW_PRIVATE_PROCESSES_HINT]: [
        "Your permission authorizes you to display processes that would otherwise not be visible to you. This gives you all the results in the organization.",
        "Ihre Berechtigung ermöglicht es Ihnen auch die Prozesse anzuzeigen, die sonst für Sie nicht sichtbar sind. Dadurch erhalten Sie alle Ergebnisse im Unternehmen.",
    ],
    [Keys.VDLANG_SHOW_ZERO_GROUPS]: ["Show zero-sums", "Nullsummen einblenden"],
    [Keys.VDLANG_SUBTREE_SELECTED_ICON_TOOLTIP]: [
        "The group and all of its subgroups are selected",
        "Die Gruppe und alle ihre Untergruppen sind ausgewählt",
    ],
    [Keys.VDLANG_SUBTREE_CASCADING_SELECT_LABEL]: [
        "Automatically (de)select subgroups",
        "Automatisch die Untergruppen mit aus- oder abwählen",
    ],
    [Keys.VDLANG_CASCADER_NOT_SELECTED_TOOLTIP]: [
        "Neither this group nor one of its subgroups are selected.",
        "Weder diese Gruppe noch eine ihrer Untergruppen ist ausgewählt.",
    ],
    [Keys.VDLANG_CASCADER_NOT_SELECTED_PARTIALLY_CHILDREN_TOOLTIP]: [
        "This group is not selected, but at least one of its subgroups are selected.",
        "Diese Gruppe ist nicht ausgewählt, aber mindestens eine Untergruppe ist ausgewählt.",
    ],
    [Keys.VDLANG_CASCADER_SELECTED_PARTIALLY_CHILDREN_TOOLTIP]: [
        "This group is selected, but not all of its subgroups are selected.",
        "Diese Gruppe ist ausgewählt, aber nicht alle ihre Untergruppen sind ausgewählt.",
    ],
    [Keys.VDLANG_CASCADER_SELECTED_TOOLTIP]: [
        "This group and all of its subgroups are selected.",
        "Diese Gruppe und alle ihre Untergruppen sind ausgewählt.",
    ],
    [Keys.VDLANG_EXPORT_CSV]: ["CSV Export", "CSV Export"],
    [Keys.VDLANG_EXPORT_CSV_HINT]: [
        "Create a CSV file of all processes from the grid, considering the column configuration and the filter.",
        "Erstellen Sie eine CSV-Datei aller Prozesse aus der Tabelle unter Berücksichtigung der Spaltenkonfiguration und des Filters.",
    ],
    [Keys.VDLANG_EXPORT_CSV_TOGGLE_CSPLIT]: [
        "Display values for each Savings/Extra Costs.",
        "Werte für einzelne Einsparungen/Mehrkosten gesondert anzeigen.",
    ],
    [Keys.VDLANG_EXPORT_CSV_TOGGLE_UNWIND_BLANK]: [
        "Use blank cells instead of repeating data.",
        "Leere Zellen anstatt Daten zu wiederholen.",
    ],
    [Keys.VDLANG_EXPORT_CSV_TOGGLE_PRINT_FORMATTING]: [
        "Optimise for printing. Not appropriate for further calculations.",
        "Druckoptimierte Formatierung. Nicht zur weiteren Berechnung geeignet.",
    ],
    [Keys.VDLANG_EXPORT_CSV_TOGGLE_LINK_COLUMN]: [
        "Include a column with a direct link to the process.",
        "Spalte mit direktem Link zum Prozess hinzufügen.",
    ],
    [Keys.VDLANG_EXPORT_CSV_LINK_COLUMN_TITLE]: ["Link", "Link"],
    [Keys.VDLANG_EXPORT_CURRENCY_TOGGLE]: [
        "Display values for Savings/Extra Costs in their currency.",
        "Werte für Einsparungen/Mehrkosten in ihrer Währung anzeigen.",
    ],
    [Keys.VDLANG_EXPORT_SPLIT_CURRENCY_VALUES_TOGGLE]: [
        "Display monthly values for currency fields.",
        "Monatswerte für Währungsfelder anzeigen.",
    ],
    [Keys.VDLANG_EXPORT_SPLIT_CURRENCY_VALUES_TOGGLE_HINT]: [
        "Please select a fiscal year as period under consideration.",
        "Bitte wählen Sie ein Geschäftsjahr als Betrachtungszeitraum aus.",
    ],
    [Keys.VDLANG_EXPORT_CSV_BUTTON]: ["Download CSV", "CSV herunterladen"],
    [Keys.VDLANG_EXPORT_CSV_LANGUAGE]: ["Format of currency fields", "Format für Währungsfelder"],
    [Keys.VDLANG_EXPORT_CSV_TOGGLE_EXCEL_METADATA]: ["Optimise for use in Excel.", "Für Nutzung in Excel optimieren."],
    [Keys.VDLANG_EXPORT_CSV_TOGGLE_EXCEL_METADATA_HINT]: [
        "Adds a metadata header to CSV file for use with Excel.",
        "Fügt eine Metadaten-Kopfzeile zur CSV-Datei für die Nutzung in Excel hinzu.",
    ],
    [Keys.VDLANG_PREPARING_DATA]: [
        "We are preparing your data for download in the background...",
        "Wir bereiten den Download Ihrer Daten im Hintergrund vor...",
    ],
    [Keys.VDLANG_METHOD_GUIDE_MAIN_TITLE]: ["Method Guide", "Methoden-Guide"],
    [Keys.VDLANG_METHOD_GUIDE_SEARCH_RESULTS]: ["Search results ({{count}})", "Suchergebnisse ({{count}})"],
    [Keys.VDLANG_METHOD_GUIDE_APPLY_METHOD_TO_MEASURE]: ["Apply", "Verwenden"],
    [Keys.VDLANG_METHOD_GUIDE_METHOD_DETAILS]: ["Detail view", "Detailansicht"],
    [Keys.VDLANG_METHOD_GUIDE_METHOD_DETAILS_DISABLED_HINT]: [
        "Die Detailansicht ist für {{clientName}} nicht freigschaltet.",
        "The detail view is not available for {{clientName}}.",
    ],

    [Keys.VDLANG_ACTIVITIES_OVERDUE_ICON_TOOLTIP]: ["This activity is overdue", "Diese Aktivität ist überfällig"],
    [Keys.VDLANG_ACTIVITIES_DUE_ICON_TOOLTIP]: ["This activity is due soon", "Diese Aktivität ist bald fällig"],
    [Keys.VDLANG_ACTIVITIES_MARK_COMPLETED_BUTTON_LABEL]: ["Mark complete", "Als abgeschlossen markieren"],
    [Keys.VDLANG_ACTIVITIES_REOPEN_BUTTON_LABEL]: ["Completed", "Abgeschlossen"],
    [Keys.VDLANG_ACTIVITIES_NEW_ACTIVITY_PLACEHOLDER]: ["Click to create a new activity", "Hier klicken, um eine neue Aktivität anzulegen"],
    [Keys.VDLANG_ACTIVITIES_NEW_ACTIVITY_TEXTFIELD_PLACEHOLDER]: ["Enter activity title", "Titel der Aktivität eingeben"],
    [Keys.VDLANG_ACTIVITIES_NEW_ACTIVITY_TEXTFIELD_DEFAULT]: ["New activity", "Neue Aktivität"],
    [Keys.VDLANG_ACTIVITIES_PROGRESS_LABEL]: ["Progress", "Fortschritt"],
    [Keys.VDLANG_ACTIVITIES_NO_COMMENTS_MESSAGE]: ["No comments yet", "Noch keine Kommentare"],
    [Keys.VDLANG_ACTIVITIES_NUMBER_OF_COMMENTS_HINT]: ["{{count}} comment(s)", "{{count}} Kommentar(e)"],
    [Keys.VDLANG_ACTIVITIES_REOPEN_BUTTON_HINT]: ["Click to reopen", "Zum Wiederöffnen anklicken"],
    [Keys.VDLANG_ACTIVITIES_REMOVE_ACTIVITY]: ["Remove activity", "Aktivität entfernen"],
    [Keys.VDLANG_ACTIVITIES_ASSIGNED_TO_HINT]: [
        "The user assigned to this activity is responsible for ensuring that this activity will be completed on time. All users who can edit can assign a person here and, if required, also specify a due date.",
        "Die zu dieser Aktivität zugeordnete Person ist dafür verantwortlich, dass diese Aktivität rechtzeitig abgeschlossen wird. Alle Personen mit Bearbeitungsrechten können hier eine Person zuordnen und bei Bedarf zusätzlich auch einen Fälligkeitstermin mit angeben.",
    ],
    [Keys.VDLANG_ACTIVITIES_NO_ACTIVITIES]: ["No activities available.", "Keine Aktivitäten vorhanden."],
    [Keys.VDLANG_ACTIVITIES_NO_OPEN_ACTIVITIES]: ["No open activities available.", "Keine offenen Aktivitäten vorhanden."],
    [Keys.VDLANG_ACTIVITIES_SHOW_CHANGES]: ["Show", "Anzeigen"],
    remove_subtask_title: ["Remove activity?", "Aktivität entfernen?"],
    subtask_status: SubtaskStatusTranslations,
    subtask_priority: SubtaskPriorityTranslations,
    [Keys.VDLANG_ACTIVITIES_TITLE_LENGTH_ERROR]: ["Title is too long", "Titel ist zu lang"],

    [Keys.VDLANG_ACTIVITIES_NOT_ALLOWED_ERROR]: [
        "The activity does not exist or you don’t have the permission to see it.",
        "Die Aktivität existiert nicht oder Sie sind nicht berechtigt sie zu sehen.",
    ],
    [Keys.VDLANG_ACTIVITIES_NOT_VISIBLE_IN_LIST_HINT]: [
        "This activity is not displayed in the list due to your filter settings.",
        "Diese Aktivität wird aufgrund Ihrer Filtereinstellungen nicht in der Liste angezeigt.",
    ],

    [Keys.VDLANG_MY_ACTIVITIES_TITLE]: ["Activities", "Aktivitäten"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_LABEL]: ["Show activities", "Aktivitäten anzeigen"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_ASSIGNED_TO_ME]: ["Assigned to me", "Mir zugewiesen"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_CREATED_BY_ME]: ["Created by me", "Von mir erstellt"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_ALL_I_CAN_SEE]: ["All visible for me", "Alle für mich sichtbaren"],

    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_ASSIGNED_TO_TITLE]: ["Assigned to", "Zugeordnet an"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_CREATED_BY_TITLE]: ["Created by", "Erstellt durch"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_UNASSIGNED_USER_OPTION]: ["Unassigned", "Nicht zugewiesen"],

    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_STATUS_LABEL]: ["Status", "Status"],

    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_BY_LABEL]: ["Order by", "Sortieren nach"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_BY_ASSIGNED_TO]: ["Assigned person", "Zugeordneter Person"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_BY_CREATED_AT]: ["Creation date", "Erstellungsdatum"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_ASC]: ["ascending", "aufsteigend"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_DESC]: ["descending", "absteigend"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_ORDER_ALPHABETICALLY]: ["Alphabetically by name", "Name alphabetisch"],

    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUPING_TITLE]: ["Grouping", "Gruppierung"],

    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_LABEL]: ["Group by", "Gruppieren nach"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_CREATED_BY]: ["Creator", "Ersteller"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_ASSIGNED_TO]: ["Assigned person", "Zugeordneter Person"],

    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_NO_DUEDATE]: ["No due date", "Ohne Fälligkeit"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_OVERDUE]: ["In the past", "In der Vergangenheit"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_TODAY]: ["Today", "Heute"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_WEEK]: ["This week", "Diese Woche"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_MONTH]: ["This month", "Diesen Monat"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_FUTURE]: ["In future", "In Zukunft"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_OPEN_START]: ["until {{date}}", "bis einschließlich {{date}}"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_DUEDATE_OPEN_END]: ["from {{date}} on", "ab dem {{date}}"],

    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_ORDER_BY_LABEL]: ["Order groups by", "Gruppen sortieren nach"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_MEASURE_ORDER_BY_EFFECT]: ["Potential", "Potenzial"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_PROCESS_ORDER_BY_PROCESS_ID]: ["Process ID", "Prozess ID"],
    [Keys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUP_BY_MEASURE_ORDER_BY_DAYS_UNTIL_EFFECTIVENESS]: [
        "Days until effectiveness",
        "Tage bis zur Wirksamkeit",
    ],
    [Keys.VDLANG_ACTIVITIES_GATE_TASK_NOT_ASSIGNED]: ["No assignment", "Keine Zuordnung"],
    [Keys.VDLANG_ACTIVITIES_GATE_TASK_NOT_ASSIGNED_LABEL]: ["No level assigned", "Ohne Härtegrad Zuweisung"],

    [Keys.VDLANG_ACTIVITIES_PROCESS_FILTER_FILTERS]: ["Process filter", "Prozessfilter"],

    [Keys.VDLANG_DATERANGE_PICKER_PRESET_BUTTON_DISABLED_TOOLTIP]: ["No start date selected yet", "Noch kein Startdatum ausgewählt"],
    [Keys.VDLANG_DATERANGE_PICKER_NO_DATE_HINT]: ["Missing date", "Datum fehlt"],
    [Keys.VDLANG_DATERANGE_PICKER_END_BEFORE_START]: ["End date is before start date", "Enddatum liegt vor dem Startdatum"],
    [Keys.VDLANG_DATERANGE_PICKER_OUTSIDE_SCOPE]: [
        "Date is outside of the accepted scope",
        "Datum ist außerhalb des akzeptierten Zeitraums",
    ],
    [Keys.VDLANG_DATERANGE_PICKER_TODAY_BUTTON_LABEL]: ["Today", "Heute"],
    [Keys.VDLANG_DATERANGE_PICKER_CLEAR_BUTTON_LABEL]: ["Clear", "Löschen"],

    [Keys.VDLANG_ADMIN_SIDEBAR_GROUP_SETTINGS]: ["General Settings", "Allgemeine Einstellungen"],
    [Keys.VDLANG_ADMIN_SIDEBAR_SETTINGS_ORGANIZATION]: ["Organization", "Unternehmen"],

    [Keys.VDLANG_ADMIN_SIDEBAR_GROUP_DATA]: ["Data Management", "Datenverwaltung"],
    [Keys.VDLANG_ADMIN_SIDEBAR_DATA_COMPANIES]: ["Companies", "Gesellschaften"],
    [Keys.VDLANG_ADMIN_SIDEBAR_DATA_TREE_NODES]: ["Tree structures", "Baumstrukturen"],
    [Keys.VDLANG_ADMIN_SIDEBAR_DATA_SUPPLIERS]: ["Partners", "Partner"],
    [Keys.VDLANG_ADMIN_SIDEBAR_DATA_CURRENCIES]: ["Currencies", "Währungen"],
    [Keys.VDLANG_ADMIN_SIDEBAR_DATA_PROCESSES]: ["Processes", "Prozesse"],
    [Keys.VDLANG_ADMIN_SIDEBAR_DATA_DEPARTMENTS]: ["Departments", "Abteilungen"],
    [Keys.VDLANG_ADMIN_SIDEBAR_DATA_OPPS]: ["Opportunities", "Opportunitäten"],

    [Keys.VDLANG_ADMIN_SIDEBAR_GROUP_USERS_AND_ACCESS]: ["User Management", "Benutzerverwaltung"],
    [Keys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_DEFAULT_PERMISSIONS]: ["Default settings", "Standardeinstellungen"],
    [Keys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_USERS]: ["Users", "Benutzer"],
    [Keys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_GROUPS]: ["Groups", "Gruppen"],
    [Keys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_PERMISSIONS]: ["Permissions", "Berechtigungen"],
    [Keys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_DOMAINS]: ["Domains", "Domains"],
    [Keys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_SIGNON]: ["Sign-Ins", "Sign-Ins"],
    [Keys.VDLANG_ADMIN_SIDEBAR_USERS_AND_ACCESS_SCIM]: ["SCIM", "SCIM"],

    [Keys.VDLANG_ADMIN_DEFAULT_PERMISSIONS_DEFAULT_GROUP]: ["Default group", "Standardgruppe"],
    [Keys.VDLANG_ADMIN_DEFAULT_PERMISSIONS_DEFAULT_GROUP_HINT]: [
        "The selected groups and user tier are pre-selected as default values for the member invite dialog in the admin users section.",
        "Die ausgewählten Gruppen und die Benutzerstufe sind als Standardwerte für den Dialog zur Einladung von Mitgliedern im Admin-Benutzerbereich vorausgewählt.",
    ],
    [Keys.VDLANG_ADMIN_DEFAULT_PERMISSIONS_EXTERNAL_USER_GROUP]: ["Contributor group", "Mitwirkenden Gruppe"],
    [Keys.VDLANG_ADMIN_DEFAULT_PERMISSIONS_EXTERNAL_USER_GROUP_HINT]: [
        "The selected groups and user tier are applied when a user is creaded by the system using single sign-on or when a user gets invited from within a process.",
        "Die ausgewählten Gruppen und die Benutzerstufe werden angewendet, wenn ein Benutzer durch das System mittels Single Sign-On erstellt wird oder ein Benutzer aus einem Prozess heraus eingeladen wird.",
    ],
    [Keys.VDLANG_ADMIN_DEFAULT_PERMISSIONS_PSEUDO_PUBLIC_MESURE_GROUP]: ["Measure default", "Standard für Maßnahmen"],
    [Keys.VDLANG_ADMIN_DEFAULT_PERMISSIONS_PSEUDO_PUBLIC_MESURE_GROUP_HINT]: [
        "The selected groups and permission can optionally be added to a process upon completion of the first level, depending on the toggle at the first level. The same groups and permission will be added automatically during the conversion of an opportunity into a process.",
        "Die ausgewählten Gruppen und die Berechtigung können nach Abschluss des ersten Härtegrades optional zu einem Prozess hinzugefügt werden, abhängig vom Schalter am ersten Härtegrad. Die gleichen Gruppen und die Berechtigung werden automatisch hinzugefügt, wenn eine Opportunität in einen Prozess überführt wird.",
    ],

    [Keys.VDLANG_ADMIN_SIGNON]: ["Sign-on", "Anmeldedienste"],
    [Keys.VDLANG_ADMIN_SIGNON_CREATE]: ["Create sign-on", "Anmeldedienst anlegen"],
    [Keys.VDLANG_ADMIN_SIGNON_FROM_METADATA]: ["Create from Metadata", "Aus Metadaten anlegen"],
    [Keys.VDLANG_ADMIN_SIGNON_MANUAL_TEXT]: ["SSO Admin Manual", "SSO Admin Handbuch"],
    [Keys.VDLANG_ADMIN_SIGNON_MANUAL_LINK]: [
        "https://my.valuedesk.de/docs/sso_admin_manual.pdf",
        "https://my.valuedesk.de/docs/sso_admin_manual.pdf",
    ],
    [Keys.VDLANG_ADMIN_SIGNON_METADATA_TEXT]: ["Metadata XML", "Metadaten XML"],
    [Keys.VDLANG_ADMIN_SIGNON_METADATA_LINK]: ["https://my.valuedesk.de/docs/metadata.xml", "https://my.valuedesk.de/docs/metadata.xml"],
    [Keys.VDLANG_IDENTITY_PROVIDER_TYPE]: {
        [IdentityProviderType.Password]: ["Password", "Passwort"],
        [`${IdentityProviderType.Password}_2FA`]: ["Password + 2FA", "Passwort + 2FA"],
        [IdentityProviderType.SAML]: ["SAML", "SAML"],
        [IdentityProviderType.GitHub]: ["GitHub", "GitHub"],
    },
    [Keys.VDLANG_IDENTITY_PROVIDER_LOGIN_URL]: ["Login URL", "URL für Anmeldung"],
    [Keys.VDLANG_IDENTITY_PROVIDER_ISSUER]: ["Issuer", "Aussteller"],
    [Keys.VDLANG_IDENTITY_PROVIDER_CERT]: ["Certificate", "Zertifikat"],
    [Keys.VDLANG_IDENTITY_PROVIDER_AUTHN_CONTEXT]: [
        "Name identifier format to request auth context",
        "Namensbezeichnerformat zur Abfrage des Authentifizierungskontextes",
    ],
    [Keys.VDLANG_IDENTITY_PROVIDER_AUTHN_DISABLE]: [
        "Don't request a specific authentication context",
        "Keinen bestimmten Authentifizierungskontext anfordern",
    ],
    [Keys.VDLANG_IDENTITY_PROVIDER_2FA]: [
        "Require two-factor authentication for every user",
        "Erfordern einer Zwei-Faktor-Authentifizierung für alle Benutzer",
    ],
    [Keys.VDLANG_USER_ENFORCE_2FA]: ["Require two-factor authentication", "Erfordern einer Zwei-Faktor-Authentifizierung"],
    [Keys.VDLANG_USER_ENFORCE_2FA_BY_PROVIDER]: [
        "Require two-factor authentication (managed by sign-on provider)",
        "Erfordern einer Zwei-Faktor-Authentifizierung (vom Anmeldedienst verwaltet)",
    ],
    [Keys.VDLANG_USER_2FA_STATUS]: ["2FA status", "2FA-Status"],
    [Keys.VDLANG_USER_2FA_ACTIVATED_HINT]: [
        "Two-factor authentication is activated for this user or managed by their sign-on provider",
        "Zwei-Faktor-Authentifizierung ist für diesen Benutzer aktiviert oder wird von dessen Anmeldedienst verwaltet",
    ],
    [Keys.VDLANG_USER_2FA_MANAGED_BY_SIGN_ON]: ["Managed by sign-on provider", "Von Anmeldedienst verwaltet"],
    [Keys.VDLANG_USER_2FA_REQUIRED_BUT_NOT_SETUP]: ["Required but not setup yet", "Erforderlich, aber noch nicht eingerichtet"],

    remove_identity_provider_title: ["Remove single sign-on?", "Anmeldedienst entfernen?"],
    remove_identity_provider_description: [
        "After deletion, all users must log in again with the password provider!",
        "Nach dem Löschen müssen sich alle Benutzer wieder mit dem Passwort Provider anmelden!",
    ],
    remove_currency_title: ["Remove currency?", "Währung entfernen?"],
    remove_currency_description: ["The currency {{currency}} is not used anywhere.", "Die Währung {{currency}} wird nicht verwendet."],
    remove_department_title: ["Remove department?", "Abteilung entfernen?"],
    [Keys.VDLANG_IDENTITY_PROVIDER_DELETE_PASSWORD]: [
        "The password provider can only be deleted and not deactivated.",
        "Der Passwort Provider kann nur gelöscht und nicht deaktiviert werden.",
    ],

    [Keys.VDLANG_ADMIN_COMPANIES_SEATS_HINT]: [
        "<strong>{{currentSeats}}</strong> of <strong>{{maxSeats}}</strong> companies are created",
        "<strong>{{currentSeats}}</strong> von <strong>{{maxSeats}}</strong> Gesellschaften wurden angelegt",
    ],
    [Keys.VDLANG_ADMIN_ADMIN_USER_SEATS_HINT]: ["Admin", "Admin"],
    [Keys.VDLANG_ADMIN_ADVANCED_USER_SEATS_HINT]: ["Full User", "Full User"],
    [Keys.VDLANG_ADMIN_BASIC_USER_SEATS_HINT]: ["Contributor", "Mitwirkende/r"],
    [Keys.VDLANG_ADMIN_LIGHT_USER_SEATS_HINT]: ["Ideator", "Ideengeber/in"],
    [Keys.VDLANG_ADMIN_ADVANCED_USER_SEATS_IN_USE_HINT]: [
        "All Full User user seats are in use.",
        "Alle Full User Zugänge sind in Benutzung.",
    ],
    [Keys.VDLANG_ADMIN_BASIC_USER_SEATS_IN_USE_HINT]: [
        "All Contributor seats are in use.",
        "Alle Zugänge für Mitwirkende sind in Benutzung.",
    ],
    [Keys.VDLANG_ADMIN_MEMBERS_GROUPS]: ["Groups", "Gruppen"],
    [Keys.VDLANG_ADMIN_MEMBERS_GROUPS_DIRECT]: ["Directly assigned groups", "Direkt zugewiesene Gruppen"],
    [Keys.VDLANG_ADMIN_MEMBERS_RESTRICTED_GROUPS_HINT]: [
        "The list of available groups is restricted because all seats are in use.",
        "Die Liste der verfügbaren Gruppen ist eingeschränkt, da alle Zugänge in Benutzung sind.",
    ],
    [Keys.VDLANG_ADMIN_PARTNER_SEATS_HINT]: [
        "<strong>{{currentSeats}}</strong> of <strong>{{maxSeats}}</strong> partner seats are in use",
        "<strong>{{currentSeats}}</strong> von <strong>{{maxSeats}}</strong> Zugänge für Partner sind in Benutzung",
    ],

    [Keys.VDLANG_ADMIN_CURRENCIES_DEFAULT_CURRENCY_HINT]: ["1 {{currencyCode}} equals…", "1 {{currencyCode}} entspricht…"],
    [Keys.VDLANG_ADMIN_CURRENCIES_DEFAULT_CURRENCY_TOOLTIP]: [
        "Enter your exchange rates to specify how many units of the currency are equivalent to one unit in the default currency. Valuedesk uses these rates to convert processes that have been entered in a foreign currency to the default currency. Here, the exchange rate of the fiscal year in which an effect is effective is always used.",
        "Geben Sie Ihre Umrechnungskurse so ein, dass sie angeben wieviele Einheiten der Fremdwährung eine Einheit in der Standardwährung entsprechen. Valuedesk verwendet diese Kurse, um Prozesse, die in einer Fremdwährung erfasst worden sind, in die Standardwährung umzurechnen. Dabei wird immer der Umrechnungskurs des Geschäftsjahres verwendet, in dem ein Effekt wirksam ist.",
    ],
    [Keys.VDLANG_ADMIN_CURRENCIES_NO_DATA]: ["No currencies created yet", "Noch keine Fremdwährungen angelegt"],
    [Keys.VDLANG_ADMIN_CURRENCIES_ADD_CURRENCY_BUTTON_LABEL]: ["Add currency", "Währung hinzufügen"],
    [Keys.VDLANG_TOKEN_EXPIRATION_TIME]: ["Token expiration (h)", "Token Lebensdauer (h)"],
    [Keys.VDLANG_USER_PROFILE_CUSTOM]: ["Overwrite user profile fields ({{count}})", "Benutzerprofilfelder überschreiben ({{count}})"],
    [Keys.VDLANG_IS_TESTED]: ["Tested", "Getestet"],
    [Keys.VDLANG_YES]: ["Yes", "Ja"],
    [Keys.VDLANG_NO]: ["No", "Nein"],
    [Keys.VDLANG_FIELD_FAVORITE_IS_FAVORITE]: ["★ Favorite", "★ Favorit"],
    [Keys.VDLANG_FIELD_FAVORITE_IS_NOT_FAVORITE]: ["☆ No Favorite", "☆ Kein Favorit"],

    [Keys.VDLANG_SHOW_ONLY_PROCESSES_IN_PERIOD_UNDER_CONSIDERATION]: [
        "Show only processes in period under consideration",
        "Nur Prozesse im Betrachtungszeitraum anzeigen",
    ],
    [Keys.VDLANG_PROCESS]: ["Process", "Prozess"],

    [Keys.VDLANG_ADMIN_DOMAIN]: ["Domain", "Domain"],
    [Keys.VDLANG_ADMIN_DOMAIN_CREATE]: ["Create domain", "Domain anlegen"],
    remove_domain_description: ["The domain will be deleted permanently.", "Die Domain wird endgültig entfernt."],

    [Keys.VDLANG_ENTER_NUMBER_OF_DAYS]: ["Number of days", "Anzahl der Tage"],
    [Keys.VDLANG_DATERANGE]: ["Date range: {{start}} - {{end}}", "Zeitraum: {{start}} - {{end}}"],
    [Keys.VDLANG_DATERANGE_BEFORE]: ["Everything before {{date}}", "Zeitraum vor dem {{date}}"],
    [Keys.VDLANG_DATERANGE_AFTER]: ["Everything after {{date}}", "Zeitraum nach dem {{date}}"],
    [Keys.VDLANG_DATERANGE_DAYS]: ["days", "Tagen"],

    [Keys.VDLANG_ERROR_PAGE_TITLE]: ["Error", "Fehler"],
    [Keys.VDLANG_ERROR_PAGE_SOMETHING_WENT_WRONG]: ["Something went wrong", "Da ist etwas schiefgelaufen"],
    [Keys.VDLANG_ERROR_PAGE_RECOMMENDATION]: [
        "You may try reloading the current page or go to the News page.",
        "Sie können versuchen die aktuelle Seite neu zu laden oder zur Neuigkeiten-Seite zu wechseln.",
    ],
    [Keys.VDLANG_ERROR_PAGE_RELOAD_BUTTON]: ["Reload", "Neuladen"],
    [Keys.VDLANG_ERROR_PAGE_START_PAGE]: ["Go to start page", "Zur Startseite"],

    [Keys.VDLANG_CLOSE]: ["Close", "Schließen"],
    [Keys.VDLANG_ACTIVATED]: ["Activated", "Aktiviert"],
    [Keys.VDLANG_NOT_ACTIVATED]: ["Not activated", "Nicht aktiviert"],
    [Keys.VDLANG_DEACTIVATED]: ["Deactivated", "Deaktiviert"],

    [Keys.VDLANG_INTEGER_INPUT_ONLY_DIGITS]: ["You can only enter digits.", "Sie können nur ganze Zahlen eintragen."],
    [Keys.VDLANG_INTEGER_PERCENTAGE_INPUT_ONLY]: [
        "You can only enter integers from 0 to 100.",
        "Sie können nur ganze Zahlen von 0 bis 100 eintragen.",
    ],

    [Keys.VDLANG_DELETE_USER]: ["Delete user", "Benutzer löschen"],
    [Keys.VDLANG_TIMERANGE_PUC_HINT]: [
        "Processes that are effective in the period under consideration are visible. However, processes, for which the effectiveness period is not filled, are also displayed.",
        "Es werden Prozesse, die im Betrachtungszeitraum wirksam sind, angezeigt. Auch Prozesse, bei denen der Wirksamkeitszeitraum noch nicht ausgefüllt wurde, werden angezeigt.",
    ],
    [Keys.VDLANG_SYSTEM_USER]: ["Valuedesk System", "Valuedesk-System"],
    remove_user_title: ["Delete user?", "Benutzer löschen?"],
    remove_user_description: ["The user will be deleted permanently.", "Dieser Benutzer wird dauerhaft gelöscht."],

    [Keys.VDLANG_MEASURE_PRIVILEDGED_ACCESS]: ["Access provided by special permissions", "Zugriff durch spezielle Berechtigungen"],

    [Keys.VDLANG_MEASURE_VISIBILITY_RESTRICTED_MESSAGE]: [
        "You and the users listed can see / edit this process.",
        "Sie und die aufgeführten Personen können den Prozess sehen bzw. bearbeiten",
    ],
    [Keys.VDLANG_MEASURE_VISIBILITY_RESTRICTED_HINT]: [
        "The users listed here can see or edit the process, based on a special authorization.",
        "Die hier aufgeführten Personen können den Prozess, aufgrund einer speziellen Berechtigung, sehen bzw. bearbeiten",
    ],
    [Keys.VDLANG_MEASURE_PRIVILEDGED_ACCESS_NO_USER]: [
        "No users with special authorizations found.",
        "Keine Benutzer mit speziellen Berechtigungen gefunden.",
    ],

    [Keys.VDLANG_MEASURE_DECIDER_ASSIGNMENT_HINT]: [
        "Only decision makers who are allowed to edit the {{processName}} can be selected.",
        "Nur Entscheider:innen, die die {{processName}} bearbeiten dürfen, können ausgewählt werden.",
    ],

    [Keys.VDLANG_MEASURE_DECIDER_SHOULD_ADD_TITLE]: ["Add decider", "Entscheider hinzufügen"],
    [Keys.VDLANG_CONFIRM]: ["Confirm", "Bestätigen"],
    [Keys.VDLANG_MEASURE_DECIDER_SHOULD_ADD_TEXT]: [
        "{{displayname}} is not yet editor of the process. Do you want to add the person as an editor?",
        "{{displayname}} darf den Prozess noch nicht bearbeiten. Wollen Sie die Person zur Bearbeitung hinzufügen?",
    ],

    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_BUTTON]: ["Access", "Zugriff"],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_RESPONSIBLE_USER]: ["{{displayname}} (Responsible)", "{{displayname}} (Verantwortlich)"],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_ADD_MORE_MEMBERS]: ["Add more users", "Weitere Benutzer hinzufügen"],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_ADD_MORE_USERS_GROUPS]: ["Add more users/groups", "Weitere Benutzer/Gruppen hinzufügen"],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_WHO_HAS_ACCESS]: ["Who has access?", "Wer hat Zugriff?"],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_ONLY_OWNER_CAN_CHANGE]: [
        "Only the responsible person can change these settings.",
        "Nur die verantwortliche Person darf diese Einstellungen ändern.",
    ],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_ONLY_OWNER_CAN_CHANGE_WITH_USER]: [
        "Only {{userName}} can change these settings.",
        "Nur {{userName}} darf diese Einstellungen ändern.",
    ],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_MAKE_RESPONSIBLE]: ["Make responsible", "Verantwortlich machen"],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_CHANGE_RESPONSIBLE_LABEL]: ["Change responsible person", "Verantwortliche Person ändern"],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_PERSONS_WHO_HAVE_EDITED]: [
        "Persons who have edited ({{count}})",
        "Personen, die bearbeitet haben ({{count}})",
    ],

    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_REMOVE_TITLE]: ["Remove user?", "Benutzer entfernen?"],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_CHANGE_TITLE]: ["Change access?", "Zugriff ändern?"],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_CHANGE_BUTTON]: ["Change", "Anpassen"],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_CONFIRM_USER_WILL_BE_UNASSIGNED]: [
        "{{userName}} will be unassigned from the following:",
        "{{userName}} wird von folgenden Zuordnungen entfernt:",
    ],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_CANNOT_BE_CLAIMED]: [
        "You cannot claim this process, because you are not allowed to edit it or because you cannot be responsible for it.",
        "Sie können sich diesen Prozess nicht zuordnen, weil Sie ihn nicht bearbeiten dürfen oder weil Sie dafür nicht verantwortlich sein können.",
    ],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_CLAIM]: ["Claim", "Mir zuordnen"],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_CLAIM_NO_RESPONSIBLE_USER]: [
        "This {{processName}} is not yet assigned to a person. If you want to edit the {{processName}}, you need to claim it.",
        "Diese {{processName}} ist noch keiner Person zugeordnet. Wenn Sie die {{processName}} bearbeiten möchten, müssen Sie sie sich zuordnen.",
    ],
    [Keys.VDLANG_MEASURE_ACCESS_CONTROL_CLAIM_INVALID_RESPONSIBLE_USER]: [
        "This {{processName}} is assigned to a person who should not be responsible for it. If you want to edit the {{processName}}, you need to claim it.",
        "Diese {{processName}} ist einer Person zugeordnet, die dafür nicht verantwortlich sein sollte. Wenn Sie die {{processName}} bearbeiten möchten, müssen Sie sie sich zuordnen.",
    ],

    [Keys.VDLANG_MEASURE_TITLE_EMPTY_ERROR]: [
        "Title must contain at least one character",
        "Titel muss mindestens einen Buchstaben enthalten",
    ],
    [Keys.VDLANG_MEASURE_TITLE_TOO_LONG_ERROR]: ["Title is too long", "Titel ist zu lang"],

    [Keys.VDLANG_REMOVE]: ["Remove", "Entfernen"],
    [Keys.VDLANG_ACTIVITY]: ["Activity", "Aktivität"],
    [Keys.VDLANG_ACTIVITIES]: ["Activities", "Aktivitäten"],
    [Keys.VDLANG_GATETASK]: ["Level", "Härtegrad"],
    [Keys.VDLANG_FIELD]: ["Field", "Feld"],
    [Keys.VDLANG_OPPORTUNITIES]: ["Opportunities", "Opportunitäten"],
    [Keys.VDLANG_PROCESSES]: ["Processes", "Prozesse"],

    [Keys.VDLANG_MEASURE_NOTIFICATIONS_RECEIVE_NOTIFICATIONS]: ["Receive notifications", "Benachrichtigungen erhalten"],
    [Keys.VDLANG_MEASURE_NOTIFICATIONS_ADD_USER_TITLE]: ["Add user", "Person hinzufügen"],
    [Keys.VDLANG_MEASURE_NOTIFICATIONS_ENABLED_NOTIFICATIONS]: [
        "Persons with enabled notifications",
        "Personen mit aktivierten Benachrichtigungen",
    ],
    [Keys.VDLANG_MEASURE_NOTIFICATIONS_ENABLED_NOTIFICATIONS_HINT]: [
        "These persons may not receive notifications due to their personal settings.",
        "Diese Personen könnten aufgrund ihrer persönlichen Einstellungen trotzdem keine Benachrichtigungen erhalten.",
    ],
    [Keys.VDLANG_MEASURE_NOTIFICATIONS_ONLY_MEASURE_OWNER_CAN_CHANGE]: [
        "Nobody is currently responsible for this {{processName}}. Only the responsible person can change the notification settings for other users.",
        "Für diese {{processName}} ist derzeit niemand verantwortlich. Nur die verantwortliche Person darf die Benachrichtigungseinstellungen für andere Personen ändern.",
    ],
    [Keys.VDLANG_MEASURE_NOTIFICATIONS_ONLY_MEASURE_OWNER_CAN_CHANGE_WITH_USER]: [
        "Only {{userName}} can change the notification settings for other users.",
        "Nur {{userName}} darf die Benachrichtigungseinstellungen für andere Personen ändern.",
    ],
    [Keys.VDLANG_MEASURE_NOTIFICATIONS_OWN_NOTIFICATIONS_ENABLED]: [
        "Notifications for this {{processName}} have been enabled",
        "Benachrichtigungen für diese {{processName}} wurden aktiviert",
    ],
    [Keys.VDLANG_MEASURE_NOTIFICATIONS_OWN_NOTIFICATIONS_DISABLED]: [
        "Notifications for this {{processName}} have been disabled",
        "Benachrichtigungen für diese {{processName}} wurden deaktiviert",
    ],
    [Keys.VDLANG_MEASURE_NOTIFICATIONS_OTHER_NOTIFICATIONS_ENABLED]: [
        "Notifications have been enabled for: {{users}}",
        "Benachrichtigungen wurden aktiviert für: {{users}}",
    ],
    [Keys.VDLANG_MEASURE_NOTIFICATIONS_OTHER_NOTIFICATIONS_DISABLED]: [
        "Notifications have been disabled for: {{users}}",
        "Benachrichtigungen wurden deaktiviert für: {{users}}",
    ],
    [Keys.VDLANG_MEASURE_NOTIFICATIONS_USER_DISABLED_HINT]: [
        "You cannot enable notifications for this user, because the user has explicitly disabled notifications for this process.",
        "Sie können für diesen Benutzer keine Benachrichtigungen aktivieren, da der Benutzer explizit Benachrichtigungen für diesen Prozess deaktiviert hat.",
    ],

    [Keys.VDLANG_USER_SELECT_WIDGET_NO_USER_RESULTS]: ["No users found.", "Keine Benutzer gefunden."],
    [Keys.VDLANG_USER_SELECT_WIDGET_NO_SELECTABLE_USERS]: ["No selectable users available", "Keine auswählbaren Personen vorhanden"],
    [Keys.VDLANG_USER_SELECT_WIDGET_NO_OTHER_SELECTABLE_USERS]: [
        "No other selectable users available",
        "Keine weiteren auswählbaren Personen vorhanden",
    ],
    [Keys.VDLANG_USER_SELECT_WIDGET_SEARCH_LABEL]: ["Search", "Suchen"],
    [Keys.VDLANG_USER_SELECT_WIDGET_SEARCH_PLACEHOLDER]: ["Enter Name", "Name eingeben"],
    [Keys.VDLANG_USER_SELECT_WIDGET_SEARCH_ASSIGN]: ["Assign", "Zuordnen"],

    [Keys.VDLANG_MEASURE_RESPONSIBLE_FOR_CURRENT_GATETASK]: ["Responsible for current level", "Verantwortlich für aktuellen Härtegrad"],
    [Keys.VDLANG_DASHBOARD_ACCESS_CONTROL_PRIVATE]: ["Only visible to you", "Nur für Sie sichtbar"],

    [Keys.VDLANG_METHODS_DETAIL_START_A_PROCESS]: ["Start a process", "Prozess starten"],
    [Keys.VDLANG_METHODS_DETAIL_PROCESSES]: ["Processes", "Prozesse"],
    [Keys.VDLANG_METHODS_DETAIL_EFFECTIVE_MEASURES]: ["Effective processes", "Wirksame Prozesse"],
    [Keys.VDLANG_METHODS_DETAIL_AVERAGE_EFFECTIVE_EFFECT]: ["Average effective potential", "Durchschnittlich wirksames Potenzial"],
    [Keys.VDLANG_METHODS_DETAIL_NO_COST_LEVER_ATTRIBUTE]: [
        "This process type is not configured to be used with methods.",
        "Dieser Prozesstyp ist nicht für die Verwendung mit Methoden konfiguriert.",
    ],
    [Keys.VDLANG_METHODS_METHOD_NOT_FOUND]: ["The method has not been found.", "Die Methode wurde nicht gefunden."],
    [Keys.VDLANG_METHODS_NAVBAR_ITEM_LABEL]: ["Method Explorer", "Methoden-Explorer"],

    [Keys.VDLANG_METHOD_TABLE_CODE_COLUMN_LABEL]: ["Code", "Kürzel"],
    [Keys.VDLANG_METHOD_TABLE_NAME_COLUMN_LABEL]: ["Method", "Methode"],
    [Keys.VDLANG_METHOD_TABLE_FAVORITE_COLUMN_LABEL]: ["Favorites", "Favoriten"],
    [Keys.VDLANG_METHOD_TABLE_COUNT_COLUMN_LABEL]: ["Count", "Anzahl"],
    [Keys.VDLANG_METHOD_TABLE_EFFECT_COLUMN_LABEL]: ["Potential", "Potenzial"],
    [Keys.VDLANG_METHOD_TABLE_AVERAGE_EFFECT_COLUMN_LABEL]: ["Average Potential", "Durchschnittspotenzial"],
    [Keys.VDLANG_METHOD_TABLE_RELEVANCY]: ["Relevancy", "Relevanz"],
    [Keys.VDLANG_METHOD_TABLE_NO_METHODS]: ["No Methods.", "Keine Methoden."],
    [Keys.VDLANG_METHOD_TABLE_FAVORITE_COLUMN_HINT]: [
        "You can mark your personal method favorites in the tile view and in the detail view of the methods.",
        "In der Kachelansicht und in der Detailansicht der Methoden können Sie sich Ihre persönlichen Methoden-Favoriten markieren.",
    ],
    [Keys.VDLANG_METHOD_TABLE_COUNT_COLUMN_HINT]: [
        "Number of all measures and post-captures from {{clientName}} concerning this method. Discarded measures and post-captures are not counted.",
        "Anzahl sämtlicher Maßnahmen und Nacherfassungen von {{clientName}} zu dieser Methode. Verworfene Maßnahmen und Nacherfassungen werden nicht mitgezählt.",
    ],
    [Keys.VDLANG_METHOD_TABLE_EFFECT_COLUMN_HINT]: [
        "Potential of all measures and post-captures from {{clientName}} concerning this method. Discarded measures and post-captures are not counted.",
        "Potenzial sämtlicher Maßnahmen und Nacherfassungen von {{clientName}} zu dieser Methode. Verworfene Maßnahmen und Nacherfassungen werden nicht mitgezählt.",
    ],
    [Keys.VDLANG_METHOD_TABLE_AVERAGE_EFFECT_COLUMN_HINT]: [
        "Average potential of all measures and post-captures from {{clientName}} concerning this method. Discarded measures and post-captures are not counted.",
        "Durchschnittliches Potenzial sämtlicher Maßnahmen und Nacherfassungen von {{clientName}} zu dieser Methode. Verworfene Maßnahmen und Nacherfassungen werden nicht mitgezählt.",
    ],
    [Keys.VDLANG_METHOD_TABLE_RELEVANCY_HINT]: [
        "The relevance is calculated by an algorithm which considers existing process data and other success factors. It indicates how promising a new process based on the search term would be for a method. The algorithm learns with every process and will deliver better results over time.<br /><br />If you participate in the Partner Pooling program, the data of participants from other organizations will also flow into the algorithm, which can provide much better results.",
        "Hinter der Relevanz steckt ein Algorithmus, der auf Grundlage der bestehenden Daten und den Erfolgsfaktoren bewertet, wie erfolgversprechend ein neuer Prozess zu den Suchbegriffen mit einer Methode ist. Der Algorithmus lernt mit jedem Prozess dazu und wird immer bessere Bewertungen anbieten.<br /><br />Wenn Sie am Partner Pooling-Programm teilnehmen, fließen die Daten auch von Teilnehmern anderer Unternehmen mit in den Algorithmus hinein, womit wesentlich bessere Ergebnisse geliefert werden können.",
    ],

    [Keys.VDLANG_METHODS_ACTIVITY_TEMPLATE]: ["Activity template", "Aktivitätenvorlage"],
    [Keys.VDLANG_METHODS_ACTIVITY_TEMPLATE_HINT]: [
        "This is a selection of typical activities that will be done with this method. You can use and modify them for your process.",
        "Dies ist eine Zusammenstellung typischer Aktivitäten, die bei dieser Methode durchgeführt werden. Sie können diese für Ihren Prozess verwenden und anpassen.",
    ],
    [Keys.VDLANG_METHODS_ACTIVITY_TEMPLATE_NOT_AVAILABLE]: [
        "There is no activity template available for this method yet.",
        "Aktuell gibt es noch keine Aktivitätenvorlage für diese Methode.",
    ],
    [Keys.VDLANG_METHODS_ACTIVITY_TEMPLATE_SUGGEST_ACTIVITIES]: ["Suggest activities", "Aktivitäten vorschlagen"],
    [Keys.VDLANG_METHODS_ACTIVITY_TEMPLATE_AVAILABLE]: [
        "An activity template is available for the selected method ({{code}} - {{name}})",
        "Für die ausgewählte Methode ({{code}} - {{name}}) ist eine Aktivitätenvorlage verfügbar",
    ],
    [Keys.VDLANG_METHODS_ACTIVITY_TEMPLATE_AVAILABLE_HINT]: [
        "This is a selection of typical activities that will be done with this method. You can use and modify them for your process.",
        "Dies ist eine Zusammenstellung typischer Aktivitäten, die bei dieser Methode durchgeführt werden. Sie können diese für Ihre Prozess verwenden und anpassen.",
    ],
    [Keys.VDLANG_METHODS_ACTIVITY_TEMPLATE_PREVIEW_AND_USE]: ["Preview & Use", "Vorschau & Verwenden"],
    [Keys.VDLANG_METHODS_ACTIVITY_TEMPLATE_DIALOG_DESCRIPTION_1]: [
        "These activities are often executed for this method",
        "Diese Aktivitäten werden häufig für die Methode durchgeführt",
    ],
    [Keys.VDLANG_METHODS_ACTIVITY_TEMPLATE_DIALOG_DESCRIPTION_2]: [
        "By applying the template, selected activities will be created. You can modify them afterwards.",
        "Beim Anwenden der Vorlage werden die ausgewählten Aktivitäten erstellt. Diese können anschließend bearbeitet werden.",
    ],
    [Keys.VDLANG_METHODS_ACTIVITY_TEMPLATE_CREATE_ACTIVITIES]: ["Create activities", "Aktivitäten erstellen"],
    [Keys.VDLANG_METHODS_ACTIVITY_TEMPLATE_SUGGEST_A_CHANGE]: ["Suggest a change", "Änderung vorschlagen"],
    [Keys.VDLANG_METHODS_ACTIVITY_TEMPLATE_ALL]: ["All", "Alle"],
    [Keys.VDLANG_METHODS_ACTIVITY_SUBTASK_GATE]: ["Activities", "Aktivitäten"],
    [Keys.VDLANG_SHOW_ACTIVITY_TEMPLATE]: ["Show activity template", "Aktivitätenvorlage anzeigen"],
    [Keys.VDLANG_METHOD_TILE_EFFECT_LABEL]: ["Potential", "Potenzial"],

    [Keys.VDLANG_METHOD_MEASURE_WIDGET_TITLE]: ["Processes of this method", "Prozesse mit dieser Methode"],

    [Keys.VDLANG_FEEDBACK_DIALOG_TEXT]: [
        "You can formulate a request to Valuedesk support here. We will contact you as soon as possible.",
        "Sie können hier eine Anfrage an Valuedesk formulieren. Wir werden uns dann schnellstmöglich mit Ihnen dazu in Verbindung setzen.",
    ],
    [Keys.VDLANG_FEEDBACK_DIALOG_LABEL]: ["Enter your request message", "Formulieren Sie Ihre Anfrage"],
    [Keys.VDLANG_FEEDBACK_DIALOG_SEND]: ["Send feedback", "Rückmeldung abschicken"],
    [Keys.VDLANG_FEEDBACK_DIALOG_SUCCESS]: ["Thank you for your feedback!", "Vielen Dank für Ihre Rückmeldung!"],

    [Keys.VDLANG_METHODS_SUGGEST_METHOD]: ["Suggest a method", "Methode vorschlagen"],

    [Keys.VDLANG_METHOD_FILES]: ["Helpful documents", "Hilfreiche Dokumente"],
    [Keys.VDLANG_METHOD_FAVORITE_BUTTON_LABEL]: ["Select as favorite", "Als Favorit auswählen"],
    [Keys.VDLANG_METHOD_UNFAVORITE_BUTTON_LABEL]: ["Unselect as favorite", "Als Favorit abwählen"],
    [Keys.VDLANG_METHOD_UPLOAD_FILE]: ["Upload document", "Dokument hochladen"],

    [Keys.VDLANG_NO_FILES]: ["No documents available.", "Keine Dokumente vorhanden."],
    [Keys.VDLANG_NO_PARTNERS]: ["No partners available.", "Keine Partner vorhanden."],

    [Keys.VDLANG_RESET]: ["Reset", "Zurücksetzen"],
    [Keys.VDLANG_SAVE]: ["Save", "Speichern"],
    [Keys.VDLANG_UPDATE]: ["Update", "Aktualisieren"],
    [Keys.VDLANG_USER_NOT_ASSIGNED]: ["Not assigned", "Nicht zugeordnet"],

    [Keys.VDLANG_LOGIN_TITLE]: ["Login", "Login"],
    [Keys.VDLANG_LOGIN_BUTTON]: ["Login", "Einloggen"],
    [Keys.VDLANG_LOGIN_NEXT]: ["Next", "Weiter"],
    [Keys.VDLANG_LOGIN_PASSWORD_RESET]: ["Forgot your password?", "Passwort vergessen?"],
    [Keys.VDLANG_LOGIN_NOT_REGISTERED]: ["No account yet?", "Noch keinen Account?"],
    [Keys.VDLANG_LOGIN_REGISTER]: ["Contact us.", "Kontakt aufnehmen."],
    [Keys.VDLANG_LOGIN_INCORRECT_EMAIL_OR_PASSWORD]: [
        "Incorrect email address or password",
        "Ungültige E-Mail-Adresse oder ungültiges Passwort",
    ],

    [Keys.VDLANG_INVITE_GREETING]: ["Hello {{name}}!", "Hallo {{name}}!"],
    [Keys.VDLANG_INVITE_INSTRUCTIONS]: [
        "You can get started right away, just one more step: Please choose a password to protect you account.",
        "Sie können gleich loslegen, nur noch einen Schritt: Bitte wählen Sie ein Passwort, um Ihr Konto zu schützen.",
    ],
    [Keys.VDLANG_INVITE_SET_PASSWORD_BUTTON]: ["Get started", "Loslegen"],

    [Keys.VDLANG_TOKEN_EXPIRED_TITLE]: ["Please check your email inbox", "Bitte schauen Sie in Ihr Email-Postfach"],
    [Keys.VDLANG_TOKEN_EXPIRED_CONTENT]: [
        "Your access link has already expired, so we have sent you a new link directly. Please check your inbox and follow the instructions in the email. For security reasons, the access links are only valid for 72 hours.",
        "Ihr Zugangslink ist bereits abgelaufen, daher haben wir Ihnen direkt einen neuen Link geschickt. Bitte schauen Sie in ihr Postfach und folgen Sie den Anweisungen in der Email. Aus Sicherheitsgründen sind die Zugangslinks nur 72 Stunden gültig.",
    ],
    [Keys.VDLANG_TOKEN_EXPIRED_HINT]: ["If you have any questions, please contact ", "Bei Fragen wenden Sie sich bitte an "],

    [Keys.VDLANG_PASSWORD_RESET_TITLE]: ["Reset password", "Passwort zurücksetzen"],
    [Keys.VDLANG_PASSWORD_RESET_BUTTON]: ["Send", "Senden"],

    [Keys.VDLANG_PASSWORD_TOGGLE_VISIBILITY]: ["Toggle password visibility", "Sichtbarkeit des Passworts umschalten"],

    [Keys.VDLANG_PASSWORD_RESET_CONFIRM_TITLE]: ["Choose password", "Passwort wählen"],
    [Keys.VDLANG_PASSWORD_RESET_CONFIRM_BUTTON]: ["Send", "Senden"],

    [Keys.VDLANG_PASSWORD_REQUIREMENTS]: [
        "Use 12 or more characters with a mix of letters, numbers and symbols.",
        "12 oder mehr Zeichen mit einer Mischung aus Buchstaben, Ziffern und Symbolen verwenden.",
    ],
    [Keys.VDLANG_PROCESS_COMPLETE_HINT]: [
        "Confirm that the {{name}} is complete and all results are documented. Once the {{name}} has been completed, it cannot be edited further.",
        "Bestätigen Sie, dass die {{name}} vollständig ist und alle Ergebnisse dokumentiert sind. Nach dem Abschließen kann die {{name}} nicht weiter bearbeitet werden.",
    ],
    [Keys.VDLANG_COMPLETION_HINT_PREDECESSORS]: [
        "Previous levels have to be finished first",
        "Die vorherigen Härtegrade müssen abgeschlossen sein",
    ],
    [Keys.VDLANG_COMPLETION_HINT_PREDECESSOR]: ["Level {{gate}} has to be finished first", "Härtegrad {{gate}} muss abgeschlossen sein"],
    [Keys.VDLANG_COMPLETION_HINT_COMPANY_SPLIT]: [
        "Complete the marked {{effectType}} to complete the level.",
        "Vervollständigen Sie die markierten {{effectType}}, um den Härtegrad abzuschließen.",
    ],
    [Keys.VDLANG_COMPLETION_HINT_COMPANY_SPLIT_MULTIPLE]: [
        "Please complete the highlighted Savings resp. Extra Costs.",
        "Bitte vervollständigen Sie die markierten Einsparungen bzw. Mehrkosten.",
    ],

    [Keys.VDLANG_COMPLETION_HINT_ATLEAST_ONE_EFFECT_CATEGORY]: [
        "Please add Savings or Extra Costs to the {{gate}}.",
        "Bitte fügen Sie Einsparungen oder Mehrkosten für die {{gate}} hinzu.",
    ],
    [Keys.VDLANG_COMPLETION_HINT_NO_PERMISSION]: [
        "This level can only be completed with the corresponding authorization",
        "Dieser Härtegrad kann nur mit der entsprechenden Berechtigung abgeschlossen werden",
    ],
    [Keys.VDLANG_COMPLETION_HINT_DECIDER_HAS_NO_PERMISSION]: [
        "The selected decider does not have the appropriate permission",
        "Der ausgewählte Entscheider hat nicht die passende Berechtigung",
    ],
    [Keys.VDLANG_COMPLETION_HINT_MANDATORY_FIELD_NOT_FILLED]: [
        'Please fill the field "{{field}}" in the sidebar.',
        'Bitte füllen Sie das Feld "{{field}}" in der Seitenleiste aus.',
    ],
    [Keys.VDLANG_COMPLETION_HINT_LEVEL_DATES_INCOMPLETE]: [
        "Please select start date and due date for the level {{gate}}.",
        "Bitte wählen Sie Startdatum und Fälligkeit für den Härtegrad {{gate}} aus.",
    ],
    [Keys.VDLANG_COMPLETION_HINT_LEVEL_DATES_START_DATE_BEFORE_PREDECESSOR]: [
        "Start date of the level {{gate}} cannot be before start date of preceding levels.",
        "Das Startdatum des Härtegrads {{gate}} darf nicht vor dem Startdatum vorheriger Härtegrade liegen.",
    ],
    [Keys.VDLANG_COMPLETION_HINT_LEVEL_DATES_DUE_DATE_BEFORE_PREDECESSOR]: [
        "Due date of the level {{gate}} cannot be before due date of preceding levels.",
        "Die Fälligkeit des Härtegrads {{gate}} darf nicht vor der Fälligkeit vorheriger Härtegrade liegen.",
    ],
    [Keys.VDLANG_COMPLETION_HINT_MULTIPLE]: [
        "{{count}} actions required to finish the level.",
        "{{count}} Aktionen erforderlich, um den Härtegrad abzuschließen.",
    ],
    [Keys.VDLANG_COMPLETION_HINT_DIALOG_TITLE]: ["Required actions", "Notwendige Aktionen"],
    [Keys.VDLANG_COMPLETION_HINT_SHOW_DIALOG_LABEL]: ["Show", "Anzeigen"],
    [Keys.VDLANG_COMPLETION_HINT_DECIDER_INVALID]: [
        "You cannot request a decision from this decider because they do not have permission to edit this process.",
        "Sie können keine Entscheidung von diesem Entscheider anfragen, da er keine Berechtigung hat diesen Prozess zu bearbeiten.",
    ],

    [Keys.VDLANG_CALCULATION_GATE_SUMMARY_IGNORED_VALUES_HINT]: [
        "This summary only contains effects that are completely filled out.",
        "Diese Zusammenfassung enthält nur Effekte, die komplett ausgefüllt sind.",
    ],

    [Keys.VDLANG_GATE_TASK_FOOTER_IN_CURRENT_GATE_SINCE_TODAY]: ["Since today in this level", "Seit heute in diesem Härtegrad"],
    [Keys.VDLANG_GATE_TASK_FOOTER_IN_CURRENT_GATE_SINCE_YESTERDAY]: ["Since yesterday in this level", "Seit gestern in diesem Härtegrad"],
    [Keys.VDLANG_GATE_TASK_FOOTER_IN_CURRENT_GATE_FOR]: ["For {{days}} days in this level", "Seit {{days}} Tagen in diesem Härtegrad"],
    [Keys.VDLANG_GATE_TASK_FOOTER_LAST_GATE_HINT]: ["", ""],

    [Keys.VDLANG_DESK_SORT_BY]: ["Sort by", "Sortieren nach"],
    [Keys.VDLANG_DESK_SORT_BY_LAST_MODIFICATION_AT]: ["Last change", "Letzte Änderung"],
    [Keys.VDLANG_DESK_SORT_BY_EFFECT]: ["Potential", "Potenzial"],
    [Keys.VDLANG_DESK_SORT_BY_EFFECTIVENESS_START]: ["Days to effectiveness", "Tage bis zur Wirksamkeit"],
    [Keys.VDLANG_DESK_SORT_BY_CREATED_AT]: ["Creation date", "Erstellungsdatum"],

    [`${Keys.VDLANG_DESK_TILE_DAYS_UNTIL_EFFECTIVENESS_TOOLTIP}`]: [
        "{{count}} days until effectiveness",
        "{{count}} Tage bis zur Wirksamkeit",
    ],
    [`${Keys.VDLANG_DESK_TILE_DAYS_UNTIL_EFFECTIVENESS_TOOLTIP}_one`]: ["One day until effectiveness", "Ein Tag bis zur Wirksamkeit"],
    [`${Keys.VDLANG_DESK_TILE_DAYS_UNTIL_EFFECTIVENESS_TOOLTIP}_zero`]: [
        "Start of Effectiveness is today",
        "Der Beginn der Wirksamkeit ist heute",
    ],
    [`${Keys.VDLANG_DESK_TILE_DAYS_UNTIL_EFFECTIVENESS_TOOLTIP}_negative`]: [
        "Start of Effectiveness was {{count}} days ago",
        "Der Beginn der Wirksamkeit war vor {{count}} Tagen",
    ],
    [`${Keys.VDLANG_DESK_TILE_DAYS_UNTIL_EFFECTIVENESS_TOOLTIP}_negative_one`]: [
        "Start of Effectiveness was yesterday",
        "Der Beginn der Wirksamkeit war gestern",
    ],

    [Keys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_TITLE]: ["Merge clients", "Clients zusammenführen"],
    [Keys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_PRIMARY_CLIENT]: ["First client", "Erster Client"],
    [Keys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_SECONDARY_CLIENT]: ["Second client", "Zweiter Client"],
    [Keys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_VERIFY_CLIENT_NAME]: [
        "Type name of second client to confirm",
        "Zum Bestätigen den Namen des zweiten Clients eingeben",
    ],
    [Keys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_MERGE_BUTTON]: ["Merge", "Zusammenführen"],
    [Keys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_SUCCESS]: ["Client merge successful", "Clients erfolgreich zusammengeführt"],
    [Keys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_INFO]: [
        "The second client will be merged into the first client. This has the following consequences:",
        "Der zweite Client wird mit dem ersten Client zusammengeführt. Dies hat folgende Konsequenzen:",
    ],
    [Keys.VDLANG_SUPER_ADMIN_MERGE_CLIENTS_INFO_ITEMS]: {
        1: ["The second client will be deleted after the merge.", "Der zweite Client wird nach dem Zusammenführen gelöscht."],
        2: [
            "All users of the second client become users of the first client.",
            "Alle Benutzer des zweiten Clients werden zu Benutzern des ersten Clients.",
        ],
        3: [
            "All processes of the second client become processes of the first client. Note that company-wide processes will be visible to all users of both clients.",
            "Alle Prozesse des zweiten Clients werden zu Prozessen des ersten Clients. Beachten Sie, dass unternehmensweite Prozesse für alle Benutzer beider Clients sichtbar sein werden.",
        ],
        4: [
            "All relations of the second client to other clients will be transferred to the first client. When both clients have a relation of the same type to a third client, the relation of the first client will be kept.",
            "Alle Beziehungen des zweiten Clients zu anderen Clients werden auf den ersten Client übertragen. Wenn beide Clients eine Beziehung des gleichen Typs zu einem dritten Client haben, wird die Beziehung des ersten Clients beibehalten.",
        ],
    },
    [Keys.VDLANG_SUPER_ADMIN_PREFILL_GATE_TASKS_LABEL]: [
        "Pre-fill level assignee on process creation",
        "Härtegradverantwortlichen bei Prozesserstellung vorbelegen",
    ],
    [Keys.VDLANG_SUPER_ADMIN_PREFILL_GATE_TASKS_HINT]: [
        "When checked, the creator of a process is automatically assigned to all levels.",
        "Bei Aktivierung wird der Ersteller eines Prozesses automatisch allen Härtegraden zugeordnet.",
    ],

    [Keys.VDLANG_MARKDOWN_EDITOR_VALUE_TOO_LONG]: ["Value is too long and won't be saved", "Text ist zu lang und wird nicht gespeichert"],
    [Keys.VDLANG_MARKDOWN_EDITOR_ERROR_INFO]: [
        "Invalid content or unexpected input has led to an error when displaying the field.",
        "Ungültiger Inhalt oder eine unerwartete Eingabe hat zu einem Fehler bei der Anzeige des Feldes geführt.",
    ],

    [Keys.VDLANG_CURRENCY_INPUT_DIGIT_LIMIT]: [
        "The value cannot have more than 15 digits and 4 fractional digits",
        "Der Wert kann nicht mehr als 15 Stellen und 4 Nachkommastellen haben",
    ],
    [Keys.VDLANG_EMAIL_REMINDER]: ["Email reminder", "Email Erinnerung"],
    [Keys.VDLANG_REMINDER_IN_PAST]: ["Date is in the past", "Datum liegt in der Vergangenheit"],
    [Keys.VDLANG_REMINDER_HINT]: [
        "Select the date & time when an email reminder should be sent.",
        "Wählen Sie Datum & Uhrzeit an der eine Email-Erinnerung erfolgen soll.",
    ],

    [Keys.VDLANG_DATEPICKER_TOO_LATE]: ["Start date may not be greater than due date", "Startdatum darf nicht nach der Fälligkeit liegen"],
    [Keys.VDLANG_DATEPICKER_TOO_EARLY]: ["Due date may not be smaller than start date", "Fälligkeit darf nicht vor dem Startdatum liegen"],

    [Keys.VDLANG_CURRENCY_INPUT_INVALID_FORMULA]: [
        "The result of the provided formula is outside the range of valid values.",
        "Das Ergebnis der angegebenen Formel liegt außerhalb des gültigen Wertebereichs.",
    ],
    [Keys.VDLANG_CURRENCY_INPUT_NEGATIVE_NUMBER]: [
        "{{effectType}} must be entered with a positive sign so that they are considered as such in the calculation.",
        "{{effectType}} sind mit positivem Vorzeichen einzugeben, damit sie bei der Berechnung als solche berücksichtigt werden.",
    ],

    [Keys.VDLANG_FORMULA_INPUT_INVALID_FORMULA]: [
        "The formula is invalid. Please check the syntax.",
        "Die Formel ist ungültig. Bitte überprüfen Sie die Syntax.",
    ],

    [Keys.VDLANG_MENU]: ["Menu", "Menü"],
    [Keys.VDLANG_CONTEXT_MENU]: ["Context menu", "Kontextmenü"],
    [Keys.VDLANG_BREADCRUMB]: ["You are here: ", "Sie sind hier: "],

    [Keys.VDLANG_PROCESS_ABBREVIATION]: ["{{count}} P.", "{{count}} P."],

    [Keys.VDLANG_NUMBER_MAGNITUDE_TRILLION]: ["T", "Bio."],
    [Keys.VDLANG_NUMBER_MAGNITUDE_BILLION]: ["B", "Mrd."],
    [Keys.VDLANG_NUMBER_MAGNITUDE_MILLION]: ["M", "Mio."],
    [Keys.VDLANG_NUMBER_MAGNITUDE_THOUSAND]: ["K", "Tsd."],

    [Keys.VDLANG_DESK_GRID_SEARCH]: ["Search", "Suche"],
    [Keys.VDLANG_GRID_CONFIG_TITLE_HINT]: ["The title column cannot be removed.", "Die Titel-Spalte kann nicht entfernt werden."],

    [Keys.VDLANG_GRID_TOTAL_CONVERSION_RATE_TOOLTIP]: [
        "Percentage of displayed processes that reach the last level",
        "Prozentsatz der angezeigten Prozesse, die den letzten Härtegrad erreichen",
    ],
    [Keys.VDLANG_GRID_CONVERSION_RATE_TOOLTIP]: [
        "Percentage of displayed processes that reach the following level",
        "Prozentsatz der angezeigten Prozesse, die den folgenden Härtegrad erreichen",
    ],
    [Keys.VDLANG_ADDITIONAL_INFORMATION]: ["Additional Information", "Zusätzliche Anzeige"],
    [Keys.VDLANG_SWITCH_LANGUAGE]: ["Language & Region", "Sprache & Region"],

    [Keys.VDLANG_DECISION_COMPLETED_POSITIVE]: [
        "{{decider}} has approved the {{processName}} on {{date}} at {{time}}.",
        "{{decider}} hat die {{processName}} am {{date}} um {{time}} Uhr freigegeben.",
    ],
    [Keys.VDLANG_DECISION_COMPLETED_NEGATIVE]: [
        "{{decider}} has discarded the {{processName}} on {{date}} at {{time}}.",
        "{{decider}} hat die {{processName}} am {{date}} um {{time}} Uhr verworfen.",
    ],
    [Keys.VDLANG_DECISION_COMPLETED_POSITIVE_DOCUMENTED]: [
        "{{completedBy}} has documented that {{decider}} has approved the {{processName}} on {{date}} at {{time}}.",
        "{{completedBy}} hat dokumentiert, dass {{decider}} die {{processName}} am {{date}} um {{time}} freigegeben hat.",
    ],
    [Keys.VDLANG_DECISION_COMPLETED_NEGATIVE_DOCUMENTED]: [
        "{{completedBy}} has documented that {{decider}} has discarded the {{processName}} on {{date}} at {{time}}.",
        "{{completedBy}} hat dokumentiert, dass {{decider}} die {{processName}} am {{date}} um {{time}} verworfen hat.",
    ],
    [Keys.VDLANG_DECISION_COMPLETED_POSITIVE_REQUESTED]: [
        "On request by {{requester}}, {{decider}} has approved the {{processName}} on {{date}} at {{time}}.",
        "Auf Anfrage von {{requester}} hat {{decider}} die {{processName}} am {{date}} um {{time}} freigegeben.",
    ],
    [Keys.VDLANG_DECISION_COMPLETED_NEGATIVE_REQUESTED]: [
        "On request by {{requester}}, {{decider}} has discarded the {{processName}} on {{date}} at {{time}}.",
        "Auf Anfrage von {{requester}} hat {{decider}} die {{processName}} am {{date}} um {{time}} verworfen.",
    ],
    [Keys.VDLANG_DECISION_COMPLETED_COMMENT]: ["Decision comment", "Kommentar zur Entscheidung"],
    [Keys.VDLANG_DECISION_COMPLETED_SHOW_COMMENT]: ["Show comment", "Kommentar anzeigen"],
    [Keys.VDLANG_DECISION_EMPTY_DECIDER]: ["Please select", "Bitte auswählen"],

    [Keys.VDLANG_MEASURE_TABS_PROCESS]: ["Workflow", "Abfolge"],
    [Keys.VDLANG_MEASURE_TABS_CALCULATION]: ["Calculation", "Berechnung"],
    [Keys.VDLANG_MEASURE_TABS_ACTIVITIES]: ["Activity list", "Aktivitätenliste"],
    [Keys.VDLANG_MEASURE_TABS_ADD_ACTIVITY]: ["Add activity", "Aktivität hinzufügen"],
    [Keys.VDLANG_MEASURE_TABS_COMMENTS]: ["Comments", "Kommentare"],
    [Keys.VDLANG_MEASURE_TABS_DOCUMENTS]: ["Documents", "Dokumente"],
    [Keys.VDLANG_MEASURE_TABS_HISTORY]: ["History", "Historie"],
    [Keys.VDLANG_MEASURE_TABS_FEED_HISTORY]: ["News history", "Neuigkeiten Historie"],
    [Keys.VDLANG_MEASURE_TABS_SUGGESTIONS]: ["Suggestions", "Vorschläge"],

    [Keys.VDLANG_ACTIVITY_GATE_LIST_TILE]: ["Activity Overview", "Aktivitätenübersicht"],
    [Keys.VDLANG_ACTIVITY_GATE_HEADER]: ["Activities in this level", "Aktivitäten in diesem Härtegrad"],
    [Keys.VDLANG_ACTIVITY_GATE_LIST_ORDER_ASCENDING]: ["Duedate ascending", "Fälligkeit aufsteigend"],
    [Keys.VDLANG_ACTIVITY_GATE_LIST_ORDER_DESCENDING]: ["Duedate descending", "Fälligkeit absteigend"],
    [Keys.VDLANG_ACTIVITY_GATE_LIST_LINK]: ["Show all", "Alle anzeigen"],
    [Keys.VDLANG_ACTIVITY_GATE_LIST_EMPTY_MESSAGE]: [
        "No open activities available in this level.",
        "Keine offenen Aktivitäten in diesem Härtegrad vorhanden.",
    ],
    [Keys.VDLANG_ACTIVITY_CHART]: ["Activities", "Aktivitäten"],

    [Keys.VDLANG_EFFECT_CATEGORY_GATE_TOTAL_EFFECT]: ["Total Potential", "Gesamtes Potenzial"],
    [Keys.VDLANG_EFFECT_CATEGORY_GATE_HEADER_EFFECT]: ["Effect", "Effekt"],
    [Keys.VDLANG_EFFECT_CATEGORY_GATE_HEADER_EFFECT_TYPE]: ["Effect Type", "Effekttyp"],
    [Keys.VDLANG_EFFECT_CATEGORY_GATE_HEADER_TIMERANGE]: ["Effectiveness of the financial effect", "Wirksamkeit des Finanzeffekts"],
    [Keys.VDLANG_EFFECT_CATEGORY_GATE_ADD_BUTTON_DISABLED_HINT]: [
        "All possible combinations have already been created. Edit or remove the already existing {{effectType}}",
        "Es wurden bereits alle möglichen Kombinationen angelegt. Bearbeiten oder entfernen Sie die bereits vorhandenen {{effectType}}.",
    ],

    [Keys.VDLANG_EFFECT_CATEGORY_MODAL_REMOVE_FIELD]: ["Remove", "Entfernen"],
    [Keys.VDLANG_EFFECT_CATEGORY_MODAL_ADDITIONAL_FIELDS]: ["Additional fields", "Zusätzliche Felder"],
    [Keys.VDLANG_EFFECT_CATEGORY_MODAL_NO_ADDITIONAL_FIELDS]: ["No additional fields available", "Keine zusätzlichen Felder verfügbar"],
    [Keys.VDLANG_EFFECT_CATEGORY_MODAL_HAS_INITIAL]: ["Initial Value", "Ausgangswert"],
    [Keys.VDLANG_EFFECT_CATEGORY_MODAL_HAS_INITIAL_HINT]: [
        "If possible, it is recommended to always enter an initial value so that the system can calculate the {{effectType}} in %. If this is not possible, you can only specify the {{effectType}}.",
        "Empfehlung: Hinterlegen Sie, wenn möglich, einen Ausgangswert, damit das System die {{effectType}} in % berechnen kann. Wenn dies nicht möglich ist, können Sie nur die {{effectType}} angeben.",
    ],
    [Keys.VDLANG_EFFECT_CATEGORY_MODAL_CURRENCY_SELECT_LABEL]: ["Currency", "Währung"],
    [Keys.VDLANG_CURRENCY_INPUT_INVALID_NUMBER_RANGE]: [
        "The result of the effect is outside the value range.",
        "Das Ergebnis des Effekts liegt außerhalb des Wertebereichs.",
    ],
    [Keys.VDLANG_EFFECT_CATEGORY_MODAL_EC_ALREADY_EXISTS]: [
        "{{effectType}} configuration already exists",
        "{{effectType}}-Konfiguration existiert bereits",
    ],

    [Keys.VDLANG_UPLOAD_SELECT_DOCUMENT]: ["Select a document", "Dokument auswählen"],
    [Keys.VDLANG_UPLOAD_SELECT_DOCUMENT_PHOTO]: ["Select a document or photo", "Dokument oder Foto auswählen"],
    [Keys.VDLANG_UPLOAD_DRAGDROP_DOCUMENT]: [
        "Drag & Drop here or select a document or photo",
        "Drag & Drop hier oder Dokument oder Foto auswählen",
    ],
    [Keys.VDLANG_UPLOAD_SELECT_IMAGE]: ["Select an photo", "Foto auswählen"],

    [Keys.VDLANG_PROCESS_TOOLTIPS_FURTHER_OPTIONS]: ["Further options", "Weitere Optionen"],
    [Keys.VDLANG_PROCESS_TOOLTIPS_FULLSCREEN]: ["Fullscreen", "Vollbild"],
    [Keys.VDLANG_PROCESS_TOOLTIPS_CLOSE_FULLSCREEN]: ["Close fullscreen", "Vollbild schließen"],

    [Keys.VDLANG_PROCESS_TOOLTIPS_ADD_FIELD_OPTION]: ["Add {{name}}", "{{name}} hinzufügen"],

    [Keys.VDLANG_PROCESS_CHANGE_CURRENCY_HINT]: [
        "If you change the currency, the amounts will not be converted to the new currency, only the currency symbol will be changed.",
        "Wenn Sie die Währung ändern, werden die Beträge nicht in die neue Währung umgerechnet, sondern es wird lediglich das Währungssymbol geändert.",
    ],

    [Keys.VDLANG_EFFECT_FIELD_LABEL_COLUMN_HEADER]: ["Value", "Wert"],

    [Keys.VDLANG_NEW_VALUEDESK_VERSION_HINT]: [
        "We have updated the software in the background. To ensure that the changes are applied, we have reloaded the page. Thank you for your understanding.",
        "Wir haben die Software im Hintergrund aktualisiert. Damit die Änderungen auch angewendet werden können, wurde die Seite neu geladen. Wir bedanken uns für Ihr Verständnis.",
    ],

    [Keys.VDLANG_MEASURE_CALCULATION_GRANULARITY]: {
        [MeasureCalculationGranularity.MONTH]: ["M", "M"],
        [MeasureCalculationGranularity.FISCAL_QUARTER]: ["FQ", "GQ"],
        [MeasureCalculationGranularity.FISCAL_YEAR]: ["FY", "GJ"],
    },
    [Keys.VDLANG_MEASURE_CALCULATION_GRANULARITY_TOOLTIP]: {
        [MeasureCalculationGranularity.MONTH]: ["Show effects by month", "Effekte nach Monaten aufschlüsseln"],
        [MeasureCalculationGranularity.FISCAL_QUARTER]: ["Show effects by fiscal quarter", "Effekte Nach Geschäftsquartalen aufschlüsseln"],
        [MeasureCalculationGranularity.FISCAL_YEAR]: ["Show effects by fiscal year", "Effekte Nach Geschäftsjahren aufschlüsseln"],
    },

    [Keys.VDLANG_MEASURE_CALCULATION_GRANULARITY_PLACEHOLDER]: {
        [MeasureCalculationGranularity.MONTH]: ["MM.YY", "MM.JJ"],
        [MeasureCalculationGranularity.FISCAL_QUARTER]: ["FQ", "GQ"],
        [MeasureCalculationGranularity.FISCAL_YEAR]: ["FY", "GJ"],
    },

    [Keys.VDLANG_MEASURE_CALCULATION_TOTAL_COLUMN_HEADER]: ["Total", "Gesamt"],
    [Keys.VDLANG_MEASURE_CALCULATION_TABLE_NO_DATA]: [
        "Please select a time period above so that effects can be entered.",
        "Bitte oben einen Zeitraum auswählen, damit Effekte eingeben werden können.",
    ],
    [Keys.VDLANG_MEASURE_CALCULATION_TABLE_CURRENCY]: ["Values in {{currencyCode}}", "Werte in {{currencyCode}}"],

    [Keys.VDLANG_NO_CALCULATION_DATA]: ["No values have been entered yet.", "Es sind noch keine Werte hinterlegt worden."],

    [Keys.VDLANG_APPLY]: ["Apply", "Übernehmen"],
    [Keys.VDLANG_LEVEL_POPOVER_WARNING]: [
        "Note that the values will be permanently deleted when you remove them.",
        "Beachten Sie, dass die Werte beim Entfernen permanent gelöscht werden.",
    ],
    [Keys.VDLANG_LEVEL_POPOVER_HEADING]: ["Add/Remove columns", "Spalten hinzufügen/entfernen"],

    tableName: {
        // used by e.g. VDLANG_PROCESS_TOOLTIPS_ADD_FIELD_OPTION
        suppliers: ["partners", "Partner"],
        projects: ["tag", "Schlagwort"],
    },

    [Keys.VDLANG_CALCULATION_IDENTIFIER]: {
        [GlobalCalculationIdentifier.Plan]: ["PLAN", "PLAN"],
        [GlobalCalculationIdentifier.Forecast]: ["FC", "FC"],
        [GlobalCalculationIdentifier.Actual]: ["ACT", "ACT"],
    },

    [Keys.VDLANG_LEVEL_HEADER_NONLINEAR_HINT]: ["Nonlinear", "Nichtlinear"],

    [Keys.VDLANG_FILTER_FILTERS]: ["Filters", "Filter"],
    [Keys.VDLANG_FILTER_EDIT_FILTER]: ["Edit Filter", "Filter bearbeiten"],
    [Keys.VDLANG_FILTER_CUSTOM_FILTER]: ["Custom Filter", "Eigener Filter"],
    [Keys.VDLANG_FILTER_COMPLETED_FILTER]: ["Completed", "Abgeschlossen"],
    [`${Keys.VDLANG_FILTER_APPLIED_ACROSS_VIEWS_HINT}_one`]: [
        "Changes to these filter settings affect the following view: <strong>{{views}}</strong>.",
        "Änderungen an diesen Filtereinstellungen wirken sich auf folgende Ansicht aus: <strong>{{views}}</strong>.",
    ],
    [`${Keys.VDLANG_FILTER_APPLIED_ACROSS_VIEWS_HINT}_other`]: [
        "Changes to these filter settings affect the following views: <strong>{{views}}</strong>.",
        "Änderungen an diesen Filtereinstellungen wirken sich auf folgende Ansichten aus: <strong>{{views}}</strong>.",
    ],
    [Keys.VDLANG_FILTER_DIALOG_SAVE]: ["Save", "Speichern"],
    [Keys.VDLANG_FILTER_DIALOG_SAVE_AS]: ["Save as", "Speichern unter"],
    [Keys.VDLANG_FILTER_DIALOG_APPLY]: ["Apply", "Anwenden"],
    [Keys.VDLANG_FILTER_DIALOG_REMOVE]: ["Remove", "Entfernen"],
    [Keys.VDLANG_FILTER_DIALOG_FILTER_IN_USE]: [
        "This filter is used in at least one other view and therefore it cannot be deleted.",
        "Dieser Filter wird in mindestens einer weiteren Ansicht verwendet und kann daher nicht gelöscht werden.",
    ],
    [Keys.VDLANG_FILTER_DIALOG_FILTER_NAME_EXISTS]: ["This filter name is already in use", "Dieser Filtername wird bereits verwendet"],

    [Keys.VDLANG_FILTER_FORM_NO_CONDITION]: ["No filter condition", "Keine Filterbedingung"],
    [Keys.VDLANG_FILTER_FORM_ADD_CONDITION]: ["Add condition", "Bedingung hinzufügen"],

    [Keys.VDLANG_TARGETS_FORM_NO_TARGETS]: ["No targets", "Keine Ziele"],
    [Keys.VDLANG_TARGETS_FORM_ADD_TARGET]: ["Add target", "Ziel hinzufügen"],

    [Keys.VDLANG_TREE_NODE_DELETE_NO_REPLACE]: ["Do not replace", "Nicht ersetzen"],
    [Keys.VDLANG_TREE_NODE_DELETE_NO_REPLACE_DESCRIPTION]: [
        "Deletes the node if it is not in use. If the node is being used somewhere, the deletion process is canceled and an error message is displayed.",
        "Löscht den Knoten, sofern er nicht in Gebrauch ist. Sollte der Knoten irgendwo verwendet werden, wird der Löschvorgang abgebrochen und eine Fehlermeldung angezeigt.",
    ],
    [Keys.VDLANG_TREE_NODE_DELETE_REMOVE_USAGE]: ["Remove usage", "Verwendung entfernen"],
    [Keys.VDLANG_TREE_NODE_DELETE_REMOVE_USAGE_DESCRIPTION]: [
        'With this option, all fields in which this node is used are reset to "no selection". If the node is used in effect categories, the deletion process is canceled and an error message is displayed.',
        'Bei dieser Option werden alle Felder, in denen dieser Knoten verwendet wird, auf "keine Auswahl" zurückgesetzt. Wird der Knoten in Effektkategorien verwendet, wird der Löschvorgang abgebrochen und eine Fehlermeldung angezeigt.',
    ],
    [Keys.VDLANG_TREE_NODE_DELETE_REPLACE]: ["Replace", "Ersetzen"],
    [Keys.VDLANG_TREE_NODE_DELETE_REPLACE_DESCRIPTION]: [
        "Replaces the node with a newly selected node. All old values in processes, opportunities and effect categories are replaced with the new node.",
        "Ersetzt den Knoten mit einem neu ausgewählten Knoten. Alle alten Werte in Prozessen, Opportunitäten und Effektkategorien werden mit dem neuen Knoten ersetzt.",
    ],
    [Keys.VDLANG_TREE_NODE_IMPORT]: ["Import tree nodes", "Baumknoten importieren"],
    [Keys.VDLANG_TREE_NODE_NO_ATTRIBUTE]: [
        "Currently, you have not created any tree structures.",
        "Derzeit haben Sie keine Baumstrukturen angelegt.",
    ],
    [Keys.VDLANG_TREE_NODE_EMPTY]: ["The selected tree structure has no nodes yet.", "Die ausgewählte Baumstruktur hat noch keine Knoten."],
    [Keys.VDLANG_TREE_NODE_MOVE_TO_ROOT]: ["Convert into root node", "Zu Wurzelknoten machen"],
    [Keys.VDLANG_TREE_NODE_MOVE_DESCRIPTION]: [
        'Select a node to which "{{nodeName}}" should be moved.',
        'Wählen Sie einen Knoten, zu dem "{{nodeName}}" verschoben werden soll.',
    ],
    [Keys.VDLANG_TREE_NODE_PARENT]: ["Node parent", "Elternknoten"],
    [Keys.VDLANG_TREE_NODE_NEW_PARENT]: ["New node parent", "Neuer Elternknoten"],
    [Keys.VDLANG_TREE_NODE_NO_PARENT_SELECTED]: [
        "No new parent selected (this will create a root node)",
        "Kein neuer Elternknoten ausgewählt (dadurch wird ein Wurzelknoten erstellt)",
    ],
    [Keys.VDLANG_TREE_NODE_ADD_NODE]: ["Add node", "Knoten hinzufügen"],
    [Keys.VDLANG_TREE_NODE_MOVE_NODE]: ["Move node", "Knoten verschieben"],
    [Keys.VDLANG_TREE_NODE_EDIT_NODE]: ["Edit", "Bearbeiten"],
    [Keys.VDLANG_TREE_NODE_EDIT_SELECTABLE_LABEL]: ["Node can be selected", "Knoten kann ausgewählt werden"],
    [Keys.VDLANG_TREE_NODE_EDIT_SELECTABLE_HINT]: [
        "Activated = The node can be selected by users in fields that use a tree structure.<br /><br />Deactivated = The node is displayed, but cannot be selected as the final node, only a higher or lower level.",
        "Aktiviert = Der Knoten kann in Feldern, die auf eine Baumstruktur zurückgreifen, von den Benutzern ausgewählt werden.<br /><br />Deaktiviert = Der Knoten wird angezeigt, kann aber nicht als finaler Knoten ausgewählt werden, sondern nur eine höhere oder niedrigere Ebene.",
    ],
    [Keys.VDLANG_TREE_SELECT_NODE_HINT]: ["Please select a node.", "Bitte wählen Sie einen Knoten."],
    [Keys.VDLANG_TREE_MULTI_SELECT_NODE_HINT]: ["Please select one or more nodes.", "Bitte wählen Sie einen oder mehrere Knoten."],
    [Keys.VDLANG_TREE_NODE_NOT_SELECTABLE]: ["Node cannot be selected.", "Knoten kann nicht ausgewählt werden."],
    [Keys.VDLANG_TREE_NODE_CONTEXT_MENU_TITLE]: ["Edit node", "Knoten bearbeiten"],
    [Keys.VDLANG_TREE_NODE_CONTEXT_MENU_HINT]: [
        'With "Adjust order" you can change the order of nodes within a parent node.<br /><br />If you want to move a node to another level, please use the "Move node" function. That way you can choose a new parent node.',
        'Mit "Sortierung anpassen" können Sie die Sortierung von Baumknoten innerhalb des Elternknotens anpassen.<br /><br />Wenn Sie einen Knoten auf eine andere Ebene verschieben wollen, nutzen Sie bitte die "Knoten verschieben" Funktion. So können Sie einen neuen Elternknoten wählen.',
    ],
    [Keys.VDLANG_TREE_NODE_IMPORT_TEXT]: [
        "Do you really want to import new tree nodes for {{clientName}}?",
        "Wollen Sie wirklich neue Baumknoten für {{clientName}} importieren?",
    ],
    [Keys.VDLANG_SUPPLIERS_IMPORT_TEXT]: [
        "Do you really want to import new partners for {{clientName}}?",
        "Wollen Sie wirklich neue Partner für {{clientName}} importieren?",
    ],
    [Keys.VDLANG_SUPERADMIN_SUPPLIERS_SUCCESS]: ["Partner upload successful", "Partner upload erfolgreich"],
    [Keys.VDLANG_TREE_NODE_ADJUST_ORDER]: ["Adjust order", "Sortierung anpassen"],
    [Keys.VDLANG_TREE_NODE_ADJUST_CHILD_ORDER_DESCRIPTION]: [
        'Adjust order within parent node <strong>"{{parentNode}}"</strong>.',
        'Passen Sie die Sortierung in dem Elternknoten <strong>"{{parentNode}}"</strong> an.',
    ],
    [Keys.VDLANG_TREE_NODE_ADJUST_ROOT_ORDER_DESCRIPTION]: ["Adjust order of root nodes.", "Sortierung der Wurzelknoten anpassen."],
    [Keys.VDLANG_IMPORT]: ["Import", "Importieren"],
    [Keys.VDLANG_MEASURES_SIDEBAR_SCOPE]: ["Scope", "Auswahlbereich"],

    [Keys.VDLANG_PRIVACY_POLICY]: ["Privacy Policy", "Datenschutzerklärung"],

    [Keys.VDLANG_MEASURE_PREVIEW_TOTAL_EFFECT]: ["Total Potential", "Gesamtes Potenzial"],
    [Keys.VDLANG_MEASURE_PREVIEW_NO_DESCRIPTION]: ["No description", "Keine Beschreibung"],
    [Keys.VDLANG_MEASURE_PREVIEW_NO_EFFECT_CATEGORIES]: ["No Effect Categories added yet", "Keine Effekt-Kategorien angelegt"],
    [Keys.VDLANG_MEASURE_PREVIEW_NOT_FILLED_YET]: ["Incompletely filled", "Unvollständig ausgefüllt"],

    [Keys.VDLANG_SAVE_VIEW]: ["Save view", "Ansicht speichern"],
    [Keys.VDLANG_RENAME_VIEW]: ["Rename view", "Ansicht umbenennen"],
    [Keys.VDLANG_SEARCH_CONFIGS_STANDARD_NAME]: ["Standard", "Standard"],
    [Keys.VDLANG_SEARCH_CONFIGS_REMOVE]: ["Remove view", "Ansicht entfernen"],
    [Keys.VDLANG_SEARCH_CONFIGS_ADD]: ["Add view", "Ansicht hinzufügen"],
    [Keys.VDLANG_SEARCH_CONFIGS_ADD_HINT]: [
        "The currently selected settings will be saved in the new view.",
        "Die aktuell ausgewählten Einstellungen werden in der neuen Ansicht gespeichert.",
    ],
    [Keys.VDLANG_SEARCH_CONFIGS_REMOVE_TITLE]: ['Remove view "{{name}}"?', 'Ansicht "{{name}}" entfernen?'],
    [Keys.VDLANG_SEARCH_CONFIGS_COMPANY_WIDE_EDITING_DISABLED_HINT]: [
        "This view is available for everyone in the organization and therefore it cannot be edited.",
        "Diese Ansicht ist für alle im Unternehmen verfügbar und kann daher nicht bearbeitet werden.",
    ],
    [Keys.VDLANG_SEARCH_CONFIGS_TITLE_TOO_LONG_HINT]: ["Title is too long", "Titel ist zu lang"],

    [Keys.VDLANG_DASHBOARDS_ADD_WIDGET_BUTTON_LABEL]: ["Add widget", "Widget hinzufügen"],
    [Keys.VDLANG_DASHBOARD_EMPTY_WIDGET]: ["No widgets added yet", "Es wurden noch keine Widgets hinzugefügt"],
    [Keys.VDLANG_DASHBOARDS_REMOVE_WIDGET_MENU_ITEM_LABEL]: ["Remove widget", "Widget entfernen"],
    [Keys.VDLANG_DASHBOARDS_REMOVE_WIDGET_CONFIRM_DIALOG_TITLE]: ['Remove widget "{{name}}"?', 'Widget "{{name}}" entfernen?"'],
    [Keys.VDLANG_DASHBOARDS_WIDGET_CONFIGURATION_DIALOG_TITLE]: ["Widget configuration", "Widget-Einstellungen"],
    [Keys.VDLANG_DASHBOARDS_DUPLICATE_WIDGET_MENU_ITEM_LABEL]: ["Duplicate widget", "Widget duplizieren"],
    [Keys.VDLANG_DASHBOARDS_DUPLICATE_WIDGET_BUTTON_LABEL]: ["Show widget", "Widget anzeigen"],
    [Keys.VDLANG_DASHBOARDS_PERMISSIONS_ACCEPT_BUTTON_LABEL]: ["Understood", "Verstanden"],
    [Keys.VDLANG_DASHBOARDS_NO_DASHBOARD]: ["No dashboard selected", "Kein Dashboard ausgewählt"],
    [Keys.VDLANG_DASHBOARDS_NO_DASHBOARD_FOUND]: ["No dashboard found", "Kein Dashboard gefunden"],
    [Keys.VDLANG_DASHBOARDS_MY_CREATED]: ["My created dashboards", "Meine erstellen Dashboards"],
    [Keys.VDLANG_DASHBOARDS_OTHERS]: ["Other Dashboards", "Andere Dashboards"],
    [Keys.VDLANG_DASHBOARDS_LAST_VIEWED]: ["Last viewed", "Zuletzt aufgerufen"],
    [Keys.VDLANG_DASHBOARDS_WIDGET_CONFIG_DISABLED_HINT]: [
        "This widget is part of a dashboard created by {{displayname}}. Only {{displayname}} is allowed to edit it.",
        "Dieses Widget gehört zu einem Dashboard, das von {{displayname}} erstellt wurde. Nur {{displayname}} darf es bearbeiten.",
    ],
    [Keys.VDLANG_DASHBOARDS_CREATE]: ["Create dashboard", "Dashboard erstellen"],
    [Keys.VDLANG_DASHBOARDS_CREATE_NAME]: ["Name", "Name"],
    [Keys.VDLANG_DASHBOARDS_CREATE_NAME_ERROR]: [
        "Name must contain at least one character",
        "Name muss mindestens einen Buchstaben enthalten",
    ],
    [Keys.VDLANG_DASHBOARDS_CREATE_NAME_LENGTH_ERROR]: ["Name is too long", "Name ist zu lang"],

    [Keys.VDLANG_DASHBOARDS_CREATE_BUTTON]: ["Create", "Erstellen"],
    [Keys.VDLANG_DASHBOARDS_UPDATE]: ["Rename dashboard", "Dashboard umbenennen"],
    [Keys.VDLANG_DASHBOARDS_UPDATE_NAME]: ["Name", "Name"],
    [Keys.VDLANG_DASHBOARDS_UPDATE_NAME_ERROR]: [
        "Name must contain at least one character",
        "Name muss mindestens einen Buchstaben enthalten",
    ],
    [Keys.VDLANG_DASHBOARDS_UPDATE_NAME_TOO_LONG]: ["Name is too long", "Name ist zu lang"],
    [Keys.VDLANG_DASHBOARDS_UPDATE_BUTTON]: ["Save", "Speichern"],
    [Keys.VDLANG_DASHBOARDS_CONTEXT_MENU]: ["Dashboard context menu", "Dashboard Kontextmenü"],
    [Keys.VDLANG_DASHBOARDS_CONTEXT_UPDATE]: ["Rename", "Umbenennen"],
    [Keys.VDLANG_DASHBOARDS_CONTEXT_DELETE]: ["Delete", "Löschen"],
    [Keys.VDLANG_DASHBOARDS_CONTEXT_DELETE_DISABLED_HINT]: [
        "You cannot delete this dashboard because you need to have at least one personal dashboard.",
        "Sie können dieses Dashboard nicht löschen, da Sie mindestens ein eigenes Dashboard haben müssen.",
    ],
    [Keys.VDLANG_DASHBOARDS_DELETE_TITLE]: ['Delete dashboard "{{dashboard}}"?', 'Dashboard "{{dashboard}}" löschen?'],

    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_GENERAL_SETTINGS]: ["General settings", "Allgemeine Einstellungen"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_CONFIG_TAB_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_FILTER_CONFIGURATION]: ["Filter", "Filter"],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_FORECAST_CONFIGURATION]: ["Forecast", "Prognose"],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_TARGET_SUMMARY]: ["Target: {{value}}", "Ziel: {{value}}"],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_FORECAST_HINT]: [
        "With the forecast you can display the expected savings for the next months. The expected savings are the sum of all potentials for the current fiscal year which have their begin of effectiveness in the regarding month. With the percentage the effects can weighted for each level.",
        "Mit der Prognose können Sie sich die erwarteten Einsparungen für die nächsten Monate anzeigen lassen. Die erwarteten Einsparungen sind die Summe aller Potenziale für das derzeitige Geschäftsjahr welche Ihren Wirksamkeitsbeginn im entsprechenden Monat haben. Mit dem Prozentsatz kann die Gewichtung der einzelnen Werte für jeden Härtegrad bestimmt werden.",
    ],
    [Keys.VDLANG_DASHBOARDS_DUPLICATE]: ["Duplicate dashboard", "Dashboard duplizieren"],
    [Keys.VDLANG_DASHBOARDS_DUPLICATE_LABEL]: ["Duplicate of {{dashboard}}", "Duplikat von {{dashboard}}"],
    [Keys.VDLANG_DASHBOARDS_WIDGET_UPDATED_AT]: ["Last updated on {{date}}", "Zuletzt bearbeitet am {{date}}"],
    [Keys.VDLANG_DASHBOARDS_WIDGET_DESCRIPTION_PLACEHOLDER]: [
        "Describe what this widget is about....",
        "Beschreiben Sie, worum es bei diesem Widget geht....",
    ],
    [Keys.VDLANG_DASHBOARDS_DIFFERENCE_TO_TARGET_LABEL]: ["Difference to target", "Differenz zum Ziel"],
    [Keys.VDLANG_DASHBOARDS_TARGET_LABEL]: ["Target", "Ziel"],
    [Keys.VDLANG_DASHBOARDS_DASHBOARD_OWNER]: ["Dashboard owner", "Besitzer des Dashboards"],

    [Keys.VDLANG_INVITE_USER_INVALID_EMAIL]: ["Not a valid email address", "Dies ist keine valide E-Mail Adresse"],
    [Keys.VDLANG_INVITE_USER_INVALID_DOMAIN]: [
        "The email address cannot be used for invitation because it does not match the company domain.",
        "Die Email Adresse kann nicht zu Einladung verwendet werden weil sie nicht mit der Unternehmensdomain übereinstimmt.",
    ],
    [Keys.VDLANG_INVITE_USER_ALREADY_EXISTS]: ["Email address already in use", "E-Mail Adresse bereits in Benutzung"],
    [Keys.VLDANG_INVITE_NO_USER_RESULTS]: [
        "No users found. Enter an email address to invite new users.",
        "Keine Benutzer gefunden. Geben Sie eine Email Adresse ein, um eine neue Person einzuladen.",
    ],

    [Keys.VDLANG_ENTITY_ACTION_COPY]: ["Copy {{entityName}}", "{{entityName}} kopieren"],
    [Keys.VDLANG_ENTITY_ACTION_COPY_OPTION]: {
        measure: {
            attributes: ["Sidebar fields", "Seitenleisten-Felder"],
            activities: ["Activities", "Aktivitäten"],
        },
    },
    [Keys.VDLANG_ENTITY_ACTION_COPY_OPTIONS_LEGEND]: ["What information should be copied?", "Welche Informationen sollen kopiert werden?"],
    [Keys.VDLANG_ENTITY_ACTION_COPY_TITLE_LEGEND]: ["Title", "Titel"],
    [Keys.VDLANG_ENTITY_ACTION_COPY_HINT]: [
        "Please note that copying calculations is currently not supported.",
        "Bitte beachten Sie, dass das Kopieren von Berechnungen derzeit nicht unterstützt wird.",
    ],
    [Keys.VDLANG_ENTITY_ACTION_COPY_TITLE_PLACEHOLDER]: ["Copy of {{title}}", "Kopie von {{title}}"],

    [Keys.VDLANG_ATTRIBUTE_CATEGORY_ERROR_FIRST_ITEM_NOT_A_CATEGORY]: [
        "The first element must be a category.",
        "Der erste Eintrag muss eine Kategorie sein.",
    ],

    count: ["Count", "Anzahl"],
    effectType: ["Effect Type", "Effekttyp"],
    create_partner: ["Create partner", "Partner anlegen"],
    effectiveness_start: ["Start of Effectiveness of the Financial Effect", "Beginn der Wirksamkeit des Finanzeffekts"],
    effectiveness_end: ["End of Effectiveness of the Financial Effect", "Ende der Wirksamkeit des Finanzeffekts"],
    "product measure": ["OMV Other Material Variance", "AMV Andere Material Veränderung"],
    "purchasing measure": ["PPV Purchase Price Variance", "EPV Einkaufs Preis Veränderung"],
    "customer specific method": ["Company Specific Methods", "Unternehmensspezifische Methoden"],
    material_reference: ["Material Reference", "Materialreferenz"],
    measureViews: ["Number of views", "Anzahl der Aufrufe"],
    idea: ["Idea", "Idee"],
    calculation: ["Qualification", "Qualifizierung"],
    controllingCheck: ["Controlling Check", "Controlling Check"],
    implementation: ["Potential Confirmation", "Potenzialbestätigung"],
    viewer: ["Viewer", "Darf kommentieren"],
    editors: ["Editors", "Darf bearbeiten"],
    finishStep: ["Finish", "Abschließen"],
    skipStep: ["Skip Level", "Härtegrad überspringen"],
    yes: ["Yes", "Ja"],
    no: ["No", "Nein"],
    discardEntity: ["Discard {{entityName}}", "{{entityName}} verwerfen"],
    "Process discarded": ["{{processName}} discarded", "{{processName}} verworfen"],
    "Process closed": ["{{processName}} closed", "{{processName}} abgeschlossen"],
    "Discarded by user on date": ["Discarded by {{userName}} on {{date}}", "Verworfen von {{userName}} am {{date}}"],
    "Closed by user on date": ["Closed by {{userName}} on {{date}}", "Abgeschlossen von {{userName}} am {{date}}"],
    "Please select...": ["Please select...", "Bitte wählen ..."],
    "Enter comment...": ["Enter comment...", "Kommentar eingeben..."],
    "Save comment": ["Save comment", "Kommentar speichern"],
    visibility: ["Visibility", "Sichtbarkeit"],
    measure: ["Measure", "Maßnahme"],
    post_capture: ["Post-Capture", "Nacherfassung"],
    history: ["History", "Historie"],
    show_history: ["Show history", "Historie anzeigen"],
    documents: ["Number of documents", "Anzahl Dokumente"],
    [Keys.VDLANG_COMMENTS]: ["Comments", "Kommentare"],
    [Keys.VDLANG_COMMENT]: ["Comment", "Kommentar"],
    [Keys.VDLANG_COMMENT_EDITED]: ["Edited", "Bearbeitet"],
    [Keys.VDLANG_COMMENT_EDITED_HINT]: ["Last edited: {{date}}", "Zuletzt bearbeitet: {{date}}"],
    [Keys.VDLANG_COMMENT_ACTION]: ["Comment", "Kommentieren"],
    [Keys.VDLANG_DUE_DATE]: ["Due date", "Fälligkeit"],
    [Keys.VDLANG_RESPONSIBLE]: ["Responsible", "Verantwortlich"],
    [Keys.VDLANG_RESPONSIBLE_HINT]: [
        "The user assigned to this level is responsible for ensuring that this level will be completed. All users who can edit can assign another person here and, if required, also specify a due date.",
        "Die zu diesem Härtegrad zugeordnete Person ist dafür verantwortlich, dass dieser Härtegrad abgeschlossen wird. Alle Personen mit Bearbeitungsrechten können hier eine andere Person zuweisen und bei Bedarf zusätzlich auch einen Fälligkeitstermin mit angeben.",
    ],
    [Keys.VDLANG_ONLY_NUMBERS]: ["Please enter a number", "Bitte geben Sie eine Zahl ein"],
    close: ["Close", "Abbrechen"],
    COMPLETED: ["Effectiveness", "Wirksamkeit"],
    [Keys.VDLANG_GATE_CLOSED]: ["Completed", "Abgeschlossen"],
    [Keys.VDLANG_GATE_START_DATE_PLACEHOLDER]: ["Start date", "Startdatum"],
    [Keys.VDLANG_GATE_DUEDATE_DATE_PLACEHOLDER]: ["Due date", "Fälligkeit"],
    [Keys.VDLANG_ACTIVITY_GATE_LIST_TITLE]: ["{{activities}} activities in {{gateType}}", "{{activities}} Aktivitäten in der {{gateType}}"],
    [Keys.VDLANG_GATE_ACTIVITY_TOOLTIP_COMPLETED]: ["Completed activities", "Abgeschlossene Aktivitäten"],
    [Keys.VDLANG_GATE_ACTIVITY_TOOLTIP_DUESOON]: ["Soon overdue activities", "Bald fällige Aktivitäten"],
    [Keys.VDLANG_GATE_ACTIVITY_TOOLTIP_OVERDUE]: ["Overdue activities", "Überfällige Aktivitäten"],
    [Keys.VDLANG_GATE_ACTIVITY_TOOLTIP_OPEN]: ["Open activities", "Offene Aktivitäten"],

    [Keys.VDLANG_PAGINATION_PAGE]: ["Page", "Seite"],
    [Keys.VDLANG_PAGINATION_FIRST_PAGE]: ["First page", "Erste Seite"],
    [Keys.VDLANG_PAGINATION_PREVIOUS_PAGE]: ["Previous page", "Vorherige Seite"],
    [Keys.VDLANG_PAGINATION_NEXT_PAGE]: ["Next page", "Nächste Seite"],
    [Keys.VDLANG_PAGINATION_LAST_PAGE]: ["Last page", "Letzte Seite"],

    [Keys.VDLANG_COLUMN_CONFIGURATION]: ["Column configuration", "Spaltenkonfiguration"],
    [Keys.VDLANG_FILENAME]: ["Filename", "Dateiname"],

    desk_company_split_calculation: ["Qualification", "Qualifizierung"],
    desk_decision: ["Decision", "Entscheidung"],
    desk_company_split_implementation: ["Potential Confirmation", "Potenzialbestätigung"],
    desk_effectiveness: ["Confirmation of effectiveness", "Wirksamkeitsbestätigung"],
    desk_activities: ["Implementation", "Umsetzung"],
    desk_9007199254740991: ["Completed", "Abgeschlossen"],

    activities: ["Implementation", "Umsetzung"],
    company_split_calculation: ["Qualification", "Qualifizierung"],
    company_split_implementation: ["Potential Confirmation", "Potenzialbestätigung"],
    effectiveness: ["Confirmation of effectiveness", "Wirksamkeitsbestätigung"],

    action_cannot_be_undone: ["This action cannot be undone.", "Diese Aktion kann nicht rückgängig gemacht werden."],
    remove_avatar_title: ["Remove avatar?", "Avatar entfernen?"],
    remove_avatar_description: ["The avatar will be permanently removed.", "Der Avatar wird unwiderruflich entfernt."],

    remove_logo_title: ["Remove logo?", "Unternehmenslogo entfernen?"],
    remove_logo_description: ["The logo will be permanently removed.", "Das Unternehmenslogo wird unwiderruflich entfernt."],

    remove_comment_title: ["Remove comment?", "Kommentar entfernen?"],
    remove_comment_description: ["The comment will be permanently removed.", "Der Kommentar wird unwiderruflich entfernt."],
    remove_company_title: ["Remove company?", "Gesellschaft entfernen?"],
    remove_company_description: [
        'The company "{{company}}" will be deleted permanently.',
        'Die Gesellschaft "{{company}}" wird endgültig entfernt.',
    ],
    remove_supplier_title: ["Remove partner?", "Partner entfernen?"],
    remove_supplier_description: [
        'The partner "{{supplier}}" will be deleted permanently.',
        'Der Partner "{{supplier}}" wird endgültig entfernt.',
    ],
    remove_file_title: ["Delete Document?", "Dokument löschen?"],
    remove_file_description: ["Are you sure you want to delete this file?", "Möchten Sie diese Datei wirklich löschen?"],
    remove_filter_title: ["Remove filter?", "Filter entfernen?"],
    remove_filter_description: ["The filter will be permanently removed.", "Der Filter wird unwiderruflich entfernt."],
    sum: ["Sum", "Summe"],
    download_file: ["Download", "Herunterladen"],
    [Keys.VDLANG_UPLOAD]: ["Upload", "Hochladen"],
    [Keys.VDLANG_FILE_SIZE_UPLOAD]: ["Upload up to 20MiB", "Upload bis maximal 20MiB"],
    Complete: ["Effectiveness", "Wirksamkeit"],
    calculated: ["calculated", "berechnet"],

    clickToEdit: ["Click to edit", "Zum Bearbeiten klicken."],
    Edit: ["Edit", "Bearbeiten"],
    showActivitiesChanges: ["Show", "Anzeigen"],
    Cancel: ["Cancel", "Abbrechen"],
    date: ["Date", "Datum"],
    time: ["Time", "Uhrzeit"],
    "Invalid date": ["Invalid Date", "Ungültiges Datum"],
    "Invalid time": ["Invalid Time", "Ungültige Uhrzeit"],
    noResults: ["No Results found", "Keine Ergebnisse gefunden"],
    Error: ["Error", "Fehler"],
    "Unknown Error": ["Unknown Error", "Unbekannter Fehler"],
    [Keys.VDLANG_PROCESS_DETAIL_PROCESS_NOT_FOUND]: [
        "You cannot access this process because you do not have the permission for this.",
        "Sie können auf diesen Prozess nicht zugreifen, da Sie nicht die Berechtigung dazu haben.",
    ],
    [Keys.VDLANG_SELECT_TYPE_AT_LEAST_3_CHARACTERS]: ["Please type at least 3 characters", "Bitte geben Sie mindestens 3 Zeichen ein"],
    [Keys.VDLANG_SELECT_PLEASE_SELECT]: ["Please select an option", "Bitte auswählen"],
    [Keys.VDLANG_SELECT_NO_RESULTS]: ["No results found.", "Keine Einträge gefunden."],
    [Keys.VDLANG_SELECT_MORE_OPTIONS_HINT]: [
        "Only the first 1000 entries are displayed. Enter at least 3 characters to find other results.",
        "Es werden nur die ersten 1000 Einträge angezeigt. Geben Sie min. 3 Zeichen ein, um weitere Ergebnisse zu finden.",
    ],
    [Keys.VDLANG_SELECT_CREATE_OPTION]: ['Create "{{input}}"', '"{{input}}" erstellen'],
    [Keys.VDLANG_SELECT_DELETED_OPTION]: ["{{name}} (deleted)", "{{name}} (gelöscht)"],
    "Enter an activity title": ["New Activity", "Neue Aktivität"],
    "Enter a title": ["Enter a title", "Titel eingeben"],
    "Enter an opportunity title": ["Give a title to your opportunity", "Geben Sie Ihrer Opportunität einen Titel"],
    "Enter a comment": ["Click to add a comment", "Klicken, um einen Kommentar hinzuzufügen"],
    "Enter a description": ["Click to add a description", "Klicken, um eine Beschreibung hinzuzufügen"],
    decimalSeperator: [".", ","],
    thousandSeperator: [",", "."],
    market: ["Market ({{code}})", "Markt ({{code}})"],
    quantity: ["Quantity ({{code}})", "Menge ({{code}})"],
    price: ["Price ({{code}})", "Preis ({{code}})"],
    contract: ["Contract ({{code}})", "Vertrag ({{code}})"],
    product: ["Product ({{code}})", "Produkt ({{code}})"],
    process: ["Process ({{code}})", "Prozess ({{code}})"],
    makeorbuy: ["Make or Buy ({{code}})", "Make or Buy ({{code}})"],
    waste: ["Sustainability ({{code}})", "Nachhaltigkeit ({{code}})"],
    custom: ["Company Specific", "Unternehmensspezifisch"],
    marketHint: ["Where do we buy?", "Wo kaufen wir?"],
    quantityHint: ["How much do we buy?", "Wie viel kaufen wir ein?"],
    priceHint: ["How much are we paying?", "Wie viel bezahlen wir?"],
    contractHint: ["On what terms do we purchase?", "Zu welchen Bedingungen kaufen wir ein?"],
    productHint: ["What do we buy?", "Was kaufen wir ein?"],
    processHint: ["How do we produce?", "Wie produzieren wir?"],
    makeorbuyHint: ["Own production vs. outsourcing?", "Eigene Produktion vs. Outsourcing?"],
    wasteHint: ["How do we manage sustainability efficient?", "Wie managen wir Nachhaltigkeit effizient?"],
    customHint: ["Company specific method", "Unternehmensspezifische Methoden"],
    automaticVisibilityChange_one: [
        "Add '{{permission}}' permission to the group '{{group}}' after level completion.",
        'Beim Abschließen die Berechtigung "{{permission}}" für die Gruppe "{{group}}" hinzufügen.',
    ],
    automaticVisibilityChange_other: [
        "Add '{{permission}}' permission to the groups '{{group}}' after level completion.",
        'Beim Abschließen die Berechtigung "{{permission}}" für die Gruppen "{{group}}" hinzufügen.',
    ],
    begin: ["Begin", "Beginn"],
    end: ["End", "Ende"],
    "1day": ["1 Day", "1 Tag"],
    "1year": ["1 Year", "1 Jahr"],
    days: ["Days", "Tage"],
    effectivenessBegins: ["Effectiveness begins", " Wirksamkeit beginnt"],
    inDays: ["in {{days}} days", "in {{days}} Tagen"],
    Savings: ["Savings (calculated by the system)", "Einsparungen (vom System berechnet)"],
    "How to Calculate": ["How is it calculated?", "Wie wird gerechnet?"],
    FY: ["FY", "GJ"],
    "Create new item": ["Create new {{type}}", "{{type}} erstellen"],
    Create: ["Create", "Erstellen"],
    "Please enter a name": ["Please enter a name", "Bitte Bezeichnung eingeben"],
    classification: ["Source of idea classification", "Klassifizierung der Ideen-Herkunft"],
    vs_operating_effect: ["Effect on the operating result", "Auswirkung auf das Betriebsergebnis"],
    vsOperatingEffect: ["Effect on the operating result", "Auswirkung auf das Betriebsergebnis"],
    vs_operating_effect_hint: [
        "Define whether the process generates an effect on sales revenue (sales) or costs (procurement).",
        "Definieren Sie, ob der Prozess Auswirkungen auf die Umsatzerlöse (Vertrieb) oder die Kosten (Beschaffung) generiert.",
    ],
    action_area: ["Action Area", "Handlungsfeld"],
    actionArea: ["Action Area", "Handlungsfeld"],
    "completed on date": [
        "{{gate}} completed on {{date}} at {{time}} (after {{daysInLevel}} days)",
        "{{gate}} abgeschlossen am {{date}} um {{time}} Uhr (nach {{daysInLevel}} Tagen)",
    ],
    "completed on date by": [
        "{{name}} has completed the {{gate}} on {{date}} at {{time}} (after {{daysInLevel}} days)",
        "{{name}} hat die {{gate}} am {{date}} um {{time}} Uhr (nach {{daysInLevel}} Tagen) abgeschlossen",
    ],
    NoLettersInCurrency: ["Please enter a valid currency value", "Bitte korrekten Währungswert eingeben"],
    "Visibility and access": ["Visibility & access", "Sichtbarkeit & Zugriff"],
    Visibility: ["Visibility", "Sichtbarkeit"],
    Members: ["Users", "Benutzer"],
    domains: ["Domains", "Domains"],
    TeamInfo: ["Organization information", "Unternehmensinformationen"],
    "Measure Owner": ["Responsible", "Verantwortlich"], // TODO: Constant
    "Can Edit": ["Can Edit", "Darf bearbeiten"],
    "Can See": ["Can See", "Darf sehen"],
    "Can Comment": ["Can Comment", "Darf kommentieren"],
    Add: ["Add", "Hinzufügen"],
    "Change Visibility": ["Change Visibility", "Sichtbarkeit ändern"],
    [Keys.VDLANG_ENTER_NAME_OR_EMAIL]: ["Enter name or email", "Name oder E-Mail eingeben"],
    [Keys.VDLANG_PERMISSION_DIALOG_READ_HINT]: [
        "Can view and comment, but not edit it",
        "Darf sehen und kommentieren, aber nicht bearbeiten",
    ],
    [Keys.VDLANG_PERMISSION_DIALOG_UPDATE_HINT]: [
        "Can edit everything, but the user settings.",
        "Darf alles bis auf die Benutzereinstellungen bearbeiten.",
    ],
    [Keys.VDLANG_PERMISSION_DIALOG_REMOVE_HINT]: ["Can’t view and edit anymore.", "Darf nicht mehr sehen und bearbeiten."],
    Abort: ["Abort", "Abbrechen"],
    measureOwnerWarning: [
        "If you pass responsibility to someone else, you cannot undo this action yourself. In addition, you can no longer manage the users of the {{processName}}.",
        "Wenn Sie jemand anderes die Verantwortung für die {{processName}} übertragen, können Sie diese Aktion selbst nicht rückgängig machen. Außerdem können Sie dann nicht mehr die Mitglieder der {{processName}} verwalten.",
    ],
    confirm_change_measure_owner: ["Change responsibility", "Verantwortlichkeit ändern"],
    confirm_change_measure_owner_title: ["Change responsibility?", "Verantwortlichkeit ändern?"],
    [Keys.VDLANG_RESPONSIBLE_CURRENT_PERSON]: ["Person currently responsible", "Aktuell verantwortliche Person"],
    [Keys.VDLANG_RESPONSIBLE_CHANGE_PERSON]: ["Change responsible person", "Verantwortliche Person ändern"],
    [Keys.VDLANG_RESPONSIBLE_NOT_ASSIGNED]: ["Not assigned", "Nicht zugeordnet"],
    [Keys.VDLANG_RESPONSIBLE_ASSIGN_PERSON]: ["Assign person", "Person zuordnen"],
    [Keys.VDLANG_ASSIGN_CLIENT_GROUPS]: ["Assign group", "Gruppe zuordnen"],
    "Assign responsible person": ["Assign responsible person", "Verantwortliche Person zuordnen"],
    measureOwnerHint: [
        "The person who is responsible for the {{processName}}. In addition to editing, this person can also change other users permissions and visibility.",
        "Die Person, die für die {{processName}} verantwortlich ist. Das bedeutet neben Bearbeiten auch, dass diese Person die Berechtigungen der anderen Benutzer und die Sichtbarkeit ändern kann.",
    ],
    [Keys.VDLANG_CALCULATION_TABLE_COPY_DATA_WARNING]: [
        "During copying the values may be overwritten. Are you sure you want to proceed?",
        "Die Werte werden beim Kopieren möglicherweise überschrieben. Sind Sie sicher, dass Sie fortfahren wollen?",
    ],

    [Keys.VDLANG_CALCULATION_TABLE_TOOLTIP]: {
        chip_state_active: ["Hide {{calculationIdentifier}} values", "{{calculationIdentifier}}-Werte ausblenden"],
        chip_state_inactive: ["Show {{calculationIdentifier}} values", "{{calculationIdentifier}}-Werte anzeigen"],
        chip_state_disabled: ["The last option cannot be hidden", "Die letzte Option kann nicht ausgeblendet werden"],
        time_range_select: ["Time period for the table", "Zeitraum für die Tabelle"],
        add_or_remove_effect_column: ["Add/remove columns", "Spalten hinzufügen/entfernen"],
        copy_effect_column: ["Copy all values from {{prevCalculationIdentifier}}", "Alle Werte aus {{prevCalculationIdentifier}} kopieren"],
        calculation_locked: [
            "Calculation in this level is locked after completion or by a following decision level. Changes can only be made if the respective level is reopened by an admin.",
            "Die Berechnung in diesem Härtegrad ist nach Abschluss oder durch einen nachfolgenden Entscheidungs-Härtegrad gesperrt. Änderungen können erst vorgenommen werden, wenn der entsprechende Härtegrad durch einen Administrator wieder geöffnet wurde.",
        ],
    },
    enter_surname: ["Enter surname", "Nachnamen eingeben"],
    accept_terms: ["accept_terms", "AGBs gelesen und akzeptiert"],
    accept_privacy: ["accept_privacy", "Datenschutzerklärung gelesen und akzeptiert"],
    submit: ["Submit", "Abschicken"],
    success: ["Success", "Erfolgreich"],
    Firstname: ["First name", "Vorname"],
    Lastname: ["Surname", "Nachname"],
    lastIdea: ["Last Idea", "Letzte Idee"],
    name: ["name", "Name"],
    Name: ["Name", "Name"],
    enterName: ["Enter name", "Namen eingeben"],
    addDomain: ["Add domain", "Domain hinzufügen"],
    internal_number: ["Internal Number", "Interne Nummer"],
    enter_internal_number: ["Enter internal number", "Interne Nummer eingeben"],
    create_partner_submit: ["Create", "Erstellen"],
    lastIdeaAt: ["Last idea at", "Letzte Idee am"],
    partnerAccessEnabled: ["Partner access active", "Partnerzugang aktiv"],
    partnerAccessDisabled: ["Partner access disabled", "Partnerzugang inaktiv"],
    partnerAccessEnabledAndInactive: ["Partner access unused", "Partnerzugang nicht benutzt"],
    pageSize: ["Rows per page:", "Einträge pro Seite:"],
    [Keys.VDLANG_NEW_DOMAIN_PLACEHOLDER]: ["e.g. www.example.org", "z.B. www.example.org"],
    [Keys.VDLANG_GLOBAL_DOMAIN_HINT]: [
        "This domain is managed by Valuedesk and cannot be edited.",
        "Diese Domain wird von Valuedesk verwaltet und kann nicht bearbeitet werden.",
    ],
    [Keys.VDLANG_NO_DOMAINS]: ["No domains available.", "Keine Domains vorhanden."],
    [Keys.VDLANG_DOMAIN_NAME_ALREADY_EXISTS]: ["Domain name already exists.", "Domainname existiert bereits."],
    [Keys.VDLANG_INVALID_DOMAIN_NAME]: ["Invalid domain name.", "Ungültiger Domainname."],
    [Keys.VDLANG_GROUP_CONTRIBUTOR]: ["Contributor", "Mitwirkende:r"],
    [Keys.VDLANG_GROUP_FULL_USER]: ["Full Access", "Voller Zugang"],
    edit_partner: ["Edit Partner", "Partner bearbeiten"],
    until: ["until", "bis"],
    dragHandle: ["Drag & drop to change order", "Klicken und halten, um die Reihenfolge zu ändern"],
    or: ["or", "oder"],
    Enabled: ["Enabled", "Aktiviert"],
    Disabled: ["Disabled", "Deaktiviert"],
    "Page not found": ["Page not found", "Seite nicht gefunden"],
    [Keys.VDLANG_CHECK_LINK]: [
        "If you entered a web address please check it was correct.",
        "Wenn Sie eine Adresse eingegeben haben, prüfen Sie bitte ob sie korrekt ist.",
    ],
    Language: ["Language", "Sprache"],
    de: ["German", "Deutsch"],
    en: ["English", "Englisch"],
    Sort: ["Sort", "Sortierung"],
    Filter: ["Filter", "Filter"],
    Order: ["Order", "Reihenfolge"],
    Position: ["Position", "Position"],
    MethodMeasureTimerange: {
        [MethodMeasureTimerange.LAST_12_MONTHS]: ["Last 12 months", "Letzte 12 Monate"],
        [MethodMeasureTimerange.LAST_FISCAL_YEAR]: ["Last  fiscal year", "Letztes Geschäftsjahr"],
        [MethodMeasureTimerange.CURRENT_FISCAL_YEAR]: ["Current fiscal year", "Aktuelles Geschäftsjahr"],
        [MethodMeasureTimerange.ALL_MEASURES]: ["Total", "Gesamt"],
    },

    /* NAVBAR */
    [Keys.VDLANG_SECTIONS_NEWS]: ["News", "Neuigkeiten"],
    [Keys.VDLANG_SECTIONS_DASHBOARD]: ["Dashboards", "Dashboards"],
    [Keys.VDLANG_SECTIONS_IDEAS]: ["Opportunities", "Opportunitäten"],
    [Keys.VDLANG_DASHBOARD_DEFAULT_NAME]: ["My Dashboard", "Mein Dashboard"],
    [Keys.VDLANG_ENTITY_OWNER]: ["Owner", "Besitzer"],
    [Keys.VDLANG_SECTIONS_PROCESSES]: ["Processes", "Prozesse"],
    [Keys.VDLANG_SECTIONS_PROCESSES_GRID]: ["Grid", "Tabelle"],
    [Keys.VDLANG_SECTIONS_PROCESSES_DESK]: ["Desk", "Desk"],
    [Keys.VDLANG_SECTIONS_LOGIN]: ["Login", "Einloggen"],
    [Keys.VDLANG_SECTIONS_PARTNERS]: ["Partners", "Partner"],
    Search: ["Search", "Suchen"],

    [Keys.VDLANG_SECTIONS_SETTINGS]: ["Settings", "Einstellungen"],
    [Keys.VDLANG_SECTIONS_LOGOUT]: ["Logout", "Ausloggen"],
    [Keys.VDLANG_SECTIONS_ADMIN]: ["Administration", "Administration"],
    [Keys.VDLANG_SECTIONS_SUPER_ADMIN]: ["Super Admin Section", "Super-Admin-Bereich"],
    "Set team targets": ["Set team targets", "Team-Ziele setzen"], // still effective?
    /* END NAVBAR */

    Suppliers: ["Partners", "Partner"],
    Login: ["Login", "Einloggen"],
    Logout: ["Logout", "Ausloggen"],

    /* SEARCH */
    [Keys.VDLANG_SEARCH_RESULTS]: ["Search results", "Suchergebnisse"],
    [Keys.VDLANG_SEARCH_INPUT_HINT]: [
        "Please enter at least {{count}} characters or an id",
        "Bitte mindestens {{count}} Zeichen oder eine ID eingeben",
    ],
    [Keys.VDLANG_SEARCH_NO_RESULTS]: ["No results for search key '{{key}}'.", "Keine Treffer für Suchbegriff '{{key}}'."],
    /* END SEARCH */

    /* SUPPORT */
    Support: ["Support", "Hilfe"],
    KnowledgeCenter: ["Knowledge Center", "Wissensdatenbank"],
    TechnicalQuestions: ["For technical questions, please contact ", "Bei technischen Fragen wenden Sie sich bitte an "],
    /* END SUPPORT */

    /* CREATE CALL TO ACTION DIALOG */
    [Keys.VDLANG_CREATE_MEASURE_LABELS]: {
        measure: ["Start measure", "Maßnahme starten"],
        post_capture: ["Capture result", "Ergebnis nacherfassen"],
    },
    [Keys.VDLANG_CREATE_MEASURE_HINTS]: {
        measure: [
            "A measure is a <strong>future-oriented action-plan</strong> with the goal of an effective financial effect.",
            "Eine Maßnahme ist ein in die <strong>Zukunft gerichteter Handlungsplan</strong> mit dem Ziel eines wirksamen Finanzeffektes.",
        ],
        post_capture: [
            "The Post-Capture serves for the <strong>later documentation of realized results</strong>. The effectiveness has already been decided and scheduled at the time of entry.",
            "Die Nacherfassung dient der <strong>nachträglichen Dokumentation realisierter Ergebnisse</strong>. Bei der Erfassung ist die Wirksamkeit bereits entschieden und terminiert.",
        ],
    },
    [Keys.VDLANG_CREATE_MEASURE_QUESTIONS]: {
        measure: ["What is the title?", "Wie lautet der Titel?"],
        post_capture: ["What is the title?", "Wie lautet der Titel?"],
    },

    [Keys.VDLANG_CREATE_IDEA_LABELS]: ["Create opportunity", "Opportunität anlegen"],
    [Keys.VDLANG_CREATE_IDEA_LABELS_HINT]: [
        "An opportunity is an initial rough possibility of a process with initial estimates of, among other things, potential, implementation time and effort.",
        "Eine Opportunität ist eine erste grobe Möglichkeit eines Prozesses mit ersten Schätzungen zu u.a. Potenzial, Umsetzungszeitraum und Aufwand.",
    ],
    [Keys.VDLANG_CREATE_IDEA_QUESTIONS]: ["What is the title?", "Wie lautet der Titel?"],
    /* END CALL TO ACTION DIALOG */

    companies: ["Companies", "Gesellschaften"],
    create: ["Create", "Erstellen"],
    update: ["Update", "Bearbeiten"],
    delete: ["Delete", "Entfernen"],
    move: ["Move", "Verschieben"],
    processes: ["Processes", "Prozesse"],
    invite: ["Invite", "Einladen"],
    reinvite: ["Send invitation again", "Nochmal einladen"],
    opportunities: ["Opportunities", "Opportunitäten"],

    Password: ["Password", "Passwort"],
    "Change Password": ["Change Password", "Passwort ändern"],
    Effect: ["Effect", "Effekt"],
    "Cost Avoidance": ["Cost Avoidance", "Abgewehrte Mehrkosten"],
    total: ["Total", "Gesamt"],
    required_fields_hint: [" required fields are not filled.", " der Pflichtfelder sind noch nicht ausgefüllt."],
    Copy: ["Copy values", "Werte übernehmen"],
    copy_button_hint: [
        "Copy values for this {{effectType}} from {{previousCalculationLevelName}}",
        "Werte für diese {{effectType}} aus der {{previousCalculationLevelName}} übernehmen",
    ],
    copy_all_button_hint: [
        "Copy all values from the {{previousCalculationLevelName}}",
        "Alle Werte aus der {{previousCalculationLevelName}} übernehmen",
    ],
    calculated_field_hint: [
        "This field is calculated. Click into field to see formula.",
        "Dieses Feld wurde berechnet. Klicken Sie auf das Feld um die Berechnung zu sehen.",
    ],
    Delete: ["Delete", "Löschen"],
    Replace: ["Replace", "Ersetzen"],
    fileToDelete: ["Document to delete", "Zu löschendes Dokument"],

    question: ["Question", "Fragestellung"],
    currentGate: ["Level", "Härtegrad"],

    //Measures View Labels
    "operator_=": ["equals", "ist gleich"],
    "operator_!=": ["doesn't equal", "ist nicht gleich"],
    "operator_<": ["is less than", "ist kleiner als"],
    "operator_>": ["is greater than", "ist größer als"],
    operator_IN: ["is any of", "ist eines von"],
    operator_NOT_IN: ["is none of", "ist keines von"],
    operator_LIKE: ["contains", "enthält"],
    "operator_date_<": ["is before", "vor dem"],
    "operator_date_>": ["is after", "nach dem"],
    "operator_date_=": ["at", "am"],
    "operator_date_!=": ["not at", "nicht am"],
    operator_date_BEFORE: ["was before more than", "war vor mehr als"],
    operator_date_WITHIN: ["was in the last", "war in den letzten"],
    operator_date_IN_MORE: ["will be in more than", "in mehr als"],
    operator_date_IN_NEXT: ["will be in the next", "in den nächsten"],
    operator_NOT_SET: ["is not set", "ist nicht gesetzt"],
    operator_date_NOT_SET: ["is not set", "ist nicht gesetzt"],
    value: ["Value", "Wert"],

    time_range: ["Period under Consideration", "Betrachtungszeitraum"],
    quick_search: ["Quick Search", "Schnellsuche"],
    display: ["Process", "Prozess"],
    display_many: ["Processes", "Prozesse"],
    custom_time_range: ["Custom time range", "Eigener Zeitraum"],
    total_time_range: ["All fiscal years", "Alle Geschäftsjahre"],
    [Keys.VDLANG_TIME_RANGE_SELECT_WITHOUT_TIME_RANGE]: ["Without Period under Consideration", "Ohne Betrachtungszeitraum"],
    [Keys.VDLANG_SCOPE_CURRENCY]: ["Currency", "Währung"],
    change_columns: ["Change Columns", "Spalten anpassen"],
    columns: ["Columns", "Spalten"],
    fiscal_year: ["Fiscal Year", "Geschäftsjahr"],
    fiscal_quarter: ["Fiscal Quarter", "Geschäftsquartal"],
    month: ["Month", "Monat"],
    apply: ["Apply", "Anwenden"],
    [Keys.VDLANG_BACK]: ["Back", "Zurück"],
    [Keys.VDLANG_DELETE]: ["Delete", "Löschen"],
    [Keys.VDLANG_START_DATE]: ["Start date", "Startdatum"],
    [Keys.VDLANG_END_DATE]: ["End date", "Enddatum"],
    [Keys.VDLANG_DATE_PLACEHOLDER]: ["mm/dd/yyyy", "TT.MM.JJJJ"],
    [Keys.VDLANG_DATE_MONTH_YEAR_PLACEHOLDER]: ["mm-yyyy", "MM-JJJJ"],
    available_columns: ["Available Columns", "Verfügbare Spalten"],
    selected_columns: ["Selected Columns", "Ausgewählte Spalten"],
    discard: ["Discard", "Verwerfen"],
    implement: ["Implement", "Umsetzen"],
    /* Global filters */
    all: ["All", "Alle"],
    discarded: ["Discarded", "Verworfen"],
    my_measures: ["My Processes", "Meine Prozesse"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_PROCESSES_TITLE]: ["My Processes", "Meine Prozesse"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_PROCESSES_DESCRIPTION]: [
        "A table with all the processes you are responsible for.",
        "Eine Tabelle mit allen Prozessen, die Sie verantworten.",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_OVERDUE_PROCESSES_TITLE]: ["Overdue processes", "Überfällige Prozesse"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_OVERDUE_PROCESSES_DESCRIPTION]: [
        "A table with all overdue processes.",
        "Eine Tabelle mit allen überfälligen Prozessen.",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_TOP_PROCESSES_TITLE]: ["Top processes", "Top Prozesse"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_TOP_PROCESSES_DESCRIPTION]: [
        "Table of processes with the highest potential",
        "Tabelle der Prozesse mit dem höchsten Potenzial",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_NEXT_60_DAYS_TITLE]: [
        "Processes with begin of effectiveness in the next 60 days",
        "Prozesse mit Wirksamkeitsstart in den nächsten 60 Tagen",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_IN_DECISION_OVER_60_DAYS_TITLE]: [
        "Processes which are in Decision for more than 60 days",
        "Prozesse in Entscheidung seit mehr als 60 Tagen",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_IN_DECISION_OVER_60_DAYS_DESCRIPTION]: [
        "A table of processes which are in the decision level for more than 60 days.",
        "Eine Tabelle mit allen Prozessen, die seit mehr als 60 Tagen im Härtegrad Entscheidung sind.",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_NEXT_60_DAYS_DESCRIPTION]: [
        "A table of all open processes that are scheduled to begin taking effect in the next 60 days.",
        "Eine Tabelle mit allen offenen Prozessen, deren Wirksamkeit in den nächsten 60 Tagen beginnen soll.",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_WITHOUT_CHANGE_LAST_60_DAYS_TITLE]: [
        "Processes without change in the last 60 days.",
        "Prozesse ohne Änderung in den letzten 60 Tagen.",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_WITHOUT_CHANGE_LAST_60_DAYS_DESCRIPTION]: [
        "A table with all processes without processing within the last 60 days.",
        "Eine Tabelle mit allen Prozessen ohne Bearbeitung innerhalb der letzten 60 Tagen.",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_BLANK_TITLE]: ["Process list template", "Prozesslistenvorlage"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_BLANK_DESCRIPTION]: [
        "A table with all undiscarded processes",
        "Eine Tabelle mit allen nicht verworfenen Prozessen",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_FAVORITE_TITLE]: ["My Favorites", "Meine Favoriten"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_FAVORITE_DESCRIPTION]: [
        "A table of all your processes marked as favorites.",
        "Eine Tabelle aller Ihrer als Favorit markierten Prozesse.",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_LEVEL_RESPONSIBILITIES]: [
        "My level responsibilities",
        "Meine Härtegrad-Verantwortlichkeiten",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_MY_LEVEL_RESPONSIBILITIES_DESCRIPTION]: [
        "A table with all the levels you are responsible for.",
        "Eine Tabelle mit allen Härtegraden, die Sie verantworten.",
    ],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_PLAN_FC_VS_TARGET_TITLE]: [
        "Run-up diagram: PLAN + FC versus target",
        "Hochlaufdiagramm: PLAN + FC gegen Ziel",
    ],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_PLAN_FC_VS_TARGET_DESCRIPTION]: [
        "This run-up diagram shows the comparison of the cumulated monthly effects in PLAN and FC of all processes.",
        "Dieses Hochlaufdiagramm zeigt den Vergleich der kumulierten monatlichen Effekte in PLAN und FC für alle Prozesse.",
    ],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_VS_PLAN_TITLE]: [
        "Rolling Forecast: FC + ACT vs. PLAN",
        "Rollierender Forecast: FC + ACT gegen PLAN",
    ],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_VS_PLAN_DESCRIPTION]: [
        "This run-up diagram shows a rolling forecast of the FC and ACT effects for a fiscal year in comparison to the PLAN effects.",
        "Dieses Hochlaufdiagramm zeigt einen rollierenden Forecast der FC- und ACT-Effekte für ein Geschäftsjahr im Vergleich zu den PLAN-Effekten.",
    ],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_PRESENTATION_NONE]: ["Do not show", "Nicht anzeigen"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_PRESENTATION_LINE]: ["Line", "Linie"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_PRESENTATION_BAR]: ["Bar", "Balken"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_CUMULATED_LEGEND]: ["{{identifier}} (cumulated)", "{{identifier}} (kumuliert)"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_TARGET]: ["Target", "Ziel"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ROLLING_FORECAST]: ["FC (rolling forecast)", "FC (rollierender Forecast)"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_ROLLING_FORECAST]: ["FC + ACT (rolling forecast)", "FC + ACT (rollierender Forecast)"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_ROLLING_FORECAST_SHOW_SUMS]: ["Show sums for bars", "Summen für Balken anzeigen"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_AXIS_MIN_VALUE]: ["Custom minimum axis value", "Benutzerdefinierter minimaler Achsenwert"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_AXIS_MAX_VALUE]: ["Custom maximum axis value", "Benutzerdefinierter maximaler Achsenwert"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_FC_ACT_ROLLING_FORECAST_HINT]: [
        "The rolling forecast shows the ACT effects combined with the FC effect for the months where no ACT effect is available yet. Thus, the rolling forecast is always the most accurate forecast for the target achievement.",
        "Der rollierende Forecast zeigt die ACT-Effekte in Kombination mit den mit den FC-Effekten für die Monate, für die noch kein ACT-Effekt vorhanden ist. Somit bietet der rollierende Forecast die aktuellste und Prognose über die Zielerreichung.",
    ],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_TITLE]: ["Run-up diagram", "Hochlaufdiagramm"],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_DESCRIPTION]: [
        "The run-up diagram shows the progress of target achievement for a fiscal year over the months.",
        "Das Hochlaufdiagramm zeigt den Fortschritt in der Zielerreichung für ein Geschäftsjahr über die Monate.",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_DISCARDED_LAST_60_DAYS_TITLE]: [
        "Discarded Processes in the last 60 days",
        "Verworfene Prozesse in den letzten 60 Tagen",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_LIST_WIDGET_DISCARDED_LAST_60_DAYS_DESCRIPTION]: [
        "A table of all processes that have been discarded in the last 60 days, including the discard reason.",
        "Eine Tabelle aller Prozesse, die in den letzten 60 Tagen verworfen worden sind, einschließlich des Verwerfungsgrunds.",
    ],
    [Keys.VDLANG_DASHBOARDS_UNDEFINED_GROUP]: ["No relationship", "Keine Zuordnung"],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_EFFECTS]: ["Realized savings", "Realisierte Einsparungen"],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_TARGET]: ["Fiscal year target", "Jahreszielsetzung"],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_NAME]: ["Name", "Name"],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_FISCAL_YEAR]: ["Fiscal Year", "Geschäftsjahr"],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_SHOW_SUMS]: ["Show sums for bars", "Summen für Balken anzeigen"],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_AXIS_MIN_VALUE]: [
        "Custom minimum axis value",
        "Benutzerdefinierter minimaler Achsenwert",
    ],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_AXIS_MAX_VALUE]: [
        "Custom maximum axis value",
        "Benutzerdefinierter maximaler Achsenwert",
    ],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_TARGET]: ["Target", "Ziel"],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_GROUP]: ["Stacked by", "Gestapelt nach"],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_CONFIG_NO_GROUP_SELECTED]: ["No stacking", "Keine Aufteilung"],
    [Keys.VDLANG_DASHBOARDS_COMPLETED_EFFECTS_LEGEND_SUM]: ["Total potential", "Gesamtes Potenzial"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_GENERAL]: ["General", "Allgemein"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_SCOPE]: ["Scope", "Auswahlbereich"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_FILTER]: ["Filter", "Filter"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_TARGETS]: ["Targets", "Ziele"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TAB_REFERENCE_VALUES]: ["Reference values", "Referenzwerte"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_NAME]: ["Name", "Name"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_BARTYPE]: ["Bar Type", "Balkentyp"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_FIRST_PIVOT_FIELD]: ["Bars", "Balken"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TITLE_ASCENDING]: ["Title ascending", "Titel aufsteigend"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_TITLE_DESCENDING]: ["Title descending", "Titel absteigend"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_ASCENDING]: ["Aggregation ascending", "Aggregation aufsteigend"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_DESCENDING]: ["Aggregation descending", "Aggregation absteigend"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_FIELD_ORDER_ASCENDING]: ["Field order ascending", "Feldordnung aufsteigend"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_FIELD_ORDER_DESCENDING]: ["Field order descending", "Feldordnung absteigend"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_BAR_HORIZONTAL]: ["Horizontal bars", "Horizontale Balken"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_BAR_VERTICAL]: ["Vertical bars", "Vertikale Balken"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_SECOND_PIVOT_FIELD]: ["Stacked by", "Gestapelt nach"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION]: ["Aggregation", "Aggregation"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_COUNT_OF_PROCESSES]: ["Count of processes", "Anzahl der Prozesse"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_AGGREGATION_SUM_OF_POTENTIAL]: ["Sum of the potential", "Summe des Potenzials"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_DEFAULT_TREE_FIELD_LEVEL]: [
        "Default sublevel of bars field",
        "Standard-Unterebene des Balkenfelds",
    ],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_CONFIG_DEFAULT_TREE_FIELD_LEVEL_HINT]: [
        "For tree-type bar fields, you may select one of its subordinate levels to be shown by default.",
        "Für Balken-Felder vom Typ Baum können Sie eine der untergeordneten Ebenen auswählen, die standardmäßig angezeigt werden sollen.",
    ],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_DISCARDED_BY_LEVEL_TITLE]: [
        "Potential by discard reason stacked by level",
        "Potenzial nach Verwerfungsgrund gestapelt nach Härtegrad",
    ],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_DISCARDED_BY_LEVEL_DESCRIPTION]: [
        "Bar chart of the potential of each discard reason stacked by level.",
        "Ein Balkendiagramm des Potentials für alle Verwerfungsgründe gestapelt nach den Härtegraden.",
    ],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_COMPANY_BY_LEVEL_TITLE]: [
        "Potential by company stacked by level",
        "Potenzial nach Gesellschaft gestapelt nach Härtegrad",
    ],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_COMPANY_BY_LEVEL_DESCRIPTION]: [
        "Bar chart of the potential of each company stacked by level.",
        "Ein Balkendiagramm des Potentials für jede Gesellschaft gestapelt nach den Härtegraden.",
    ],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_WIDGET_BLANK_TEMPLATE_TITLE]: ["Process bar chart template", "Prozessbalkendiagrammvorlage"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_WIDGET_BLANK_TEMPLATE_DESCRIPTION]: [
        "Bar chart of the potential of each level.",
        "Ein Balkendiagramm des Potentials für jeden Härtegrad.",
    ],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_PRODUCT_GROUP_BY_LEVEL_TITLE]: [
        "Potential by material group stacked by level",
        "Potenzial nach Warengruppe gestapelt nach Härtegrad",
    ],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_PRODUCT_GROUP_BY_LEVEL_DESCRIPTION]: [
        "Bar chart of the potential of each material group stacked by level.",
        "Ein Balkendiagramm des Potentials für alle Warengruppen gestapelt nach den Härtegraden.",
    ],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_DRILLDOWN_TITLE]: ["Drilldown", "Drilldown"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_POTENTIAL]: ["Potential", "Potenzial"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AGGREGATION]: ["Count", "Anzahl"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AXIS_MIN_VALUE]: ["Custom minimum axis value", "Benutzerdefinierter minimaler Achsenwert"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_AXIS_MAX_VALUE]: ["Custom maximum axis value", "Benutzerdefinierter maximaler Achsenwert"],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_REFERENCE_VALUES_CONFIG_MISMATCH_HINT]: [
        "Please note that the current selected bars, aggregation, scope or filters do not match the reference values. For this reason, the reference values are not displayed.",
        "Bitte beachten Sie, dass die aktuell ausgewählten Balken, die Aggregation, der Auswahlbereich oder die Filter nicht mit den Referenzwerten übereinstimmen. Aus diesem Grund werden die Referenzwerte nicht angezeigt.",
    ],
    [Keys.VDLANG_DASHBOARDS_CUSTOM_BAR_CHART_SHOW_RELATIVE_REPRESENTATION]: [
        "Show relative representation",
        "Relative Darstellung anzeigen",
    ],

    [Keys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_TITLE]: ["Funnel chart", "Trichterdiagramm"],
    [Keys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_DESCRIPTION]: [
        "Funnel chart of the potential of each level.",
        "Ein Trichterdiagramm des Potentials für jeden Härtegrad.",
    ],
    [Keys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_PROCESS_TYPE]: ["Process type", "Prozesstyp"],
    [Keys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_NO_POTENTIAL_MESSAGE]: [
        "No potential for process type.",
        "Kein Potenzial für den Prozesstyp.",
    ],
    [Keys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_GENERAL]: ["General", "Allgemein"],
    [Keys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_SCOPE]: ["Scope", "Auswahlbereich"],
    [Keys.VDLANG_DASHBOARDS_FUNNEL_CHART_WIDGET_CONFIG_TAB_FILTER]: ["Filter", "Filter"],

    [Keys.VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_TITLE]: ["Comment Stream", "Kommunikationsaktivität"],
    [Keys.VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_DESCRIPTION]: ["A list of the latest comments", "Eine Liste mit allen neuen Kommentaren"],
    [Keys.VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_CONFIG_TAB_GENERAL]: ["General", "Allgemein"],
    [Keys.VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_CONFIG_TAB_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_COMMENT_STREAM_WIDGET_CONFIG_TAB_FILTER]: ["Filter", "Filter"],
    [Keys.VDLANG_DASHBOARDS_COMMENT_STREAM_TITLE_PREFIX]: ['The <strong>{{type}}</strong> "', 'Die <strong>{{type}}</strong> "'],
    [Keys.VDLANG_DASHBOARDS_COMMENT_STREAM_TITLE_SUFFIX]: ['" has been commented.', '" wurde kommentiert.'],
    [Keys.VDLANG_DASHBOARDS_COMMENT_STREAM_FILTER_ENTITY]: ["Comment types", "Kommentartypen"],
    [Keys.VDLANG_DASHBOARDS_COMMENT_STREAM_FILTER_COMMENTED_BY]: ["Commented by", "Kommentiert von"],
    [Keys.VDLANG_DASHBOARDS_COMMENT_STREAM_FILTER_RESPONSIBLE_PERSON]: ["Responsible person/people", "Verantwortliche Person(en)"],

    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TAB_GENERAL]: ["General", "Allgemein"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TAB_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TAB_TARGET]: ["Target", "Ziel"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_NAME]: ["Name", "Name"],
    [Keys.VDLANG_DASHBOARDS_ROLLING_FORECAST_CONFIG_TARGET_HEADING]: ["Monthly targets (cumulated)", "Monatliche Ziele (kumuliert)"],
    [Keys.VDLANG_DASHBOARDS_WIDGET_NO_DATA]: [
        "There is no data available for your widget configuration.",
        "Zu Ihrer Widgetkonfiguration sind keine Daten verfügbar",
    ],
    [Keys.VDLANG_DASHBOARDS_WIDGET_ERROR]: [
        "You don't have the required permission to see this widget.",
        "Sie haben nicht die notwendige Berechtigung um dieses Widget zu sehen.",
    ],
    [Keys.VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_TITLE]: ["Opportunities list", "Opportunitätenliste"],
    [Keys.VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_DESCRIPTION]: ["A list with all opportunities.", "Eine Liste mit allen Opportunitäten."],
    [Keys.VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_CONFIG_TAB_GENERAL]: ["General", "Allgemein"],
    [Keys.VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_CONFIG_TAB_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_IDEA_LIST_WIDGET_CONFIG_TAB_FILTER]: ["Filter", "Filter"],

    [Keys.VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_TITLE]: ["Opportunities matrix", "Opportunitätenmatrix"],
    [Keys.VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_DESCRIPTION]: [
        "An eisenhower decision matrix with all opportunities.",
        "Eine Eisenhower-Matrix mit allen Opportunitäten.",
    ],
    [Keys.VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_CONFIG_TAB_GENERAL]: ["General", "Allgemein"],
    [Keys.VDLANG_DASHBOARDS_IDEA_MATRIX_WIDGET_CONFIG_TAB_FILTER]: ["Filter", "Filter"],

    [Keys.VDLANG_DASHBOARDS_IDEA_WIDGET_CONFIG_FILTERS_SELECTED]: ["Filters set ({{count}})", "Filter gesetzt ({{count}})"],
    [Keys.VDLANG_DASHBOARDS_IDEA_WIDGET_CONFIG_FILTERS_RESET]: ["Reset", "Zurücksetzen"],
    [Keys.VDLANG_DASHBOARDS_IDEA_WIDGET_CONFIG_FILTERS_MORE_FIELDS]: ["More fields ({{count}})", "Weitere Felder ({{count}})"],

    [Keys.VDLANG_DASHBOARDS_MY_NEXT_ACTIVITIES_TITLE]: ["My next activities", "Meine nächsten Aktivitäten"],
    [Keys.VDLANG_DASHBOARDS_MY_NEXT_ACTIVITIES_DESCRIPTION]: [
        "A list of the next due activities you are assigned to.",
        "Eine Liste mit den als nächstes fälligen Aktivitäten, denen Sie zugewiesen sind.",
    ],
    [Keys.VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_BLANK_TEMPLATE_TITLE]: ["Activity list template", "Aktivitätslistenvorlage"],
    [Keys.VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_BLANK_TEMPLATE_DESCRIPTION]: [
        "A list of the next due activities visible for you.",
        "Eine Liste mit den als nächstes fälligen Aktivitäten, die für Sie sichtbar sind.",
    ],
    [Keys.VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_CONFIG_TAB_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_ACTIVITY_LIST_WIDGET_FILTER_HINT]: [
        "These filters apply to processes to which the activities belong.",
        "Diese Filter beziehen sich auf die Prozesse, zu denen die Aktivitäten gehören.",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_TITLE]: ["White spot matrix for Processes", "White-Spot-Matrix für Prozesse"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_DESCRIPTION]: [
        "A matrix to analyze white spots in the existing processes.",
        "Eine Matrix zur Analyse von White Spots in den bestehenden Prozessen.",
    ],
    [Keys.VDLANG_DASHBOARDS_CHART_WIDGET_INCOMPLETE_CONFIG]: ["Please configure the widget.", "Bitte konfigurieren Sie das Widget."],
    [Keys.VDLANG_DASHBOARDS_CHART_WIDGET_INCOMPLETE_CONFIG_BUTTON_LABEL]: ["Configure", "Konfigurieren"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_SUM_LABEL]: ["Total result", "Gesamtergebnis"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_NOT_FILLED_LABEL]: ["Not filled", "Nicht ausgefüllt"],
    [`${Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_HIDDEN_PROCESSES_MESSAGE}_one`]: [
        "{{count}} process is hidden, because {{xField}} and/or {{yField}} are not filled.",
        "{{count}} Prozess ist ausgeblendet, da {{xField}} und/oder {{yField}} nicht ausgefüllt sind.",
    ],
    [`${Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_HIDDEN_PROCESSES_MESSAGE}_other`]: [
        "{{count}} processes are hidden, because {{xField}} and/or {{yField}} are not filled.",
        "{{count}} Prozesse sind ausgeblendet, da {{xField}} und/oder {{yField}} nicht ausgefüllt sind.",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_Y_AXIS]: ["Y-Axis", "Y-Achse"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_WIDGET_X_AXIS]: ["X-Axis", "X-Achse"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_AGGREGATION]: ["Display", "Anzeige"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_USE_MAX_VALUES_HEADING]: [
        "Set manual reference values",
        "Referenzwerte manuell festlegen",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_USE_MAX_VALUES_HINT]: [
        "The reference value determines the value for which the darkest color level is used. Cells with smaller values are displayed in corresponding color gradations. The reference value is derived from the highest value of all cells. To establish comparability between multiple widgets, the reference value can also be set manually.",
        "Der Referenzwert bestimmt den Wert, ab welchem Wert die dunkelste Farbstufe verwendet wird. Zellen mit kleineren Werten werden in entsprechenden Farbabstufungen dargestellt. Der Referenzwert wird aus dem höchsten Wert aller Zellen berechnet. Um Vergleichbarkeit zwischen verschiedenen Widgets herzustellen, kann der Referenzwert aber auch manuell definiert werden.",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_MAX_VALUE_POTENTIAL]: ["Potential", "Potenzial"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_MAX_VALUE_COUNT]: ["Process count", "Anzahl der Prozesse"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_SORT_TITLE]: ["Title", "Titel"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_SORT_FIELD]: ["Field order", "Feldordnung"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_SORT_AGGREGATION]: ["Aggregation", "Aggregation"],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_DEFAULT_X_AXIS_TREE_FIELD_LEVEL]: [
        "Default sublevel of x-axis field",
        "Standard-Unterebene des X-Achsenfelds",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_DEFAULT_Y_AXIS_TREE_FIELD_LEVEL]: [
        "Default sublevel of y-axis field",
        "Standard-Unterebene des Y-Achsenfelds",
    ],
    [Keys.VDLANG_DASHBOARDS_PROCESS_WHITESPOT_CHART_CONFIG_DEFAULT_TREE_FIELD_LEVEL_HINT]: [
        "For tree-type axis fields, you may select one of its subordinate levels to be shown by default.",
        "Für Axenfelder vom Typ Baum können Sie eine der untergeordneten Ebenen auswählen, die standardmäßig angezeigt werden sollen.",
    ],

    [Keys.VDLANG_DASHBOARDS_LIVE_RUN_UP_EMPTY_TEMPLATE_TITLE]: ["Live run-up", "Live-Hochlauf"],
    [Keys.VDLANG_DASHBOARDS_LIVE_RUN_UP_EMPTY_TEMPLATE_DESCRIPTION]: [
        "A live run-up diagram with all processes.",
        "Ein Live-Hochlaufdiagramm mit allen Prozessen.",
    ],

    [Keys.VDLANG_DASHBOARDS_LIVE_RUN_UP_TARGET_LABEL]: ["Target", "Zielsetzung"],
    [Keys.VDLANG_DASHBOARDS_LIVE_RUN_UP_TARGET_CHIP]: ["Target: {{value}}", "Zielsetzung: {{value}}"],
    [Keys.VDLANG_DASHBOARDS_LIVE_RUN_UP_CUSTOMIZE_TIME_AXIS_HEADING]: ["Customize time axis", "Zeitachse anpassen"],
    [Keys.VDLANG_DASHBOARDS_LIVE_RUN_UP_GRANULARITY]: ["Granularity", "Granularität"],
    [Keys.VDLANG_DASHBOARDS_LIVE_RUN_UP_GRANULARITY_LABELS]: {
        [LiveRunUpGranularity.Month]: ["Months", "Monate"],
        [LiveRunUpGranularity.Quarter]: ["Quarters", "Quartale"],
        [LiveRunUpGranularity.Year]: ["Years", "Jahre"],
        [LiveRunUpGranularity.HalfYear]: ["Half-years", "Halbjahre"],
    },
    [Keys.VDLANG_DASHBOARDS_LIVE_RUN_UP_ACCUMULATION_START_LABEL]: ["Include effects as of", "Effekte berücksichtigen seit"],
    [Keys.VDLANG_DASHBOARDS_LIVE_RUN_UP_SHOW_SUMS]: ["Show sums for bars", "Summen für Balken anzeigen"],
    [Keys.VDLANG_DASHBOARDS_LIVE_RUN_UP_AXIS_MIN_VALUE]: ["Custom minimum axis value", "Benutzerdefinierter minimaler Achsenwert"],
    [Keys.VDLANG_DASHBOARDS_LIVE_RUN_UP_AXIS_MAX_VALUE]: ["Custom maximum axis value", "Benutzerdefinierter maximaler Achsenwert"],
    [Keys.VDLANG_DASHBOARDS_LIVE_RUN_UP_SHOW_REFERENCE_VALUES]: ["Show delta to reference values", "Delta zu Referenzwerten anzeigen"],
    [Keys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_TIMESTAMP]: ["Last saved at {{timestamp}}", "Zuletzt gespeichert am {{timestamp}}"],
    [Keys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_TIMESTAMP_LEGEND]: ["Delta to {{timestamp}}", "Abweichung zum {{timestamp}}"],
    [Keys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIG_MISMATCH_HINT]: [
        "Please note that the current time axis configuration or selected filters do not match the reference values. For this reason, the reference values are not displayed.",
        "Bitte beachten Sie, dass die aktuelle Zeitachsenkonfiguration oder ausgewählten Filter nicht mit den Referenzwerten übereinstimmen. Aus diesem Grund werden die Referenzwerte nicht angezeigt.",
    ],
    [Keys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_NO_VALUES]: ["No reference values", "Keine Referenzwerte"],
    [Keys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_OVERRIDE_TITLE]: ["Override reference values?", "Referenzwerte überschreiben?"],
    [Keys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_OVERRIDE_TEXT]: [
        "The existing reference values will be overwritten. This cannot be undone.",
        "Die vorhandenen Referenzwerte werden überschreiben. Dies kann nicht rückgängig gemacht werden.",
    ],
    [Keys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_DELETE_TITLE]: ["Delete reference values?", "Referenzwerte löschen?"],
    [Keys.VDLANG_DASHBOARDS_REFERENCE_VALUES_FORM_CONFIRM_DELETE_TEXT]: [
        "The existing reference values will be deleted. This cannot be undone.",
        "Die vorhandenen Referenzwerte werden gelöscht. Dies kann nicht rückgängig gemacht werden.",
    ],

    [Keys.VDLANG_DASHBOARDS_POTENTIAL_KPI_WIDGET_TITLE]: ["Total potential", "Gesamtes Potenzial"],
    [Keys.VDLANG_DASHBOARDS_POTENTIAL_KPI_WIDGET_DESCRIPTION]: [
        "A simple widget that shows you the potential of all visible processes.",
        "Ein einfaches Widget welches das Gesamtpotenzial aller sichtbaren Prozesse darstellt.",
    ],
    [Keys.VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_GENERAL]: ["General", "Allgemein"],
    [Keys.VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_SCOPE]: ["Scope", "Auswahlbereich"],
    [Keys.VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_TAB_FILTER]: ["Filter", "Filter"],
    [Keys.VDLANG_DASHBOARDS_POTENTIAL_KPI_CONFIG_NAME]: ["Name", "Name"],
    [Keys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_TITLE]: ["Status aggregation", "Status-Aggregation"],
    [Keys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_DESCRIPTION]: [
        "Aggregation of all measures that are on track or delayed. Measures are on track if they are open and their effectiveness has not started yet. Measures are delayed if they are open and their effectiveness has already started.",
        "Aggregation aller Maßnahmen, die im Plan oder verspätet sind. Maßnahmen sind im Plan, wenn sie offen sind, der Wirksamkeitszeitraum aber noch nicht begonnen hat. Maßnahmen sind verspätet, wenn sie offen sind, der Wirksamkeitszeitraum aber bereits begonnen hat.",
    ],
    [`${Keys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_ON_TRACK}_one`]: ["{{count}} measure on track", "{{count}} Maßnahme im Plan"],
    [Keys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_ON_TRACK]: ["{{count}} measures on track", "{{count}} Maßnahmen im Plan"],
    [`${Keys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_DELAYED}_one`]: ["{{count}} measure delayed", "{{count}} Maßnahme verzögert"],
    [Keys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_WIDGET_DELAYED]: ["{{count}} measures delayed", "{{count}} Maßnahmen verzögert"],
    [Keys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_GENERAL]: ["General", "Allgemein"],
    [Keys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_SCOPE]: ["Scope", "Auswahlbereich"],
    [Keys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_TAB_FILTER]: ["Filter", "Filter"],
    [Keys.VDLANG_DASHBOARDS_STATUS_AGGREGATION_CONFIG_NAME]: ["Name", "Name"],

    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_TITLE]: ["Weekly level progression", "Wöchentlicher Härtegradverlauf"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_DESCRIPTION]: [
        "The run-up diagram shows the weekly progress of target achievement for a fiscal year.",
        "Das Hochlaufdiagramm zeigt den wöchentlichen Fortschritt in der Zielerreichung für ein Geschäftsjahr.",
    ],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_GENERAL]: ["General", "Allgemein"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_FILTER_CONFIGURATION]: ["Filter", "Filter"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TAB_FLIGHT_PATHS]: ["Flight paths", "Flight Paths"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_FLIGHT_PATH_FORMULA_HEADING]: [
        "How to enter a formula?",
        "Wie sind Formeln einzugeben?",
    ],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_FLIGHT_PATH_FORMULA_HINT]: [
        "Enter a formula for calculating the flightpath here. Use the parameter <strong><1>w</1></strong> for the week number in your formula. Common <3>functions</3> and <5>constants</5> are available. Make sure to enter the formula syntactically correct. <br /> Example: f(w)=0.5*sin(24*w/pi)/2",
        "Geben Sie hier eine Formel zur Berechnung des Flight Paths an. Nutzen Sie den Parameter <strong><1>w</1></strong> für die Anzahl der Wochen in Ihrer Formel. Die gängigen <3>Funktionen</3> und <5>Konstanten</5> können hier verwendet werden. Achten Sie darauf, die Formel syntaktisch richtig einzugeben. <br /> Beispiel: f(w)=0.5*sin(24*w/pi)/2",
    ],

    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_FLIGHT_PATH_FORMULA_LABEL]: [
        "{{level}} flight path formula",
        "{{level}} Flight Path Formel",
    ],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_NAME]: ["Name", "Name"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TARGET]: ["Target", "Zielsetzung"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_AXIS_MIN_VALUE]: [
        "Custom minimum axis value",
        "Benutzerdefinierter minimaler Achsenwert",
    ],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_AXIS_MAX_VALUE]: [
        "Custom maximum axis value",
        "Benutzerdefinierter maximaler Achsenwert",
    ],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_SCOPE]: ["Scope", "Auswahlbereich"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_CONFIG_TIME_FRAME]: ["Time frame", "Zeitraum"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_LEGEND_CARRY_OVER]: ["Carry-over", "Übertrag"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_LEGEND_TARGET]: ["Target", "Zielsetzung"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_WEEK]: ["CW", "KW"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_WEEK_AXIS_LABEL]: ["CW of {{timeFrame}}", "KW in {{timeFrame}}"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_LEGEND_LABEL]: ["{{level}} flight path", "{{level}} Flight Path"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_N_WEEKS_ERROR]: ["{{level}} for {{count}} weeks", "{{level}} an {{count}} Wochen"],
    [`${Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_WEEK_ERROR}_one`]: [
        "{{level}} at week {{weeks}}",
        "{{level}} an Woche {{weeks}}",
    ],
    [`${Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_WEEK_ERROR}_other`]: [
        "{{level}} at weeks {{weeks}}",
        "{{level}} an Wochen {{weeks}}",
    ],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_FLIGHT_PATH_WEEK_ERROR_LIST]: [
        "The following formulas contain errors or return invalid values: {{errors}}",
        "Folgende Formeln enthalten Fehler oder geben ungültige Werte zurück: {{errors}}",
    ],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_GATETASK]: ["Levels", "Härtegrade"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_FLIGHTPATH]: ["Flightpaths", "Flightpaths"],
    [Keys.VDLANG_DASHBOARDS_TIMELINE_WIDGET_TOOLTIP_TOTAL]: ["Total Levels", "Gesamt Härtegrade"],

    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_TITLE]: ["Project progress", "Projektfortschritt"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_DESCRIPTION]: [
        "A Project Progress Chart which shows the recurring and one-off effects for a defined project time frame.",
        "Ein Projektfortschrittsdiagramm, welches die wiederkehrenden und einmaligen Effekte in einem definieren Projektzeitraum anzeigt.",
    ],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_DRILLDOWN_RECURRING_TAB]: ["Recurring effects", "Wiederkehrende Effekte"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_WIDGET_DRILLDOWN_ONE_TIME_TAB]: ["One-time effects", "Einmalige Effekte"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_GENERAL]: ["General", "Allgemein"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_SCOPE]: ["Scope", "Auswahlbereich"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_FILTER_CONFIGURATION]: ["Filter", "Filter"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_REFERENCE_VALUES]: ["Reference values", "Referenzwerte"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_TAB_TARGETS]: ["Targets", "Ziele"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_NAME]: ["Name", "Name"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_AXIS_MIN_VALUE]: [
        "Custom minimum axis value",
        "Benuzterdefinierter minimaler Achsenwert",
    ],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_AXIS_MAX_VALUE]: [
        "Custom maximum axis value",
        "Benutzerdefinierter maximaler Achsenwert",
    ],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_SHOW_SUMS]: ["Show sums for bars", "Summen für Balken anzeigen"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_SHOW_REFERENCE_VALUES]: [
        "Show delta to reference values",
        "Delta zu Referenzwerten anzeigen",
    ],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_SHOW_TIME_AXIS_HEADING]: ["Project time frame", "Projektzeitraum"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_CONFIGURE_RECURRING_EFFECTS]: [
        "Distinguishing between recurring and one-off effects",
        "Unterscheidung wiederkehrender und einmaliger Effekte",
    ],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_CONFIGURE_RECURRING_EFFECTS_HINT]: [
        "In this diagram, recurring effects are displayed over the entire project time frame, while one-off effects are only taken into account in the year in which they are effective.<br />In this widget, you have to specify how recurring and one-off effects are distinguished. To do this, an attribute is selected and the field values for recurring and one-off effects are specified.",
        "Wiederkehrende Effekte werden in diesem Diagramm über den gesamten Projektzeitraum angezeigt, während Einmaleffekte nur in dem Jahr berücksichtigt werden, indem sie wirksam sind.<br />Dabei muss in diesem Widget angegeben werden, woran wiederkehrende und einmalige Effekte unterschieden werden. Dazu wird ein Attribut ausgewählt und festgelegt, welche Feldwerte für wiederkehrende bzw. einmalige Effekte stehen.",
    ],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_ATTRIBUTE_LABEL]: ["Attribute", "Attribut"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_ONETIME_LABEL]: [
        "Field values for one-time effects",
        "Feldwerte für einmalige Effekte",
    ],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_CONFIG_RECURRING_LABEL]: [
        "Field values for recurring effects",
        "Feldwerte für wiederkehrende Effekte",
    ],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_TARGETS_FORM_TARGETS]: ["Fiscal year targets", "Geschäftsjahresziele"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_TARGETS_FORM_ADD_TARGET]: ["Add target", "Ziel hinzufügen"],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_TARGETS_FORM_ADD_TARGET_AFTER]: [
        "Add target for subsequent fiscal year",
        "Ziel für folgendes Geschäftsjahr hinzufügen",
    ],
    [Keys.VDLANG_DASHBOARDS_PROJECT_PROGRESS_TARGETS_FORM_ADD_TARGET_BEFORE]: [
        "Add target for previous fiscal year",
        "Ziel für vorheriges Geschäftsjahr hinzufügen",
    ],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_WIDGET_TITLE]: ["Weekly savings run-up", "Wöchentlicher Hochlauf"],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_USE_CUSTOM_REFERENCE_DATE]: [
        "Use custom reference date",
        "Benutzerdefiniertes Referenzdatum verwenden",
    ],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_DIFFERENCE_TO_TARGET]: ["Difference to target", "Differenz zum Ziel"],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_WIDGET_DESCRIPTION]: [
        "This widget shows the annual savings of the MG3+ measures per week.",
        "Dieses Widget zeigt die jährlichen Einsparungen der MG3+-Maßnahmen pro Woche.",
    ],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TAB_GENERAL]: ["General", "Allgemein"],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TAB_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TAB_FILTER_CONFIGURATION]: ["Filter", "Filter"],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_NAME]: ["Name", "Name"],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_FISCAL_YEAR]: ["Fiscal year", "Geschäftsjahr"],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_TARGET]: ["Target", "Ziel"],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_CONFIG_REFERENCE_DATE]: ["Reference date", "Referenzdatum"],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_MATERIALIZED_SAVINGS]: ["Materialized savings", "Materialisierte Einsparungen"],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_MATERIALIZED_CARRY_OVER]: ["Materialized carry-over", "Materialisierter Übertrag"],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_PLANNED_SAVINGS]: ["Planned savings", "Geplante Einsparungen"],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_PLANNED_CARRY_OVER]: ["Planned carry-over", "Geplanter Übertrag"],
    [Keys.VDLANG_DASHBOARDS_WEEKLY_SAVINGS_RUN_UP_OVERDUE_SAVINGS]: ["Overdue savings", "Überfällige Einsparungen"],

    [Keys.VDLANG_DASHBOARDS_NOT_FOUND]: [
        "You cannot access this dashboard because you do not have the permission for this.",
        "Sie können auf dieses Dashboard nicht zugreifen, da Sie nicht die Berechtigung dazu haben.",
    ],

    [Keys.VDLANG_ENTRY_ALREADY_EXISTS]: ["Entry already exists", "Eintrag existiert bereits"],

    measures_with_open_activities: ["Open Activities", "Offene Aktivitäten"],
    supplier_measures: ["From Partners", "Von Partnern"],
    measure_with_price_increases: ["With Price Increases", "Mit Preiserhöhungen"],
    no_progress_30_days: ["No progress for 30 days", "Kein Fortschritt seit 30 Tagen"],

    // TODO: final gate names
    desk_post_capture: ["Post-Capture", "Nacherfassung"],
    measure_status_0: ["Discarded", "Verworfen"],
    measure_status_1: ["Open", "Offen"],
    measure_status_2: ["Closed", "Abgeschlossen"],
    visibility_1: ["Restricted access", "Eingeschränkt sichtbar"],
    visibility_2: ["Visible", "Sichtbar"],
    new_indicator_desk_hint: [
        "This process has been edited since you last viewed it.",
        "Dieser Prozess wurde seit Ihrer letzten Ansicht bearbeitet.",
    ],

    //END Measures View Labels
    notification_measure_discarded: [
        "A process you are involved in has been discarded",
        "Ein Prozess wurde verworfen, an dem ich beteiligt war",
    ],
    notification_measure_closed: [
        "A process you are involved in has been completed",
        "Ein Prozess wurde abgeschlossen, an dem ich beteiligt war",
    ],
    notification_measure_permission_changed: [
        "My permission for a process has been changed",
        "Meine Berechtigung für einen Prozess wurde geändert",
    ],
    notification_measure_permission_removed: [
        "My permission for a process has been revoked",
        "Meine Berechtigung für einen Prozess wurde entfernt",
    ],
    notification_gate_task_user_assigned: [
        "I have been assigned to a level as the responsible person",
        "Mir wurde die Verantwortlichkeit für einen Härtegrad zugeordnet",
    ],
    notification_gate_task_user_unassigned: [
        "I have been unassigned from a level",
        "Ich wurde als Verantwortlicher für einen Härtegrad entfernt",
    ],
    notification_gate_task_completed: [
        "A level of a process I am involved in has been completed",
        "Ein Härtegrad eines Prozesses, an dem ich beteiligt bin, wurde abgeschlossen",
    ],
    notification_gate_task_assigned_completed: [
        "A level where I am assigned to has been completed",
        "Ein Härtegrad für den ich verantwortlich bin, wurde abgeschlossen",
    ],
    notification_gate_task_startdate_changed: [
        "The start date of a level has been changed",
        "Das Startdatum für einen Härtegrad wurde geändert",
    ],
    notification_gate_task_duedate_changed: [
        "The due date of a level has been changed",
        "Die Fälligkeit für einen Härtegrad wurde geändert",
    ],
    notification_gate_task_record_decision: ["A decision of me was documented", "Es wurde eine Entscheidung für mich dokumentiert"],
    notification_gate_task_reminder: ["Reminders for levels that are due soon", "Erinnerungen für bald fällige Härtegrade"],
    notification_activity_reminder: ["Reminders for activities that are due soon", "Erinnerungen für bald fällige Aktivitäten"],
    notification_sub_task_user_assigned: ["I have been assigned to an activity", "Ich wurde einer Aktivität zugeordnet"],
    notification_sub_task_user_unassigned: ["I have been unassigned from an activity", "Meine Zuordnung für eine Aktivität wurde entfernt"],
    notification_sub_task_duedate_changed: [
        "The due date of an activity I am assigned to has been changed",
        "Die Fälligkeit einer Aktivität, der ich zugeordnet bin, wurde geändert",
    ],
    notification_sub_task_completed: ["An activity has been completed", "Eine Aktivität wurde abgeschlossen, an der ich beteiligt war"],
    notification_sub_task_removed: ["An activity has been removed", "Eine Aktivität wurde gelöscht, an der ich beteiligt war"],
    notification_comment: ["New comment on a process I am involved in", "Neuer Kommentar zu einem Prozess, an dem ich beteiligt bin"],
    notification_comment_reply: ["New reply to one of my process comments", "Neue Antwort auf einen meiner Prozess-Kommentare"],
    notification_sub_task_comment: [
        "New comment on an activity I am involved in",
        "Neuer Kommentar zu einer Aktivität, an der ich beteiligt bin",
    ],
    notification_sub_task_comment_reply: [
        "New reply to one of my activity comments",
        "Neue Antwort auf einen meiner Aktivitäten-Kommentare",
    ],
    notification_idea_comment: [
        "New comment on an opportunity I am involved in",
        "Neuer Kommentar zu einer Opportunität, an der ich beteiligt bin",
    ],
    notification_idea_comment_reply: [
        "New reply to one of my opportunity comments",
        "Neue Antwort auf einen meiner Opportunitäten-Kommentare",
    ],
    notification_feed_comment_reply: ["New reply to one of my news comments", "Neue Antwort auf einen meiner Neuigkeiten-Kommentare"],
    notification_mentions: [
        "I was mentioned in a process, activity or comment",
        "Ich wurde in einem Prozess, Aktivität oder Kommentar erwähnt",
    ],
    notification_measure_user_assigned: [
        "I have been assigned to a process as the responsible person",
        "Mir wurde die Verantwortlichkeit für einen Prozess zugeordnet",
    ],
    notification_idea_updated: ["An opportunity I created was updated", "Eine von mir erstellte Opportunität wurde aktualisiert"],
    notification_idea_discarded: ["An opportunity I created was discarded", "Eine von mir erstellte Opportunität wurde verworfen"],
    notification_idea_converted: [
        "An opportunity I created was converted to a process",
        "Eine von mir erstellte Opportunität wurde in einen Prozess konvertiert",
    ],
    idea_change_notifications: ["Opportunity changes", "Änderungen bei Opportunitäten"],
    process_change_notifications: ["Process changes", "Änderungen bei Prozessen"],
    process_permission_notifications: ["Access and assignment", "Zugriff und Zuordnungen"],
    comment_notifications: ["Comments", "Kommentare"],
    misc_notifications: ["Miscellaneous", "Sonstiges"],
    notifications: ["Notifications", "Benachrichtigungen"],
    notificationsHint: [
        "Here you can configure your email notifications. If you don't wish to receive certain notifications, you can unsubscribe here.",
        "Hier können Sie Ihre Email-Benachrichtigungen konfigurieren. Falls Sie bestimmte Benachrichtungen nicht wünschen, können Sie diese hier abbestellen.",
    ],
    "Update Password": ["Update Password", "Passwort ändern"],
    [Keys.VDLANG_MY_PROFILE_TITLE]: ["My Profile", "Mein Profil"],
    [Keys.VDLANG_MY_PROFILE_COMPANY]: ["Company", "Gesellschaft"],
    [Keys.VDLANG_MY_PROFILE_CURRENCY]: ["Preferred currency", "Bevorzugte Währung"],
    [Keys.VDLANG_INTEGRATIONS_TITLE]: ["Integrations and APIs", "Integrationen und APIs"],
    [Keys.VDLANG_INTEGRATIONS_RENEW_TOKEN]: ["Generate token", "Token erzeugen"],
    [Keys.VDLANG_INTEGRATIONS_TOKEN]: ["Token", "Token"],
    [Keys.VDLANG_INTEGRATIONS_EXISTS_EXPIRES_AT]: ["Existing token is valid until", "Der bestehende Token ist gültig bis"],
    [Keys.VDLANG_INTEGRATIONS_RENEW_INFO]: [
        "Generating a token will revoke all other tokens. The new token will only be shown once. Please copy it immediately. The token is not stored on the server.",
        "Durch die Erzeugung eines Tokens werden alle anderen Token widerrufen. Der neue Token wird nur einmal angezeigt. Bitte kopieren Sie ihn sofort. Der Token wird nicht auf dem Server gespeichert.",
    ],
    [Keys.VDLANG_SCIM_INTEGRATIONS_BASE_PATH]: ["SCIM Base Path", "SCIM Basis-URL"],
    "old password": ["Old Password", "Altes Passwort"],
    "new password": ["New Password", "Neues Passwort"],
    [Keys.VDLANG_UPDATE_PROFILE]: ["Update Profile", "Profil ändern"],
    [Keys.VDLANG_UPDATE_PICTURE]: ["Update profile picture", "Profilbild ändern"],
    [Keys.VDLANG_UPDATE_LOGO]: ["Update Company Logo", "Unternehmenslogo ändern"],
    [Keys.VDLANG_UPDATE_DASHBOARD_COLORS]: ["Customize dashboard colors", "Dashboard-Farben anpassen"],
    telephoneNumber: ["Telephone", "Telefon"],
    TelephoneNumber: ["Telephone", "Telefon"],
    mobileNumber: ["Mobile Number", "Mobilfunknummer"],
    MobileNumber: ["Mobile Number", "Mobilfunknummer"],
    department: ["Department", "Abteilung"],
    Department: ["Department", "Abteilung"],
    position: ["Position", "Position im Unternehmen"],
    Planet: ["Planet", "Planet"],
    Earth: ["Earth", "Erde"],
    Mars: ["Mars", "Mars"],
    onlyImagesUpload: [
        "Only documents with these extensions are allowed: png, jpg, gif.",
        "Es sind nur Dokumente mit folgenden Dateierweiterungen erlaubt: png, jpg, gif.",
    ],
    [Keys.VDLANG_INVALID_FILETYPE]: ["Invalid filetype", "Ungültiger Datentyp"],
    [Keys.VDLANG_INVALID_FILESIZE_TOO_LARGE]: [
        "The document is too large. Its size cannot exceed 20 MiB",
        "Das Dokument ist zu groß. Es sind maximal 20 MiB erlaubt",
    ],
    [Keys.VDLANG_INVALID_FILECOUNT]: [
        "Only a single file can be uploaded at once",
        "Es kann immer nur ein Dokument auf einmal hochgeladen werden",
    ],
    [Keys.VDLANG_FILE_NOT_READABLE]: ['Error while opening file "{{name}}"', 'Fehler beim Öffnen der Datei "{{name}}"'],
    [Keys.VDLANG_PARSING_ERROR]: ["Couldn't extract the data", "Konnte die Daten nicht auslesen"],
    [Keys.VDLANG_TFA]: ["Two-factor authentication", "Zwei-Faktor-Authentifizierung"],
    [Keys.VDLANG_TFA_SETUP_HINT_ENTER_PASSWORD]: ["Please enter your current password.", "Bitte geben Sie ihr aktuelles Passwort ein."],
    [Keys.VDLANG_TFA_SETUP_TITLE_ENTER_PASSWORD]: ["Verify password", "Passwort verifizieren"],
    [Keys.VDLANG_TFA_SETUP_HINT_SCAN_QR]: {
        1: [
            "Open the authentication app of your choice. In case you don't already have one, we recommend ",
            "Öffnen Sie die Authentifizierungs-App Ihrer Wahl. Sollte Sie keine besitzen, empfehlen wir ",
        ],
        2: [" or ", " oder "],
        3: [
            "Use the app and scan the QR code or enter the code manually.",
            "Nutzen Sie die App und scannen Sie den QR-Code oder geben Sie den Code manuell ein.",
        ],
    },
    [Keys.VDLANG_TFA_SETUP_AUTHENTICATOR_1_NAME]: ["Microsoft Authenticator", "Microsoft Authenticator"],
    [Keys.VDLANG_TFA_SETUP_AUTHENTICATOR_1_LINK]: [
        "https://www.microsoft.com/en-us/security/mobile-authenticator-app",
        "https://www.microsoft.com/de-de/security/mobile-authenticator-app",
    ],
    [Keys.VDLANG_TFA_SETUP_AUTHENTICATOR_2_NAME]: ["Google Authenticator", "Google Authenticator"],
    [Keys.VDLANG_TFA_SETUP_AUTHENTICATOR_2_LINK]: [
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
    ],
    [Keys.VDLANG_TFA_SETUP_TITLE_SCAN_QR]: ["Setup Authenticator", "Authenticator einrichten"],
    [Keys.VDLANG_TFA_SETUP_HINT_VALIDATE_TOKEN]: [
        "Please enter the code generated by your app.",
        "Bitte geben Sie den Code ein, der von Ihrer App generiert wurde.",
    ],
    [Keys.VDLANG_TFA_SETUP_TITLE_VALIDATE_TOKEN]: ["Validate Authenticator", "Authenticator validieren"],
    [Keys.VDLANG_TFA_SETUP_ENTER_CODE]: ["Enter code", "Code eingeben"],
    [Keys.VDLANG_TFA_SETUP_INFO]: [
        "Set up two-factor authentication to increase the security of your account.",
        "Einrichten der Zwei-Faktor-Authentifizierung, um die Sicherheit Ihres Kontos zu erhöhen.",
    ],
    [Keys.VDLANG_TFA_SETUP_ALREADY_DONE]: [
        "Two-factor authentication was already setup. Please contact your administrator to reset it.",
        "Die Zwei-Faktor-Authentifizierung wurde bereits eingerichtet. Bitte wenden Sie sich an Ihren Administrator, um sie zurückzusetzen.",
    ],
    [Keys.VDLANG_LOGIN_INCORRECT_TFA_TOKEN]: ["Invalid code.", "Ungültiger Code."],
    [Keys.VDLANG_TFA_REMEMBER_DEVICE]: ["Remember me on this device.", "Auf diesem Gerät merken."],

    [Keys.VDLANG_DELETE_AVATAR]: ["Delete profile picture", "Profilbild löschen"],
    [Keys.VDLANG_DELETE_LOGO]: ["Delete Logo", "Unternehmenslogo löschen"],
    [Keys.VDLANG_ADMIN_DASHBOARD_COLOR_LABEL]: ["Color #{{index}}", "Farbe #{{index}}"],
    [Keys.VDLANG_ADMIN_DASHBOARD_WHITE_SPOT_COLOR_LABEL]: ["White spot matrix color", "White-Spot Matrix Farbe"],
    [Keys.VDLANG_ADMIN_DASHBOARD_WHITE_SPOT_COLOR_WARNING]: [
        "The color should have a medium brightness so that the gradations are clearly distinguishable while the text remains easily readable.",
        "Die Farbe sollte eine mittlere Helligkeit haben, damit die Abstufung klar erkennbar sind aber auch der Text noch lesbar ist.",
    ],
    [Keys.VDLANG_ADMIN_DASHBOARD_COLOR_LABEL_PREVIEW]: ["Preview: ", "Vorschau: "],
    [Keys.VDLANG_ADMIN_DASHBOARD_RESET_DASHBOARD_COLORS_TO_DEFAULT]: ["Reset to default", "Auf Standard zurücksetzen"],
    [Keys.VDLANG_COLOR_INPUT_BAD_VALUE]: [
        "Please enter the color as 6-digit hex value",
        "Bitte geben sie die Farbe als 6-stelligen Hex-Wert ein",
    ],
    "Incorrect password": ["The old password is incorrect", "Das alte Passwort ist falsch"],
    realname: ["Full name", "Vollständiger Name"],
    displayname: ["Display name", "Anzeigename"],
    displaynameDe: ["Display name (DE)", "Anzeigename (DE)"],
    displaynameEn: ["Display name (EN)", "Anzeigename (EN)"],
    moreinfo: ["More info", "Mehr Infos"],
    password_criteria: PasswordCriteriaTranslations,

    /* ADMIN SECTION */
    change_password_title: ["Choose a new password", "Setzen Sie ein neues Passwort"],
    change_password_button_text: ["Change password", "Passwort setzen"],
    password_strength_text: [
        "Password must have minimum 8 characters, upper & lower letters, atleast one number & a symbol: (!#@$%^&*)(+=._-).",
        "Das Passwort muss mindestens acht Zeichen lang sein, Groß- und Kleinbuchstaben, mindestens eine Ziffer und eines der folgenden Zeichen enthalten: !#@$%^&*)(+=._-.",
    ],

    treeNodes: ["Tree Nodes", "Baumknoten"],
    targets: ["Targets", "Ziele"],
    externalContributions: ["External Contributions", "Externe Beteiligte"],
    clientName: ["Organization Name", "Unternehmensname"],
    internalContactPerson: ["Internal contact person", "Interne:r Ansprechpartner:in"],

    street: ["Street", "Straße"],
    zip: ["Zip", "PLZ"],
    city: ["City", "Stadt"],
    country: ["Country", "Land"],
    active: ["Active", "Aktiv"],
    archived: ["Archived", "Archiviert"],
    deactivate: ["Deactivate", "Deaktivieren"],
    [Keys.VDLANG_TFA_ACTIVATE]: ["Activate", "Aktivieren"],
    USER_STATUS_DELETED: ["Deleted", "Gelöscht"],
    USER_STATUS_DELETED_HINT: ["Deleted", "Gelöscht"], // Should not be shown anywhere
    USER_STATUS_REGISTERED: ["Registered", "Registriert"],
    USER_STATUS_REGISTERED_HINT: [
        "Was added to the Valuedesk database, but must still be invited",
        "Ist in der Valuedesk-Datenbank hinterlegt, muss jedoch noch eingeladen werden",
    ],
    USER_STATUS_INVITED: ["Invited", "Eingeladen"],
    USER_STATUS_INVITED_HINT: [
        "Has received an invitation email to Valuedesk, but the password has not yet been set",
        "Hat eine Einladungs-E-mail zu Valuedesk erhalten, das Passwort wurde jedoch noch nicht gesetzt",
    ],
    USER_STATUS_INACTIVE: ["Deactivated", "Deaktiviert"],
    USER_STATUS_INACTIVE_HINT: ["Has no access to Valuedesk anymore", "Hat keinen Zugriff mehr auf Valuedesk"],
    USER_STATUS_ACTIVE: ["Active", "Aktiv"],
    USER_STATUS_ACTIVE_HINT: ["A regular Valuedesk user", "Kann Valuedesk regulär nutzen"],

    invite_another_member: ["Invite another user", "Einen weiteren Benutzer einladen"],
    invite_member: ["Invite Member", "Benutzer einladen"],
    invite_almost_expired_hint: [
        "The invitation link expires soon. You can send the person a new link.",
        "Der versendete Einladungslink läuft bald ab. Sie können der Person einen neuen Link schicken.",
    ],
    invite_expired_hint: [
        "The invitation link has expired. You can send the person a new link.",
        "Der versendete Einladungslink ist abgelaufen. Sie können der Person einen neuen Link schicken.",
    ],
    edit_member: ["Edit Member", "Benutzer bearbeiten"],
    purchaser: ["Purchaser", "Einkäufer/in"],
    setArchive: ["Archive", "Archivieren"],
    setUnarchive: ["Unarchive", "Unarchivieren"],
    setPrivate: ["Set visibility to private", "Sichtbarkeit auf privat setzen"],
    setCompanyWide: ['Set visibility to "Visible"', 'Sichtbarkeit auf "Sichtbar" setzen'],
    [Keys.VDLANG_ADMIN_MEASURES_REOPEN_MEASURE]: ["Reopen process", "Prozess wieder öffnen"],
    [Keys.VDLANG_ADMIN_MEASURES_UNDISCARD_MEASURE]: ["Undiscard process", "Verwerfung zurücknehmen"],
    [Keys.VDLANG_ADMIN_MEASURES_REOPEN_LAST_GATE_TASK]: ["Reopen last completed level", "Zuletzt abgeschlossenen Härtegrad wieder öffnen"],
    [Keys.VDLANG_ADMIN_MEASURES_CHANGE_RESPONSIBILITY]: ["Change responsibility", "Verantwortlichkeit ändern"],
    [Keys.VDLANG_ADMIN_OPPS_REOPEN_OPP]: ["Reopen opportunity", "Opportunität wieder öffnen"],
    [Keys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM]: ["Change Valuestream", "Valuestream ändern"],
    [Keys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_ERROR]: [
        "This Valuestream can't be used as the configuration is not compatible with the current configuration",
        "Dieser Valuestream kann nicht benutzt werden, da die aktuelle Konfiguration nicht mit der Zielkonfiguration übereinstimmt",
    ],
    [Keys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_ERROR_GT]: [
        "Level configuration is not compatible",
        "Härtegrad-Konfiguration ist nicht kompatibel",
    ],
    [Keys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_ERROR_EC]: [
        "Effect configuration is not compatible",
        "Effekt-Konfiguration ist nicht kompatibel",
    ],
    [Keys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_WARN_FIELD_ADD]: [
        "Changing the Valuestream will add the following fields without values",
        "Durch das Ändern des Valuestreams werden folgende Felder ohne Werte hinzugefügt",
    ],
    [Keys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_WARN_FIELD_REMOVE]: [
        "Changing the Valuestream will remove the following fields",
        "Durch das Ändern des Valuestreams werden folgende Felder entfernt",
    ],
    [Keys.VDLANG_ADMIN_MEASURES_CHANGE_VALUESTREAM_WARN_RESPONSIBLE_MISSING_PERMISSION]: [
        "The responsible will not be able to see the process as they do not have the necessary permissions for the Valuestream",
        "Die verantwortliche Person wird den Prozess nicht sehen können, da sie nicht die notwendigen Berechtigungen für den Valuestream hat",
    ],

    [Keys.VDLANG_ADMIN_GROUPS_NO_GROUPS]: ["No groups found.", "Keine Gruppen gefunden."],
    [Keys.VDLANG_ADMIN_GROUPS_NO_USERS]: ["No users assigned", "Keine Benutzer zugewiesen"],
    [Keys.VDLANG_ADMIN_GROUPS_GROUP_NAME]: ["Name", "Name"],
    [Keys.VDLANG_ADMIN_GROUPS_GROUP_DESCRIPTION]: ["Description", "Beschreibung"],
    [`${Keys.VDLANG_USERS}_one`]: ["{{count}} User", "{{ count }} Benutzer/in"],
    [`${Keys.VDLANG_USERS}_other`]: ["{{count}} Users", "{{ count }} Benutzer/innen"],
    [Keys.VDLANG_USER_TIER]: ["User Tier", "Benutzerstufe"],
    [Keys.VDLANG_USER_TIER_HINT]: [
        "See the groups section in the create or edit dialog to get an overview of the possible permission for each user tier.",
        "Schauen Sie in den Gruppenbereich im Erstellungs- oder Bearbeitungsdialog, um einen Überblick über die möglichen Berechtigungen für jede Benutzerstufe zu erhalten.",
    ],
    [Keys.VDLANG_USER_TIERS]: {
        [UserTier.SuperAdmin]: ["Super Admin", "Super-Admin"],
        [UserTier.Admin]: ["Admin", "Admin"],
        [UserTier.Advanced]: ["Full User", "Full User"],
        [UserTier.Basic]: ["Contributor", "Mitwirkende/r"],
        [UserTier.Light]: ["Ideator", "Ideengeber/in"],
    },
    [Keys.VDLANG_ADMIN_GROUPS_CREATE]: ["Create group", "Gruppe erstellen"],
    [Keys.VDLANG_ADMIN_GROUPS_DIALOG_CREATE]: ["Create group", "Gruppe erstellen"],
    [Keys.VDLANG_ADMIN_GROUPS_DIALOG_UPDATE]: ["Update group", "Gruppe bearbeiten"],
    [Keys.VDLANG_ADMIN_GROUPS_DIALOG_NAME_LONG]: [
        "The group must have a name that is not too long.",
        "Die Gruppe muss einen Namen haben, der nicht zu lang ist.",
    ],
    [Keys.VDLANG_ADMIN_GROUPS_DIALOG_NAME_SHORT]: [
        "Name must contain at least one character.",
        "Name muss mindestens einen Buchstaben enthalten.",
    ],
    [Keys.VDLANG_ADMIN_GROUPS_DIALOG_DESCRIPTION_ERROR]: ["The description is too long.", "Die Beschreibung ist zu lang."],
    [Keys.VDLANG_ADMIN_GROUPS_DIALOG_CREATE_BUTTON]: ["Create", "Erstellen"],
    [Keys.VDLANG_ADMIN_GROUPS_DIALOG_UPDATE_BUTTON]: ["Save", "Speichern"],
    [Keys.VDLANG_ADMIN_GROUPS_EDIT_BUTTON]: ["Update group", "Gruppe bearbeiten"],
    [Keys.VDLANG_ADMIN_GROUPS_DUPLICATE_BUTTON]: ["Copy group", "Gruppe kopieren"],
    [Keys.VDLANG_ADMIN_GROUPS_DUPLICATE_DISABLED]: ["You cannot copy the admin group", "Sie können die Admin Gruppe nicht kopieren"],
    [Keys.VDLANG_ADMIN_GROUPS_DUPLICATE_ADDITION]: [" (copy)", " (Kopie)"],
    [Keys.VDLANG_ADMIN_GROUPS_DELETE_BUTTON]: ["Delete Group", "Gruppe löschen"],
    [Keys.VDLANG_ADMIN_GROUPS_TITLE_ALLGROUPS]: ["All Groups", "Alle Gruppen"],
    [Keys.VDLANG_ADMIN_GROUPS_REMOVE_USER]: ["Remove user from group", "Benutzer aus Gruppe entfernen?"],
    [Keys.VDLANG_ADMIN_GROUPS_REMOVE_USER_CONFIRM]: [
        "Do you really want to remove the user from the group?",
        "Möchten Sie den Benutzer wirklich aus der Gruppe entfernen?",
    ],
    [Keys.VDLANG_ADMIN_GROUPS_MANAGE_USERS_BUTTON]: ["Manage users", "Benutzer verwalten"],
    [Keys.VDLANG_ADMIN_GROUPS_DELETE_IN_USE_DISABLED]: [
        "Can't delete group in use",
        "Die Gruppe ist in Verwendung und kann nicht gelöscht werden",
    ],
    [Keys.VDLANG_ADMIN_GROUPS_SUBGROUPS_DISABLED]: ["Maximum group depth reached", "Maximale Gruppentiefe erreicht"],
    [Keys.VDLANG_ADMIN_GROUPS_SPECIAL_DISABLED]: [
        "You cannot modify this group. It is managed by Valuedesk and updated automatically.",
        "Sie können diese Gruppe nicht bearbeiten. Sie wird von Valuedesk verwaltet und automatisch aktualisiert.",
    ],
    [Keys.VDLANG_ADMIN_GROUPS_PERMISSION_DERIVED]: ["Inherited", "Vererbt"],
    remove_group_title: ["Delete Group?", "Gruppe löschen?"],
    remove_group_description: ["Do you want to delete this group?", "Möchten Sie die ausgewählte Gruppe wirklich löschen?"],
    remove_usergroup_title: ["Remove User from Group?", "Benutzer aus Gruppe entfernen?"],
    remove_usergroup_description: [
        "Do you really want to remove the user from this group?",
        "Möchten Sie den Benutzer wirklich aus der Gruppe entfernen?",
    ],
    [Keys.VDLANG_ADMIN_GROUPS_USERS]: ["Users", "Benutzer"],
    [Keys.VDLANG_ADMIN_GROUPS_GROUPS]: ["Groups", "Gruppen"],
    [Keys.VDLANG_ADMIN_GROUPS_SUBGROUPS]: ["Subgroups", "Untergruppen"],
    [Keys.VDLANG_ADMIN_GROUPS_ALL_USERS]: ["All Users", "Alle Benutzer"],
    [Keys.VDLANG_ADMIN_GROUPS_ALL_SUBGROUPS]: ["All Subgroups", "Alle Untergruppen"],
    [Keys.VDLANG_ADMIN_GROUPS_MANAGE_USERS_TITLE]: ["Manage Users", "Benutzer verwalten"],
    [Keys.VDLANG_ADMIN_GROUPS_ALL_USERS_TITLE]: ["All Users", "Alle Benutzer"],
    [Keys.VDLANG_ADMIN_GROUPS_ASSIGNED_USERS]: ["Assigned Users", "Zugewiesene Benutzer"],
    [Keys.VDLANG_ADMIN_GROUPS_CACHE_HINT]: [
        "It can take up to 2 minutes for changes to be visible in the application.",
        "Es kann bis zu 2 Minuten dauern, bis Änderungen in der Anwendung zu sehen sind.",
    ],
    [Keys.VDLANG_ADMIN_GROUPS_VISIBILITY]: ["Selectable", "Auswählbar"],
    [Keys.VDLANG_ADMIN_GROUPS_VISIBLE]: ["Selectable in access dialog", "Auswählbar im Zugriffsdialog"],
    [Keys.VDLANG_ADMIN_GROUPS_VISIBLE_HINT]: [
        "Enabling this option allows the group to be selected in access dialogs. If this checkbox is disabled, this group and all subgroups are hidden and cannot be selected in access dialogs.",
        "Durch Aktivierung dieser Option kann die Gruppe in Zugriffsdialogen ausgewählt werden. Wenn diese Option deaktiviert ist, werden diese Gruppe und alle Untergruppen ausgeblendet und können in Zugriffsdialogen nicht ausgewählt werden.",
    ],
    [Keys.VDLANG_ADMIN_GROUPS_HIDDEN]: ["Hidden", "Ausgeblendet"],

    "Create Company": ["Create Company", "Gesellschaft anlegen"],
    "Can't delete address in use": ["Can't delete location in use", "Der Standort ist in Verwendung und kann nicht gelöscht werden."],
    cannot_delete_company_in_use: ["Can't delete company in use", "Die Gesellschaft ist in Verwendung und kann nicht gelöscht werden."],
    "Add another Address": ["Add another location", "Weiteren Standort hinzufügen"],
    "Add Address": ["Add location", "Standort hinzufügen"],
    Currency: ["Currency", "Währung"],
    MEASURE_STATUS_DISCARDED: ["Discarded", "Verworfen"],
    MEASURE_STATUS_CLOSED: ["Closed", "Abgeschlossen"],
    MEASURE_STATUS_OPEN: ["Open", "Offen"],
    reset_user_password: ["Reset Password", "Passwort zurücksetzen"],
    [Keys.VDLANG_USER_RESET_2FA]: ["Reset two-factor authentication", "Zwei-Faktor-Authentifizierung zurücksetzen"],
    set_user_password: ["Invite user", "Benutzer einladen"],
    bulkActionSelect: ["Select action...", "Aktion auswählen..."],
    "Create Partner": ["Create Partner", "Partner anlegen"],

    of: ["of", "von"],
    registered_at: ["Registered at", "Registriert am"],
    last_modification: ["Last change", "Letzte Änderung"],
    invited_at: ["Invited at", "Eingeladen am"],
    last_login: ["Last login", "Letzte Anmeldung"],
    never: ["Never", "Nie"],
    "active members": ["Active users", "Aktive Benutzer"],
    "Edit Company": ["Edit Company", "Gesellschaft bearbeiten"],
    "Edit Process": ["Edit process", "Prozess bearbeiten"],
    reactivate: ["Reactivate", "Reaktivieren"],
    // dialogs
    create_item: ["Create {{item}}", "{{item}} erstellen"],
    edit_item: ["Edit {{item}}", "{{item}} bearbeiten"],
    supplier: ["Partner", "Partner"],

    // History table column names
    history_column_date: ["Date", "Datum"],
    history_column_user: ["User", "Benutzer"],
    history_column_time: ["Time", "Uhrzeit"],
    history_column_entry: ["Change", "Änderung"],
    history_changes: ["Changes", "Änderungen"],
    history_calculationIdentifier: ["Label", "Label"],

    // File table column names
    files_column_date: ["Date", "Datum"],
    files_column_user: ["Uploaded By", "Hochgeladen von"],
    files_column_delete: ["Delete", "Löschen"],
    files_column_actions: ["Action", "Aktion"],
    files_column_filename: ["Name", "Name"],
    files_pagination: ["Files", "Dokumente"],
    // feedback for admin
    "user-deactivated": ["User deactivated successfully", "Benutzer erfolgreich deaktiviert"],
    "user-reactivated": ["Reactivated user successfully", "Benutzer erfolgreich reaktiviert"],
    location_more: ["more", "weitere(r)"],
    "No locations": ["No locations", "Keine Standorte"],
    admin_self_remove_disabled: [
        "Admins cannot remove themselves from Admin permission",
        "Admins können sich nicht selbst die Admin Berechtigung entziehen",
    ],
    Remove: ["Remove", "Entfernen"],
    confirm_delete: ["Confirm delete", "Entfernen bestätigen"],
    VDLANG_USER_PROFILE_PASSWORD_SSO_INFO: [
        "You are using your corporate central logon service. Please contact your HelpDesk or administrator for a password change.",
        "Sie nutzen den zentralen Anmeldedienst Ihres Unternehmens. Bitte wenden Sie sich an Ihren HelpDesk oder Administrator für eine Passwortänderung.",
    ],
    confirm_password_reset_title: ["Reset password?", "Passwort zurücksetzen?"],
    confirm_password_reset_text: [
        "{{user}} will receive a mail with instructions how to reset the password.",
        "{{user}} erhält eine Email mit Anweisungen, um das Passwort zurückzusetzen.",
    ],
    confirm_invite_text: [
        "{{user}} will receive a mail with instructions how to set a password.",
        "{{user}} erhält eine Email mit Anweisungen, um ein Passwort zu setzen.",
    ],
    request_details_text: [
        "You can formulate a request to Valuedesk support here. We will contact you as soon as possible.",
        "Sie können hier eine Anfrage an Valuedesk formulieren. Wir werden uns dann schnellstmöglich mit Ihnen dazu in Verbindung setzen.",
    ],

    email: ["Email", "Email"],
    create_client: ["Create Client", "Client anlegen"],
    edit_client: ["Edit Client", "Client bearbeiten"],
    print_measure: ["Print {{processName}}", "{{processName}} drucken"],
    /* DECISION */
    decision: ["Decision", "Entscheidung"],
    decision_type_0: ["Document decision from {{name}}", "Entscheidung von {{name}} dokumentieren"],
    decision_type_1: ["Request decision from {{name}}", "Entscheidung von {{name}} anfragen"],
    decision_type_1_action: ["Request", "Anfragen"],
    decision_type_1_again: ["Request again", "Erneut anfragen"],

    decision_result_0: ["Discard", "Verwerfen"],
    decision_result_1: ["Implement", "Umsetzen"],
    who_should_decide: ["Who should make the decision?", "Wer soll die Entscheidung treffen?"],

    decider: ["Decider", "Entscheider:in"],
    decision_request_info: [
        "{{by}} has requested a decision from {{from}} on {{date}} at {{time}}.",
        "{{by}} hat am {{date}} um {{time}} Uhr eine Entscheidung von {{from}} angefragt.",
    ],
    decision_request_info_self: [
        "You have been requested for a decision on {{date}} at {{time}} by {{by}}.",
        "{{by}} hat am {{date}} um {{time}} Uhr eine Entscheidung von Ihnen angefragt.",
    ],
    discard_reason_placeholder: ["Add comment", "Kommentar hinzufügen"],
    decision_reason_placeholder: ["Document decision", "Entscheidung festhalten"],
    /* SuperAdminSection */
    remove_translation_title: ["Delete translation", "Übersetzung löschen"],
    remove_translation_description: [
        "Do you want to delete this translation?",
        "Möchten Sie die ausgewählte Übersetzung wirklich löschen?",
    ],
    create_translation: ["Create translation", "Übersetzung hinzufügen"],
    edit_translation: ["Edit translation", "Übersetzung bearbeiten"],
    delete_translation: ["Delete translation", "Übersetzung löschen"],
    create_cost_lever: ["Create Method", "Methode hinzufügen"],
    edit_cost_lever: ["Edit Method", "Methode bearbeiten"],
    delete_cost_lever: ["Delete Method", "Methode löschen"],
    save_cost_lever: ["Save Method", "Methode speichern"],
    remove_cost_lever_title: ["Delete Method?", "Methode löschen?"],
    create_domain: ["Create Domain", "Domain anlegen"],
    edit_domain: ["Edit Domain", "Domain bearbeiten"],
    save_domain: ["Save Domain", "Domain speichern"],
    delete_domain: ["Delete Domain", "Domain löschen"],
    remove_domain_title: ["Delete Domain?", "Domain löschen?"],
    create_relation: ["Create Relation", "Relation anlegen"],
    edit_relation: ["Edit Relation", "Relation bearbeiten"],
    save_relation: ["Save Relation", "Relation speichern"],
    delete_relation: ["Delete Relation", "Relation löschen"],
    remove_relation_title: ["Delete Relation?", "Relation löschen?"],
    remove_relation_description: ["The relation will be deleted permanently.", "Die Relation wird endgültig entfernt."],

    create_attributeCategory: ["Create Category", "Kategorie anlegen"],
    edit_attributeCategory: ["Edit Category", "Kategorie bearbeiten"],
    save_attributeCategory: ["Save Category", "Kategorie speichern"],
    delete_attributeCategory: ["Delete Category", "Kategorie löschen"],
    remove_attributeCategory_title: ["Delete Category?", "Kategorie löschen?"],
    remove_attributeCategory_description: [
        "The category will be deleted permanently from measure sidebar and opportunities.",
        "Die Kategorie wird endgültig aus der Maßnahmen-Seitenleiste und den Opportunitäten entfernt.",
    ],

    deleted_user: ["Deleted User", "Gelöschter Benutzer"],
    timezone: ["Timezone", "Zeitzone"],
    timezone_current: ["Use current browser timezone", "Nutze aktuelle Zeitzone des Browsers"],
    no_additional_information: ["Nothing", "Keine Anzeige"],
    edit_description: ["Description", "Beschreibung"],
    create_customfield: ["Create custom field", "CustomField anlegen"],
    edit_customfield: ["Edit custom field", "CustomField bearbeiten"],
    save_customfield: ["Save custom field", "CustomField speichern"],
    remove_customfield_title: ["Remove custom field?", "Customfield entfernen?"],
    remove_customfield_description: [
        "The custom field {{customField}} is not used anywhere.",
        "Das Customfield {{customField}} wird nicht verwendet.",
    ],
    remove_customvalue_title: ["Remove custom value?", "Customvalue entfernen?"],
    remove_customvalue_description: [
        "The custom value {{customValue}} is not used anywhere.",
        "Das Customvalue {{customValue}} wird nicht verwendet.",
    ],
    edit_customfieldorder: ["Edit custom field order", "Reihenfolge CustomField anpassen"],
    save_customfieldorder: ["Save custom field order", "Reihenfolge CustomField speichern"],
    edit_customvalueorder: ["Edit custom value order", "Reihenfolge CustomValue anpassen"],

    create_tree_node: ["Create tree node", "Baumknoten anlegen"],
    delete_tree_node: ["Delete tree node", "Baumknoten löschen"],
    update_tree_node: ["Edit tree node", "Baumknoten bearbeiten"],
    move_tree_node: ["Move tree node", "Baumknoten verschieben"],

    VDLANG_PRIORITY: ["Priority", "Priorität"],
    VDLANG_LOW: ["Low", "Niedrig"],
    VDLANG_MEDIUM: ["Medium", "Mittel"],
    VDLANG_HIGH: ["High", "Hoch"],

    VDLANG_ACTIVITY_OPEN: ["Open", "Offen"],
    VDLANG_ACTIVITY_DUESOON: ["Due soon", "Bald fällig"],
    VDLANG_ACTIVITY_OVERDUE: ["Overdue", "Überfällig"],
    VDLANG_ACTIVITY_COMPLETED: ["Completed", "Abgeschlossen"],

    [Keys.VDLANG_ACTIVITY_EFFORT_ESTIMATED_LABEL]: ["estimated", "geschätzt"],
    [Keys.VDLANG_ACTIVITY_TIME_TRACKED_LABEL]: ["needed", "benötigt"],
    [Keys.VDLANG_ACTIVITY_TIME_OVERDRAWN_LABEL]: ["overdrawn", "überzogen"],
    [Keys.VDLANG_ACTIVITY_EMPTY_LABEL]: ["Enter an estimation here.", "Geben Sie hier eine Schätzung ab."],
    [Keys.VDLANG_ACTIVITY_EFFORT_ESTIMATION_TITLE]: ["Effort estimation", "Geschätzter Aufwand"],
    [Keys.VDLANG_ACTIVITY_EFFORT_ESTIMATION_OVERVIEW]: ["Effort estimation overview", "Übersicht des geschätzten Aufwands"],
    [Keys.VDLANG_ACTIVITY_EFFORT_ESTIMATION_OVERVIEW_NO_VALUES]: [
        "No effort estimate for activities has been provided yet.",
        "Es wurde noch kein Aufwand für Aktivitäten geschätzt.",
    ],
    [Keys.VDLANG_ACTIVITY_EFFORT_ESTIMATION_OVERVIEW_TOTAL]: ["Total", "Insgesamt"],
    [Keys.VDLANG_ACTIVITY_TIME_TRACKED_INPUT_LABEL]: ["Time needed", "Benötigte Zeit"],
    [Keys.VDLANG_ACTIVITY_EFFORT_ESTIMATION_INPUT_LABEL]: ["Estimated effort", "Geschätzter Aufwand"],
    [Keys.VDLANG_ACTIVITY_EFFORT_FORMAT]: ["Use the format:", "Nutzen Sie das Format:"],
    [Keys.VDLANG_ACTIVITY_EFFORT_FORMAT_WEEKS]: ["weeks", "Wochen"],
    [Keys.VDLANG_ACTIVITY_EFFORT_FORMAT_DAYS]: ["days", "Tage"],
    [Keys.VDLANG_ACTIVITY_EFFORT_FORMAT_HOURS]: ["hours", "Stunden"],
    [Keys.VDLANG_ACTIVITY_EFFORT_FORMAT_MINUTES]: ["minutes", "Minuten"],

    VDLANG_GENERATION_LINEAR_TABLE_HINT: [
        "The values have been adjusted in the table. If you make changes here, the values in the table will be overwritten.",
        "Die Werte wurden in der Tabelle angepasst. Wenn Sie hier Veränderungen vornehmen, werden die Werte in der Tabelle überschrieben.",
    ],

    "create_search-config": ["Add view", "Ansicht hinzufügen"],
    "edit_search-config": ["Rename view", "Ansicht umbenennen"],

    // Effect types
    effect_type_1: ["Savings", "Einsparungen"],
    effect_type_2: ["Extra Costs", "Mehrkosten"],
    // dialog for
    create_effect_type_1: ["Add Savings", "Einsparungen anlegen"],
    create_effect_type_2: ["Add Extra Costs", "Mehrkosten anlegen"],
    edit_effect_type_1: ["Edit Savings", "Einsparungen bearbeiten"],
    edit_effect_type_2: ["Edit Extra Costs", "Mehrkosten bearbeiten"],
    remove_effect_type_1_title: ["Remove Savings?", "Einsparungen entfernen?"],
    remove_effect_type_2_title: ["Remove Extra Costs?", "Mehrkosten entfernen?"],
    remove_effect_type_1_description: [
        "By removing the Savings, the associated data is removed in all levels!",
        "Durch das Entfernen der Einsparungen werden die zugehörigen Daten in allen Härtegraden entfernt!",
    ],
    remove_effect_type_2_description: [
        "By removing the Extra Costs, the associated data is removed in all levels!",
        "Durch das Entfernen der Mehrkosten werden die zugehörigen Daten in allen Härtegraden entfernt!",
    ],

    "Unknown User": ["Unknown User", "Unbekannter Nutzer"],

    VDLANG_TYPE: ["Type", "Typ"],
    VDLANG_ALLOW_SELF_REGISTER: ["Auto register", "Selbstregistrierung"],
    VDLANG_VERIFIED_BY_VALUEDESK: ["Verified by Valuedesk", "Von Valuedesk verifiziert"],
    VDLANG_VERIFIED_BY_VALUEDESK_INFO: [
        "The ownership of this domain has been verified by Valuedesk.",
        "Der Inhaber dieser Domain wurde durch Valuedesk verifiziert.",
    ],
    VDLANG_VERIFIED_BY_VALUEDESK_DELETE: ["Can't delete verified domain.", "Verifizierte Domains können nicht gelöscht werden."],
    VDLANG_IDENTITY_PROVIDER_ASSIGNED_DELETE: [
        "Can't delete domain with a provider set.",
        "Domains mit einem gesetzten Anmeldedienst können nicht gelöscht werden.",
    ],
    VDLANG_USED_IN_IDENTITY_PROVIDER: ["Used in Sign-ons", "In Anmeldedienste genutzt"],
    VDLANG_DEFAULT_IDENTITY_PROVIDER_EXISTS: [
        "There already exists another default Sign-on.",
        "Es existiert bereits ein anderer Standard Anmeldedienst.",
    ],
    VDLANG_DEFAULT: ["Default", "Standard"],

    VDLANG_SHOW_MORE: ["Show more", "Mehr anzeigen"],
    VDLANG_SHOW_LESS: ["Show less", "Weniger anzeigen"],

    // Currency table
    VDLANG_CURRENCY_TABLE_COLUMN_NAME: ["Name", "Name"],
    VDLANG_CURRENCY_TABLE_COLUMN_CODE: ["Code", "Code"],
    VDLANG_PAGING_ROWS_PER_PAGE: ["Rows per page", "Zeilen pro Seite"],

    VDLANG_DUEDATE_AFTER_START_WARNING: [
        "The selected date is after the beginning of the effectiveness of the process.",
        "Das ausgewählte Datum liegt nach dem Beginn der Wirksamkeit des Prozesses.",
    ],

    // Departments table
    VDLANG_DEPARTMENT_TABLE_COLUMN_NAME_EN: ["Department name (EN)", "Abteilungsname (EN)"],
    VDLANG_DEPARTMENT_TABLE_COLUMN_NAME_DE: ["Department name (DE)", "Abteilungsname (DE)"],
    VDLANG_DEPARTMENT_TABLE_NO_DATA: ["No department created yet", "Noch keine Abteilung angelegt"],
    VDLANG_DEPARTMENT_TABLE_CANNOT_DELETE_IN_USE: [
        "Can't delete department in use.",
        "Die Abteilung ist in Verwendung und kann nicht gelöscht werden.",
    ],

    // Pulse Cell
    processPulseProcessChange: ["Process changes", "Prozessveränderungen"],
    processPulseActivityChange: ["Activity changes", "Aktivitätenveränderungen"],
    processPulseError: ["No data available", "Keine Daten vorhanden"],

    // Widget Types
    showAllWidgetTypeTemplates: ["All widgets", "Alle Widgets"],
    showCustomerSpecificWidgetTypeTemplates: ["Company widgets", "Unternehmenswidgets"],
    [WidgetType.ActivityList]: ["Activities list", "Aktivitätenliste"],
    [WidgetType.CompletedEffects]: ["Run-up diagram", "Hochlaufdiagramm"],
    [WidgetType.CustomBarChart]: ["Process bar chart", "Prozess-Balkendiagramm"],
    [WidgetType.FunnelChart]: ["Funnel chart", "Trichterdiagramm"],
    [WidgetType.ProcessList]: ["Process list", "Prozessliste"],
    [WidgetType.RollingForecast]: ["Rolling run-up diagram", "Rollierendes Hochlaufdiagramm"],
    [WidgetType.IdeaList]: ["Opportunities list", "Opportunitätenliste"],
    [WidgetType.IdeaMatrix]: ["Opportunities matrix", "Opportunitätenmatrix"],
    [WidgetType.ProcessWhiteSpotMatrix]: ["White spot analysis", "White-Spot-Analyse"],
    [WidgetType.CommentStream]: ["Comment stream", "Kommunikationsaktivität"],
    [WidgetType.LiveRunUp]: ["Live run-up", "Live-Hochlauf"],
    [WidgetType.StatusAggregation]: ["Status aggregation", "Status-Aggregation"],
    [WidgetType.PotentialKPI]: ["Potential KPI", "Potenzial Kennzahl"],
    [WidgetType.Timeline]: ["Weekly funnel progression", "Wöchentlicher Trichterverlauf"],
    [WidgetType.ProjectProgress]: ["Project progress", "Projektfortschritt"],
    [WidgetType.WeeklySavingsRunUp]: ["Weekly savings run-up", "Wöchentlicher Hochlauf"],
    [WidgetType.Waterfall]: ["Waterfall", "Wasserfall"],

    // Idea Section
    [Keys.VDLANG_IDEAS_IDEA]: ["Opportunity", "Opportunität"],
    [Keys.VDLANG_IDEAS_TITLE_EMPTY_ERROR]: [
        "Title must contain at least one character",
        "Titel muss mindestens einen Buchstaben enthalten",
    ],
    [Keys.VDLANG_IDEAS_TITLE_TOO_LONG_ERROR]: ["Title is too long", "Titel ist zu lang"],
    [Keys.VDLANG_IDEAS_NAVBAR_ITEM_LABEL]: ["Opportunities", "Opportunitäten"],
    [Keys.VDLANG_IDEAS_ITEMS_LABEL]: ["Opportunities", "Opportunitäten"],
    [Keys.VDLANG_IDEAS_NO_IDEAS_YET]: ["No opportunities have been created yet", "Es wurden noch keine Opportunitäten angelegt"],
    [Keys.VDLANG_IDEAS_ADD_IDEA_BUTTON_LABEL]: ["Capture opportunity", "Opportunität festhalten"],
    [Keys.VDLANG_IDEAS_SAVE_IDEA_BUTTON_LABEL]: ["Save opportunity", "Opportunität speichern"],
    [Keys.VDLANG_IDEAS_IDEA_FIELDS]: ["More fields", "Weitere Felder"],
    [Keys.VDLANG_IDEAS_IDEA_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_IDEAS_IDEA_COMMENTS]: ["Comments ({{count}})", "Kommentare ({{count}})"],
    [Keys.VDLANG_IDEAS_IDEA_HISTORY]: ["History", "Historie"],
    [Keys.VDLANG_IDEAS_IDEA_POTENTIAL]: ["Estimated potential", "Geschätztes Potenzial"],
    [Keys.VDLANG_IDEAS_IDEA_POTENTIAL_TOTAL_HINT]: [
        "The total potential is based on averages from intervals ranging from very low to very high estimates.",
        "Das Gesamtpotential basiert auf Durchschnittswerten der Intervalle, die von sehr niedrigen bis zu sehr hohen Schätzungen reichen.",
    ],
    [Keys.VDLANG_IDEAS_IDEA_PERIOD]: ["Estimated implementation period in months", "Geschätzter Umsetzungszeitraum in Monaten"],
    [Keys.VDLANG_IDEAS_IDEA_SHOW_CREATOR]: ["Show creator", "Ersteller:in anzeigen"],
    [Keys.VDLANG_IDEAS_IDEA_CREATOR_LABEL]: ["Creator", "Ersteller:in"],
    [Keys.VDLANG_IDEAS_IDEA_RESPONSIBLE_LABEL]: ["Responsible person", "Verantwortliche:r"],
    [Keys.VDLANG_IDEAS_FIELDS_NOT_SET]: ["Not set", "Nicht gesetzt"],
    [Keys.VDLANG_IDEAS_CONVERT_IDEA]: ["Convert to process", "In Prozess überführen"],
    [Keys.VDLANG_IDEAS_CONVERT]: ["Convert", "Überführen"],
    [Keys.VDLANG_IDEAS_DISCARDED]: ["Discarded", "Verworfen"],
    [Keys.VDLANG_IDEAS_CONVERTED]: ["Converted", "Überführt"],
    [Keys.VDLANG_IDEAS_OPEN]: ["Open", "Offen"],
    [Keys.VDLANG_IDEAS_SHOW_MEASURE]: ["Show process", "Prozess anzeigen"],
    [Keys.VDLANG_IDEAS_PROCESS_RESPONSIBLE]: ["Process responsible", "Prozessverantwortliche:r"],
    [Keys.VDLANG_IDEAS_DISCARD_IDEA]: ["Discard opportunity", "Opportunität verwerfen"],
    [Keys.VDLANG_IDEAS_DISCARD_TITLE]: ['Discard opportunity "{{opportunity}}"?', 'Opportunität "{{opportunity}}" verwerfen?'],
    [Keys.VDLANG_IDEAS_CONVERT_TITLE]: [
        'Convert opportunity "{{opportunity}}" to process?',
        'Opportunität "{{opportunity}}" in einen Prozess überführen?',
    ],
    [Keys.VDLANG_IDEAS_IDEA_POTENTIAL_ESTIMATE_LABEL]: [
        "Estimated potential in {{currencyCode}}",
        "Geschätztes Potenzial in {{currencyCode}}",
    ],
    [Keys.VDLANG_IDEAS_IDEA_TIME_ESTIMATE_LABEL]: [
        "Estimated implementation period in months",
        "Geschätzter Umsetzungszeitraum in Monaten",
    ],
    [Keys.VDLANG_IDEAS_TIME_ESTIMATE_SLIDER_VALUE]: {
        [EstimatesValue.VERY_LOW]: ["0-3", "0-3"],
        [EstimatesValue.LOW]: ["3-6", "3-6"],
        [EstimatesValue.MEDIUM]: ["6-9", "6-9"],
        [EstimatesValue.HIGH]: ["9-12", "9-12"],
        [EstimatesValue.VERY_HIGH]: [">12", ">12"],
    },
    [Keys.VDLANG_IDEAS_EFFORT_ESTIMATE_SLIDER_VALUE]: {
        [EstimatesValue.VERY_LOW]: ["Low", "Gering"],
        [EstimatesValue.LOW]: ["Rather low", "Eher gering"],
        [EstimatesValue.MEDIUM]: ["Medium", "Mittel"],
        [EstimatesValue.HIGH]: ["Rather high", "Eher hoch"],
        [EstimatesValue.VERY_HIGH]: ["High", "Hoch"],
    },

    [Keys.VDLANG_IDEAS_MONTH_LABEL]: ["Months", "Monate"],
    [Keys.VDLANG_IDEAS_SEARCH_RESULT]: ["No results for your search.", "Keine Treffer für Ihre Suche."],

    [Keys.VDLANG_IDEAS_TOGGLE_SEARCH]: ["Toggle opportunities search", "Opportunitäten-Suche umschalten"],
    [Keys.VDLANG_IDEAS_SORT_BY]: ["Sort opportunities by", "Opportunitäten sortieren nach"],
    [Keys.VDLANG_IDEAS_SORT_BY_CREATED_ASC]: ["Oldest first", "Älteste zuerst"],
    [Keys.VDLANG_IDEAS_SORT_BY_CREATED_DESC]: ["Newest first", "Neueste zuerst"],
    [Keys.VDLANG_IDEAS_SORT_BY_POTENTIAL_ASC]: ["Potential estimate ascending", "Potenzial aufsteigend"],
    [Keys.VDLANG_IDEAS_SORT_BY_POTENTIAL_DESC]: ["Potential estimate descending", "Potenzial absteigend"],
    [Keys.VDLANG_IDEAS_SORT_BY_TIME_ASC]: ["Time estimate ascending", "Umsetzungszeitraum aufsteigend"],
    [Keys.VDLANG_IDEAS_SORT_BY_TIME_DESC]: ["Time estimate descending", "Umsetzungszeitraum absteigend"],
    [Keys.VDLANG_IDEAS_SORT_BY_EFFORT_ASC]: ["Effort estimate ascending", "Geschätzter Aufwand aufsteigend"],
    [Keys.VDLANG_IDEAS_SORT_BY_EFFORT_DESC]: ["Effort estimate descending", "Geschätzter Aufwand absteigend"],
    [Keys.VDLANG_IDEAS_SORT_BY_LAST_MODIFICATION_ASC]: ["Last modification ascending", "Letzte Änderung aufsteigend"],
    [Keys.VDLANG_IDEAS_SORT_BY_LAST_MODIFICATION_DESC]: ["Last modification descending", "Letzte Änderung absteigend"],
    [Keys.VDLANG_IDEAS_CONVERT_IDEA_NOT_ALLOWED]: [
        "Cannot convert opportunity due to missing permission",
        "Opportunität kann aufgrund von fehlender Berechtigung nicht überführt werden",
    ],
    [Keys.VDLANG_IDEAS_SAVE_AND_CONVERT_INFO]: [
        "Unsaved Changes will be saved and the opportunity will be converted to a process. This action cannot be undone.",
        "Nicht gespeichert Änderungen werden gespeichert und die Opportunität wird in einen Prozess überführt. Diese Aktion kann nicht rückgängig gemacht werden.",
    ],
    [Keys.VDLANG_IDEAS_NO_PROCESS_RESPONSIBILITY_INFO]: [
        "The person responsible for this opportunity does not have permission to be responsible for processes. Please select an eligible user below to continue.",
        "Die für diese Opportunität verantwortliche Person ist nicht berechtigt, verantwortlich für Prozesse zu sein. Bitte wählen Sie unten einen berechtigten Nutzer aus, um fortzufahren.",
    ],
    [Keys.VDLANG_IDEAS_SAVE_AND_CONVERT]: ["Save opportunity & convert", "Opportunität speichern & überführen"],
    [Keys.VDLANG_IDEAS_DISCARDED_BY_INFO]: [
        `Discarded by {{discarder}} on {{date}} at {{time}}.`,
        `Von {{discarder}} am {{date}} um {{time}} Uhr verworfen.`,
    ],
    [Keys.VDLANG_IDEAS_DISCARDED_BY_INFO_REASON]: [`Discard reason: {{reason}}`, `Verwerfungsgrund: {{reason}}`],
    [Keys.VDLANG_IDEAS_DISCARDED_BY_INFO_STATEMENT]: [`Discard statement: {{statement}}`, `Verwerfungskommentar: {{statement}}`],
    [Keys.VDLANG_IDEAS_CONVERTED_FROM_INFO]: [
        "Converted to a process by {{userName}} on {{date}} at {{time}}.",
        "Von {{userName}} am {{date}} um {{time}} Uhr in einen Prozess überführt.",
    ],
    [Keys.VDLANG_IDEAS_EFFORT_ESTIMATE_LABEL]: ["Effort estimation", "Geschätzter Aufwand"],
    [Keys.VDLANG_IDEAS_DESCRIPTION_PREFIX]: [
        "The following information was entered in the opportunity",
        "Folgende Informationen wurden in der Opportunität hinterlegt",
    ],

    [Keys.VDLANG_IDEAS_MATRIX_HEADER]: ["Potential Matrix", "Potenzialmatrix"],
    [Keys.VDLANG_IDEAS_MATRIX_TIME_ESTIMATE]: ["Estimated implementation period", "Geschätzter Umsetzungszeitraum"],
    [Keys.VDLANG_IDEAS_MATRIX_TIME_ESTIMATE_LOW]: ["Short", "Kurz"],
    [Keys.VDLANG_IDEAS_MATRIX_TIME_ESTIMATE_HIGH]: ["Long", "Lang"],
    [Keys.VDLANG_IDEAS_MATRIX_POTENTIAL_ESTIMATE]: ["Potential", "Potenzial"],
    [Keys.VDLANG_IDEAS_MATRIX_POTENTIAL_ESTIMATE_LOW]: ["Low", "Niedrig"],
    [Keys.VDLANG_IDEAS_MATRIX_POTENTIAL_ESTIMATE_HIGH]: ["High", "Hoch"],
    [Keys.VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE]: ["Estimated efforts", "Geschätzter Aufwand"],
    [Keys.VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE_LOW]: ["Low", "Niedrig"],
    [Keys.VDLANG_IDEAS_MATRIX_EFFORT_ESTIMATE_HIGH]: ["High", "Hoch"],
    [Keys.VDLANG_IDEAS_MATRIX_NO_SORT]: [
        "Opportunities can be sorted only in tile view",
        "Opportunitäten können nur in der Kachel-Ansicht sortiert werden",
    ],
    [Keys.VDLANG_IDEAS_MATRIX_SECTION_QUICKWIN]: ["Quick wins", "Quick wins"],
    [Keys.VDLANG_IDEAS_MATRIX_SECTION_FUTURE]: ["Securing the future", "Die Zukunft sichern"],
    [Keys.VDLANG_IDEAS_MATRIX_SECTION_SKIP]: ["Skip for now", "Vorerst weglassen"],
    [Keys.VDLANG_IDEAS_MATRIX_SECTION_WHEN_TIME]: ["When there is time", "Wenn Zeit ist"],

    [Keys.VDLANG_IDEAS_MATRIX_X_AXIS]: ["X-Axis", "X-Achse"],
    [`${Keys.VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_HINT}_one`]: [
        "{{ count }} opportunity is hidden, because {{ xAxisLabel }} or {{ yAxisLabel }} are not set.",
        "{{ count }} Opportunität ist ausgeblendet, da {{ xAxisLabel }} oder {{ yAxisLabel }} nicht ausgefüllt sind.",
    ],
    [`${Keys.VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_HINT}_other`]: [
        "{{ count }} opportunities are hidden, because {{ xAxisLabel }} or {{ yAxisLabel }} are not set.",
        "{{ count }} Opportunitäten sind ausgeblendet, da {{ xAxisLabel }} oder {{ yAxisLabel }} nicht ausgefüllt sind.",
    ],
    [Keys.VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_SUMMARY_HINT]: [
        "If you have not set values for the selected axes, the opportunities will not be displayed in the matrix.",
        "Wenn Sie keine Werte für die ausgewählten Achsen festgelegt haben, werden die Opportunitäten nicht in der Matrix angezeigt.",
    ],
    [`${Keys.VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_SUMMARY}_one`]: ["{{ count }} has unset values", "{{ count }} hat nicht gesetzte Werte"],
    [`${Keys.VDLANG_IDEAS_MATRIX_HIDDEN_IDEAS_SUMMARY}_other`]: [
        "another {{ count }} have unset values",
        "weitere {{ count }} haben nicht gesetzte Werte",
    ],

    [Keys.VDLANG_IDEAS_TAB_GRID]: ["Grid view", "Rasteransicht"],
    [Keys.VDLANG_IDEAS_TAB_MATRIX]: ["Matrix view", "Matrixansicht"],
    [Keys.VDLANG_IDEAS_TAB_TABLE]: ["Table view", "Tabellenansicht"],

    [Keys.VDLANG_IDEAS_NUMBER_OF_IDEAS]: ["Number of opportunities", "Anzahl der Opportunitäten"],
    [Keys.VDLANG_IDEAS_SEARCH_POTENTIAL]: ["Potential", "Potenzial"],
    [Keys.VDLANG_IDEAS_SEARCH_TIME]: ["Implementation period", "Umsetzungszeitraum"],
    [Keys.VDLANG_IDEAS_SEARCH_EFFORT]: ["Effort", "Aufwand"],
    [Keys.VDLANG_IDEAS_SEARCH_STATUS]: ["Status", "Status"],
    [Keys.VDLANG_IDEAS_TABLE_OPTIONS]: ["Table options", "Tabellenoptionen"],

    [Keys.VDLANG_OPENAI_MEASURE_HINT]: [
        "By requesting additional suggestions for a measure some measure information are sent to the OpenAI services.",
        "Bei der Anforderung von Vorschlägen für eine Maßnahme werden einige Maßnahmeninformationen an die OpenAI-Dienste gesendet.",
    ],
    [Keys.VDLANG_MEASURE_SUGGESTIONS_ACTION_AREA]: ["Action area suggestion", "Empfehlung für Handlungsfeld"],

    [Keys.VDLANG_DATA_IMPORT_TITLE]: ["Data import", "Datenimport"],
    [Keys.VDLANG_DATA_IMPORT_DIALOG_TITLE]: ["Generate document", "Dokument generieren"],
    [Keys.VDLANG_DATA_IMPORT_DIALOG_PROCESS_MAIN]: ["Process main", "Prozess Hauptdaten"],
    [Keys.VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION]: ["Process calculation", "Prozess Berechnung"],
    [Keys.VDLANG_DATA_IMPORT_DIALOG_OPPS]: ["Opportunities", "Opportunitäten"],
    [Keys.VDLANG_DATA_IMPORT_ACTION_GENERATE]: ["Generate", "Generieren"],

    [Keys.VDLANG_DATA_IMPORT_DIALOG_PROCESS_MAIN_DESC]: [
        "Generate an Excel spreadsheet template to import the basic data of a process (name, description, fields, ...).",
        "Generieren Sie eine Excel-Tabellenvorlage, um die Grunddaten eines Prozesses zu importieren (Name, Beschreibung, Felder, ...).",
    ],
    [Keys.VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION_DESC]: [
        "Generate an Excel spreadsheet template to import the calculation data of a process.",
        "Generieren Sie eine Excel-Tabellenvorlage, um die Berechnungsdaten eines Prozesses zu importieren.",
    ],
    [Keys.VDLANG_DATA_IMPORT_DIALOG_OPPS_DESC]: [
        "Generate an Excel spreadsheet template to import opportunities.",
        "Generieren Sie eine Excel-Tabellenvorlage zum Importieren von Opportunitäten.",
    ],
    [Keys.VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION_EFFECT_DATE_RANGE]: ["Effect date range", "Wirkungszeitraum"],
    [Keys.VDLANG_DATA_IMPORT_DIALOG_PROCESS_CALCULATION_NO_EFFECTS]: [
        "This process type has no calculation level.",
        "Dieser Prozesstyp hat keinen Berechnungs-Härtegrad.",
    ],

    [Keys.VDLANG_DATA_IMPORT_PROCESS_TYPE]: ["Process type", "Prozesstyp"],
    [Keys.VDLANG_DATA_IMPORT_UPLOAD_DOCUMENT]: ["Upload document", "Dokument hochladen"],
    [Keys.VDLANG_DATA_IMPORT_TABLE_NO_DATA]: ["No data has been uploaded", "Es wurden noch keine Daten hochgeladen"],
    [`${Keys.VDLANG_DATA_IMPORT_TABLE_NUMBER_ITEMS}_one`]: [
        "{{count}} data set is to be imported",
        "{{count}} Datensatz soll importiert werden",
    ],
    [`${Keys.VDLANG_DATA_IMPORT_TABLE_NUMBER_ITEMS}_other`]: [
        "{{count}} data sets are to be imported",
        "{{count}} Datensätze sollen importiert werden",
    ],
    [`${Keys.VDLANG_DATA_IMPORT_TABLE_NUMBER_UPLOADED_ITEMS}_one`]: [
        "{{numberUploadedData}} of {{count}} data set was imported",
        "{{numberUploadedData}} von {{count}} Datensatz wurde importiert",
    ],
    [`${Keys.VDLANG_DATA_IMPORT_TABLE_NUMBER_UPLOADED_ITEMS}_other`]: [
        "{{numberUploadedData}} of {{count}} data sets were imported",
        "{{numberUploadedData}} von {{count}} Datensätzen wurden importiert",
    ],
    [`${Keys.VDLANG_DATA_IMPORT_TABLE_NUMBER_ERROR_ITEMS}_one`]: ["{{count}} error was found", "Es wurde {{count}} Fehler gefunden"],
    [`${Keys.VDLANG_DATA_IMPORT_TABLE_NUMBER_ERROR_ITEMS}_other`]: ["{{count}} errors were found", "Es wurden {{count}} Fehler gefunden"],
    [Keys.VDLANG_DATA_IMPORT]: ["Data import", "Datenimport"],
    [Keys.VDLANG_DATA_IMPORT_TABLE_CLEAR_DATA]: ["Clear", "Löschen"],

    [Keys.VDLANG_DATA_IMPORT_TABLE_STATUS_ADD]: ["Add", "Hinzufügen"],
    [Keys.VDLANG_DATA_IMPORT_TABLE_STATUS_UPDATE]: ["Update", "Aktualisieren"],
    [Keys.VDLANG_DATA_IMPORT_TABLE_STATUS_PENDING]: ["Pending", "Ausstehend"],
    [Keys.VDLANG_DATA_IMPORT_TABLE_STATUS_CANCELLED]: ["Cancelled", "Abgebrochen"],
    [Keys.VDLANG_DATA_IMPORT_NAVIGATION_PROMPT]: [
        "Upload is still in progress and will be aborted if you leave the page",
        "Der Upload ist noch nicht abgeschlossen und wird abgebrochen, wenn Sie die Seite verlassen",
    ],
    [Keys.VDLANG_DATA_IMPORT_BUTTON_CANCEL]: ["Cancel import", "Import abbrechen"],
    [Keys.VDLANG_DATA_IMPORT_BUTTON_RESUME]: ["Resume import", "Import fortsetzen"],
    [`${Keys.VDLANG_DATA_IMPORT_INFO_DELETED}_one`]: ["{{count}} data set was deleted", "{{count}} Datensatz wurde gelöscht"],
    [`${Keys.VDLANG_DATA_IMPORT_INFO_DELETED}_other`]: ["{{count}} data sets were deleted", "{{count}} Datensätze wurden gelöscht"],
    [Keys.VDLANG_DATA_IMPORT_INFO_CANCELLED]: ["Import was cancelled", "Import wurde abgebrochen"],
    [Keys.VDLANG_DATA_IMPORT_PARSE_ERROR]: ["Error parsing file: {{error}}", "Datei konnte nicht gelesen werden: {{error}}"],
    [Keys.VDLANG_DATA_IMPORT_CONFIG_SHEET_NOT_FOUND]: [
        'Configuration sheet "vd_config" not found. Has it been deleted?',
        'Tabellenblatt "vd_config" zur Konfiguration nicht gefunden. Wurde es gelöscht?',
    ],
    [Keys.VDLANG_DATA_IMPORT_MAIN_SHEET_NOT_FOUND]: [
        'Worksheet "Valuedesk" not found. Has it been deleted?',
        'Tabellenblatt "Valuedesk" nicht gefunden. Wurde es gelöscht?',
    ],
    [Keys.VDLANG_REQUEST_DIALOG_TEXT_TOO_LONG]: ["The entered text is too long", "Der eingegebene Text ist zu lang"],
    [Keys.VDLANG_REQUEST_DIALOG_EMPTY_TEXT]: [
        "The request must contain at least one character",
        "Die Anfrage muss mindestens einen Buchstaben enthalten",
    ],

    [Keys.VDLANG_REACTION_EMOJI_LIKE]: ["I like it", "Gefällt mir"],
    [Keys.VDLANG_REACTION_EMOJI_LIGHTBULB]: ["Insightful", "Aufschlussreich"],
    [Keys.VDLANG_REACTION_EMOJI_STRONG]: ["Strong", "Stark"],
    [Keys.VDLANG_REACTION_EMOJI_PARTY]: ["Celebrate", "Feiern"],
    [Keys.VDLANG_REACTION_EMOJI_THINKING]: ["Thoughtful", "Nachdenklich"],
    [Keys.VDLANG_REACTION_REACT]: ["React", "Reagieren"],
    [`${Keys.VDLANG_REACTION_REACTION}_one`]: ["{{ count }} reaction", "{{ count }} Reaktion"],
    [`${Keys.VDLANG_REACTION_REACTION}_other`]: ["{{ count }} reactions", "{{ count }} Reaktionen"],
    [`${Keys.VDLANG_REACTION_REACTION_UNIFORM}_one`]: ['{{ count }} "{{ type }}" reaction', '{{ count }} "{{ type }}" Reaktion'],
    [`${Keys.VDLANG_REACTION_REACTION_UNIFORM}_other`]: ['{{ count }} "{{ type }}" reactions', '{{ count }} "{{ type }}" Reaktionen'],
    [Keys.VDLANG_REACTION_REACTIONCHIP]: ["Show likes", '"Gefällt mir"-Angaben anzeigen'],

    [Keys.VDLANG_SUPERADMIN_VALUE_TOO_LARGE]: [
        "Value is too large and cannot be saved",
        "Wert ist zu hoch und kann nicht gespeichert werden",
    ],
    [Keys.VDLANG_SUPERADMIN_TRANSLATION_KEY_ALREADY_IN_USE]: [
        "Translation key is already in use in the selected client",
        "Übersetzungsschlüssel wird in dem ausgewählten Client bereits verwendet",
    ],
    [Keys.VDLANG_SUPERADMIN_INTERNAL_NAME_ALREADY_IN_USE]: [
        "Name is already in use in the selected client",
        "Name wird in dem ausgewählten Client bereits verwendet",
    ],
    [Keys.VDLANG_SUPERADMIN_INTERNAL_NAME_HINT]: [
        "Supported characters: a-z, A-Z, 0-9. First character must be a-z.",
        "Unterstützte Schriftzeichen: a-z, A-Z, 0-9. Erstes Schriftzeichen muss a-z sein.",
    ],

    [Keys.VDLANG_VALUE_TOO_LONG_SHORT_TEXT]: [
        "Value must not be longer than 255 characters.",
        "Der Wert darf nicht länger als 255 Zeichen sein.",
    ],
    [Keys.VDLANG_ACL_NAMESPACES]: {
        [AclNamespaces.Valuestream]: ["Valuestream", "Valuestream"],
        [AclNamespaces.Dashboard]: ["Dashboard", "Dashboard"],
        [AclNamespaces.Idea]: ["Opportunity", "Opportunität"],
        [AclNamespaces.Process]: ["Process", "Prozess"],
        [AclNamespaces.Activity]: ["Activity", "Aktivität"],
        [AclNamespaces.Api]: ["API", "API"],
        [AclNamespaces.Feed]: ["News", "Neuigkeiten"],
        [AclNamespaces.Method]: ["Method", "Methode"],
        [AclNamespaces.Supplier]: ["Partner", "Partner"],
        [AclNamespaces.User]: ["User", "Benutzer"],
        [AclNamespaces.GateTask]: ["Level", "Härtegrad"],
        [AclNamespaces.Admin]: ["Admin", "Admin"],
        [AclNamespaces.SuperAdmin]: ["SUPER ADMIN", "SUPER ADMIN"],
    },
    [Keys.VDLANG_ACL_PERMISSIONS]: {
        [AclPermissions.Access]: ["Access", "Zugreifen"],
        [AclPermissions.Delete]: ["Delete", "Löschen"],
        [AclPermissions.Read]: ["Read", "Lesen"],
        [AclPermissions.Update]: ["Edit", "Bearbeiten"],
        [AclPermissions.Create]: ["Create", "Erstellen"],

        [AclPermissions.Import]: ["Import", "Importieren"],
        [AclPermissions.Export]: ["Export", "Exportieren"],
        [AclPermissions.FileUpload]: ["File Upload", "Datei-Upload"],

        [AclPermissions.Filter]: ["Filter", "Filter"],
        [AclPermissions.Complete]: ["Complete", "Abschließen"],
        [AclPermissions.Decide]: ["Decide", "Entscheiden"],
        [AclPermissions.SelfDecision]: ["Self-decision", "Eigenentscheidung"],
        [AclPermissions.Responsible]: ["Responsible", "Verantwortlich"],
    },
    [Keys.VDLANG_ACL_NAMESPACE]: ["Namespace", "Namensraum"],
    [Keys.VDLANG_ACL_PERMISSION]: ["Permission", "Berechtigung"],
    [Keys.VDLANG_ACL_RULE]: ["Rule", "Regel"],
    [Keys.VDLANG_ACL_SIMPLE_ENTITY_ID]: ["Entity ID", "Entitäten ID"],
    [Keys.VDLANG_ACL_USER_GROUP]: ["User / Group", "Benutzer:in / Gruppe"],
    [Keys.VDLANG_ACL_PERMISSION_ORIGIN]: ["Permission origin", "Berechtigungsursprung"],
    [Keys.VDLANG_ACL_DIRECT]: ["Direct", "Direkt"],
    [Keys.VDLANG_ACL_INDIRECT]: ["Indirect", "Indirekt"],
    [Keys.VDLANG_ACL_ADD_RULE]: ["Add rule", "Regel hinzufügen"],
    [Keys.VDLANG_ACL_MANAGE_ACCESS]: ["Manage access", "Zugriff verwalten"],
    [Keys.VDLANG_ACL_VIEW_EFFECTIVE_PERMISSIONS]: ["View effective permissions", "Effektive Berechtigungen anzeigen"],
    [Keys.VDLANG_ACL_SELECT_USER_GROUP_PLACEHOLDER]: ["Select group/user", "Gruppe/Benutzer:in auswählen"],
    [Keys.VDLANG_ACL_EFFECTIVE_PERMISSIONS_SELECTION_REQUIRED]: [
        "Select a user or group to view the permissions.",
        "Wählen Sie eine/n Benutzer:in oder eine Gruppe aus, um die Berechtigungen anzuzeigen.",
    ],
    [Keys.VDLANG_ACL_TITLE_VALUESTREAM]: ["Valuestream", "Valuestream"],
    [Keys.VDLANG_ACL_TITLE_DASHBOARD]: ["Dashboard", "Dashboard"],
    [Keys.VDLANG_ACL_TITLE_PROCESS]: ["Process", "Prozess"],
    [Keys.VDLANG_ACL_TITLE_PROCESS_CREATION]: ["Process creation", "Prozesserstellung"],
    [Keys.VDLANG_ACL_TITLE_PROCESS_STATUS_VISBILITY]: ["Process status", "Prozessstatus"],
    [Keys.VDLANG_ACL_TITLE_SUPPLIER_CREATION]: ["Partner creation", "Partnererstellung"],
    [Keys.VDLANG_ACL_TITLE_PERSON_FILTER]: ["Person filter", "Personenfilter"],
    [Keys.VDLANG_ACL_HINT_PERSON_FILTER]: [
        "Can filter without restriction by person. Without this permission it is only possible to filter by oneself.",
        "Kann uneingeschränkt nach Personen filtern. Ohne diese Berechtigung ist es nur möglich nach sich selbst zu filtern.",
    ],
    [Keys.VDLANG_ACL_TITLE_METHOD_FILE_UPLOAD]: ["Method section file upload", "Methodenbereich Datei-Upload"],
    [Keys.VDLANG_ACL_HINT_METHOD_FILE_UPLOAD]: [
        "This additional permission enables a user to upload/delete documents in the method section.",
        "Diese zusätzliche Berechtigung ermöglicht es einem Benutzer Dokumente im Methoden Bereich hochzuladen und zu löschen.",
    ],
    [Keys.VDLANG_ACL_TITLE_CAN_COMPLETE_EFFECTIVENESS]: ["Complete last level", "Letzten Härtegrad abschließen"],
    [Keys.VDLANG_ACL_HINT_CAN_COMPLETE_EFFECTIVENESS]: [
        "Can complete the last level and thus also complete processes",
        "Darf den letzten Härtegrad und somit auch Prozesse abschließen",
    ],
    [Keys.VDLANG_ACL_TITLE_ACCESS_PERMISSION]: ["Access", "Zugriff"],
    [Keys.VDLANG_ACL_HINT_ACCESS_PERMISSION]: [
        "User can access and use the area (read permission on data required for some areas as well).",
        "Der Benutzer kann auf den Bereich zugreifen und ihn benutzen (Leseberechtigung für Daten in einigen Bereichen erforderlich).",
    ],
    [Keys.VDLANG_ACL_TITLE_MEASURE_PERMISSION]: ["Process permission", "Prozessberechtigungen"],
    [Keys.VDLANG_ACL_TITLE_API_PERMISSION]: ["API permission", "API-Berechtigungen"],
    [Keys.VDLANG_ACL_DISABLED_OTHER]: [
        "These rules are managed in a dedicated area.",
        "Diese Regeln werden über einen eigenen Bereich verwaltet.",
    ],
    [Keys.VDLANG_ACL_SHOW_ALL_PERMISSIONS]: ["Include entity related rules", "Einbeziehen von entitätsbezogenen Regeln"],
    acls: ["Permissions", "Berechtigungen"],
    remove_rule_title: ["Delete Rule?", "Regel löschen?"],
    remove_rule_description: ["Do you want to delete this rule?", "Möchten Sie die Regel wirklich löschen?"],

    [`${Keys.VDLANG_NUMBER_USERS_IN_GROUP}_one`]: ["{{count}} user", "{{count}} Benutzer"],
    [`${Keys.VDLANG_NUMBER_USERS_IN_GROUP}_other`]: ["{{count}} users", "{{count}} Benutzer"],
    [Keys.VDLANG_ACL_PERMISSION_TABLE_PROCESS_NOT_FOUND]: ["Process not found (ID: {{id}})", "Prozess nicht gefunden (ID: {{id}})"],
    [Keys.VDLANG_ACL_PERMISSION_TABLE_DASHBOARD_NOT_FOUND]: ["Dashboard not found (ID: {{id}})", "Dashboard nicht gefunden (ID: {{id}})"],
    [Keys.VDLANG_ACL_PERMISSION_TABLE_VALUESTREAM_NOT_FOUND]: [
        "Valuestream not found (ID: {{id}})",
        "Valuestream nicht gefunden (ID: {{id}})",
    ],

    [Keys.VDLANG_SUPPLIERS_NAME_ALREADY_EXISTS]: [
        "A partner with this name already exists.",
        "Ein Partner mit diesem Namen existiert bereits.",
    ],
    [Keys.VDLANG_SUPPLIERS_NAME_TOO_LONG]: ["Name is too long", "Name is zu lang"],
    [Keys.VDLANG_SUPPLIERS_NAME_TOO_SHORT]: ["Name must contain at least one character", "Name muss mindestens einen Buchstaben enthalten"],
    [Keys.VDLANG_PERMISSION_DIALOG_NO_USERS_FOUND]: ["No users found", "Keine Benutzer gefunden"],
    [Keys.VDLANG_PERMISSION_DIALOG_NO_GROUPS_FOUND]: ["No groups found", "Keine Gruppen gefunden"],
    [Keys.VDLANG_PERMISSION_DIALOG_NO_RULES_FOUND]: ["No rules found", "Keine Regeln gefunden"],
    [Keys.VDLANG_PERMISSION_DIALOG_ONLY_PERSON_CAN_CHANGE]: [
        "Only {{person}} can manage access.",
        "Nur {{person}} kann den Zugriff verwalten.",
    ],
    [Keys.VDLANG_ACL_TABLE_STATUS]: ["Status", "Status"],

    [Keys.VDLANG_ACL_ACCESS_PERMISSION_API]: [
        "Allowed to access the 'Integrations and APIs' page",
        "Darf auf die 'Integrationen und APIs'-Seite zugreifen",
    ],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_API_HINT]: [
        "This additional permission allows the user to generate an API token in their settings.",
        "Diese zusätzliche Berechtigung ermöglicht es dem Benutzer, in seinen Einstellungen ein API-Token zu generieren.",
    ],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_IDEA]: [
        "Allowed to access the 'Opportunities' page",
        "Darf auf die 'Opportunitäten'-Seite zugreifen",
    ],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_IDEA_HINT]: [
        "This additional permission allows the user to access the 'Opportunities' page.",
        "Diese zusätzliche Berechtigung erlaubt es dem Benutzer, auf die 'Opportunitäten'-Seite zuzugreifen.",
    ],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_ACTIVITY]: ["Allowed to access the 'Activities' page", "Darf auf die 'Aktivitäten'-Seite zugreifen"],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_ACTIVITY_HINT]: [
        "This additional permission allows the user to see the 'Activities' page.",
        "Diese zusätzliche Berechtigung erlaubt es dem Benutzer, auf die 'Aktivitäten'-Seite zuzugreifen.",
    ],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_DASHBOARD]: ["Allowed to access the 'Dashboard' page", "Darf auf die 'Dashboard'-Seite zugreifen"],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_DASHBOARD_HINT]: [
        "This additional permission allows the user to see the 'Dashboard' page.",
        "Diese zusätzliche Berechtigung erlaubt es dem Benutzer, auf die 'Dashboard'-Seite zuzugreifen.",
    ],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_FEED]: ["Allowed to access the 'Feed' page", "Darf auf die 'Neuigkeiten'-Seite zugreifen"],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_FEED_HINT]: [
        "This additional permission allows the user to see the 'Feed' page.",
        "Diese zusätzliche Berechtigung erlaubt es dem Benutzer, auf die 'Neuigkeiten'-Seite zuzugreifen.",
    ],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_METHOD]: [
        "Allowed to access the 'Method Explorer' page",
        "Darf auf die 'Methoden-Explorer'-Seite zugreifen",
    ],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_METHOD_HINT]: [
        "This additional permission allows the user to see the 'Method Explorer' page.",
        "Diese zusätzliche Berechtigung erlaubt es dem Benutzer, auf die 'Methoden-Explorer'-Seite zuzugreifen.",
    ],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_PROCESS]: ["Allowed to access the 'Processes' page", "Darf auf die 'Prozesse'-Seite zugreifen"],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_PROCESS_HINT]: [
        "This additional permission allows the user to see the 'Processes' page.",
        "Diese zusätzliche Berechtigung erlaubt es dem Benutzer, auf die 'Prozesse'-Seite zuzugreifen.",
    ],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_SUPPLIER_HINT]: [
        "With this additional permission, the user can create new partners from within a process.",
        "Mit dieser zusätzlichen Berechtigung kann der Benutzer neue Partner aus einem Prozess heraus anlegen.",
    ],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_ADMIN]: [
        "Allowed to access the 'Administration' page",
        "Darf auf die 'Administration'-Seite zugreifen",
    ],
    [Keys.VDLANG_ACL_ACCESS_PERMISSION_ADMIN_HINT]: [
        "Can manage users, companies, processes and organization data.",
        "Kann Benutzer, Gesellschaften, Prozesse und Unternehmensdaten verwalten.",
    ],

    [Keys.VDLANG_ACL_MEASURE_PERMISSION_CSV_DOWNLOAD]: ["Can use the CSV export", "Darf den CSV-Export benutzen"],
    [Keys.VDLANG_ACL_MEASURE_PERMISSION_CSV_DOWNLOAD_HINT]: [
        "This additional permission allows the user to export all process data which is visible for this person.",
        "Diese zusätzliche Berechtigung erlaubt es dem Benutzer, alle Prozess-Daten zu exportieren, die für diese Person sichtbar sind.",
    ],
    [Keys.VDLANG_ACL_MEASURE_PERMISSION_EXCEL_IMPORT]: ["May import data from Excel", "Darf Daten aus Excel importieren"],
    [Keys.VDLANG_ACL_MEASURE_PERMISSION_EXCEL_IMPORT_HINT]: [
        "This additional permission enables a user to import data from generated Excel files.",
        "Diese zusätzliche Berechtigung ermöglicht es einem Benutzer Daten aus generierten Excel Dateien zu importieren.",
    ],
    [Keys.VDLANG_ACL_MEASURE_PERMISSION_ALWAYS_DECIDE]: ["Can always decide", "Darf immer entscheiden"],
    [Keys.VDLANG_ACL_MEASURE_PERMISSION_ALWAYS_DECIDE_HINT]: [
        "This additional permission allows the user to be selected as the decision maker for any visible process.",
        "Diese zusätzliche Berechtigung ermöglicht es dem Benutzer bei jedem sichtbaren Prozess als Entscheider:in auswählbar zu sein.",
    ],
    [Keys.VDLANG_ACL_MEASURE_PERMISSION_CAN_DECIDE_PROCESS_ON_OWN]: [
        "Can decide themselves for processes",
        "Kann selbst über Prozesse entscheiden",
    ],
    [Keys.VDLANG_ACL_MEASURE_PERMISSION_CAN_DECIDE_PROCESS_ON_OWN_HINT]: [
        "This additional permission allows the user to decide for their responsible processes.",
        "Diese zusätzliche Berechtigung ermöglicht es Benutzern, für ihre verantwortlichen Prozesse zu entscheiden",
    ],
    [Keys.VDLANG_ACL_MEASURE_PERMISSION_CAN_BE_RESPONSIBLE_FOR_PROCESSES]: [
        "Can be responsible for processes",
        "Kann für Prozesse verantwortlich sein",
    ],
    [Keys.VDLANG_ACL_MEASURE_PERMISSION_CAN_BE_RESPONSIBLE_FOR_PROCESSES_HINT]: [
        "This additional permission enables a user to be responsible for processes.",
        "Diese zusätzliche Berechtigung ermöglicht es einem Benutzer für Prozesse verantwortlich zu sein.",
    ],

    [Keys.VDLANG_DASHBOARDS_WATERFALL_CHART_WIDGET_TITLE]: ["Waterfall chart", "Wasserfalldiagramm"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CHART_WIDGET_DESCRIPTION]: [
        "Displays the potential as a waterfall chart with configurable attributes.",
        "Zeigt das Potential in einem Wasserfalldiagramm mit konfigurierbaren Attributen.",
    ],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_GENERAL]: ["General", "Allgemein"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_BLOCKS]: ["Field configuration", "Feldkonfiguration"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_SCOPE]: ["Scope", "Auswahlbereich"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_TAB_FILTER]: ["Filter", "Filter"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_NAME]: ["Name", "Name"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_DESCRIPTION]: ["Description", "Beschreibung"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_AXIS_MIN_VALUE]: ["Custom minimum axis value", "Benutzerdefinierter minimaler Achsenwert"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_AXIS_MAX_VALUE]: ["Custom maximum axis value", "Benutzerdefinierter maximaler Achsenwert"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_SHOW_SUMS]: ["Show sums for bars", "Summen für Balken anzeigen"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_INITIAL_NAME]: ["Initial block name", "Initialer Blockname"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_RESULT_PLACEHOLDER]: ["Name result...", "Ergebnis benennen..."],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_BLOCK_NAME]: ["Block name", "Blockname"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_BLOCK_NAME_DEFAULT]: ["New block", "Neuer Block"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_ATTRIBUTE]: ["Attribute", "Attribut"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_FIELD_VALUES]: ["Field values", "Feldwerte"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_BLOCKS]: ["Waterfall blocks", "Wasserfallblöcke"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_CREATE_BLOCK]: ["Create block", "Block anlegen"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_DELETE_BLOCK]: ["Delete block", "Block löschen"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_SIGN]: ["Sign", "Vorzeichen"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_SIGN_PLUS]: ["Plus (+)", "Plus (+)"],
    [Keys.VDLANG_DASHBOARDS_WATERFALL_CONFIG_SIGN_MINUS]: ["Minus (-)", "Minus (-)"],

    [Keys.VDLANG_GATE_TASK_COLUMNS]: {
        [MeasureGateTaskFieldNames.StartDate]: ["Start date - {{levelName}}", "Startdatum - {{levelName}}"],
        [MeasureGateTaskFieldNames.DueDate]: ["Due date - {{levelName}}", "Fälligkeitsdatum - {{levelName}}"],
        [MeasureGateTaskFieldNames.CompletedAt]: ["Completed at - {{levelName}}", "Abschlussdatum - {{levelName}}"],
    },
    [Keys.VDLANG_GATE_TASK_COLUMN_NOT_FOUND]: ["Invalid field", "Ungültiges Feld"],

    [Keys.VDLANG_UNKNOWN_GROUP]: ["Unknown group", "Unbekannte Gruppe"],

    [Keys.VDLANG_EFFECTS_REWORK_HINT_DESCRIPTION]: [
        "Simplification of entering effects. The period of effectiveness is now selected in monthly granularity. More information in the",
        "Vereinfachung der Effekterfassung. Der Zeitraum der Wirksamkeit wird nun in monatlicher Granularität ausgewählt. Weitere Informationen im",
    ],
    [Keys.VDLANG_EFFECTS_REWORK_HINT_LINK_TEXT]: ["Knowledge Center", "Knowledge Center"],
    [Keys.VDLANG_EFFECTS_REWORK_HINT_LINK_URL]: [
        "https://knowledge.valuedesk.de/en/knowledge/entering-effects-monthly-granularity",
        "https://knowledge.valuedesk.de/knowledge/effekterfassung-mit-monatsauswahl",
    ],

    [Keys.VDLANG_NOTIFICATION_BANNER_CONFIRMATION]: ["Understood", "Verstanden"],
};

export { Language } from "api-shared";

export const languages = [Language.EN, Language.DE];
export const defaultLanguage = Language.EN;
export const isLanguage = (value: unknown): value is Language => typeof value === "string" && languages.includes(value as Language);
