import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FieldDefinitionsDto, FilterDefinition, FilterDto, FilterListDto } from "api-shared";
import { apiDelete, apiGet, apiPatch, apiPost } from "../lib/api";
import { SearchConfigQueryKeys } from "./search-config";
import { UiStateQueryKeys } from "./ui-state.ts";

export interface CreateFilterInputDto {
    name: string;
    definition: FilterDefinition;
}

interface UpdateFilterInputDto {
    id: number;
    name?: string;
    definition?: FilterDefinition;
    isActive?: boolean;
}

const FILTERS_PATH = "filters";

export const FiltersKeys = {
    filters: [FILTERS_PATH] as const,
    measureFields: [FILTERS_PATH, "measureFields"] as const,
    ideaFields: [FILTERS_PATH, "ideaFields"] as const,
};

export const useFiltersQuery = () => {
    return useQuery({
        queryKey: FiltersKeys.filters,
        queryFn: ({ signal }) => apiGet<FilterListDto>(FILTERS_PATH, { signal }),
    });
};

export const useCreateFilterMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data: CreateFilterInputDto) => apiPost<FilterDto>(FILTERS_PATH, data),
        onSuccess: (response) => {
            queryClient.invalidateQueries(FiltersKeys.filters);
        },
    });
};

export const useUpdateFilterMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ id, ...restProps }: UpdateFilterInputDto) => apiPatch<FilterDto>(`${FILTERS_PATH}/${id}`, restProps),
        onSuccess: (response) => {
            queryClient.invalidateQueries(FiltersKeys.filters);
        },
    });
};

export const useDeleteFilterMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (id: number) => apiDelete(`${FILTERS_PATH}/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries(FiltersKeys.filters);
            queryClient.invalidateQueries(SearchConfigQueryKeys.all());
            queryClient.invalidateQueries(UiStateQueryKeys.all());
        },
    });
};

export const useMeasureFieldDefinitionsQuery = (enabled = true) => {
    return useQuery({
        queryKey: FiltersKeys.measureFields,
        queryFn: ({ signal }) => apiGet<FieldDefinitionsDto>(`${FILTERS_PATH}/fields/measure`, { signal }),
        enabled,
    });
};

export const useIdeaFieldDefinitionsQuery = (enabled = true) => {
    return useQuery({
        queryKey: FiltersKeys.ideaFields,
        queryFn: ({ signal }) => apiGet<FieldDefinitionsDto>(`${FILTERS_PATH}/fields/idea`, { signal }),
        enabled,
    });
};
