import { CreateSupplierRequestBody } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import SupplierForm, { SupplierFormData } from "./SupplierForm";

interface CreateSupplierDialogProps {
    open: boolean;
    onClose: () => void;
    createSupplier: (payload: CreateSupplierRequestBody) => void;
}
const newSupplier: SupplierFormData = {
    name: "",
    supplierNumber: "",
};

const CreateSupplierDialog = ({ open, onClose, createSupplier }: CreateSupplierDialogProps) => {
    const [supplier, setSupplier] = useState<SupplierFormData>(newSupplier);
    const [isValid, setIsValid] = useState(false);

    const { t: translate } = useTranslation();

    const updateSupplier = (changes: Partial<SupplierFormData>, isValid: boolean) => {
        setIsValid(isValid);
        setSupplier((prev) => ({ ...prev, ...changes }));
    };

    const handleClose = () => {
        setIsValid(false);
        setSupplier({ ...newSupplier });
        onClose();
    };

    const saveAndClose = () => {
        if (!isValid) {
            return;
        }
        const payload = {
            ...supplier,
        };
        createSupplier(payload);
        handleClose();
    };

    return (
        <ActionItemDialog
            open={open}
            onClose={handleClose}
            action="create"
            item="partner"
            primary="create_partner_submit"
            primaryDisabled={!isValid}
            onPrimary={saveAndClose}
            translate={translate}
        >
            <SupplierForm supplier={supplier} updateSupplier={updateSupplier} onSubmit={saveAndClose} />
        </ActionItemDialog>
    );
};

export default CreateSupplierDialog;
