import { Alert, Card, CardActions, CardContent, CardHeader, Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../components/Form";
import LoadingButton from "../../components/LoadingButton";
import CopieableTextField from "../../components/input/CopieableTextField";
import Select from "../../components/input/select/Select";
import { useApiTokenMeta, useGenerateApiToken } from "../../domain/users";
import useTimezone from "../../hooks/useTimezone";
import { translationKeys } from "../../translations/main-translations";

const periodOptions = [1, 3, 5, 7, 10, 30, 90, 180, 360].map((p) => ({ label: `${p}`, value: p }));

const Integrations = () => {
    const { t: translate } = useTranslation();

    const { formatDateTime } = useTimezone();

    const [selectedPeriod, setSelectedPeriod] = useState(periodOptions.find(({ value }) => value === 5));

    const generateApiTokenMutation = useGenerateApiToken();

    const getMetaApiToken = useApiTokenMeta();

    const submit = () => {
        if (selectedPeriod == null) {
            return;
        }
        generateApiTokenMutation.mutate(selectedPeriod.value);
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={7}>
                <Card>
                    <CardHeader title={translate(translationKeys.VDLANG_INTEGRATIONS_TITLE)} />
                    <CardContent>
                        <Alert severity="warning">{translate(translationKeys.VDLANG_INTEGRATIONS_RENEW_INFO)}</Alert>
                        {getMetaApiToken.isSuccess && getMetaApiToken.data.expiresAt != null ? (
                            <Alert severity="info" sx={{ mt: 2 }}>
                                {`${translate(translationKeys.VDLANG_INTEGRATIONS_EXISTS_EXPIRES_AT)} ${formatDateTime(
                                    getMetaApiToken.data.expiresAt,
                                )}.`}
                            </Alert>
                        ) : null}
                        <Form onSubmit={submit}>
                            <Select
                                label={translate("days")}
                                options={periodOptions}
                                value={selectedPeriod}
                                onChange={(newValue) => newValue != null && setSelectedPeriod(newValue)}
                                menuPortalTarget={document.body}
                            />
                            {generateApiTokenMutation.isSuccess ? (
                                <CopieableTextField
                                    label={translate(translationKeys.VDLANG_INTEGRATIONS_TOKEN)}
                                    value={generateApiTokenMutation.data?.token}
                                    fullWidth
                                />
                            ) : null}
                        </Form>
                    </CardContent>
                    <CardActions>
                        <LoadingButton isLoading={generateApiTokenMutation.isLoading} variant="contained" onClick={submit}>
                            {translate(translationKeys.VDLANG_INTEGRATIONS_RENEW_TOKEN)}
                        </LoadingButton>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Integrations;
