import { Button, Card, CardActions, CardContent, CardHeader, FormControlLabel, FormLabel, Grid, Switch } from "@mui/material";
import { AclNamespaces, IdeaNotificationsTypes, isIdeaNotificationKey } from "api-shared";
import { flatMapDeep, isString } from "lodash";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../components/Form";
import { useUserHasAccessPermissionQuery } from "../../domain/permissions";
import { useUpdateUserNotifications } from "../../domain/users";
import { translationKeys } from "../../translations/main-translations";

const ideaNotificationGroup = "idea_change_notifications";

const notificationGroups = {
    process_change_notifications: [
        "measure_discarded",
        "measure_closed",
        "gate_task_completed",
        "gate_task_assigned_completed",
        "gate_task_record_decision",
        "measure_user_assigned",
    ],
    [ideaNotificationGroup]: [
        IdeaNotificationsTypes.IDEA_UPDATED,
        IdeaNotificationsTypes.IDEA_DISCARDED,
        IdeaNotificationsTypes.IDEA_CONVERTED,
    ],
    process_permission_notifications: [
        "measure_permission_changed",
        "measure_permission_removed",
        "gate_task_user_assigned",
        "gate_task_user_unassigned",
        "gate_task_startdate_changed",
        "gate_task_duedate_changed",
        "gate_task_reminder",
    ],
    subtasks: [
        "sub_task_user_assigned",
        "sub_task_user_unassigned",
        "sub_task_duedate_changed",
        "sub_task_completed",
        "sub_task_removed",
        "activity_reminder",
    ],
    comment_notifications: [
        "comment",
        "comment_reply",
        "sub_task_comment",
        "sub_task_comment_reply",
        IdeaNotificationsTypes.IDEA_COMMENT,
        IdeaNotificationsTypes.IDEA_COMMENT_REPLY,
        "feed_comment_reply",
    ],
    misc_notifications: ["mentions"],
};
const flatNotifications = flatMapDeep(notificationGroups, (value) => value);

const parseStringArray = (value: unknown): string[] => {
    if (typeof value !== "string") {
        return [];
    }
    const parsedValue = JSON.parse(value);

    if (!Array.isArray(parsedValue)) {
        return [];
    }

    if (!parsedValue.every(isString)) {
        return [];
    }

    // validate and return
    return parsedValue.filter((value) => flatNotifications.includes(value));
};

interface NotificationsSettingsProps {
    enabledNotifications: string | null | undefined;
}

const NotificationsSettings = ({ enabledNotifications: enabledNotificationsProps }: NotificationsSettingsProps) => {
    const updateUserNotificationsMutation = useUpdateUserNotifications();
    const hasIdeaAccessPermissionQuery = useUserHasAccessPermissionQuery(AclNamespaces.Idea);
    const hasFeedAccessPermissionQuery = useUserHasAccessPermissionQuery(AclNamespaces.Feed);

    const { t: translate } = useTranslation();

    const inputNotifications = parseStringArray(enabledNotificationsProps);

    const [enabledNotifications, setEnabledNotifications] = useState(inputNotifications);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, value: name } = event.target;
        setEnabledNotifications((prev) => (checked ? [...prev, name] : prev.filter((e) => e !== name)));
    };

    const saveChanges = () => updateUserNotificationsMutation.mutate(enabledNotifications);

    const hasChanges =
        inputNotifications.length !== enabledNotifications.length || !inputNotifications.every((x) => enabledNotifications.includes(x));

    if (!hasIdeaAccessPermissionQuery.isSuccess || !hasFeedAccessPermissionQuery.isSuccess) {
        return null;
    }

    const hasIdeaPermission = hasIdeaAccessPermissionQuery.data.hasPermission;
    const hasFeedPermission = hasFeedAccessPermissionQuery.data.hasPermission;

    return (
        <Grid container spacing={2} justifyContent="center" data-testid="notifications-settings">
            <Grid item xs={12} md={7}>
                <Card>
                    <CardHeader
                        title={translate("notifications")}
                        subheader={translate("notificationsHint")}
                        subheaderTypographyProps={{ variant: "body2" }}
                    />
                    <CardContent>
                        <Form onSubmit={saveChanges}>
                            <Grid container>
                                {Object.entries(notificationGroups)
                                    .filter(([group]) => group != ideaNotificationGroup || hasIdeaPermission)
                                    .map(([group, values]) => (
                                        <Fragment key={group}>
                                            <Grid item xs={12} sx={{ mt: 1 }}>
                                                <FormLabel>{translate(group)}</FormLabel>
                                            </Grid>
                                            {values
                                                .filter((value: string) => {
                                                    if (isIdeaNotificationKey(value)) {
                                                        return hasIdeaPermission;
                                                    }

                                                    if (value === "feed_comment_reply") {
                                                        return hasFeedPermission;
                                                    }

                                                    return true;
                                                })
                                                .map((setting: string) => (
                                                    <Grid item xs={12} key={setting}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={enabledNotifications.includes(setting)}
                                                                    onChange={handleChange}
                                                                    value={setting}
                                                                />
                                                            }
                                                            label={translate("notification_" + setting)}
                                                        />
                                                    </Grid>
                                                ))}
                                        </Fragment>
                                    ))}
                            </Grid>
                        </Form>
                    </CardContent>
                    <CardActions>
                        <Button color="primary" disabled={!hasChanges} onClick={saveChanges}>
                            {translate(translationKeys.VDLANG_SAVE)}
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    );
};
export default NotificationsSettings;
