import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Button, InputAdornment, styled, TextField } from "@mui/material";
import { FilterDto } from "api-shared";
import { TFunction } from "i18next";
import { KeyboardEvent, PropsWithChildren, useState } from "react";
import DangerButton from "../../../../components/DangerButton";
import BaseDialog from "../../../../components/dialogues/BaseDialog";
import DeleteDialog from "../../../../components/dialogues/DeleteDialog";
import DialogBackButton from "../../../../components/dialogues/DialogBackButton";
import DialogButton from "../../../../components/dialogues/DialogButton";
import ErrorTooltip from "../../../../components/ErrorTooltip";
import Tooltip from "../../../../components/Tooltip";
import useDialog from "../../../../hooks/useDialog";
import { isEnterEvent } from "../../../../lib/keybindings";
import { translationKeys } from "../../../../translations/main-translations";

interface IFilterDialogProps {
    open: boolean;
    filterId: number | null;
    filterName: string;
    isFilterValid: boolean;
    isFilterDeletable: boolean;
    onSave: () => void;
    onChange: (name: string) => void;
    onDelete: () => void;
    onClose: () => void;
    translate: TFunction;
    filters: FilterDto[];
}

const ErrorInputIcon = styled(ErrorIcon)(({ theme }) => ({
    color: theme.palette.error.main,
}));

const FilterDialog = ({
    open,
    filterId,
    filterName,
    isFilterValid,
    isFilterDeletable,
    onSave,
    onChange,
    onDelete,
    onClose,
    translate,
    children,
    filters,
}: PropsWithChildren<IFilterDialogProps>) => {
    const [isFilterNameVisibleForNewFilter, setIsFilterNameVisibleForNewFilter] = useState(false);
    const deleteDialog = useDialog();

    const isNameValid = !filters.some((fil) => fil.name === filterName && fil.id !== filterId);

    const onKeyDown = (event: KeyboardEvent) => isEnterEvent(event) && isFilterValid && isNameValid && onSave();

    const renderSaveButton = (title: string, skipTranslation = false) => (
        <DialogButton
            name={title}
            key={title}
            onClick={onSave}
            onClose={onClose}
            disabled={!isFilterValid || !isNameValid}
            translate={skipTranslation ? undefined : translate}
            variant="contained"
            role="submit"
        />
    );

    const renderFilterNameField = () => (
        <TextField
            key="name-input"
            name="filter-name"
            placeholder={translate("Please enter a name")}
            value={filterName}
            error={!isNameValid}
            onChange={(event) => onChange(event.target.value)}
            onKeyDown={onKeyDown}
            InputProps={{
                endAdornment: !isNameValid && (
                    <InputAdornment position="end">
                        <ErrorTooltip title={translate(translationKeys.VDLANG_FILTER_DIALOG_FILTER_NAME_EXISTS)}>
                            <ErrorInputIcon />
                        </ErrorTooltip>
                    </InputAdornment>
                ),
            }}
        />
    );

    const handleDeleteFilter = () => {
        onDelete();
        onClose();
    };

    const isFilterSelected = filterId != null;

    const actions = [<DialogBackButton key="cancel" translate={translate} onClose={onClose} />];

    if (isFilterSelected) {
        actions.push(
            renderFilterNameField(),
            <Tooltip key="delete" title={!isFilterDeletable ? translate(translationKeys.VDLANG_FILTER_DIALOG_FILTER_IN_USE) : undefined}>
                <span>
                    <DangerButton soft key="delete" onClick={deleteDialog.open} disabled={!isFilterDeletable}>
                        {translate(translationKeys.VDLANG_FILTER_DIALOG_REMOVE)}
                    </DangerButton>
                </span>
            </Tooltip>,
            renderSaveButton(translationKeys.VDLANG_SAVE),
        );
    }

    if (!isFilterSelected && !isFilterNameVisibleForNewFilter) {
        actions.push(
            <Button key="saveAs" onClick={() => setIsFilterNameVisibleForNewFilter(true)}>
                {translate(translationKeys.VDLANG_FILTER_DIALOG_SAVE_AS)}...
            </Button>,
            renderSaveButton(translationKeys.VDLANG_FILTER_DIALOG_APPLY),
        );
    }

    if (!isFilterSelected && isFilterNameVisibleForNewFilter) {
        actions.push(
            renderFilterNameField(),
            renderSaveButton(translate(translationKeys.VDLANG_SAVE) + " & " + translate(translationKeys.VDLANG_FILTER_DIALOG_APPLY), true),
        );
    }

    return (
        <BaseDialog
            open={open}
            onClose={onClose}
            title={translate(translationKeys.VDLANG_FILTER_CUSTOM_FILTER)}
            actions={actions}
            maxWidth="md"
            scroll="paper"
        >
            <DeleteDialog
                item="filter"
                open={deleteDialog.isOpen}
                onClose={deleteDialog.close}
                onDelete={handleDeleteFilter}
                translate={translate}
            />
            {children}
        </BaseDialog>
    );
};

export default FilterDialog;
