import { Link, styled } from "@mui/material";
import { Fragment, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import BannerLayout from "../../components/BannerLayout";
import { useUiState } from "../../domain/ui-state";
import { translationKeys } from "../../translations/main-translations";
import Navbar from "./Navbar";
import NotificationBanner from "./NotificationBanner";
import { trackEvent } from "../../infrastructure/tracking";

const Container = styled("div")(({ theme }) => ({
    height: `calc(100vh - ${theme.spacing(8)})`,
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(7.5),
        height: `calc(100vh - ${theme.spacing(7.5)})`,
    },
}));

const KnowledgeCenterLink = styled(Link)(({ theme }) => ({
    color: "inherit",
    textDecoration: "underline",
    cursor: "pointer",
    marginLeft: theme.spacing(0.5),
}));

const NavbarDecorator = ({ children }: PropsWithChildren<unknown>) => {
    const [uiState, updateUiState] = useUiState();
    const { t } = useTranslation();

    function handleCloseHint() {
        trackEvent({ category: "Notification Banner", action: "Notification Banner Dismissed" });

        updateUiState({ hasDismissedEffectsReworkHint: true });
    }

    return (
        <Fragment>
            <Navbar />
            <Container>
                {uiState.hasDismissedEffectsReworkHint ? (
                    children
                ) : (
                    <BannerLayout
                        banner={
                            <NotificationBanner handleClose={handleCloseHint} translate={t}>
                                {t(translationKeys.VDLANG_EFFECTS_REWORK_HINT_DESCRIPTION)}
                                <KnowledgeCenterLink
                                    onClick={() => trackEvent({ category: "Notification Banner", action: "Knowledge Center Link Clicked" })}
                                    href={t(translationKeys.VDLANG_EFFECTS_REWORK_HINT_LINK_URL)}
                                    target="_blank"
                                >
                                    {t(translationKeys.VDLANG_EFFECTS_REWORK_HINT_LINK_TEXT)}
                                </KnowledgeCenterLink>
                                .
                            </NotificationBanner>
                        }
                    >
                        {children}
                    </BannerLayout>
                )}
            </Container>
        </Fragment>
    );
};

export default NavbarDecorator;
