import { Box, Grid, Typography, styled } from "@mui/material";
import { AclNamespaces, AclPermissions, UserDto } from "api-shared";
import { TFunction } from "i18next";
import { Location } from "react-router-dom";
import { useClientFeatures } from "../../domain/client";
import { useInitialAccessPermissionsQueries, useUserHasPermissionQuery } from "../../domain/permissions";
import { getDesktopNavItems } from "../../lib/routes";
import NavLinkButton from "./NavLinkButton";
import NavbarHelp from "./NavbarHelp";
import NavbarSearch from "./NavbarSearch";
import NavbarUserButton from "./NavbarUserButton";
import PlusButton from "./PlusButton";

const ButtonContainer = styled(Grid)(({ theme }) => ({
    overflow: "hidden",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("lg")]: {
        paddingLeft: theme.spacing(),
        paddingRight: theme.spacing(),
    },
}));

interface IDesktopNavbarContentProps {
    location: Location;
    disabled?: boolean;
    translate: TFunction;
    currentUser: UserDto | null;
    searchKey: string;
    updateSearchKey: (newKey: string) => void;
}

const DesktopNavbarContent = ({ location, currentUser, translate, disabled, searchKey, updateSearchKey }: IDesktopNavbarContentProps) => {
    const features = useClientFeatures();

    const canCreateProcessQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.Process,
        permission: AclPermissions.Create,
        fact: {},
    });
    const accessPermissionQueries = useInitialAccessPermissionsQueries();

    if (!canCreateProcessQuery.isSuccess || !accessPermissionQueries.isSuccess) {
        return null;
    }

    const items = getDesktopNavItems(features, {
        hasIdeaAccess: accessPermissionQueries.hasIdeaAccessQuery.data.hasPermission,
        hasActivityAccess: accessPermissionQueries.hasActivityAccessQuery.data.hasPermission,
        hasFeedAccess: accessPermissionQueries.hasFeedAccessQuery.data.hasPermission,
        hasDashboardAccess: accessPermissionQueries.hasDashboardAccessQuery.data.hasPermission,
        hasProcessAccess: accessPermissionQueries.hasProcessAccessQuery.data.hasPermission,
        hasMethodAccess: accessPermissionQueries.hasMethodAccessQuery.data.hasPermission,
    });

    const hasCreatePermission = canCreateProcessQuery.data.hasPermission || accessPermissionQueries.hasIdeaAccessQuery.data.hasPermission;

    return (
        <>
            <ButtonContainer item>
                {items.map((item) => (
                    <NavLinkButton key={item.route} disabled={disabled} to={item.route}>
                        {translate(item.label)}
                    </NavLinkButton>
                ))}
            </ButtonContainer>
            {hasCreatePermission ? (
                <Grid item>
                    <PlusButton disabled={disabled} data-testid="navbar_add" />
                </Grid>
            ) : null}
            {window.location.hostname === "freeze.valuedesk.de" ? (
                <Grid item>
                    <Typography variant="h6" color="red" paddingLeft={2}>
                        ❄️ FREEZE ENVIRONMENT - CHANGES WILL BE LOST ❄️
                    </Typography>
                </Grid>
            ) : null}
            <Box flexGrow={1} />
            {!disabled && (
                <Grid item>
                    <NavbarSearch searchKey={searchKey} updateSearchKey={updateSearchKey} translate={translate} />
                </Grid>
            )}
            <Grid item>
                <NavbarHelp />
            </Grid>
            <Grid item>
                <NavbarUserButton user={currentUser} location={location} translate={translate} />
            </Grid>
        </>
    );
};

export default DesktopNavbarContent;
