import CloseRounded from "@mui/icons-material/CloseRounded";
import {
    Collapse,
    collapseClasses,
    FormControlLabel,
    IconButton,
    List,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    styled,
    Switch,
} from "@mui/material";
import {
    AclNamespaces,
    AclPermissions,
    Sort,
    SubTaskGroupBy,
    SubTaskGroupOrderBy,
    SubTaskOrderBy,
    SubTaskStatusFilter,
    UiStateDto,
} from "api-shared";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import OverlineLabel from "../../../components/OverlineLabel";
import SidebarPreferences from "../../../components/sidebar/SidebarPreferences";
import SidebarSelect from "../../../components/sidebar/SidebarSelect";
import SidebarTitleItem from "../../../components/sidebar/SidebarTitleItem";
import { useMeasureAttributes } from "../../../domain/endpoint";
import { useFiltersQuery } from "../../../domain/filters";
import { useUserHasPermissionQuery } from "../../../domain/permissions";
import { useUiState } from "../../../domain/ui-state";
import { useCurrentUserId } from "../../../domain/users";
import { useIsMobile } from "../../../lib/mobile";
import { translationKeys } from "../../../translations/main-translations";
import FilterSelect from "../../measures/preferences/filter-configuration/FilterSelect";
import ActivitiesGroupBySelect from "./ActivitiesGroupBySelect";
import ActivitiesGroupOrderSelect, { ACTIVITIES_GROUP_ORDER_DEPENDENCIES } from "./ActivitiesGroupOrderSelect";
import ActivitiesOrderSelect from "./ActivitiesOrderSelect";
import ActivitiesShowSelect, { ActivityShowSelectValue } from "./ActivitiesShowSelect";
import ActivitiesStatusSelect from "./ActivitiesStatusSelect";
import ActivitiesUserSelect from "./ActivitiesUserSelect";

const GroupingFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    marginLeft: 0,
}));

const GroupLabelContainer = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(4.5),
}));

const ListContainer = styled(Stack)(({ theme }) => ({
    height: "100%",
    paddingTop: theme.spacing(3),
}));

const FullWidthCollapse = styled(Collapse)(({ theme }) => ({
    [`& .${collapseClasses.wrapper}, & .${collapseClasses.wrapperInner}`]: {
        width: "100%",
    },
    width: "100%",
}));

export interface IActivitiesPreferencesProps {
    setIsOpen: (open: boolean) => void;
}

const ActivitiesPreferences = ({ setIsOpen }: IActivitiesPreferencesProps) => {
    const { t: translate } = useTranslation();

    const [uiState, updateUiState] = useUiState();
    const filters = useFiltersQuery();
    const measureAttributes = useMeasureAttributes();

    const hasPersonFilterPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.User,
        permission: AclPermissions.Filter,
        fact: {},
    });
    const currentUserId = useCurrentUserId();

    const saveChanges = useCallback(
        (changes: Partial<UiStateDto>) => {
            const sanitizedChanges = { ...changes };
            const newGroupBy = changes.activityGroupBy ?? uiState.activityGroupBy;
            const newGroupOrderBy = changes.activityGroupOrderBy ?? uiState.activityGroupOrderBy;
            const availableGroupOrderByOptions = ACTIVITIES_GROUP_ORDER_DEPENDENCIES[newGroupBy];
            if (!availableGroupOrderByOptions.includes(newGroupOrderBy)) {
                // activityGroupBy has changed and the selected groupOrderBy is not available anymore. Fallback to an available value
                sanitizedChanges.activityGroupOrderBy = availableGroupOrderByOptions[0];
            }

            updateUiState(sanitizedChanges);
        },
        [uiState.activityGroupBy, uiState.activityGroupOrderBy, updateUiState],
    );

    const updateShowActivities = (newValues: ActivityShowSelectValue | null) =>
        newValues != null && saveChanges({ activityAssignedToIds: newValues.assignedToIds, activityCreatedByIds: newValues.createdByIds });

    const updateStatus = (activityStatus: SubTaskStatusFilter | null) => activityStatus != null && saveChanges({ activityStatus });

    const updateOrder = (activityOrderBy: SubTaskOrderBy | null, activitySort: Sort | null) =>
        activityOrderBy != null && activitySort != null && saveChanges({ activityOrderBy, activitySort });

    const updateFilter = (activityFilterId: number | null) => activityFilterId != null && saveChanges({ activityFilterId });

    const updateGroupBy = (activityGroupBy: SubTaskGroupBy | null) => activityGroupBy != null && saveChanges({ activityGroupBy });

    const updateGroupOrder = (activityGroupOrderBy: SubTaskGroupOrderBy | null, activityGroupSort: Sort | null) =>
        activityGroupOrderBy != null && activityGroupSort != null && saveChanges({ activityGroupOrderBy, activityGroupSort });

    const updateGrouping = (e: React.ChangeEvent<HTMLInputElement>) => saveChanges({ activityGrouping: e.target.checked });

    const updateAssignedToIds = (activityAssignedToIds: number[] | null) =>
        activityAssignedToIds != null && saveChanges({ activityAssignedToIds });

    const updateCreatedByIds = (activityCreatedByIds: number[] | null) =>
        activityCreatedByIds != null && saveChanges({ activityCreatedByIds });

    const isMobile = useIsMobile();

    if (!hasPersonFilterPermissionQuery.isSuccess) {
        return null;
    }

    const canFilterByPerson = hasPersonFilterPermissionQuery.data.hasPermission;

    return (
        <SidebarPreferences
            header={
                <List disablePadding={true}>
                    <SidebarTitleItem>
                        {translate(translationKeys.VDLANG_MY_ACTIVITIES_TITLE)}
                        {isMobile ? (
                            <ListItemSecondaryAction>
                                <IconButton edge="end">
                                    <CloseRounded onClick={() => setIsOpen(false)} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        ) : null}
                    </SidebarTitleItem>
                </List>
            }
        >
            <ListContainer>
                {canFilterByPerson ? (
                    <>
                        <OverlineLabel>{translate(translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_LABEL)}</OverlineLabel>
                        <ActivitiesUserSelect
                            value={uiState.activityAssignedToIds}
                            translate={translate}
                            onChange={(newValues) => updateAssignedToIds([...newValues])}
                            label={translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_ASSIGNED_TO_TITLE}
                            hasUnassignedUser={true}
                        />
                        <ActivitiesUserSelect
                            value={uiState.activityCreatedByIds}
                            translate={translate}
                            onChange={(newValues) => updateCreatedByIds([...newValues])}
                            label={translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_SHOW_CREATED_BY_TITLE}
                            hasUnassignedUser={false}
                        />
                    </>
                ) : currentUserId !== null ? (
                    <ActivitiesShowSelect
                        component={SidebarSelect}
                        currentUserId={currentUserId}
                        value={{ assignedToIds: uiState.activityAssignedToIds, createdByIds: uiState.activityCreatedByIds }}
                        translate={translate}
                        onChange={updateShowActivities}
                    />
                ) : null}
                <ActivitiesStatusSelect
                    component={SidebarSelect}
                    value={uiState.activityStatus}
                    onChange={updateStatus}
                    translate={translate}
                />
                <ActivitiesOrderSelect
                    component={SidebarSelect}
                    order={uiState.activitySort}
                    value={uiState.activityOrderBy}
                    onChange={updateOrder}
                    translate={translate}
                />
                <FilterSelect
                    value={uiState.activityFilterId}
                    filters={filters?.data ?? []}
                    updateSelectedFilter={updateFilter}
                    translate={translate}
                    measureAttributes={measureAttributes}
                    label={translate(translationKeys.VDLANG_ACTIVITIES_PROCESS_FILTER_FILTERS)}
                />
                <GroupLabelContainer>
                    <GroupingFormControlLabel
                        control={<Switch onChange={updateGrouping} checked={uiState.activityGrouping} />}
                        labelPlacement="start"
                        label={
                            <ListItemText
                                primary={translate(translationKeys.VDLANG_MY_ACTIVITIES_PREFERENCES_GROUPING_TITLE)}
                                primaryTypographyProps={{ variant: "h6" }}
                            />
                        }
                    />
                </GroupLabelContainer>
                <FullWidthCollapse in={uiState.activityGrouping}>
                    <Stack spacing={1}>
                        <ActivitiesGroupBySelect
                            value={uiState.activityGroupBy}
                            onChange={updateGroupBy}
                            translate={translate}
                            canFilterByPerson={canFilterByPerson}
                        />
                        <ActivitiesGroupOrderSelect
                            parentValue={uiState.activityGroupBy}
                            value={uiState.activityGroupOrderBy}
                            order={uiState.activityGroupSort}
                            onChange={updateGroupOrder}
                            translate={translate}
                        />
                    </Stack>
                </FullWidthCollapse>
            </ListContainer>
        </SidebarPreferences>
    );
};

export default ActivitiesPreferences;
