import { styled, tooltipClasses } from "@mui/material";
import React from "react";
import Tooltip, { ITooltipProps } from "./Tooltip";

// Tooltip content is portaled out, so nested css would not work
// attach className to the root portal element (popper), so that tooltip can be styled from there
const StylableTooltip = React.forwardRef<HTMLDivElement, ITooltipProps>(({ className, ...props }: ITooltipProps, ref) => (
    <Tooltip {...props} arrow classes={{ popper: className }} ref={ref} />
));

const ErrorTooltip = styled(StylableTooltip)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
}));

export default ErrorTooltip;
