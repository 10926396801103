import { AclNamespaces, AclPermissions, DEFAULT_FILTER_ID } from "api-shared";
import { useMemo } from "react";
import SubtaskMasterDetail, { ISubtaskMasterDetailProps } from "../../components/tasks/SubtaskMasterDetail";
import { useFiltersQuery } from "../../domain/filters";
import { useUserHasPermissionQuery } from "../../domain/permissions";
import { useSubTasks } from "../../domain/subtasks";
import { useUiState } from "../../domain/ui-state";
import { useAllUsers, useCurrentUserId } from "../../domain/users";
import { useDebounce } from "../../hooks/useDebounce";
import { filterAssignedCreatedIds } from "../../lib/subtask";

const ActivitiesViewContent = (props: Omit<ISubtaskMasterDetailProps, "tasks" | "users">) => {
    const [
        {
            activityGroupBy,
            activityGroupOrderBy,
            activityGroupSort,
            activityGrouping,
            activityOrderBy,
            activitySort,
            activityStatus,
            activityCreatedByIds,
            activityAssignedToIds,
            activityFilterId,
        },
    ] = useUiState();
    const users = useAllUsers();
    const hasPersonFilterPermissionQuery = useUserHasPermissionQuery({
        namespace: AclNamespaces.User,
        permission: AclPermissions.Filter,
        fact: {},
    });
    const currentUserId = useCurrentUserId();

    const [filteredAssignedToIds, filteredCreatedByIds] =
        hasPersonFilterPermissionQuery.isSuccess && hasPersonFilterPermissionQuery.data.hasPermission
            ? [activityAssignedToIds, activityCreatedByIds]
            : filterAssignedCreatedIds(currentUserId, activityAssignedToIds, activityCreatedByIds);

    // Generate a stable reference, that can be consumed by useDebounce
    const queryOptions = useMemo(
        () => ({
            grouping: activityGrouping,
            orderBy: activityOrderBy,
            sort: activitySort,
            status: activityStatus,
            groupBy: activityGroupBy,
            groupOrderBy: activityGroupOrderBy,
            groupSort: activityGroupSort,
            createdByIds: filteredCreatedByIds,
            assignedToIds: filteredAssignedToIds,
            filterId: activityFilterId ?? DEFAULT_FILTER_ID,
        }),
        [
            activityFilterId,
            activityGroupBy,
            activityGroupOrderBy,
            activityGroupSort,
            activityGrouping,
            activityOrderBy,
            activitySort,
            activityStatus,
            filteredAssignedToIds,
            filteredCreatedByIds,
        ],
    );

    const debouncedQueryOptions = useDebounce(queryOptions, 500);
    const filters = useFiltersQuery();
    const filterDefinition = filters.data?.find((filter) => filter.id === debouncedQueryOptions.filterId)?.definition;

    const subTaskQuery = useSubTasks({
        filter: {
            ...debouncedQueryOptions,
            filterDefinition: JSON.stringify(filterDefinition),
        },
        filterDefinition,
    });

    return (
        <SubtaskMasterDetail
            isFetching={!subTaskQuery.isSuccess}
            users={users}
            tasks={subTaskQuery.data?.tasks ?? []}
            groups={subTaskQuery.data?.groups}
            {...props}
        />
    );
};

export default ActivitiesViewContent;
